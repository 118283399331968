export const JWT_TOKEN = 'jwt_token';
export const VAT_OPTIONS = [
  { label: '0.0%', value: 0 },
  { label: '2.1%', value: 0.021 },
  { label: '5.5%', value: 0.055 },
  { label: '8.5%', value: 0.085 },
  { label: '10%', value: 0.1 },
  { label: '20%', value: 0.2 }
];
export const FILTER_DAYS_OPTIONS = [
  { label: `Aujourd'hui`, value: 'currentDay' },
  { label: 'Hier', value: 'lastDay' },
  { label: '7 derniers jours', value: '7days' },
  { label: '12 dernières semaines', value: '12weeks' },
  { label: '12 derniers mois', value: '12months' }
];
export const FILTER_MERCHANT_OPTIONS = [
  { label: 'Commerçant pour le client', value: 'customer_invoice' },
  { label: `Achil pour le commerçant`, value: 'merchant_invoice' }
];
export const Day = [
  { label: 'Lundi', value: 0 },
  { label: 'Mardi', value: 1 },
  { label: 'Mercredi', value: 2 },
  { label: 'Jeudi', value: 3 },
  { label: 'Vendredi', value: 4 },
  { label: 'Samedi', value: 5 },
  { label: 'Dimanche', value: 6 }
];
export const REGEX_ONLY_ALPHABET_SPACE = /^[a-zA-Z ]*$/;
export const REGEX_VALIDATE_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REGEX_VALIDATE_PASSWORD =
  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,25}$/;
export const REGEX_NO_SPACE_FIRST = /^[^\S]*\S/;
export const REGEX_NO_SPACE_FIRST_ALPHABET_SPACE = /^[A-Za-z]+[A-Za-z\s]*$/;
export const ASPECT_RATIO = [
  { label: 'Paysage', value: 2 / 1 },
  { label: 'Portrait', value: 9 / 16 }
];
export const UNIT_TYPE = [
  { label: 'kilogramme', value: 'kilogram' },
  { label: 'litre', value: 'lit' }
];
export const CHANNELS_PROMOTION = '/api/v2/admin/channels/default';
export const SHORT_UNIT = {
  kilogram: 'Kg',
  lit: 'L',
  liter: 'L'
} as const;
export const SPECIAL_OFFER_LABEL = 'Offre(s) spéciale(s)';
