import React from 'react';

function CreditCardIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 17.549 13.162"
      {...props}>
      <path
        id="Icon_open-credit-card"
        data-name="Icon open-credit-card"
        d="M.548,0A.543.543,0,0,0,0,.548V2.194H17.549V.548A.543.543,0,0,0,17,0ZM0,4.387v8.226a.543.543,0,0,0,.548.548H17a.543.543,0,0,0,.548-.548V4.387ZM2.194,8.774H4.387v2.194H2.194Zm4.387,0H8.774v2.194H6.581Z"
        fill="#878c97"
      />
    </svg>
  );
}

export default CreditCardIcon;
