import api from 'api/setupAxios';
import { AxiosResponse } from 'axios';
import { PRODUCT, PRODUCT_BY_CODE } from 'const/endpoint';
import { ProductOptionRequestV3 } from 'types/ProductOption';
import { ProductRes } from 'types/Products';

type GetProductOptionsParams = {
  signal?: AbortSignal;
  isOptionOrSellingAsOption: boolean;
  'merchant.id': number;
  itemsPerPage: number;
  page: number;
  'translations.name'?: string;
  isProductScreen?: boolean;
};

export const getProductOptions = ({
  signal,
  ...params
}: GetProductOptionsParams) => {
  return api.call(signal).get('/merchant/products', { params });
};

export const getProductOptionByCodeV2 = ({
  signal,
  code,
  params
}: GetProductOptionByCodeParams): Promise<AxiosResponse<ProductRes>> => {
  return api
    .call(signal)
    .get(PRODUCT_BY_CODE.replace(':code', code), { params });
};

export const createProductOptionV2 = (data: ProductOptionRequestV3) => {
  return api.call().post(PRODUCT, data);
};

export const updateProductOptionV2 = ({
  code,
  data
}: UpdateProductOptionParams) => {
  return api.call().put(PRODUCT_BY_CODE.replace(':code', code), data);
};

export const deleteProductOptionV2 = ({
  code,
  data
}: DeleteProductOptionParams) => {
  const config = {
    headers: {
      'Content-Type': 'application/merge-patch+json'
    }
  };
  return api.call().patch(PRODUCT_BY_CODE.replace(':code', code), data, config);
};

type DeleteProductOptionParams = {
  code: string;
  data: { enabled: boolean };
};
type GetProductOptionByCodeParams = {
  signal?: AbortSignal;
  code: string;
  params?: {
    [key: string]: any;
  };
};
type UpdateProductOptionParams = {
  code: string;
  data: ProductOptionRequestV3;
};
