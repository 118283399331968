import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import CatalogsForm from 'components/CatalogsLayout/CatalogsForm';
import { CatalogEnabled, IMenuHour, IOpeningHour } from 'types/Catalog';

import Loader from 'components/Loader';
import { handleSuccess, handleError } from 'utils/index';
import { UPDATE_MESSAGE } from 'const/Message';
import { getCatalogById, updateCatalog } from 'services/catalogService';
import useInvalidateQuery from 'hooks/useInvalidateQuery';

interface MatchParams {
  catalogId: string;
}

const CatalogsEdit = () => {
  const { catalogId } = useParams<MatchParams>();
  const [dataCatalog, setDataCatalog] = useState<any>();
  const history = useHistory();
  const { inValidateQueryProductInCatalog } = useInvalidateQuery();

  const { data: resultCatalog, isFetching } = useQuery(
    'getCatalogId',
    ({ signal }) => getCatalogById(catalogId, signal),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    const catalog = resultCatalog?.data;
    if (catalog) {
      const listTimeline: IMenuHour[] = [];
      if (catalog.openingHours?.length > 0) {
        catalog.openingHours.forEach((wd: IOpeningHour) => {
          const check = listTimeline.some((item: IMenuHour) => {
            return (
              item.closed_at === wd.closed_at &&
              item.started_at === wd.started_at
            );
          });
          if (!check) {
            const timelineItem: IMenuHour = {
              weekday: [String(wd.weekday)],
              closed_at: wd.closed_at,
              started_at: wd.started_at
            };
            listTimeline.push(timelineItem);
          } else {
            listTimeline.forEach((item: IMenuHour) => {
              if (
                item.started_at === wd.started_at &&
                item.closed_at === wd.closed_at
              ) {
                item.weekday.push(String(wd.weekday));
              }
            });
          }
        });
      }
      const data = {
        id: catalog.id,
        name: catalog.name,
        enabled: catalog.enabled,
        openingHours: listTimeline,
        categories: catalog.categories
      };
      setDataCatalog(data);
    }
  }, [resultCatalog]);

  const handleEdit = (data: CatalogEnabled) => {
    onUpdateCatalog(
      { id: catalogId, data: data },
      {
        onSuccess: () => {
          handleSuccess(UPDATE_MESSAGE.CATALOG_SUCCESS);
          inValidateQueryProductInCatalog();
          history.push('/menu/catalogs');
        },
        onError: (error: any) => {
          handleError(error.response.status, UPDATE_MESSAGE.CATALOG_ERROR);
        }
      }
    );
  };

  const { isLoading, mutate: onUpdateCatalog } = useMutation(
    ({ id, data }: Required<{ id: string; data: CatalogEnabled }>) =>
      updateCatalog(id, data)
  );

  return (
    <>
      {dataCatalog && (
        <CatalogsForm
          key={dataCatalog.id}
          dataEdit={dataCatalog}
          onEdit={handleEdit}
          mode="edit"
        />
      )}
      {(isLoading || isFetching) && <Loader />}
    </>
  );
};

export default CatalogsEdit;
