import React, { useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { DeliveryMapWrapper, DeliveryZoneWrapper } from './MapZoneBlock.style';
import ZoneCard from './ZoneCard';
import useDrawingZone from './useDrawingZone';
import ButtonIcon from 'components/ui-kits/ButtonIcon';
import { IconNames } from 'components/ui-kits/Icon/Icon';
import { CoordsWithKey, ZoneField, ZoneInfo } from 'types/Delivery';
import { useForm } from 'react-hook-form';
import ModalPrompt from 'components/ui-kits/ModalPrompt';
import { CONFIRM_MESSAGE } from 'const/Message';
import rfdc from 'rfdc';
import { NameForm } from 'types/general';
import { replaceCharacter } from 'utils';

const { REACT_APP_GOOGLE_API_KEY } = process.env;
interface IMapZoneBlock {
  deliveryZone: Array<ZoneInfo>;
  mutateSave(data: ZoneField): void;
  mutateDelete(id: number): void;
}

export default function MapZoneBlock({
  deliveryZone,
  mutateSave,
  mutateDelete
}: IMapZoneBlock) {
  const mapRef = useRef<any>(null);
  const [editingZoneIndex, setEditingZoneIndex] = useState(-1);
  const [isPolluted, setPolluted] = useState(false);
  const [zoneToMoveTo, setZoneToMoveTo] = useState(-1);
  const [zoneToDelete, setZoneToDelete] = useState(-1);
  const {
    zone,
    isActiveDraw,
    setMapInstance,
    setActiveDraw,
    zoneWithPolygon,
    deleteZone
  } = useDrawingZone(deliveryZone, editingZoneIndex, setPolluted);
  const [coordsCenter, setCoordsCenter] = useState([46.6, 1.89]);
  const {
    register,
    getValues,
    control,
    reset,
    setValue,
    formState: { isDirty }
  } = useForm({
    defaultValues: {
      ...zone.reduce((acc, cur) => {
        acc[cur.name] = {
          enabled: cur.enabled,
          deliFee: cur.deliveryFee / 100
        };
        return acc;
      }, {} as any)
    }
  });
  useEffect(() => {
    reset({
      ...deliveryZone.reduce((acc, cur) => {
        acc[cur.name] = {
          enabled: cur.enabled,
          deliFee: cur.deliveryFee / 100
        };
        return acc;
      }, {} as any)
    });
  }, [deliveryZone, reset]);
  const onGoogleApiLoaded = ({ map }: any) => {
    setMapInstance(map);
    mapRef.current = map;
  };
  function handleEditZone(index: number) {
    if (zone[index].coords[0]) {
      setCoordsCenter([zone[index].coords[0].lat, zone[index].coords[0].lng]);
    }
    if (editingZoneIndex === index) {
      return;
    }
    if (editingZoneIndex === -1) {
      setEditingZoneIndex(index);
      return;
    }
    if (isPolluted || isDirty) {
      setZoneToMoveTo(index);
    } else {
      setEditingZoneIndex(index);
    }
  }
  function handleClickZoneOnMap(index: number) {
    console.log(isPolluted);
    console.log(isDirty);
    if (isPolluted || isDirty) {
      return;
    }
    if (editingZoneIndex === index) {
      return;
    }
    setEditingZoneIndex(index);
  }
  function handleSavePolygon() {
    const nameZone = zone[editingZoneIndex].name;
    const id = zone[editingZoneIndex].id;
    const polygon = zoneWithPolygon[editingZoneIndex].polygon;
    mutateSave({
      name: nameZone,
      enabled: getValues(`${nameZone}.enabled` as NameForm),
      deliveryFee: Math.round(
        Number(
          replaceCharacter(
            getValues(`${nameZone}.deliFee` as NameForm),
            ',',
            '.'
          )
        ) * 100
      ),
      polygon: {
        rings: [formatCoordsFromMapToAPI(getCoordsFromPolygon(polygon))]
      },
      id
    });
    setPolluted(false);
  }
  function handleDeletePolygon() {
    const id = zone[editingZoneIndex].id;
    if (id) {
      mutateDelete(id);
    }
  }
  function handleCancelEditing() {
    setEditingZoneIndex(-1);
  }
  function getLatLngZoneFirst(listZone: ZoneInfo[]) {
    const coords: { lat: number; lng: number } = { lat: 46.6, lng: 1.89 };
    let i = 0;
    while (i < listZone.length) {
      if (listZone[i].coords.length) {
        coords['lat'] = listZone[i].coords[0].lat;
        coords['lng'] = listZone[i].coords[0].lng;
        i += listZone.length;
      }
      i++;
    }
    return coords;
  }

  useEffect(() => {
    const lat = getLatLngZoneFirst(zone)['lat'];
    const lng = getLatLngZoneFirst(zone)['lng'];
    setCoordsCenter([lat, lng]);
  }, [zone]);

  return (
    <>
      <DeliveryMapWrapper>
        <GoogleMapReact
          bootstrapURLKeys={{
            language: 'fr',
            key: REACT_APP_GOOGLE_API_KEY || '',
            libraries: ['places,drawing'].join(',')
          }}
          center={{
            lat: coordsCenter[0],
            lng: coordsCenter[1]
          }}
          yesIWantToUseGoogleMapApiInternals
          defaultZoom={9}
          onGoogleApiLoaded={onGoogleApiLoaded}
        />
        {editingZoneIndex > -1 && (
          <div className="toolbar">
            <div className="drawing-toolbar">
              <ButtonIcon
                className="tool-btn"
                variant={isActiveDraw ? 'square-grey' : 'square-white'}
                icon={IconNames.DrawPolygonIcon}
                disabled={!!zoneWithPolygon[editingZoneIndex].polygon}
                widthIcon={24}
                onClick={() => {
                  setActiveDraw(true);
                }}
              />
              <ButtonIcon
                className="tool-btn"
                variant={isActiveDraw ? 'square-white' : 'square-grey'}
                icon={IconNames.HandPaper}
                widthIcon={20}
                onClick={() => {
                  setActiveDraw(false);
                }}
              />
            </div>
          </div>
        )}
      </DeliveryMapWrapper>
      <DeliveryZoneWrapper>
        {zone.map((item, index) => (
          <ZoneCard
            key={item.name}
            zoneItem={item}
            onClickZone={() => handleEditZone(index)}
            isEdit={editingZoneIndex === index}
            polygon={zoneWithPolygon[index].polygon}
            register={register}
            control={control}
            setValue={setValue}
            getValues={getValues}
            handleClickZoneOnMap={() => handleClickZoneOnMap(index)}
            onPollute={() => {
              if (!isPolluted) {
                setPolluted(true);
              }
            }}
            onSaveZone={() => {
              handleSavePolygon();
              setEditingZoneIndex(-1);
            }}
            onDeleteZone={() => {
              setZoneToDelete(editingZoneIndex);
            }}
            onCancel={handleCancelEditing}
            mapRef={mapRef.current}
          />
        ))}
      </DeliveryZoneWrapper>
      <ModalPrompt
        isShow={zoneToMoveTo > -1}
        onConfirm={() => {
          handleSavePolygon();
          setEditingZoneIndex(zoneToMoveTo);
          setZoneToMoveTo(-1);
        }}
        onClose={() => setZoneToMoveTo(-1)}
        title={CONFIRM_MESSAGE.SAVE_ZONE}
        confirmBtnText="Enregistrer maintenant"
        cancelBtnText="Continuer la modification"
      />
      <ModalPrompt
        isShow={zoneToDelete > -1}
        onConfirm={() => {
          deleteZone(zoneToDelete);
          handleDeletePolygon();
          setEditingZoneIndex(-1);
          setZoneToDelete(-1);
        }}
        onClose={() => setZoneToDelete(-1)}
        title={CONFIRM_MESSAGE.DELETE_ZONE}
        confirmBtnText="Supprimer"
        confirmType="withTrashBin"
      />
    </>
  );
}

function getCoordsFromPolygon(polygon?: google.maps.Polygon) {
  if (polygon) {
    return polygon
      .getPath()
      .getArray()
      .map((item) => item.toJSON());
  }
  return [];
}

function formatCoordsFromMapToAPI(coords: Array<CoordsWithKey>) {
  if (!coords.length) {
    return [];
  }
  return toCoordsWithoutKey(toAddLastPoint(coords));
}

function toAddLastPoint(coords: Array<CoordsWithKey>) {
  const newCoords = rfdc({ proto: false })(coords);
  newCoords[newCoords.length] = newCoords[0];
  return newCoords;
}

function toCoordsWithoutKey(coords: Array<CoordsWithKey>): [number, number][] {
  return coords.map((item) => {
    return [item.lng, item.lat];
  });
}
