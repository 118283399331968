import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';

export const CardInformationSubscriptionFeeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4.4rem 4.6rem;
`;
export const BlockLeftLayout = styled.div`
  .subAddress {
    font-size: ${FONT_SIZE.M};
    text-transform: upper-case;
    font-weight: ${FONT_WEIGHT.LIGHT};
    margin-bottom: 1rem;
    color: ${COLORS.BLACK_4};
  }
  .mainName {
    font-size: ${FONT_SIZE.XL};
    font-weight: ${FONT_WEIGHT.BOLD};
    margin-bottom: 1.6rem;
    color: ${COLORS.GREY_6};
  }
  .inforWrapper {
    margin-bottom: 2.8rem;
  }
  .address {
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.LIGHT};
    text-transform: uppercase;
    color: ${COLORS.GREY_6};
    max-width: 26.4rem;
  }

  .vatWrapper,
  .leftBottomWapper {
    display: flex;
    color: ${COLORS.BLACK_3};

    .vatLabel,
    .leftBottom {
      &:first-of-type {
        margin-left: 0;
      }
      margin-left: 4.8rem;
    }
    .vatLabel {
      margin: 0 0 8.6rem 10.1rem;
      .vatNumber {
        color: ${COLORS.BLACK_4};
      }
    }
    .vatNumber,
    .leftBottom {
      font-size: ${FONT_SIZE.M};
      font-weight: ${FONT_WEIGHT.LIGHT};
      color: ${COLORS.BLACK_3};
    }
    .labelBottom {
      font-weight: ${FONT_WEIGHT.BOLD};
      padding: 0.7rem 0;
      height: 3.3rem;
    }
    .valueBottom {
      font-weight: ${FONT_WEIGHT.LIGHT};
      padding: 0.7rem 0;
      color: ${COLORS.BLACK_4};
      height: 3.3rem;

      span {
        font-weight: ${FONT_WEIGHT.BOLD};
      }
    }
  }
`;
export const BlockRightLayout = styled.div`
  overflow: hidden;
  margin: 4.6rem 4.4rem 0 0;
`;
