import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Label from 'components/ui-kits/Form/Label';
import TextInput from 'components/ui-kits/Form/TextInput';
import ButtonIcon from 'components/ui-kits/ButtonIcon';
import { IconNames } from 'components/ui-kits/Icon/Icon';
import Button from 'components/ui-kits/Button';
import { ResetPassword } from 'types/ResetPassword';
import { decodeJwt, handleError, handleSuccess } from 'utils';
import { RESET_PASSWORD_MESSAGE } from 'const/Message';
import Loader from 'components/Loader';
import { JWT_TOKEN } from 'const/Const';
import { addUser } from 'features/user/userSlice';
import {
  Actions,
  FieldWrapper,
  ForgotPasswordWrapper,
  MessageError,
  ShowPasswordIcon,
  ShowPasswordWrapper
} from 'styles/login-reset-page';
import { loginV2, resetPasswordV2 } from 'services/authService';

type FormInputs = {
  newPassword: string;
  confirmNewPassword: string;
  email: string;
  password: string;
};

interface NewPassword {
  dataVerifyOtp: Omit<ResetPassword, 'newPassword'>;
  onBackSendEmail?(): void;
}

const NewPassword = ({ dataVerifyOtp, onBackSendEmail }: NewPassword) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { isDirty, errors }
  } = useForm();
  const [isShowNewPassword, setIsShowNewPassword] = useState<boolean>(false);
  const [isShowConfirmNewPassword, setIsShowConfirmNewPassword] =
    useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = ({ newPassword }: FormInputs) => {
    const data = { ...dataVerifyOtp, newPassword };
    resetPasswordMutate(data, {
      onSuccess: () => {
        loginMutate(
          { email: data.email, password: data.newPassword },
          {
            onSuccess: (res: any) => {
              handleSuccess(RESET_PASSWORD_MESSAGE.SUCCESS);
              localStorage.setItem(JWT_TOKEN, res.data.token);

              const decode = decodeJwt(res.data.token);
              dispatch(
                addUser({
                  exp: decode.exp,
                  iat: decode.iat,
                  merchantId: decode.merchant_id,
                  merchantSlug: decode.merchant_slug,
                  roles: decode.roles,
                  username: decode.username
                })
              );
              history.push('/home');
            },
            onError: (errors: any) => {
              handleError(errors.response.status, RESET_PASSWORD_MESSAGE.ERROR);
            }
          }
        );
      },
      onError: (errors: any) => {
        handleError(errors.response.status, RESET_PASSWORD_MESSAGE.ERROR);
      }
    });
  };

  const { isLoading: isLoadingResetPassword, mutate: resetPasswordMutate } =
    useMutation((data: Required<ResetPassword>) => resetPasswordV2(data));

  const { isLoading: isLoadingLogin, mutate: loginMutate } = useMutation(
    ({ email, password }: Required<Pick<FormInputs, 'email' | 'password'>>) =>
      loginV2({ email, password })
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldWrapper>
          <Label forId="newPassword" textTransform="none" fontWeight="MEDIUM">
            Entrez votre nouveau mot de passe
          </Label>
          <ShowPasswordWrapper>
            <TextInput
              type={isShowNewPassword ? 'text' : 'password'}
              id="newPassword"
              name="newPassword"
              register={register}
              placeholder="Mot de passe"
              validate={{
                required: {
                  value: true,
                  message: 'Saisissez un nouveau mot de passe'
                },
                pattern: {
                  value:
                    /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,25}$/,
                  message: `Le mot de passe doit être composé d'au moins 8 caractères et contenir au moins une majuscule, une minuscule, un caractère spécial, un chiffre.`
                }
              }}
              hasError={Boolean(errors.newPassword)}
            />
            <ShowPasswordIcon>
              {!isShowNewPassword && (
                <ButtonIcon
                  onClick={() => setIsShowNewPassword(true)}
                  icon={IconNames.ShowEye}
                  widthIcon={18}
                />
              )}
              {isShowNewPassword && (
                <ButtonIcon
                  onClick={() => setIsShowNewPassword(false)}
                  icon={IconNames.hideEye}
                  widthIcon={18}
                />
              )}
            </ShowPasswordIcon>
          </ShowPasswordWrapper>
          {errors.newPassword && (
            <MessageError>{errors.newPassword.message}</MessageError>
          )}
        </FieldWrapper>

        <FieldWrapper>
          <Label
            forId="confirmNewPassword"
            textTransform="none"
            fontWeight="MEDIUM">
            Confirmez votre nouveau mot de passe
          </Label>
          <ShowPasswordWrapper>
            <TextInput
              type={isShowConfirmNewPassword ? 'text' : 'password'}
              id="confirmNewPassword"
              name="confirmNewPassword"
              register={register}
              placeholder="Mot de passe"
              validate={{
                validate: (value: any) =>
                  value === getValues('newPassword') ||
                  'Les mots de passe ne correspondent pas'
              }}
              hasError={Boolean(errors.confirmNewPassword)}
            />
            <ShowPasswordIcon>
              {!isShowConfirmNewPassword && (
                <ButtonIcon
                  onClick={() => setIsShowConfirmNewPassword(true)}
                  icon={IconNames.ShowEye}
                  widthIcon={18}
                />
              )}
              {isShowConfirmNewPassword && (
                <ButtonIcon
                  onClick={() => setIsShowConfirmNewPassword(false)}
                  icon={IconNames.hideEye}
                  widthIcon={18}
                />
              )}
            </ShowPasswordIcon>
          </ShowPasswordWrapper>
          {errors.confirmNewPassword && (
            <MessageError>{errors.confirmNewPassword.message}</MessageError>
          )}
        </FieldWrapper>
        <Actions>
          <Button
            type="submit"
            variant="primary"
            isFluid={true}
            sizeType="l"
            fontWeight="BOLD"
            isDisabled={!Boolean(isDirty)}>
            SUIVANT
          </Button>
          <ForgotPasswordWrapper>
            <Button
              label="Retour"
              variant="link"
              isFluid={false}
              isUpperCase={false}
              onClick={onBackSendEmail}
              fontWeight="BOLD"
            />
          </ForgotPasswordWrapper>
        </Actions>
      </form>
      {isLoadingResetPassword || (isLoadingLogin && <Loader />)}
    </>
  );
};

export default NewPassword;
