import React from 'react';
import { TableHeadCell, TableWrapper } from './Table.style';
interface IHeader {
  label?: React.ReactNode;
  width?: string;
}
interface ITable {
  header?: IHeader[];
  children?: React.ReactNode;
  borderHead?: boolean;
  hasScroll?: boolean;
  heightScroll?: string;
  tFoot?: React.ReactNode;
}

const Table = ({
  header,
  borderHead = true,
  children,
  heightScroll = '40rem',
  hasScroll = false,
  tFoot
}: ITable) => {
  const widthArr = header?.map((item) => item.width || 'initial') || [];
  return (
    <TableWrapper
      className="table-wrapper"
      borderHead={borderHead}
      hasScroll={hasScroll}
      heightScroll={heightScroll}
      cellWidth={widthArr}>
      <table>
        <thead>
          <tr>
            {header?.map((item, i) => (
              <TableHeadCell key={i} colWidth={item.width || ''}>
                {item.label}
              </TableHeadCell>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
        <tfoot>{tFoot}</tfoot>
      </table>
    </TableWrapper>
  );
};

export default Table;
