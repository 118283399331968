import React from 'react';
import { TableCustomerWrapper } from './TableCustomer.style';
import ItemCustomer from './ItemCustomer';
import Table from 'components/ui-kits/Table';
import { formatDollar } from 'utils';

const headerTable = [
  { label: 'Description' },
  { label: 'Prix unitaire' },
  { label: 'Qté.' },
  { label: 'Total HT' },
  { label: 'Réduction' },
  { label: 'Prix net HT' },
  { label: 'TVA' },
  { label: 'Montant TVA' },
  { label: 'Total TTC' }
];

interface Iprops {
  onDataTable: any;
}

const TableCustomer = ({ onDataTable }: Iprops) => {
  const items: any = onDataTable?.normalizedOrderItemVats;
  const subtotal = onDataTable?.groupVat?.subtotal;
  const delivery = onDataTable?.groupVat?.delivery;
  const total = onDataTable?.groupVat?.total;
  const vat = [];

  for (const key in onDataTable?.groupVat) {
    if (key !== 'delivery' && key !== 'total' && key !== 'subtotal') {
      vat.push(onDataTable?.groupVat[key]);
    }
  }

  return (
    <TableCustomerWrapper>
      <Table header={headerTable}>
        {items?.map((item: any, index: number) => {
          const children = item?.children;
          return (
            <>
              <ItemCustomer
                key={item?.id}
                number={index < 10 ? `${0}${index + 1}.` : `${index + 1}.`}
                description={item?.name}
                qty={item?.quantity}
                vat={`${item?.vat * 100}%`}
                vatAmount={`${formatDollar(item?.vatAmount)} €`}
                totalHT={`${formatDollar(item?.totalHt)} €`}
                totalPrice={`${formatDollar(item?.totalTtc)} €`}
                unitPrice={`${formatDollar(item?.unitPrice)} €`}
                reduction={`${item?.reduction * 100}%`}
                netPriceHT={`${formatDollar(item?.netPriceHt)} €`}
              />
              {children.map((child: any) => (
                <ItemCustomer
                  key={child?.id}
                  subDescription={<div key={child?.id}>{child?.name}</div>}
                  subQty={<div key={child?.id}>{child?.quantity}</div>}
                  subVat={<div key={child?.id}>{child?.vat * 100}%</div>}
                  subVatAmount={<div>{formatDollar(child?.vatAmount)} €</div>}
                  subTotalHT={<div>{formatDollar(child?.totalHt)} €</div>}
                  subTotalPrice={<div>{formatDollar(child?.totalTtc)} €</div>}
                  subUnitPrice={<div>{formatDollar(child?.unitPrice)} €</div>}
                  subReduction={`${child?.reduction * 100}%`}
                  subNetPriceHT={`${formatDollar(child?.netPriceHt)} €`}
                />
              ))}
            </>
          );
        })}
        <tr>
          <td className="col-border" colSpan={9}></td>
        </tr>
        <tr className="sub-total total-wrapper">
          <td colSpan={4}></td>
          <td className="total-wrapper__title">Sous-total</td>
          <td className="totalAmount">
            {formatDollar(subtotal?.netPriceHt)} €
          </td>
          <td className="totalAmount"></td>
          <td className="totalAmount">{formatDollar(subtotal?.vatAmount)} €</td>
          <td className="totalAmount totalPrice bold">
            {formatDollar(subtotal?.totalTtc)} €
          </td>
        </tr>
        {delivery && (
          <tr className="delivery total-wrapper">
            <td colSpan={4}></td>
            <td className="total-wrapper__title">Frais de livraison</td>
            <td className="totalAmount">
              {formatDollar(delivery?.netPriceHt)} €
            </td>
            <td className="totalAmount">{delivery?.vat * 100} %</td>
            <td className="totalAmount">
              {formatDollar(delivery?.vatAmount)} €
            </td>
            <td className="totalAmount totalPrice bold">
              {formatDollar(delivery?.totalTtc)} €
            </td>
          </tr>
        )}
        {vat.map((item, idx) => (
          <tr key={idx} className="vat total-wrapper">
            <td colSpan={4}></td>
            <td className="total-wrapper__title">{idx === 0 ? 'VAT' : ''}</td>
            <td className="totalAmount bold">
              <div>{formatDollar(item.netPriceHt)} €</div>
            </td>
            <td className="totalAmount">
              <div>{item.vat * 100} %</div>
            </td>
            <td className="totalAmount bold">
              <div>{formatDollar(item.vatAmount)} €</div>
            </td>
            <td className="totalAmount totalPrice bold">
              <div>{formatDollar(item.totalTtc)} €</div>
            </td>
          </tr>
        ))}
        <tr className="total total-wrapper">
          <td colSpan={4}></td>
          <td className="total-wrapper__title">Total</td>
          <td className="totalAmount">{formatDollar(total?.netPriceHt)} €</td>
          <td className="totalAmount"></td>
          <td className="totalAmount">{formatDollar(total?.vatAmount)} €</td>
          <td className="totalAmount totalPrice">
            {formatDollar(total?.totalTtc)} €
          </td>
        </tr>
        <tr className="footer">
          <td colSpan={9}>
            <div className="footerContent">
              Facture établie au nom et pour le compte de{' '}
              {onDataTable?.merchant?.name} par :
            </div>
            <div className="footerContent">
              Achil SAS - 3 Les Vinottes, 25500 Morteau - n° TVA : FR
              11889430765
            </div>
            <div className="footerContent">
              R.C.S. Besançon 889 430 765, Société au capital de 92 500 €
            </div>
          </td>
        </tr>
      </Table>
    </TableCustomerWrapper>
  );
};

export default TableCustomer;
