import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DropdownList } from 'components/ui-kits/Form/Dropdown';
import { DropdownListWrapper } from './DropdownListFilter.style';
import { FILTER_MERCHANT_OPTIONS } from 'const/Const';
import { RootState } from 'app/store';
import { queryFilter } from 'features/filterInvoice/filterInvoiceSlice';

const InvoicesDropdownList = () => {
  const { filter, startDate, endDate } = useSelector(
    (state: RootState) => state.filterInvoice
  );
  const dispatch = useDispatch();
  const [select, onSelect] = useState<any>(filter);

  useEffect(() => {
    dispatch(
      queryFilter({
        filter: select,
        startDate: startDate,
        endDate: endDate
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select]);

  return (
    <DropdownListWrapper>
      <DropdownList
        variant="rounded"
        options={FILTER_MERCHANT_OPTIONS}
        defaultValue={filter}
        change={onSelect}
      />
    </DropdownListWrapper>
  );
};

export default InvoicesDropdownList;
