import React from 'react';
import {
  BlockLeftLayout,
  BlockRightLayout,
  CardInformationWrapper
} from './CardInformationCustomer.style';
import { InvoicesInformationRender } from 'types/InvoicesMerchantDetails';

const CardInformationCustomer = ({
  firstName,
  lastName,
  invoiceNumber,
  invoiceDate,
  dateOfTransaction,
  mainName,
  address,
  vatNumber,
  siret,
  billingAddress
}: InvoicesInformationRender) => {
  return (
    <CardInformationWrapper>
      <BlockLeftLayout>
        <div>
          <div className="labelTopLeft">
            {firstName} {lastName}
          </div>
          <div className="labelTopLeft">{billingAddress}</div>
        </div>
        <div className="leftBottomWapper">
          <div className="leftBottom">
            <div className="labelBottom">Numéro de Facture</div>
            <div className="labelBottom">Date de la facture</div>
            <div className="labelBottom">Date de transaction</div>
          </div>
          <div className="leftBottom">
            <div className="labelBottom">{invoiceNumber}</div>
            <div className="labelBottom">{invoiceDate}</div>
            <div className="labelBottom">{dateOfTransaction}</div>
          </div>
        </div>
      </BlockLeftLayout>
      <BlockRightLayout>
        <div className="title">Facture émise par Achil SAS au nom de :</div>
        <div className="mainName">{mainName}</div>
        <div className="inforWrapper">
          <div className="address">{address}</div>
        </div>
        <div className="vatWrapper">
          <div className="vatLabel">
            <div className="vatNumber">Numéro de TVA</div>
            <div className="vatNumber">SIRET</div>
          </div>
          <div className="vatLabel vatValue">
            <div className="vatNumber">{vatNumber}</div>
            <div className="vatNumber">{siret}</div>
          </div>
        </div>
      </BlockRightLayout>
    </CardInformationWrapper>
  );
};

export default CardInformationCustomer;
