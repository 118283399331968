import React from 'react';
import { SortStyle } from 'styles/listing-page';
import { DropdownList } from 'components/ui-kits/Form/Dropdown';

interface SortProps {
  onChange?(): void;
}

const optionSort = [
  {
    label: 'Affichage de A à Z',
    value: 'increase'
  },
  {
    label: 'Affichage de Z à A',
    value: 'decrease'
  }
];

const Sort = ({ onChange }: SortProps) => {
  return (
    <SortStyle>
      <DropdownList
        options={optionSort}
        variant="rounded"
        defaultValue={optionSort[0]}
        change={onChange}
      />
    </SortStyle>
  );
};

export default Sort;
