import React from 'react';

function ArrowUpIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0.8088rem"
      height="0.8299rem"
      viewBox="0 0 8.088 8.299"
      {...props}>
      <path
        id="Up"
        d="M7.723,5.77l.411-.411a.443.443,0,0,0,0-.628l-3.6-3.6a.443.443,0,0,0-.628,0l-3.6,3.6a.443.443,0,0,0,0,.628l.411.411a.445.445,0,0,0,.635-.007L3.48,3.531V8.854a.443.443,0,0,0,.444.445h.593a.443.443,0,0,0,.444-.445V3.531L7.087,5.763a.442.442,0,0,0,.635.007Z"
        transform="translate(-0.176 -1)"
        fill="#60ddb2"
      />
    </svg>
  );
}

export default ArrowUpIcon;
