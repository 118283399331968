import DisplayText from 'components/ui-kits/DisplayText';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';
import { ArrowDownIcon, ArrowUpIcon } from 'components/ui-kits/Icon';

const CardStatsWrapper = styled.div`
  text-align: center;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GREY_4};
  border-radius: 0.7rem;
  padding: 1.7rem;
  box-sizing: border-box;
`;

const LabelTopStyle = styled(DisplayText)``;
const LabelMidStyle = styled(DisplayText)`
  line-height: 4.8rem;
  margin: 0.9rem 0 0.3rem 0;
`;
const ProfitStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: 0.5rem;
  }
`;

interface IProps {
  label?: string;
  total?: number | string;
  profit?: number;
}

const CardStats = ({ label, total, profit }: IProps) => {
  return (
    <CardStatsWrapper>
      <LabelTopStyle
        label={label}
        color="GREY_1"
        fontSize="M"
        fontWeight="LIGHT"
      />
      <LabelMidStyle label={total} fontSize="XXLM" fontWeight="BOLD" />
      <ProfitStyle>
        <span>
          {(profit || profit == 0) && profit >= 0 ? (
            <ArrowUpIcon />
          ) : (
            <ArrowDownIcon />
          )}
        </span>
        <DisplayText
          label={`${profit}%`}
          fontSize="L"
          fontWeight="BOLD"
          color={(profit || profit == 0) && profit >= 0 ? 'GREEN_1' : 'ORANGE'}
        />
      </ProfitStyle>
    </CardStatsWrapper>
  );
};

export default CardStats;
