import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from 'styles/variables';
import Icon, { IconNames } from '../Icon/Icon';

const variantStyle = {
  'square-grey': css`
    width: 4rem;
    height: 4rem;
    background-color: ${COLORS.GREY_7};
    border: 1px solid ${COLORS.GREY_1};
    color: ${COLORS.GREY_1};
    border-radius: 0.7rem;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  `,
  'square-white': css`
    width: 4rem;
    height: 4rem;
    background-color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.GREY_1};
    color: ${COLORS.GREY_1};
    border-radius: 0.7rem;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  `,
  rounded: css`
    border-radius: 50%;
  `,
  square: css`
    border-radius: 0.7rem;
  `
};
export interface IButtonIcon {
  variant?: 'rounded' | 'square' | 'square-grey' | 'square-white';
  onClick?: () => void;
  icon: IconNames;
  widthIcon: number;
  type?: 'submit' | 'button';
  className?: string;
  disabled?: boolean;
}
const ButtonStyle = styled.button<Required<Pick<IButtonIcon, 'variant'>>>`
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${COLORS.GREY_5};
    cursor: pointer;
  }
  ${({ variant }) => variantStyle[variant]}
`;

const ButtonIcon = ({
  variant = 'rounded',
  disabled,
  className,
  onClick,
  icon,
  widthIcon,
  type = 'button'
}: IButtonIcon) => {
  return (
    <ButtonStyle
      className={className}
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      type={type}>
      <Icon name={icon} width={widthIcon} />
    </ButtonStyle>
  );
};

export default ButtonIcon;
