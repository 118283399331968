import styled from 'styled-components';
import { COLORS } from 'styles/variables';

export const GripLines = styled.div`
  position: relative;
  width: 2.4rem;
  height: 0.3rem;
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 0.3rem;
    background-color: ${COLORS.GREY_1};
  }
  &::before {
    top: -0.3rem;
  }
  &::after {
    top: 0.3rem;
  }
`;

export const CardBodyItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLORS.GREY_4};
  padding: 1.3rem 2.8rem;
`;

export const GripAndDesc = styled.div`
  display: flex;
  align-items: center;
`;

export const DescriptionWrapper = styled.div`
  padding-left: 2.8rem;
`;
