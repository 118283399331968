import React from 'react';
import styled, { css } from 'styled-components';
import { GUTTER, MARGIN, MEDIA_BREAKPOINT } from 'styles/variables';
import { IGrid } from 'types/Grid';

const GridWrapper = styled.div<{
  col: number;
  start: 'large' | 'gutter';
  end: 'large' | 'gutter';
  marginLeft: boolean;
  marginRight: boolean;
}>`
  display: grid;
  ${({ col, start, end, marginLeft, marginRight }) => css`
    @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
      grid-template-columns:
        ${marginLeft ? `[margin] ${MARGIN.DESKTOP_S / 2}px` : ''}
        repeat(${col - 1}, ${TemplageStart('DESKTOP_S')[start]})
        ${Template('DESKTOP_S')[start]} ${start === end
          ? ''
          : Template('DESKTOP_S')[end]} ${marginRight ? `[margin] ${MARGIN.DESKTOP_S / 2}px` : ''};
    }
    @media ${MEDIA_BREAKPOINT.DESKTOP_M} {
      grid-template-columns:
        ${marginLeft ? `[margin] ${MARGIN.DESKTOP_M / 2}px` : ''}
        repeat(${col - 1}, ${TemplageStart('DESKTOP_M')[start]})
        ${Template('DESKTOP_M')[start]} ${start === end
          ? ''
          : Template('DESKTOP_M')[end]} ${marginRight ? `[margin] ${MARGIN.DESKTOP_M / 2}px` : ''};
    }
    @media ${MEDIA_BREAKPOINT.DESKTOP_L} {
      grid-template-columns:
        ${marginLeft
          ? `[margin] calc(50vw - ${(1920 - MARGIN.DESKTOP_L) / 2}px)`
          : ''}
        repeat(${col - 1}, ${TemplageStart('DESKTOP_L')[start]})
        ${Template('DESKTOP_L')[start]} ${start === end
          ? ''
          : Template('DESKTOP_L')[end]} ${marginRight ? `[margin] calc(50vw - ${(1920 - MARGIN.DESKTOP_L) / 2}px)` : ''};
    }
  `};
`;

const Template = (key: keyof typeof GUTTER) => ({
  large: '[large] 1fr',
  gutter: `[gutter] ${GUTTER[key]}px`
});

const TemplageStart = (key: keyof typeof GUTTER) => ({
  large: `${Template(key).large} ${Template(key).gutter}`,
  gutter: `${Template(key).gutter} ${Template(key).large}`
});

function Grid({
  children,
  col,
  start,
  end = start,
  marginLeft = false,
  marginRight = false,
  asType,
  className,
  ...props
}: IGrid) {
  return (
    <GridWrapper
      col={col}
      start={start}
      end={end}
      marginLeft={marginLeft}
      marginRight={marginRight}
      as={asType}
      className={className}
      {...props}>
      {children}
    </GridWrapper>
  );
}

export default Grid;
