import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';

export const SelectedItemWrapper = styled.div`
  width: 100%;
  border-radius: 0.7rem;
  margin: 2rem 0 2rem 2rem;
`;

export const PickedItem = styled.div`
  width: 100%;
  font-size: ${FONT_SIZE.L};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  color: ${COLORS.BLACK_2};
  background-color: ${COLORS.WHITE};
  height: 5rem;
  position: relative;
  overflow: visible;
  border: 1px solid ${COLORS.GREY_4};
  border-bottom: none;
  &:first-child {
    border-radius: 0.7rem 0.7rem 0 0;
  }
  &:last-child {
    border-radius: 0 0 0.7rem 0.7rem;
    border-bottom: 1px solid ${COLORS.GREY_4};
  }
  &:last-child:first-child {
    border-radius: 0.7rem;
  }
`;

export const OptionsWrap = styled.div``;
export const OptionLabel = styled.p`
  color: ${COLORS.BLACK_2};
  font-size: ${FONT_SIZE.M};
  font-weight: ${FONT_WEIGHT.BOLD};
`;
export const OptionPrice = styled.p`
  color: ${COLORS.BLACK_2};
  font-size: ${FONT_SIZE.S};
  font-weight: ${FONT_WEIGHT.REGULAR};
`;

export const DndZone = styled.div`
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  margin: 0 1rem;
  cursor: move;
  color: ${COLORS.GREY_1};
`;

export const LeftWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${COLORS.BLACK_2};
  font-size: ${FONT_SIZE.L};
  font-weight: ${FONT_WEIGHT.REGULAR};
`;
export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-end;
  color: ${COLORS.BLACK_2};
  /* font-size: ${FONT_SIZE.M};
  font-weight: ${FONT_WEIGHT.REGULAR}; */
  .price {
    margin-right: 1.5rem;
  }
`;

export const CreatOptionStyle = styled.div`
  text-align: center;
  background-color: ${COLORS.WHITE};
  color: ${COLORS.BLUE_1};
  text-transform: uppercase;
  font-size: ${FONT_SIZE.M};
  height: 5.2rem;
  border: 1px solid ${COLORS.GREY_4};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0 0 0.4.rrem 0.4.rrem;
  &:hover {
    background-color: ${COLORS.GREY_5};
  }
`;

export const SearchAddItemStyle = {
  placeholder: () => {
    return {
      color: `${COLORS.GREY_2}`,
      fontSize: '1.8rem'
    };
  }
};

export const SearchAddItemWrapper = styled.div`
  & > div {
    font-size: 1.6rem;
  }
`;
