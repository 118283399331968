import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Label from 'components/ui-kits/Form/Label';
import TextInput from 'components/ui-kits/Form/TextInput';
import Button from 'components/ui-kits/Button';
import {
  Actions,
  FieldWrapper,
  ForgotPasswordWrapper,
  Title
} from 'styles/login-reset-page';
import { handleError } from 'utils';
import { useMutation } from 'react-query';
import Loader from 'components/Loader';
import { Step } from 'types/ResetPassword';
import { updateEmail } from 'features/auth/authSlice';
import { EMAIL_INVALID_MESSAGE } from 'const/Message';
import { requestEmailV2 } from 'services/authService';

type FormInputs = {
  email: string;
  password: string;
};

interface SendEmail {
  statusStep?(status: Step): void;
  title: string;
}

const SendEmail = ({ statusStep, title }: SendEmail) => {
  const [errorEmailMessage, setErrorEmailMessage] = useState('');
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty }
  } = useForm();
  const dispatch = useDispatch();

  const sendEmail = (email: { email: string }) => {
    requestEmailMutate(email, {
      onSuccess: () => {
        const action = updateEmail(email);
        dispatch(action);
        statusStep?.('forgotPassword');
      },
      onError: (errors: any) => {
        setErrorEmailMessage('The email not exists');
        handleError(
          errors.response.status,
          <div>Cette adresse email est inconnu</div>
        );
      }
    });
  };

  const { isLoading: isLoadingRequestEmail, mutate: requestEmailMutate } =
    useMutation(({ email }: Required<Pick<FormInputs, 'email'>>) =>
      requestEmailV2(email)
    );
  return (
    <>
      <Title>{title}</Title>
      <form onSubmit={handleSubmit(sendEmail)}>
        <FieldWrapper>
          <Label forId="email" fontWeight="MEDIUM" textTransform="none">
            Entrez l&#39;adresse email associée à votre compte afin de recevoir
            un code de réinitialisation de mot de passe
          </Label>
          <TextInput
            id="email"
            name="email"
            register={register}
            placeholder="Email"
            validate={{
              required: true,
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: EMAIL_INVALID_MESSAGE
              }
            }}
            hasError={Boolean(errors['email']) || Boolean(errorEmailMessage)}
          />
        </FieldWrapper>
        <Actions>
          <Button
            type="submit"
            isDisabled={!isDirty}
            variant="primary"
            isFluid={true}
            sizeType="l"
            label="SUIVANT"
          />
          <ForgotPasswordWrapper>
            <Button
              label="Retour"
              variant="link"
              isFluid={false}
              isUpperCase={false}
              onClick={() => statusStep?.('login')}
              fontWeight="BOLD"
            />
          </ForgotPasswordWrapper>
        </Actions>
      </form>
      {isLoadingRequestEmail && <Loader />}
    </>
  );
};

export default SendEmail;
