import React, { useState, useEffect, useReducer } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { addSeconds, endOfDay, isSameDay, startOfDay } from 'date-fns';
import TextInput from 'components/ui-kits/Form/TextInput';
import Textarea from 'components/ui-kits/Form/Textarea';
import NumberInput from 'components/ui-kits/Form/NumberInput';
import { DropdownList, Search } from 'components/ui-kits/Form/Dropdown';
import Button from 'components/ui-kits/Button';
import TrashBinIcon from 'components/ui-kits/Icon/TrashBin';
import { Grid, GridItem } from 'components/ui-kits/Grid/index';
import Radio from 'components/ui-kits/Form/Radio';
import { Checkbox, CheckboxSwitch } from 'components/ui-kits/Form/Checkbox';
import { CalendarIcon, InfoIcon } from 'components/ui-kits/Icon';
import {
  TextInputWrapper,
  ItemWrapper,
  Regulation,
  TitleLarge,
  TitleSmall,
  ItemInfo,
  SellAsOptionWrapper,
  CategoriesWrapper,
  TitleWrapper,
  InfoIconWrapper,
  ShowInfoHover,
  SearchWrapper,
  SearchWidth,
  SearchResult,
  OptionWrapper,
  RadioWrapper,
  CheckboxWrapper,
  PriceVatWrapper,
  DescriptionWrapper,
  BlockRelative,
  SectionNameWrapper,
  ContainerInput,
  ActionWrapper,
  OptionItemWrapper,
  UploadImageStyle,
  ReferenceWrapper,
  PriceWithoutVatWrapper,
  TimelineWrapper,
  SellProductWithUnits,
  ProductWithUnitsWrapper,
  BlockItemWrapper
} from './ProductForm.style';
import { Collapse } from 'components/ProductLayout/Collapse';
import UploadImageCrop from 'components/UploadImageCrop';
import { CategoryItemData } from 'types/Category';
import Tag from 'components/ui-kits/Tag';
import {
  IAllergen,
  IDietary,
  IProductAttribute,
  Product,
  ProductOption
} from 'types/Products';
import ModalPrompt from 'components/ui-kits/ModalPrompt';
import {
  CONFIRM_MESSAGE,
  DELETE_MESSAGE,
  SIZE_IS_TOO_BIG
} from 'const/Message';
import {
  formatDollar,
  handleError,
  handleSuccess,
  replaceCharacter
} from 'utils';
import Loader from 'components/Loader';
import { UNIT_TYPE, VAT_OPTIONS } from 'const/Const';
import { GroupOptionRes, IGroupOptions } from 'types/GroupOptions';
import CurrentGroupOption from 'components/AddGroupOptionShared/CurrentGroupOption';
import ModalAddOption from 'components/AddGroupOptionShared/ModalAddOption';
import GoBack from 'components/GoBack';
import FormCreateEditLayout from 'components/FormCreateEditLayout';
import PanelRightLayout from 'components/FormCreateEditLayout/PanelRightLayout';
import FormLeftLayout from 'components/FormCreateEditLayout/FormLeftLayout';
import {
  GridItemBtnDelete,
  GridItemBtnSave,
  GridItemInput,
  GridSectionName
} from 'styles/createEdit-page';
import { MessageErrorName } from 'styles/common';
import { deleteProduct } from 'services/productService';
import { getListCategoriesV2 } from 'services/categoryService';
import { getGroupOptionsListV2 } from 'services/groupOptionService';
import { getProductAttrV2 } from 'services/ProductAttrService';
import DisplayText from 'components/ui-kits/DisplayText';
import CustomDatePicker from 'components/ui-kits/CustomDatePicker';
import { FieldPrice } from './FieldPrice';
import { FieldProductFinalPrice } from './FieldProductFinalPrice';

type TypeMode = 'create' | 'edit';
interface IProps {
  create?: (data: Product, imgCropped: File) => void;
  mode: TypeMode;
  dataEdit?: ProductOption;
  edit?: (data: Product, imgCropped: File) => void;
}

const initialState = [true, true];
const reducer = (
  state: boolean[],
  { type, idx }: { type: string; idx?: number }
) => {
  switch (type) {
    case 'expand-all':
      return [true, true];
    case 'collapse-all':
      return [false, false];
    case 'toggle':
      const arrNew: boolean[] = [...state];
      if (idx || idx === 0) {
        arrNew[idx] = !arrNew[idx];
      }
      return arrNew;
    default:
      return state;
  }
};

const ProductForm = ({ create, mode, dataEdit, edit }: IProps) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [listOption, setListOption] = useState<GroupOptionRes[]>([]);
  const [categoriesSelected, setCategoriesSelected] = useState<string[]>([]);
  const [imgCropped, setImgCropped] = useState<any>();
  const [isInitialCategory, setIsInitialCategory] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [groupOptionData, setGroupOptionData] = useState<string[]>([]);
  const [currentGroupOptionSelected, setCurrentGroupOptionSelected] = useState<
    IGroupOptions[]
  >([]);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(() => {
    if (!!dataEdit?.promotions?.startDate?.date) {
      const startDateString = dataEdit.promotions.startDate.date + 'Z';
      const formattedStartDate = startDateString.replace(' ', 'T');

      return new Date(formattedStartDate);
    } else {
      return new Date();
    }
  });
  const [endDate, setEndDate] = useState(() => {
    if (!!dataEdit?.promotions?.endDate?.date) {
      const endDateString = dataEdit.promotions.endDate.date + 'Z';
      const formattedEndDate = endDateString.replace(' ', 'T');

      return new Date(formattedEndDate);
    } else {
      return endOfDay(startDate);
    }
  });
  const queryClient = useQueryClient();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset
  } = useForm();
  const watchTitle = useWatch({
    control,
    name: 'name'
  });
  const watchChangeInputFile = useWatch({
    control,
    name: 'files'
  });
  const watchSellProductUnits = useWatch({
    control,
    name: 'isSellProductWithUnits'
  });
  const watchSellAsOption = useWatch({
    control,
    name: 'isReduction'
  });
  const watchUnitType = useWatch({
    control,
    name: 'unitType'
  });

  const { data: listCategories } = useQuery(
    ['getListCategories', { page: 1, itemsPerPage: 1000 }],
    (context) => getListCategoriesV2(context.queryKey, context.signal)
  );

  useEffect(() => {
    reset({
      ...dataEdit,
      vat: VAT_OPTIONS.filter(
        (option) => Number(option.value) == dataEdit?.includedTax
      )[0],
      price: (dataEdit?.price && dataEdit.originalPrice / 100) || 0, // the originalPrice of the product, not price
      pricePerUnit: (dataEdit && dataEdit.pricePerUnit / 100) || 0,
      unitType: UNIT_TYPE.filter((option) => option.value == dataEdit?.unit)[0],
      reductionPercent:
        (dataEdit?.promotions?.percent && dataEdit.promotions.percent * 100) ||
        0,
      quantityProductSoldByWeight: dataEdit?.quantityProductSoldByWeight || 0,
      proFinalPrice:
        (dataEdit?.variants?.[0].price && dataEdit.variants[0].price / 100) || 0
    });
    if (dataEdit) {
      setValue('isReduction', dataEdit.appliedPromotion);
      setValue('isSellProductWithUnits', dataEdit.isSellWithUnits);
    }
  }, [dataEdit, reset, setValue]);

  useEffect(() => {
    if (categoriesSelected.length || isInitialCategory) {
      return;
    }
    if (!dataEdit || mode !== 'edit') {
      return;
    }
    if (dataEdit.productTaxons?.length) {
      const listCategoriesSelected = dataEdit.productTaxons.map(
        (item: any) => item.taxon?.code
      );
      setCategoriesSelected(listCategoriesSelected);
      setIsInitialCategory(true);
    }
  }, [dataEdit, categoriesSelected.length, mode, isInitialCategory]);

  const onChangeSearch = (data: { label: string; value: string }[]) => {
    setCategoriesSelected([...categoriesSelected, data[0].value]);
  };

  const onSubmit = (product: Product) => {
    // Noted: This implementation will mutate the product object
    if (typeof product.isAlcoholicProduct == 'string') {
      product.isAlcoholicProduct == 'true'
        ? (product.isAlcoholicProduct = true)
        : (product.isAlcoholicProduct = false);
    }
    if (typeof product.dietaryAttributes == 'string') {
      product.dietaryAttributes = [product.dietaryAttributes];
    }
    if (typeof product.allergenAttributes == 'string') {
      product.allergenAttributes = [product.allergenAttributes];
    }
    product.categories = categoriesSelected;
    product.maxAmount = 0;
    product.includedTax = Number(product.vat.value);
    product.dietaryAttributes = product.dietaryAttributes || [];
    product.allergenAttributes = product.allergenAttributes || [];
    product.groupOptionIRIs = groupOptionData;
    product.name = product.name.trim();
    product.price =
      Number(replaceCharacter(String(product.price), ',', '.')) * 100;
    // product.priceWithoutVAT = Math.round(
    //   Number(replaceCharacter(String(product.priceWithoutVAT), ',', '.')) * 100
    // );
    product.unit = product.unitType?.value;
    product.pricePerUnit =
      Number(replaceCharacter(String(product.pricePerUnit), ',', '.')) * 100;
    product.reductionPercent = Number(
      Number(
        Number(replaceCharacter(String(product.reductionPercent), ',', '.')) /
          100
      ).toFixed(4)
    );
    product.quantityProductSoldByWeight = Number(
      replaceCharacter(String(product.quantityProductSoldByWeight), ',', '.')
    );

    // Change to the start of selected start date plus 1 second
    // when the start date is not current date (larger than current date).
    const isCurrentDay =
      (!!dataEdit?.promotions?.startDate?.date &&
        isSameDay(startDate, new Date(dataEdit.promotions.startDate.date))) ||
      isSameDay(startDate, new Date());

    product.startDate = isCurrentDay
      ? new Date().toISOString()
      : addSeconds(startOfDay(startDate), 1).toISOString();

    product.endDate = endOfDay(endDate).toISOString();
    product.isEditPrice = product.price !== dataEdit?.originalPrice;

    if (create) {
      create(product, imgCropped);
    } else if (edit) {
      edit(product, imgCropped);
    }
  };

  const { data: result } = useQuery('getListProductAttr', ({ signal }) =>
    getProductAttrV2(signal)
  );

  const { data: resultListOption } = useQuery(
    'getListGroupOption',
    ({ signal }) => getGroupOptionsListV2({ signal })
  );

  useEffect(() => {
    if (resultListOption) {
      const arrListGroupOption = resultListOption['hydra:member'].map(
        (item) => ({
          id: item['@id'],
          name: item.name,
          displayName: item.displayName,
          contains: Object.values(item.groupOptionProducts),
          isMandatory: item.isMandatory
        })
      );
      setListOption(arrListGroupOption);
    }
  }, [resultListOption]);

  const dietary: IDietary[] = [];
  const allergen: IAllergen[] = [];

  if (result?.data) {
    result.data['hydra:member'].forEach((item: IProductAttribute) => {
      if (item.code === 'dietary_attributes') {
        const choices = item.configuration.choices;
        for (const k in choices) {
          dietary.push({ value: k, label: choices[k].en_US });
        }
      }

      if (item.code === 'allergen_attributes') {
        const choices = item.configuration.choices;
        for (const k in choices) {
          allergen.push({ value: k, label: choices[k].en_US });
        }
      }
    });
  }

  let defaultAlcoholic = 'false';
  let defaultDietary: string[] = [];
  let defaultAllergen: string[] = [];

  dataEdit?.attributes.map((item: any) => {
    if (item.code === 'alcoholic_product') {
      defaultAlcoholic = String(item.value);
    }
    if (item.code === 'dietary_attributes') {
      defaultDietary = item.value;
    }
    if (item.code === 'allergen_attributes') {
      defaultAllergen = item.value;
    }
  });

  const checkedDietary = (listDietary: string) => {
    return defaultDietary.includes(listDietary) ? true : false;
  };

  const checkedAllergen = (listAllergen: string) => {
    return defaultAllergen.includes(listAllergen) ? true : false;
  };

  const handleDeleteTag = (code: string) => {
    setCategoriesSelected(
      categoriesSelected.filter((item: string) => item !== code)
    );
  };

  const onOpenModal = () => {
    setOpenModal(true);
  };

  // Render data edit of groupoption
  useEffect(() => {
    if (dataEdit && resultListOption) {
      const filterGroupOptionEnable = dataEdit.groupOptions?.filter(
        (option: any) => option.enabled
      );
      const groupOptionChosen = filterGroupOptionEnable.map(
        (option: any) => option['@id']
      );
      const filterGroupOption: any = groupOptionChosen.map((id: string) => {
        let data = {};
        resultListOption['hydra:member'].forEach((option) => {
          if (option['@id'] === id) {
            data = option;
          }
        });
        return data;
      });
      setCurrentGroupOptionSelected(filterGroupOption);
      setGroupOptionData(groupOptionChosen);
    }
  }, [dataEdit, resultListOption]);

  // Handle group option selected and render UI
  const handleGroupOptionSelected = (
    data: { addgroupoption: string[] },
    idGOAdd?: string[],
    idGORemove?: string[]
  ) => {
    if (typeof data.addgroupoption === 'string') {
      data.addgroupoption = [data.addgroupoption];
    }

    if (resultListOption && data.addgroupoption) {
      let currentGroupOptionSelectedClone = [...currentGroupOptionSelected];
      let groupOptionDataClone = [...groupOptionData];

      if (idGOAdd) {
        groupOptionDataClone.push(...idGOAdd);

        const filterGOAdd: any[] = resultListOption['hydra:member'].filter(
          (option) => idGOAdd.includes(option['@id'])
        );

        if (filterGOAdd.length) {
          currentGroupOptionSelectedClone.push(...filterGOAdd);
        }
      }

      if (idGORemove) {
        groupOptionDataClone = groupOptionDataClone.filter(
          (id: string) => !idGORemove.includes(id)
        );

        currentGroupOptionSelectedClone =
          currentGroupOptionSelectedClone.filter((option: IGroupOptions) => {
            return !idGORemove.includes(option['@id']);
          });
      }

      setCurrentGroupOptionSelected(currentGroupOptionSelectedClone);
      setGroupOptionData(groupOptionDataClone);
      setOpenModal(false);
    } else {
      setCurrentGroupOptionSelected([]);
      setGroupOptionData([]);
      setOpenModal(false);
    }
  };

  const handleRemoveGroupOptionItem = (id: string) => {
    setCurrentGroupOptionSelected(
      currentGroupOptionSelected.filter((item: any) => item['@id'] != id)
    );
    setGroupOptionData(groupOptionData.filter((item: string) => item != id));
  };

  const handleDelete = (res: boolean) => {
    if (res && dataEdit?.code) {
      onDeleteProduct(
        { code: dataEdit.code, data: { enabled: false } },
        {
          onSuccess: () => {
            handleSuccess(DELETE_MESSAGE.PRODUCT_SUCCESS);
            queryClient.invalidateQueries('getListProducts');
            setConfirmDelete(false);
            history.push('/menu/products');
          },
          onError: (error: any) => {
            handleError(error.response.status, DELETE_MESSAGE.PRODUCT_ERROR);
            setConfirmDelete(false);
          }
        }
      );
    }
  };

  const { isLoading: isLoadingDeleteProduct, mutate: onDeleteProduct } =
    useMutation(
      ({
        code,
        data
      }: Required<{ code: string; data: { enabled: boolean } }>) =>
        deleteProduct(code, data)
    );

  const toastErrorFileSizeLarge = () => {
    handleError(undefined, SIZE_IS_TOO_BIG);
  };

  function handleDragGO(GO: any[]) {
    const idGODrag: string[] = GO?.map((item: any) => item['@id']);
    const sortDisplayGO: any[] = idGODrag.map((id: string) => {
      let data = {};
      currentGroupOptionSelected.forEach((option) => {
        if (option['@id'] === id) {
          data = option;
        }
      });
      return data;
    });

    setGroupOptionData(idGODrag);
    setCurrentGroupOptionSelected(sortDisplayGO);
  }

  const calculateProductFinalPrice = () => {
    const pricePerUnit = Number(
      replaceCharacter(String(getValues('pricePerUnit')), ',', '.')
    );
    const quantity = Number(
      replaceCharacter(
        String(getValues('quantityProductSoldByWeight')),
        ',',
        '.'
      )
    );
    const reductionPercent = Number(
      replaceCharacter(String(getValues('reductionPercent')), ',', '.')
    );
    const originalPrice = Number(
      replaceCharacter(String(getValues('price')), ',', '.')
    );
    const isReduction = getValues('isReduction');
    const isSellProductWithUnits = getValues('isSellProductWithUnits');

    let precalculatedFinalPrice: number;

    if (!isReduction && !isSellProductWithUnits) {
      precalculatedFinalPrice = originalPrice;
    } else if (!isReduction && isSellProductWithUnits) {
      precalculatedFinalPrice = pricePerUnit * quantity;
    } else if (isReduction && !isSellProductWithUnits) {
      precalculatedFinalPrice =
        (originalPrice * (100 - reductionPercent)) / 100;
    } else {
      // isReduction && isSellProductWithUnits
      precalculatedFinalPrice =
        (pricePerUnit * quantity * (100 - reductionPercent)) / 100;
    }

    setValue('proFinalPrice', formatDollar(precalculatedFinalPrice * 100));
  };

  return (
    <>
      {openModal ? (
        <ModalAddOption
          listOption={listOption}
          onClose={() => setOpenModal(false)}
          onGroupOptionSelected={handleGroupOptionSelected}
          currentGroupOptionSelected={currentGroupOptionSelected}
          mode="product"
        />
      ) : null}
      <FormCreateEditLayout>
        <FormLeftLayout>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SectionNameWrapper
              col={GridSectionName.col}
              start={GridSectionName.start}>
              <GoBack />
              <ContainerInput
                colEnd={GridItemInput.colEnd}
                nameEnd={GridItemInput.nameEnd}>
                <TextInputWrapper>
                  <TextInput
                    placeholder="Nom"
                    variant="title"
                    name="name"
                    register={register}
                    getValues={getValues}
                    watchValue={watchTitle}
                    validate={{
                      required: {
                        value: true,
                        message: 'Ce champ est requis'
                      },
                      pattern: {
                        value: /^[^\S]*\S/,
                        message: 'Invalid data'
                      }
                    }}
                    defaultValue={dataEdit?.name}
                    hasError={Boolean(errors.name)}
                  />
                </TextInputWrapper>
                {errors.name && (
                  <MessageErrorName>{errors.name.message}</MessageErrorName>
                )}
              </ContainerInput>
              <ActionWrapper
                colStart={GridItemBtnSave.colStart}
                colEnd={GridItemBtnSave.colEnd}
                nameStart={GridItemBtnSave.nameStart}
                nameEnd={GridItemBtnSave.nameEnd}>
                <Button
                  label="Enregistrer"
                  isFluid={true}
                  sizeType="s"
                  variant="primary"
                  fontWeight="BOLD"
                  type="submit"
                  letterSpacing="S"
                />
              </ActionWrapper>
              {mode === 'edit' && (
                <ActionWrapper
                  colStart={GridItemBtnDelete.colStart}
                  colEnd={GridItemBtnDelete.colEnd}
                  nameStart={GridItemBtnDelete.nameStart}
                  nameEnd={GridItemBtnDelete.nameEnd}>
                  <Button
                    isFluid={false}
                    sizeType="s"
                    fontWeight="BOLD"
                    variant="cancel"
                    onClick={() => setConfirmDelete(true)}
                    icon={<TrashBinIcon width="14" height="16" />}
                    label="Supprimer"
                    letterSpacing="S"
                  />
                </ActionWrapper>
              )}
            </SectionNameWrapper>
            <Grid col={7} start="large">
              <GridItem colEnd={6} nameEnd="gutter">
                <ItemWrapper>
                  <Regulation>
                    <p>
                      En cliquant sur &ldquo;Enregistrer&rdquo; vous confirmez
                      prendre la responsabilitée des informations fournies
                      (droit d&#39;auteur photo, TVA, ...)
                    </p>
                  </Regulation>
                  <ItemInfo>
                    <TitleLarge>Infos produit</TitleLarge>
                    <SellAsOptionWrapper type="option">
                      <CheckboxSwitch
                        name="sellingAsOption"
                        id="selling-as-option"
                        defaultValue={dataEdit?.sellingAsOption}
                        isChildLeft={false}
                        register={register}>
                        <DisplayText
                          fontSize="L"
                          fontWeight="BOLD"
                          className="title">
                          Vendre également en tant qu&#39;option
                        </DisplayText>
                      </CheckboxSwitch>
                      <InfoIconWrapper>
                        <InfoIcon />
                        <ShowInfoHover>
                          <p>
                            En cochant cette case, ce produit sera également
                            disponible en tant qu&#39;option et pourra être
                            rattaché à un autre produit dans un groupe
                            d&#39;option.
                          </p>
                          <p>
                            (Exemple : Ajouter un sandwich dans une formule,
                            vendre un fromage dans un plateau de fromage à
                            composer,... )
                          </p>
                        </ShowInfoHover>
                      </InfoIconWrapper>
                    </SellAsOptionWrapper>

                    <ReferenceWrapper>
                      <TitleSmall>Référence</TitleSmall>
                      <TextInput
                        placeholder="Veuillez saisir la référence de votre produit ici. (facultatif)"
                        name="reference"
                        register={register}
                      />
                    </ReferenceWrapper>

                    <UploadImageStyle>
                      <TitleSmall>Photo</TitleSmall>
                      <UploadImageCrop
                        id="upload"
                        name="files"
                        register={register}
                        watchValue={watchChangeInputFile}
                        dataImage={dataEdit?.images}
                        getValues={getValues}
                        setValue={setValue}
                        errors={errors}
                        errorFileSizeLarge={toastErrorFileSizeLarge}
                        imgCropped={imgCropped}
                        setImgCropped={setImgCropped}
                      />
                    </UploadImageStyle>

                    <DescriptionWrapper>
                      <Textarea
                        label="Description"
                        countLength={true}
                        name="description"
                        defaultValue={dataEdit?.description}
                        vaLengthDefault={dataEdit?.description?.length}
                        register={register}
                        maxlength={400}></Textarea>
                    </DescriptionWrapper>
                    <CategoriesWrapper>
                      <TitleSmall>Catégories</TitleSmall>
                      <SearchWrapper>
                        <SearchWidth>
                          <Search
                            change={onChangeSearch}
                            placeholder="Ajouter le produit à des catégories"
                            variant="default"
                            options={listCategories?.['hydra:member']
                              .filter(
                                (item: CategoryItemData) =>
                                  categoriesSelected.indexOf(item.code) === -1
                              )
                              .map((item: CategoryItemData) => ({
                                label: item.name,
                                value: item.code
                              }))}
                            isMulti={true}
                            value={[]}
                          />
                        </SearchWidth>
                        <SearchResult>
                          {listCategories?.['hydra:member']
                            .filter((item: CategoryItemData) =>
                              categoriesSelected.includes(item.code)
                            )
                            .map((item: CategoryItemData) => (
                              <Tag
                                key={item['@id']}
                                label={item.name}
                                className="tag"
                                onDelete={() => handleDeleteTag(item.code)}
                              />
                            ))}
                        </SearchResult>
                      </SearchWrapper>
                    </CategoriesWrapper>

                    <SellProductWithUnits>
                      <CheckboxSwitch
                        name="isSellProductWithUnits"
                        id="isSellProductWithUnits"
                        isChildLeft={false}
                        handleChange={calculateProductFinalPrice}
                        register={register}>
                        <DisplayText
                          label="Produit vendu au poids / litre"
                          fontSize="L"
                          fontWeight="BOLD"
                          className="title"
                        />
                      </CheckboxSwitch>
                    </SellProductWithUnits>

                    {watchSellProductUnits && (
                      <ProductWithUnitsWrapper>
                        <BlockItemWrapper>
                          <TitleWrapper>
                            <TitleSmall>Prix unitaire TTC</TitleSmall>
                          </TitleWrapper>
                          <NumberInput
                            name="pricePerUnit"
                            validate={{ required: true }}
                            register={register}
                            variant="unit"
                            setValue={setValue}
                            getValues={getValues}
                            type="text"
                            onBlur={calculateProductFinalPrice}
                          />
                        </BlockItemWrapper>
                        <BlockItemWrapper>
                          <TitleWrapper>
                            <TitleSmall>Unité</TitleSmall>
                          </TitleWrapper>
                          <BlockRelative className="unit-type">
                            <DropdownList
                              name="unitType"
                              variant="selectSm"
                              placeholder="Select"
                              options={UNIT_TYPE}
                              control={control}
                              validate={{
                                required: {
                                  value: true,
                                  message: 'La unit est obligatoire'
                                }
                              }}
                              hasError={Boolean(errors.unitType)}
                            />
                            {errors.unitType && (
                              <MessageErrorName>
                                {errors.unitType.message}
                              </MessageErrorName>
                            )}
                          </BlockRelative>
                        </BlockItemWrapper>
                        <BlockItemWrapper>
                          <TitleWrapper>
                            <TitleSmall>Quantité</TitleSmall>
                          </TitleWrapper>
                          <NumberInput
                            name="quantityProductSoldByWeight"
                            variant="unit"
                            type="text"
                            register={register}
                            getValues={getValues}
                            setValue={setValue}
                            noIcon={true}
                            numberToFixed={3}
                            onBlur={calculateProductFinalPrice}
                            unitType={watchUnitType?.value}
                          />
                        </BlockItemWrapper>
                      </ProductWithUnitsWrapper>
                    )}

                    <PriceVatWrapper>
                      <FieldPrice
                        control={control}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        onBlur={calculateProductFinalPrice}
                        defaultValue={dataEdit && dataEdit.originalPrice / 100}
                      />
                      <BlockItemWrapper>
                        <TitleSmall>TVA</TitleSmall>
                        <BlockRelative>
                          <DropdownList
                            name="vat"
                            variant="selectSm"
                            placeholder=""
                            options={VAT_OPTIONS}
                            control={control}
                            validate={{
                              required: {
                                value: true,
                                message: 'La TVA est obligatoire'
                              }
                            }}
                            hasError={Boolean(errors.vat)}
                            defaultValue={
                              VAT_OPTIONS.filter(
                                (option) =>
                                  option.value === dataEdit?.includedTax
                              )[0]
                            }
                          />
                          {errors.vat && (
                            <MessageErrorName>
                              {errors.vat.message}
                            </MessageErrorName>
                          )}
                        </BlockRelative>
                      </BlockItemWrapper>
                    </PriceVatWrapper>

                    {/* Apply for next version */}
                    {/* <PriceWithoutVatWrapper>
                      <TitleWrapper>
                        <TitleSmall>Price not subject to VAT</TitleSmall>
                      </TitleWrapper>
                      <NumberInput
                        name="priceWithoutVAT"
                        validate={{ required: true }}
                        register={register}
                        variant="unit"
                        setValue={setValue}
                        getValues={getValues}
                        type="text"
                        defaultValue={
                          dataEdit && dataEdit.priceWithoutVAT / 100
                        }
                      />
                    </PriceWithoutVatWrapper> */}

                    <SellAsOptionWrapper>
                      <CheckboxSwitch
                        name="isReduction"
                        id="isReduction"
                        isChildLeft={false}
                        handleChange={calculateProductFinalPrice}
                        register={register}>
                        <DisplayText
                          fontSize="L"
                          fontWeight="BOLD"
                          className="title"
                          label="Ajouter une réduction au produit"
                        />

                        <InfoIconWrapper>
                          <InfoIcon />
                          <ShowInfoHover>
                            <p>Lorem ipsum...</p>
                          </ShowInfoHover>
                        </InfoIconWrapper>
                      </CheckboxSwitch>
                    </SellAsOptionWrapper>
                    {watchSellAsOption && (
                      <React.Fragment>
                        <PriceWithoutVatWrapper className="promotion-wrapper">
                          <BlockItemWrapper>
                            <TitleWrapper>
                              <TitleSmall>Pourcentage de réduction</TitleSmall>
                            </TitleWrapper>
                            <NumberInput
                              name="reductionPercent"
                              variant="percent"
                              register={register}
                              setValue={setValue}
                              getValues={getValues}
                              type="text"
                              onBlur={calculateProductFinalPrice}
                            />
                          </BlockItemWrapper>
                        </PriceWithoutVatWrapper>
                        <TimelineWrapper>
                          <TitleWrapper>
                            <TitleSmall>Durée de la réduction</TitleSmall>
                          </TitleWrapper>
                          <div className="dateAndTime__wrapper">
                            <div>
                              <div className="dateAndTime__title">
                                Date de début
                              </div>
                              <CustomDatePicker
                                onChange={(date: Date) => {
                                  setStartDate(date);
                                  const s = new Date(date);
                                  const e = new Date(endDate);
                                  if (s.getTime() > e.getTime()) {
                                    setEndDate(date);
                                  }
                                }}
                                variant="default"
                                icon={<CalendarIcon />}
                                id="startDate"
                                placeholderText="DD/MM/YYYY"
                                selected={startDate}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                minDate={new Date()}
                              />
                            </div>
                            <div>
                              <div className="dateAndTime__title">
                                Heure de début
                              </div>
                              <CustomDatePicker
                                onChange={(date: Date) =>
                                  setEndDate(endOfDay(date))
                                }
                                variant="default"
                                icon={<CalendarIcon />}
                                id="endDate"
                                placeholderText="DD/MM/YYYY"
                                selected={endDate}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                              />
                            </div>
                          </div>
                        </TimelineWrapper>
                      </React.Fragment>
                    )}
                    <PriceWithoutVatWrapper>
                      <FieldProductFinalPrice
                        control={control}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        defaultValue={
                          dataEdit && dataEdit.variants[0].price / 100
                        }
                      />
                    </PriceWithoutVatWrapper>
                  </ItemInfo>
                  <Collapse
                    title="Personnalisez votre produit"
                    isOpen={state[0]}
                    toggle={() => dispatch({ type: 'toggle', idx: 0 })}
                    subtitle={
                      <>
                        <SellAsOptionWrapper>
                          <CheckboxSwitch
                            name="notMultipleQuantityOption"
                            id="isNotMultipleQuantityOption"
                            isChildLeft={true}
                            register={register}>
                            <DisplayText
                              fontSize="L"
                              fontWeight="BOLD"
                              className="title"
                              label="Ne pas multiplier la quantité d'option par la quantité de produit"
                            />
                          </CheckboxSwitch>
                        </SellAsOptionWrapper>
                        <p>
                          Personnalisez les produits en y ajoutant des groupes
                          d&#39;options.
                        </p>
                        <p>
                          Cela permettra aux clients de personnaliser le produit
                          en choississant par exemple la taille, des garnitures
                          ou des accompagnements.
                        </p>
                      </>
                    }>
                    <CurrentGroupOption
                      currentGroupOptionSelected={currentGroupOptionSelected}
                      onOpenModal={onOpenModal}
                      onRemoveGroupOptionItem={handleRemoveGroupOptionItem}
                      onHandleDragGO={handleDragGO}
                    />
                  </Collapse>
                  <Collapse
                    title="Propriétés du produit"
                    isOpen={state[1]}
                    toggle={() => dispatch({ type: 'toggle', idx: 1 })}
                    subtitle="Inclus les propriétés de personnalisation (options)">
                    <OptionWrapper>
                      <TitleSmall>
                        Produit contenant de l&#39;alcool ?
                      </TitleSmall>
                      <RadioWrapper className="option-right-wrapper">
                        <Radio
                          name="isAlcoholicProduct"
                          id="yes"
                          value="true"
                          validate={{
                            required: true,
                            value: defaultAlcoholic
                          }}
                          className="alcoholic-item option-item"
                          register={register}>
                          Oui
                        </Radio>
                        <Radio
                          name="isAlcoholicProduct"
                          id="no"
                          value="false"
                          validate={{ required: true, value: defaultAlcoholic }}
                          className="alcoholic-item option-item"
                          register={register}>
                          Non
                        </Radio>
                      </RadioWrapper>
                    </OptionWrapper>
                    <OptionWrapper>
                      <TitleSmall>Cartéristiques alimentaires</TitleSmall>
                      <CheckboxWrapper className="option-right-wrapper">
                        {dietary?.map((item, i) => {
                          return (
                            <div key={i} className="dietary-item option-item">
                              <Checkbox
                                name="dietaryAttributes"
                                id={item.label}
                                value={item.value}
                                defaultChecked={checkedDietary(item.label)}
                                register={register}>
                                {item.label}
                              </Checkbox>
                            </div>
                          );
                        })}
                      </CheckboxWrapper>
                    </OptionWrapper>
                    <OptionWrapper>
                      <TitleSmall>Listes des allergènes</TitleSmall>
                      <CheckboxWrapper className="option-right-wrapper">
                        {allergen?.map((item, i) => (
                          <div key={i} className="allergen-item option-item">
                            <Checkbox
                              name="allergenAttributes"
                              id={item.label}
                              value={item.value}
                              defaultChecked={checkedAllergen(item.label)}
                              register={register}>
                              {item.label}
                            </Checkbox>
                          </div>
                        ))}
                      </CheckboxWrapper>
                    </OptionWrapper>
                  </Collapse>
                </ItemWrapper>
              </GridItem>
            </Grid>
          </form>
        </FormLeftLayout>
        <PanelRightLayout>
          <OptionItemWrapper></OptionItemWrapper>
        </PanelRightLayout>
      </FormCreateEditLayout>

      <ModalPrompt
        isShow={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onConfirm={() => handleDelete(true)}
        title={CONFIRM_MESSAGE.DELETE_PRODUCT}
        confirmType="withTrashBin"
      />
      {isLoadingDeleteProduct && <Loader />}
    </>
  );
};

export default ProductForm;
