import Loader from 'components/Loader';
import DisplayText from 'components/ui-kits/DisplayText';
import { DONT_DATA_MESSAGE } from 'const/Message';
import React from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { CategoryItemData } from 'types/Category';
import CategoryItem from './CategoryItem';
import { getListCategoriesV2 } from 'services/categoryService';

const EmptyListWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 14.6rem;
`;

const CategoryList = () => {
  const { data, isLoading, isFetching } = useQuery(
    ['getListCategories', { itemsPerPage: 1000 }],
    (context) => getListCategoriesV2(context.queryKey, context.signal),
    { staleTime: Infinity, keepPreviousData: true }
  );
  return (
    <>
      <>
        {(isLoading || isFetching) && <Loader isFixed={false} />}
        {!data || data?.['hydra:member'].length === 0 ? (
          <EmptyListWrapper>
            <DisplayText
              label={DONT_DATA_MESSAGE('catégories')}
              fontSize="S"
              fontWeight="REGULAR"
              color="GREY_1"
            />
          </EmptyListWrapper>
        ) : (
          data['hydra:member'].map((item: CategoryItemData) => {
            if (item.name) {
              return (
                <CategoryItem
                  key={item['@id']}
                  totalProducts={item.numberOfProducts}
                  listProduct={item.products}
                  data={item}
                />
              );
            }
          })
        )}
      </>
    </>
  );
};

export default CategoryList;
