import React from 'react';
import {
  MerchantCustomerWrapper,
  MerchantHeadWrapper
} from './MerchantCustomer.style';
import GoBack from 'components/GoBack';
import MerchantHead from '../../InvoicesMerchantHead/MerchantHead';
import InformationCustomer from '../../InvoicesMerchantInformation/InformationCustomer';
import { useQuery } from 'react-query';

import Loader from 'components/Loader';
import { customerInvoicesV2 } from 'services/invoicesService';

interface Iprops {
  onId: number;
}

const MerchantCustomer = ({ onId }: Iprops) => {
  const {
    isLoading: loadingData,
    data: dataCustomerInvoices
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQuery(['getListCustomerInvoices', onId], ({ signal }) =>
    customerInvoicesV2(onId, signal)
  );

  return (
    <MerchantCustomerWrapper>
      <MerchantHeadWrapper>
        <GoBack />
        <MerchantHead
          onData={dataCustomerInvoices}
          label="Facture du commerçant pour le client"
        />
      </MerchantHeadWrapper>
      <InformationCustomer onData={dataCustomerInvoices} />
      {loadingData ? <Loader /> : null}
    </MerchantCustomerWrapper>
  );
};

export default MerchantCustomer;
