import React, { useState, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { useRouteMatch, useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import {
  SPACING_PRODUCT_OPTIONS,
  ITEMS_PERPAGE_PRODUCT
} from 'styles/variables';
import ListProduct from './List/List';
import { RootState } from 'app/store';
import Title from 'components/ui-kits/Title';
import Sort from 'components/Sort';
import { SearchStyle, Wrapper, WrapperRight } from 'styles/listing-page';
import ListLayout from 'components/ListLayout';
import Button from 'components/ui-kits/Button';
import ModalPrompt from 'components/ui-kits/ModalPrompt';
import { handleError, handleSuccess } from 'utils';
import Loader from 'components/Loader';
import {
  IMPORT_PRODUCT_ERROR_MESSAGE,
  TITLE_CONFIRM_IMPORT_PRODUCT_MESSAGE
} from 'const/Message';
import { PaginationWrapper } from 'styles/common';
import TextInput from 'components/ui-kits/Form/TextInput';
import { SearchIcon } from 'components/ui-kits/Icon';
import { statusPageProduct } from 'features/pagePagination/pagePaginationSlice';
import { getProducts, importCSVProductV2 } from 'services/productService';

const TableWrapper = styled.div`
  margin: ${SPACING_PRODUCT_OPTIONS.TABLE};
  overflow-x: auto;
`;

const Products = () => {
  const { merchantId } = useSelector((state: RootState) => state.user);
  const { currentPage, search } = useSelector(
    (state: RootState) => state.pagePagination
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [fileCSV, setFileCSV] = useState<File | null>(null);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState(0);

  const {
    control,
    register,
    setValue,
    getValues,
    formState: { isDirty }
  } = useForm();

  const watchFile = useWatch<any>({
    control,
    name: 'file'
  });

  const watchSearch = useWatch({
    control,
    defaultValue: search,
    name: 'search'
  });

  const params = {
    itemsPerPage: ITEMS_PERPAGE_PRODUCT,
    'merchant.id': merchantId,
    isOption: false,
    page: currentPage,
    'translations.name': search
  };

  const {
    isLoading: isLoadingProducts,
    isFetching,
    data: listProduct
  } = useQuery(
    ['getListProducts', { params }],
    ({ signal }) => getProducts({ signal, ...params }),
    { staleTime: Infinity }
  );

  useEffect(() => {
    if (listProduct?.data) {
      const totalItem: number = listProduct.data['hydra:totalItems'];
      setPageCount(Math.ceil(totalItem / ITEMS_PERPAGE_PRODUCT));
    }
  }, [listProduct]);

  useEffect(() => {
    if (watchFile) {
      setFileCSV(watchFile[0]);
    }

    if (fileCSV) {
      setIsModal(true);
    }
  }, [watchFile, fileCSV]);

  const { isLoading: isLoadingImport, mutate: importFileMutate } = useMutation(
    (file: any) => importCSVProductV2(file)
  );

  const handleImportProduct = (isImportFile: boolean) => {
    if (isImportFile && fileCSV) {
      const fd = new FormData();
      fd.append('file', fileCSV);

      importFileMutate(fd, {
        onSuccess: () => {
          handleSuccess(
            <>
              <div>
                L&#39;importation de votre liste de produit est en cours,
              </div>
              <div>
                un email vous sera envoyé dès que l&#39;opération sera terminée.
              </div>
            </>,
            5000
          );
        },
        onError: () => {
          handleError(undefined, IMPORT_PRODUCT_ERROR_MESSAGE);
        }
      });
      cancelImportProduct();
    }
  };

  const cancelImportProduct = () => {
    setIsModal(false);
    setFileCSV(null);
    setValue('file', '');
  };

  useEffect(() => {
    if (!watchSearch && isDirty) {
      dispatch(
        statusPageProduct({
          currentPage: 1,
          search: ''
        })
      );
    }
  }, [watchSearch, getValues, dispatch, isDirty]);

  const handlePageClick = (event: any) => {
    const currPage = event.selected + 1;
    dispatch(
      statusPageProduct({
        currentPage: currPage,
        search: getValues('search')
      })
    );
  };

  const handleSearch = () => {
    dispatch(
      statusPageProduct({
        currentPage: 1,
        search: getValues('search')
      })
    );
  };

  return (
    <React.Fragment>
      <ListLayout>
        <Wrapper>
          <Title label="Produits" hasSearch={true} />
          <WrapperRight>
            <Sort />
            <SearchStyle>
              <TextInput
                register={register}
                name="search"
                placeholder="Rechercher un produit..."
                className="default__control"
                defaultValue={search}
              />
              <button
                className={`search-icon ${
                  watchSearch?.length > 2 ? 'active' : 'disabled'
                }`}
                disabled={Boolean(!watchSearch || watchSearch?.length <= 2)}
                onClick={handleSearch}>
                <SearchIcon />
              </button>
            </SearchStyle>
            <div>
              <Button
                variant="primary"
                fontWeight="BOLD"
                onClick={() => history.push(`${path}/create`)}>
                + CRÉER UN PRODUIT
              </Button>
            </div>
            <div className="import-product">
              <label htmlFor="file-import">IMPORTER</label>
              <input
                id="file-import"
                className="hide"
                type="file"
                accept=".csv"
                {...register('file')}
              />
            </div>
          </WrapperRight>
        </Wrapper>
        <TableWrapper>
          {pageCount > 1 && (
            <PaginationWrapper>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel="<"
                pageRangeDisplayed={4}
                className="pagination"
                pageClassName="pagination-item"
                pageLinkClassName="pagination-link"
                activeLinkClassName="pagination-linkActive"
                forcePage={currentPage - 1}
              />
            </PaginationWrapper>
          )}
          {isLoadingProducts || isFetching ? (
            <Loader isFixed={false} />
          ) : (
            <ListProduct products={listProduct?.data?.['hydra:member']} />
          )}
        </TableWrapper>
      </ListLayout>
      <ModalPrompt
        isShow={isModal}
        onClose={cancelImportProduct}
        onConfirm={() => handleImportProduct(true)}
        title={TITLE_CONFIRM_IMPORT_PRODUCT_MESSAGE}
        textTransform="capitalize"
      />
      {isLoadingImport && <Loader />}
    </React.Fragment>
  );
};

export default Products;
