import styled from 'styled-components';
import { COLORS } from 'styles/variables';
import DisplayText from 'components/ui-kits/DisplayText';
import Button from 'components/ui-kits/Button';

export const CardItem = styled.div`
  position: relative;
  border: 1px solid ${COLORS.GREY_7};
  border-radius: 0.7rem;
  background-color: ${COLORS.WHITE};
  margin-bottom: 2.8rem;
`;
export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 2.8rem;
  border-bottom: 1px solid ${COLORS.GREY_4};
`;
export const CardFooter = styled.div`
  padding-left: 8rem;
  button {
    padding: 1.4rem 0;
    letter-spacing: 0;
    color: ${COLORS.BLACK_2};
  }
`;
export const NameCategory = styled(DisplayText)`
  padding-left: 2.8rem;
`;

export const CollapseIcon = styled.div`
  transform: rotate(0);
  transition: all 0.4s;
  cursor: pointer;
  svg {
    fill: ${COLORS.BLACK_2};
  }
  &.active {
    transform: rotate(180deg);
    transition: all 0.4s;
  }
`;
export const CardHeaderLeft = styled.div`
  display: flex;
  align-items: center;

  .sort {
    margin-left: 1.5rem;

    button {
      margin: 0 1rem;
      font-size: 1.8rem;
    }

    svg {
      transform: rotate(90deg);
    }
  }
`;
export const CardHeaderRight = styled.div`
  display: flex;
  align-items: center;
`;
export const GripLines = styled.div`
  position: relative;
  width: 2.4rem;
  height: 0.3rem;
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 0.3rem;
    background-color: ${COLORS.GREY_1};
  }
  &::before {
    top: -0.3rem;
  }
  &::after {
    top: 0.3rem;
  }
`;
export const TotalProductWrapper = styled(DisplayText)`
  color: ${COLORS.GREY_1};
  margin-right: 4rem;
`;

export const CloseIconStyle = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  z-index: 5;

  padding: 0;
  width: 3rem;
  height: 3rem;
  min-width: auto;
  border-radius: 50%;
  background-color: ${COLORS.BLACK_3};
  svg {
    width: 2rem;
    margin-right: 0;
  }
`;
