import DisplayText from 'components/ui-kits/DisplayText';
import React, { useState } from 'react';
import { FieldValues, UseFormGetValues } from 'react-hook-form';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';

interface ITextarea {
  name: string;
  register: any;
  variant?: 'default';
  label?: string;
  defaultValue?: string;
  maxlength?: number;
  placeholder?: string;
  countLength?: boolean;
  validate?: any;
  showLabel?: boolean;
  getValues?: UseFormGetValues<FieldValues>;
  disabled?: boolean;
  vaLengthDefault?: number;
}

const Wrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
`;
const LabelWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const TextareaStyle = styled.textarea<{ disabled?: boolean }>`
  width: 100%;
  height: 12.5rem;
  background-color: ${(props) =>
    props.disabled ? COLORS.GREY_4 : COLORS.WHITE};
  border: 1px solid ${COLORS.GREY_4};
  border-radius: 0.7rem;
  resize: none;
  padding: 2rem;
  color: ${COLORS.BLACK_2};
  font-size: 1.4rem;
  line-height: 2.3rem;
  letter-spacing: 0.36px;
  &::placeholder {
    opacity: 0.5;
  }
`;

const Textarea = ({
  name,
  register,
  maxlength,
  variant = 'default',
  placeholder,
  validate,
  defaultValue,
  countLength = false,
  label,
  showLabel = true,
  disabled,
  vaLengthDefault
}: ITextarea) => {
  const [isFocus, setIsFocus] = useState(false);
  const [vaLength, setValLength] = useState(vaLengthDefault || 0);
  const registerField = register?.(name, {
    ...validate,
    maxlength: maxlength
  });
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = e.target.value;
    if (countLength) {
      setValLength(val.length);
    }
  };
  return (
    <Wrapper>
      <LabelWrapper>
        {showLabel && (
          <DisplayText
            className="text-arealabel"
            fontSize="M"
            fontWeight="BOLD"
            label={label}
            as="label"
          />
        )}
        {countLength && maxlength && (
          <p>
            {vaLength}/{maxlength}
          </p>
        )}
      </LabelWrapper>

      <TextareaStyle
        {...registerField}
        isFocus={isFocus}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onChange={(e) => {
          registerField?.onChange(e);
          handleChange(e);
        }}
        defaultValue={defaultValue}
        variant={variant}
        disabled={disabled}
        placeholder={placeholder}
        countLength={countLength}
        maxLength={maxlength}></TextareaStyle>
    </Wrapper>
  );
};

export default Textarea;
