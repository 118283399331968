import React, { useEffect, useMemo } from 'react';
import ListLayout from 'components/ListLayout';
import { SearchStyle, Wrapper, WrapperRight } from 'styles/listing-page';
import Title from 'components/ui-kits/Title';
import Sort from 'components/Sort';
import TextInput from 'components/ui-kits/Form/TextInput';
import { SearchIcon } from 'components/ui-kits/Icon';
import Button from 'components/ui-kits/Button';
import {
  ITEMS_PERPAGE_PRODUCT,
  SPACING_PRODUCT_OPTIONS
} from 'styles/variables';
import styled from 'styled-components';
import Loader from 'components/Loader';
import OptionList from './List/List';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { AxiosResponse } from 'axios';
import { ProductOptionDataResV3 } from 'types/ProductOption';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { statusPageOption } from 'features/keepSearchPaginationOption/keepSearchPaginationOptionSlice';
import { PaginationWrapper } from 'styles/common';
import ReactPaginate from 'react-paginate';
import { getProductOptions } from 'services/productOptionService';

const TableWrapper = styled.div`
  margin: ${SPACING_PRODUCT_OPTIONS.TABLE};
  overflow-x: auto;
`;

export default function Options() {
  const { merchantId } = useSelector((state: RootState) => state.user);
  const { currentPage, search } = useSelector(
    (state: RootState) => state.searchPaginationOption
  );
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    control,
    register,
    getValues,
    formState: { isDirty }
  } = useForm();
  const params = {
    isOptionOrSellingAsOption: true,
    'merchant.id': merchantId,
    itemsPerPage: ITEMS_PERPAGE_PRODUCT,
    page: currentPage,
    isProductScreen: false,
    'translations.name': search
  };
  const watchSearch = useWatch({
    control,
    defaultValue: search,
    name: 'search'
  });
  const {
    data: productOptions,
    isLoading: isLoadingOptions,
    isFetching
  } = useQuery(
    ['options', { params }],
    ({ signal }) => getProductOptions({ signal, ...params }),
    {
      staleTime: Infinity,
      select(result: AxiosResponse<ProductOptionDataResV3>) {
        return result.data;
      }
    }
  );
  useEffect(() => {
    if (!watchSearch && isDirty) {
      dispatch(
        statusPageOption({
          currentPage: 1,
          search: ''
        })
      );
    }
  }, [watchSearch, getValues, dispatch, isDirty]);
  const handlePageClick = (event: any) => {
    const currPage = event.selected + 1;
    dispatch(
      statusPageOption({
        currentPage: currPage,
        search: getValues('search')
      })
    );
  };

  const handleSearch = () => {
    dispatch(
      statusPageOption({
        currentPage: 1,
        search: getValues('search')
      })
    );
  };

  const pageCount = useMemo(() => {
    if (productOptions) {
      const totalItem: number = productOptions['hydra:totalItems'];
      return Math.ceil(totalItem / ITEMS_PERPAGE_PRODUCT);
    }
    return 0;
  }, [productOptions]);

  return (
    <React.Fragment>
      <ListLayout>
        <Wrapper>
          <Title label="Options" hasSearch={true} />
          <WrapperRight>
            <Sort />
            <SearchStyle>
              <TextInput
                register={register}
                defaultValue={search}
                name="search"
                placeholder="Rechercher une option..."
                className="default__control"
              />
              <button
                className={`search-icon ${
                  watchSearch?.length > 2 ? 'active' : 'disabled'
                }`}
                disabled={Boolean(!watchSearch || watchSearch?.length <= 2)}
                onClick={handleSearch}>
                <SearchIcon />
              </button>
            </SearchStyle>
            <div>
              <Button
                variant="primary"
                fontWeight="BOLD"
                onClick={() => history.push(`${path}/create`)}>
                + CRÉER UNE OPTION
              </Button>
            </div>
          </WrapperRight>
        </Wrapper>
        <TableWrapper>
          {pageCount > 1 && (
            <PaginationWrapper>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel="<"
                pageRangeDisplayed={4}
                className="pagination"
                pageClassName="pagination-item"
                pageLinkClassName="pagination-link"
                activeLinkClassName="pagination-linkActive"
                forcePage={currentPage - 1}
              />
            </PaginationWrapper>
          )}
          {isLoadingOptions || isFetching ? (
            <Loader isFixed={false} />
          ) : (
            <OptionList data={productOptions?.['hydra:member']} />
          )}
        </TableWrapper>
      </ListLayout>
    </React.Fragment>
  );
}
