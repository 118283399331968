import api from 'api/setupAxios';
import {
  CATALOG_PRODUCT,
  PRODUCT,
  PRODUCT_BY_CODE,
  PRODUCT_IMPORT,
  PRODUCT_UPLOAD_IMAGE,
  TAXON_PRODUCT
} from 'const/endpoint';
import {
  ProductListDataResV2,
  ProductOptionListData,
  ProductOptionV2,
  SendProduct
} from 'types/Products';

const PRODUCT_CHUNKING_PER_PAGE = 100;

export const getProducts = ({ signal, ...params }: GetProductsParams) => {
  return api.call(signal).get(PRODUCT, { params });
};

export const getProductByCode = ({ code, signal }: GetProductByCodeParams) => {
  return api.call(signal).get(PRODUCT_BY_CODE.replace(':code', code));
};

export const createProduct = (
  product: SendProduct | Omit<SendProduct, 'reference'>
) => {
  return api.call().post(PRODUCT, product);
};

export const updateProduct = (code: string, product: SendProduct) => {
  return api.call().put(PRODUCT_BY_CODE.replace(':code', code), product);
};

export const deleteProduct = (code: string, data: DeleteProductDataParams) => {
  const config = {
    headers: {
      'Content-Type': 'application/merge-patch+json'
    }
  };
  return api.call().patch(PRODUCT_BY_CODE.replace(':code', code), data, config);
};

export const updatePatchProduct = (
  code: string,
  data: PatchProductDataParams
) => {
  const config = {
    headers: {
      'Content-Type': 'application/merge-patch+json'
    }
  };
  return api.call().patch(PRODUCT_BY_CODE.replace(':code', code), data, config);
};

export const uploadImageV2 = (code: string, fd: FormData | null) => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  };

  if (!fd) {
    fd = new FormData();
    fd.append('files[]', [] as unknown as File); // send [] for deleting product image.
  }

  return api
    .call()
    .post(PRODUCT_UPLOAD_IMAGE.replace(':code', code), fd, config);
};

export const getProductListV2 = async (
  queryKey: any,
  signal?: AbortSignal
): Promise<ProductOptionListData> => {
  const res = await api.call(signal).get(PRODUCT, { params: queryKey });
  return res.data;
};

export const getTotalProductNumV2 = async (
  queryKey: ProductListParams,
  signal?: AbortSignal
): Promise<ProductListDataResV2> => {
  const res = await api.call(signal).get(TAXON_PRODUCT, { params: queryKey });
  return res.data;
};

export const getProductListByChunkingV2 = async (
  queryKey: ProductListParams,
  signal?: AbortSignal
): Promise<ProductListDataResV2> => {
  const responseOneItem = await getTotalProductNumV2(
    {
      ...queryKey,
      itemsPerPage: 1
    },
    signal
  ).then((res) => res);
  const totalProductNum = responseOneItem['hydra:totalItems'];
  if (totalProductNum <= 1) {
    return responseOneItem;
  }
  const promiseArray = [];
  const numberOfPath = Math.ceil(totalProductNum / PRODUCT_CHUNKING_PER_PAGE);
  for (let i = 1; i <= numberOfPath; i++) {
    promiseArray.push(
      api.call(signal).get<ProductListDataResV2>(TAXON_PRODUCT, {
        params: {
          ...queryKey,
          page: i,
          itemsPerPage: PRODUCT_CHUNKING_PER_PAGE
        }
      })
    );
  }
  const res = await Promise.all(promiseArray);
  const returnData = res.reduce((acc, cur) => {
    if (!cur || !cur.data) {
      return acc;
    }
    if (acc['hydra:member']) {
      acc['hydra:member'] = [
        ...acc['hydra:member'],
        ...cur.data['hydra:member']
      ];
    } else {
      acc['hydra:member'] = cur.data['hydra:member'];
    }
    return acc;
  }, {} as ProductListDataResV2);
  return returnData;
};

export const importCSVProductV2 = (fd: any) => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  };
  return api.call().post(PRODUCT_IMPORT, fd, config);
};

export const getProductsInCategoryV2 = async (
  params: {
    [key: string]: number;
  },
  totalPro: number,
  signal?: AbortSignal
): Promise<ProductOptionV2[]> => {
  let part = 1;

  if (!!params.itemsPerPage && totalPro > params.itemsPerPage) {
    part = Math.ceil(totalPro / params.itemsPerPage);
  }

  if (part > 1) {
    const promiseArray = [];
    for (let i = 0; i < part; i++) {
      const obj = params;
      obj.page = i + 1;
      promiseArray.push(api.call(signal).get(CATALOG_PRODUCT, { params: obj }));
    }
    const dataAll = await Promise.all(promiseArray);
    const isError = dataAll.some((item) => item.status !== 200);
    const products: ProductOptionV2[] = [];
    if (!isError) {
      dataAll.map((item) => {
        if (item.data?.['hydra:member'].length) {
          products.push(...item.data['hydra:member']);
        }
        return item;
      });
    }
    return products;
  }

  const res = await api.call(signal).get(CATALOG_PRODUCT, { params });
  return res.data?.['hydra:member'];
};

export const getTaxonProducts = ({
  limit = 5,
  page = 1,
  merchantId,
  isOption = false,
  signal,
  searchByName
}: GetTaxonProductsParams) => {
  const params = {
    itemsPerPage: limit,
    page,
    'merchant.id': merchantId,
    isOption,
    'translations.name': searchByName || undefined
  };
  return api.call(signal).get(TAXON_PRODUCT, { params });
};

type GetProductsParams = {
  signal?: AbortSignal;
  isOption: boolean;
  'merchant.id': number;
  itemsPerPage: number;
  page: number;
  'translations.name'?: string;
};
type GetProductByCodeParams = {
  code: string;
  signal?: AbortSignal;
};
type DeleteProductDataParams = { enabled: boolean };
type PatchProductDataParams = { isSoldOut: boolean };
type ProductListParams = {
  'merchant.id': number;
  isOption: boolean;
  itemsPerPage?: number;
};
type GetTaxonProductsParams = {
  limit: number;
  page: number;
  merchantId: number;
  isOption: boolean;
  signal?: AbortSignal;
  searchByName?: string;
};
