import api from 'api/setupAxios';
import { MERCHANT_SLUG } from 'const/endpoint';
import { AllowPaymentMethods } from 'types/Merchant';

export const updatePaymentMethodV2 = async (
  slug: string,
  data: { allowPaymentMethods: AllowPaymentMethods[] }
) => {
  const res = await api.call().put(MERCHANT_SLUG.replace(':slug', slug), data);
  return res.data;
};
