import React from 'react';
import {
  BlockLeftLayout,
  BlockRightLayout,
  CardInformationSubscriptionFeeWrapper
} from './CardInformationSubscriptionFee.style';
import { InvoicesInformationSubscriptionFeeRender } from 'types/InvoicesMerchantDetails';
import Logo from '../../../../../../../assets/Logo/Logo.png';

const CardInformationSubscriptionFee = ({
  invoiceNumber,
  invoiceDate,
  mainName,
  address,
  vatNumber,
  typeOfInvoice,
  startAt,
  endAt,
  dateOfBankDebit,
  bankAccount
}: InvoicesInformationSubscriptionFeeRender) => {
  return (
    <CardInformationSubscriptionFeeWrapper>
      <BlockLeftLayout>
        <div className="mainName">{mainName}</div>
        <div className="inforWrapper">
          <div className="address">{address}</div>
        </div>
        <div className="vatWrapper">
          <div className="vatLabel">
            <div className="vatNumber">Numéro de TVA</div>
          </div>
          <div className="vatLabel">
            <div className="vatNumber">{vatNumber}</div>
          </div>
        </div>
        <div className="leftBottomWapper">
          <div className="leftBottom">
            <div className="labelBottom">Numéro de Facture</div>
            <div className="labelBottom">Date facture</div>
            <div className="labelBottom">Type de facture</div>
            <div className="labelBottom">Période débité</div>
            <div className="labelBottom">Date de prélèvement</div>
            {bankAccount && (
              <div className="labelBottom">Numéro de compte débité</div>
            )}
          </div>
          <div className="leftBottom">
            <div className="valueBottom">{invoiceNumber}</div>
            <div className="valueBottom">{invoiceDate}</div>
            <div className="valueBottom">{typeOfInvoice}</div>
            <div className="valueBottom">
              {startAt} - {endAt}
            </div>
            <div className="valueBottom">{dateOfBankDebit}</div>
            {bankAccount && <div className="valueBottom">{bankAccount}</div>}
          </div>
        </div>
      </BlockLeftLayout>
      <BlockRightLayout>
        <img src={Logo} alt="" />
      </BlockRightLayout>
    </CardInformationSubscriptionFeeWrapper>
  );
};

export default CardInformationSubscriptionFee;
