import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';

export const TableRow = styled.tr`
  border-bottom: 1px solid ${COLORS.GREY_4};
  &:hover {
    background-color: ${COLORS.GREY_5};
    cursor: pointer;
  }
`;
export const TableCellWrapper = styled.td`
  padding: 1.6rem 4.6rem;

  .timeHours,
  .day {
    font-size: ${FONT_SIZE.S};
    color: ${COLORS.BLACK_2};
  }
  .timeHours {
    font-weight: ${FONT_WEIGHT.LIGHT};
  }
  .day {
    font-weight: ${FONT_WEIGHT.BOLD};
  }
`;

export const TableCell = styled.td`
  padding: 2.6rem 2rem;
  font-size: ${FONT_SIZE.M};
  color: ${COLORS.BLACK_2};
  font-weight: ${FONT_WEIGHT.BOLD};

  .orderIds {
    font-weight: ${FONT_WEIGHT.LIGHT};
  }
  .attributes {
    text-transform: uppercase;
    text-align: center;
  }
`;
