import styled from 'styled-components';
import { COLORS } from 'styles/variables';

export const DeliveryWrapper = styled.div`
  padding: 5rem 0;
  .title {
    margin-right: 7rem;
    display: inline-block;
    &-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
    }
  }
  .form-block {
    padding: 2rem 0 4rem;
    &:not(.zone) {
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: ${COLORS.GREY_4};
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    &__title {
      margin-bottom: 1.1rem;
      &-deli {
        margin-bottom: 1.5rem;
      }
      &-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }

      &-left {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
      }
    }

    .action-wrapper {
      display: flex;
      align-items: center;

      .close-edit {
        margin-left: 1rem;
      }

      svg {
        fill: ${COLORS.GREY_9};
      }
    }
  }
  .form-row {
    display: flex;
    align-items: center;
    margin-top: 2.8rem;
    min-height: 4rem;
  }
  .isMinimum-wrapper {
    margin-right: 5rem;
    display: flex;
    align-items: center;
  }
  .radio-field {
    margin-bottom: 1.5rem;
  }
`;
