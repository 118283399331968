import axios from 'axios';
import { JWT_TOKEN } from 'const/Const';

const api = {
  call(signal?: AbortSignal) {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 60000
    });

    instance.interceptors.request.use((config) => {
      const token = localStorage.getItem(JWT_TOKEN);
      config.headers;
      if (token) {
        (config as any).headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`
        };
      }
      return { ...config, signal };
    });

    instance.interceptors.response.use(
      (config) => {
        return config;
      },
      (error) => {
        if (
          error.response.status === 401 &&
          error.response?.data?.message === 'Expired JWT Token'
        ) {
          localStorage.removeItem(JWT_TOKEN);
          window.location.href = '/login';
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }
};

export default api;
