export const COLORS = {
  WHITE: '#FFFFFF',
  WHITE_1: '#FAFAFA',
  WHITE_2: '#F1F9FF',
  WHITE_3: '#FCFAF7',
  BLACK: '#000000',
  BLACK_1: '#1A1818',
  BLACK_2: '#464A51',
  BLACK_3: '#676767',
  BLACK_4: '#4D4343',
  BLACK_5: '#101113',
  GREY: '#808080',
  GREY_1: '#8D9598',
  GREY_2: '#dcdcdc',
  GREY_3: '#878c97',
  GREY_4: '#C8CBD0',
  GREY_5: '#E9EAEC',
  GREY_6: '#707070',
  GREY_7: '#C8C8C8',
  GREY_8: '#5F646D',
  GREY_9: '#676767',
  GREY_10: '#4D4343',
  GREY_11: '#B0B2B2',
  GREY_12: '#727883',
  GREY_13: '#ECEFF3',
  RED: '#FF6F61', // default
  RED_1: '#E82830',
  RED_2: '#f44336',
  RED_3: '#d32f2f',
  RED_4: '#FFBBBE', // disabled
  RED_5: '#F15B4D', // hover/active
  RED_6: '#F8420B', // alert/error
  PINK: '#ff4081',
  BLUE: '#1976d2',
  BLUE_1: '#7DB4C4', // default
  BLUE_2: '#51A1B8', // hover/active
  GREEN: '#2e7d32',
  GREEN_1: '#60DDB2',
  TRANSPARENT: 'transparent',
  ZONE_1: '#00AAB9',
  ZONE_2: '#E3B023',
  ZONE_3: '#09A168',
  ZONE_4: '#E36D54',
  ZONE_5: '#9C27B0',
  ORANGE: '#F8420B'
};

export const HEADER_HEIGHT = '5.4rem';

export const FONT_WEIGHT = {
  THIN: '100',
  LIGHT: '300',
  REGULAR: '400',
  MEDIUM: '500',
  BOLD: '700',
  BLACK: '900'
};

export const FONT_SIZE = {
  XS: '1.2rem',
  S: '1.4rem',
  M: '1.6rem',
  L: '1.8rem',
  XL: '2.4rem',
  XXL: '3.2rem',
  XXLS: '3.6rem',
  XXLM: '4rem',
  XXXL: '4.8rem'
};

export const LETTER_SPACING = {
  XS: '0',
  S: '0.14rem',
  M: '0.16rem'
};

export const GRID = {
  COLUMN_S: 84,
  GUTTER_S: 18,
  WIDTH_S: 84 * 12 + 18 * 11,
  COLUMN_M: 105,
  GUTTER_M: 23,
  WIDTH_M: 105 * 12 + 23 * 11,
  COLUMN_L: 126,
  GUTTER_L: 28,
  WIDTH_L: 126 * 12 + 28 * 11
};

export const GUTTER = {
  DESKTOP_L: 28,
  DESKTOP_M: 22,
  DESKTOP_S: 18
};

export const MARGIN = {
  DESKTOP_L: 100,
  DESKTOP_M: 50,
  DESKTOP_S: 26
};

export const BREAKPOINT = {
  DESKTOP_L: 1920,
  DESKTOP_M: 1600,
  DESKTOP_S: 1280
};
export const MEDIA_BREAKPOINT = {
  DESKTOP_L: '(min-width: 1920px)',
  DESKTOP_M: '(min-width: 1600px) and (max-width: 1919.9px)',
  DESKTOP_S: '(max-width: 1599.9px)'
};

export const SPACING_PRODUCT_OPTIONS = {
  TITLE: '5.6rem 0 0 0',
  FILTER: '5.6rem 0',
  TABLE: '0 0 5.6rem 0'
};

export const GRID_COLUMN_WIDTH = {
  DESKTOP_L: `calc((1920px - ${GUTTER.DESKTOP_L * 11}px - ${
    MARGIN.DESKTOP_L
  }px) / 12)`,
  DESKTOP_M: `calc((100vw - ${GUTTER.DESKTOP_M * 11}px - ${
    MARGIN.DESKTOP_M
  }px) / 12)`,
  DESKTOP_S: `calc((100vw - ${GUTTER.DESKTOP_S * 11}px - ${
    MARGIN.DESKTOP_S
  }px) / 12)`
};

export const SCROLLBAR_WIDTH = '1.8rem';

export const ITEMS_PERPAGE_PRODUCT = 30;
