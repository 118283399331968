import React from 'react';
import { BankInfoWrapper, FieldWrapper } from './BankInfo.style';
import DisplayText from 'components/ui-kits/DisplayText';
import { Merchant } from 'types/Merchant';

const BankInfo = ({ data }: { data: Merchant }) => {
  return (
    <BankInfoWrapper>
      <DisplayText
        label="Informations bancaires"
        fontSize="XXL"
        fontWeight="LIGHT"
        margin="0 0 6rem 0"
      />
      <DisplayText
        label={data.bankName}
        fontSize="XL"
        fontWeight="BOLD"
        margin="0 0 3.2rem 0"
      />
      <FieldWrapper>
        <DisplayText
          className="col-name"
          label="Titulaire du compte"
          fontSize="M"
        />
        <DisplayText
          className="col-val"
          label={data.nameOfAccountOwner}
          fontSize="M"
          fontWeight="BOLD"
        />
      </FieldWrapper>
      <FieldWrapper>
        <DisplayText
          className="col-name"
          label="Type de compte bancaire"
          fontSize="M"
        />
        <DisplayText
          className="col-val"
          label={data.typeOfBankAccount}
          fontSize="M"
          fontWeight="BOLD"
        />
      </FieldWrapper>
      <FieldWrapper>
        <DisplayText className="col-name" label="Adresse" fontSize="M" />
        <DisplayText
          className="col-val"
          label={data.bankAddress}
          fontSize="M"
          fontWeight="BOLD"
        />
      </FieldWrapper>
      <FieldWrapper>
        <DisplayText className="col-name" label="Ville" fontSize="M" />
        <DisplayText
          className="col-val"
          label={data.cityOfBank}
          fontSize="M"
          fontWeight="BOLD"
        />
      </FieldWrapper>
      <FieldWrapper>
        <DisplayText className="col-name" label="Code postale" fontSize="M" />
        <DisplayText
          className="col-val"
          label={data.postalCode}
          fontSize="M"
          fontWeight="BOLD"
        />
      </FieldWrapper>
      <FieldWrapper>
        <DisplayText className="col-name" label="IBAN" fontSize="M" />
        <DisplayText
          className="col-val"
          label={data.iban}
          fontSize="M"
          fontWeight="BOLD"
        />
      </FieldWrapper>
      <FieldWrapper>
        <DisplayText className="col-name" label="SWIFT/BIC" fontSize="M" />
        <DisplayText
          className="col-val"
          label={data.swiftCode}
          fontSize="M"
          fontWeight="BOLD"
        />
      </FieldWrapper>
    </BankInfoWrapper>
  );
};

export default BankInfo;
