import React from 'react';
import CardStats from '../CardStats';
import CardReview from '../CardReview';
import CardBestSelling from '../CardBestSelling';
import styled from 'styled-components';
import { Period, StatisticsDashboard } from 'types/Merchant';
import { convertHMS, formatDollar } from 'utils';

const StatsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 2.4rem;
  width: 100%;
`;

interface Stats {
  dataStatistics: StatisticsDashboard;
  period: Period;
}

const Stats = ({ dataStatistics, period }: Stats) => {
  return (
    <StatsWrapper>
      <CardStats
        label="Commandes terminées"
        total={dataStatistics.acceptedOrders.total}
        profit={dataStatistics.acceptedOrders.increase}
      />
      <CardStats
        label="Temps de préparation moyen"
        total={convertHMS(dataStatistics.averagePreparationTime.total)}
        profit={dataStatistics.averagePreparationTime.increase}
      />
      <CardStats
        label="Produits vendus"
        total={dataStatistics.productSold.total}
        profit={dataStatistics.productSold.increase}
      />
      <CardStats
        label="Revenu total"
        total={`${formatDollar(dataStatistics.totalRevenue.total)}€`}
        profit={dataStatistics.totalRevenue.increase}
      />
      <CardReview dataReview={dataStatistics.reviews} />
      <CardBestSelling
        dataBestSelling={dataStatistics.bestSellingProduct}
        period={period}
      />
    </StatsWrapper>
  );
};

export default Stats;
