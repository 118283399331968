import React from 'react';
import Modal from 'components/Modal';
import Button from 'components/ui-kits/Button';
import DisplayText from 'components/ui-kits/DisplayText';
import Icon, { IconNames } from 'components/ui-kits/Icon/Icon';
import styled from 'styled-components';

type TextTransformType = 'capitalize' | 'uppercase' | 'lowercase' | 'none';
interface IModalPrompt {
  isShow: boolean;
  onClose(): void;
  onConfirm(): void;
  onDeny?(): void;
  title: string;
  description?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  confirmType?: 'default' | 'withTrashBin';
  textTransform?: TextTransformType;
}

const PromptWrapper = styled.div<{
  textTransform: TextTransformType;
}>`
  padding: 3.5rem 2.8rem;
  text-align: center;
  .note {
    margin-top: 1rem;
  }

  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
  }
  .btn {
    margin: 0 1.5rem;
    text-transform: ${({ textTransform }) => textTransform};
  }
`;

function ModalPrompt({
  isShow,
  onClose,
  onConfirm,
  onDeny = onClose,
  title,
  description,
  confirmBtnText = 'Confirmer',
  cancelBtnText = 'Annuler',
  confirmType = 'default',
  textTransform = 'uppercase'
}: IModalPrompt) {
  return isShow ? (
    <Modal
      maxWidth="46.2rem"
      minHeight="initial"
      closeOnClickOverlay={true}
      close={onClose}>
      <PromptWrapper textTransform={textTransform}>
        <DisplayText
          fontSize="L"
          fontWeight="BOLD"
          color="BLACK_2"
          label={title}
        />
        {description && (
          <DisplayText
            className="note"
            fontSize="M"
            fontWeight="REGULAR"
            color="BLACK_2"
            label={description}
          />
        )}
        <div className="btn-wrapper">
          <Button
            className="btn"
            variant="primary"
            fontWeight="BOLD"
            label={confirmBtnText}
            isFluid={false}
            onClick={onConfirm}
            {...(confirmType === 'withTrashBin' && {
              icon: <Icon name={IconNames.TrashBin} width="14" />
            })}
          />
          <Button
            className="btn"
            isFluid={false}
            variant="cancel"
            fontWeight="BOLD"
            label={cancelBtnText}
            onClick={onDeny}
          />
        </div>
      </PromptWrapper>
    </Modal>
  ) : null;
}

export default ModalPrompt;
