import DisplayText from 'components/ui-kits/DisplayText';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';

export const CardReviewWrapper = styled.div`
  grid-column: 1/3;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GREY_4};
  border-radius: 0.7rem;
  padding: 2.8rem;
  box-sizing: border-box;
`;

export const Title = styled(DisplayText)`
  line-height: 2.9rem;
  margin-bottom: 1rem;
`;

export const BlockCenter = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.7rem 0 3.9rem 0;
`;

export const AverageRating = styled.div`
  font-size: 6.4rem;
  font-weight: bold;
  line-height: 7.7rem;
  text-align: center;
  color: ${COLORS.BLACK_2};
`;

export const ListProcessRating = styled.div`
  & > div:not(& > div:last-child) {
    margin-bottom: 1.7rem;
  }
`;

export const BlockListStar = styled.div`
  position: relative;
`;

export const ListStartWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    display: inline-block;
    margin: 0 0.3rem;
  }

  &.star-checked {
    display: inline-block;
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
  }
`;
