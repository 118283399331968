import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import CatalogsForm from 'components/CatalogsLayout/CatalogsForm';
import { CatalogEnabled } from 'types/Catalog';
import Loader from 'components/Loader';
import { handleSuccess, handleError } from 'utils/index';
import { CREATE_MESSAGE } from 'const/Message';
import { createCatalog } from 'services/catalogService';

const CatalogsCreate = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const onCreate = (data: CatalogEnabled) => {
    onCreateCatalog(data, {
      onSuccess: () => {
        handleSuccess(CREATE_MESSAGE.CATALOG_SUCCESS);
        queryClient.invalidateQueries('getListTaxon');
        queryClient.invalidateQueries('getListCatalogs');
        history.push('/menu/catalogs');
      },
      onError: (error: any) => {
        handleError(error.response.status, CREATE_MESSAGE.CATALOG_ERROR);
      }
    });
  };

  const { isLoading, mutate: onCreateCatalog } = useMutation(
    (catalog: Required<CatalogEnabled>) => createCatalog(catalog)
  );

  return (
    <>
      {isLoading ? <Loader /> : null}
      <CatalogsForm mode="create" onCreate={onCreate} />
    </>
  );
};

export default CatalogsCreate;
