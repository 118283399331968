import React from 'react';

function CropIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.6rem"
      height="1.6rem"
      viewBox="0 0 16 16"
      {...props}>
      <path
        id="Icon_awesome-crop-alt"
        data-name="Icon awesome-crop-alt"
        d="M15.25,11H14V3a1,1,0,0,0-1-1H6V5h5V15.25a.75.75,0,0,0,.75.75h1.5a.75.75,0,0,0,.75-.75V14h1.25a.75.75,0,0,0,.75-.75v-1.5A.75.75,0,0,0,15.25,11ZM5,.75A.75.75,0,0,0,4.25,0H2.75A.75.75,0,0,0,2,.75V2H.75A.75.75,0,0,0,0,2.75v1.5A.75.75,0,0,0,.75,5H2v8a1,1,0,0,0,1,1h7V11H5Z"
        fill="#8d9598"
      />
    </svg>
  );
}

export default CropIcon;
