import React from 'react';
import styled from 'styled-components';
import { SPACING_PRODUCT_OPTIONS } from 'styles/variables';
import DisplayText from '../DisplayText';

const Text = styled(DisplayText)<{ hasSearch?: boolean }>`
  margin: ${({ hasSearch }) =>
    hasSearch ? '0' : SPACING_PRODUCT_OPTIONS.TITLE};
`;

const Title = ({
  label,
  hasSearch
}: {
  label?: string;
  hasSearch?: boolean;
}) => {
  return (
    <Text
      hasSearch={hasSearch}
      label={label}
      fontSize="XXL"
      fontWeight="LIGHT"
    />
  );
};

export default Title;
