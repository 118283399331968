import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import Layout from 'components/Layout';
import Info from 'components/HomeLayout/Info';
import Stats from 'components/HomeLayout/Stats';
import { Grid, GridItem } from 'components/ui-kits/Grid';
import styled from 'styled-components';
import { BREAKPOINT, COLORS } from 'styles/variables';
import { DropdownList } from 'components/ui-kits/Form/Dropdown';
import { FILTER_DAYS_OPTIONS } from 'const/Const';
import { RootState } from 'app/store';
import { GridHavePanelRight } from 'styles/common';
import { useWindowWidth } from 'components/CustomHooks';
import GoogleMapReact from 'google-map-react';
import {
  getMerchantV2,
  getStatisticsDashboardV2
} from 'services/merchantService';

const { REACT_APP_GOOGLE_API_KEY } = process.env;

const BlockLeftWrapper = styled(GridItem)`
  background-color: ${COLORS.WHITE_1};
  position: relative;
  padding-bottom: 7.8rem;
`;

const InfoTopWrapper = styled.div`
  padding: 0 0 0 0;
`;

const FilterWrapper = styled.div`
  margin: 3rem 0 3.5rem 0;
  .rounded {
    min-width: 13.5rem;
    width: 13.5rem;
    &__single-value {
      font-size: 1.4rem;
    }
    &__menu {
      text-align: center;
    }
  }
`;

const Container = styled(Grid)<{ borderBottom?: boolean }>`
  position: relative;

  border-bottom: ${({ borderBottom }) =>
    borderBottom && `1px solid ${COLORS.GREY_4}`};
`;

const Home = () => {
  const { control } = useForm();
  const { merchantSlug } = useSelector((state: RootState) => state.user);
  const watchPeriod = useWatch({
    control,
    name: 'period',
    defaultValue: FILTER_DAYS_OPTIONS[2] // default 7days
  });

  const { data: dataMerchant } = useQuery('getMerchant', ({ signal }) =>
    getMerchantV2(merchantSlug, signal)
  );
  const { data: dataStatistics, refetch } = useQuery(
    'getStatistics',
    ({ signal }) => getStatisticsDashboardV2(watchPeriod.value, signal)
  );
  const windowWidth = useWindowWidth();

  useEffect(() => {
    refetch();
    /* eslint-disable */
  }, [watchPeriod]);

  return (
    <Layout>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: REACT_APP_GOOGLE_API_KEY || '',
          libraries: ['places,drawing'].join(',')
        }}
      />

      <Grid
        col={
          windowWidth < BREAKPOINT.DESKTOP_M
            ? GridHavePanelRight.grid.numberOfColsDesktopS
            : GridHavePanelRight.grid.numberOfColsDesktopM
        }
        start="large"
        marginLeft={Boolean(windowWidth < BREAKPOINT.DESKTOP_M)}
        marginRight={true}>
        <BlockLeftWrapper
          colStart={1}
          colEnd={100}
          nameStart={windowWidth < BREAKPOINT.DESKTOP_M ? 'margin' : 'large'}
          nameEnd="large">
          <Container
            col={8}
            start="large"
            marginRight={true}
            borderBottom={true}>
            <GridItem
              colStart={2}
              colEnd={7}
              nameStart="large"
              nameEnd="gutter">
              <InfoTopWrapper>
                <Info dataMerchant={dataMerchant} merchantSlug={merchantSlug} />
              </InfoTopWrapper>
            </GridItem>
          </Container>

          <Container col={8} start="large" marginRight={true}>
            <GridItem
              colStart={2}
              colEnd={7}
              nameStart="large"
              nameEnd="gutter">
              <FilterWrapper>
                <DropdownList
                  name="period"
                  control={control}
                  variant="rounded"
                  options={FILTER_DAYS_OPTIONS}
                  defaultValue={FILTER_DAYS_OPTIONS[2]} // default 7days
                />
              </FilterWrapper>
              {dataStatistics && (
                <Stats
                  dataStatistics={dataStatistics}
                  period={watchPeriod.label}
                />
              )}
            </GridItem>
          </Container>
        </BlockLeftWrapper>
      </Grid>
    </Layout>
  );
};

export default Home;
