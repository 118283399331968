import React from 'react';
import ListLayout from 'components/ListLayout';
import { InformationCustomerWrapper } from './InformationCustomer.style';
import TableCustomer from '../../InvoicesMerchantTable/TableCustomer';
import CardInformationCustomer from './CardInformationCustomer';
import { format } from 'date-fns';
import { formatDateMonthYear } from 'utils';

interface Iprops {
  onData: any;
}

const InformationCustomer = ({ onData }: Iprops) => {
  const billingAddress = onData?.order?.billingAddress?.street;

  return (
    <ListLayout>
      <InformationCustomerWrapper>
        <CardInformationCustomer
          firstName={onData?.order?.firstName}
          lastName={onData?.order?.lastName}
          billingAddress={
            billingAddress ? JSON.parse(billingAddress)?.displayName : ''
          }
          invoiceNumber={onData?.number}
          invoiceDate={
            onData?.createdAt
              ? format(new Date(onData?.createdAt), formatDateMonthYear)
              : ''
          }
          dateOfTransaction={
            onData?.transactionDate
              ? format(new Date(onData?.transactionDate), formatDateMonthYear)
              : ''
          }
          mainName={onData?.merchant?.name}
          address={onData?.merchant?.billingAddress}
          vatNumber={onData?.merchant?.taxNumber}
          siret={onData?.merchant?.siretCode}
          country={'aaaa'}
        />
        <TableCustomer onDataTable={onData} />
      </InformationCustomerWrapper>
    </ListLayout>
  );
};

export default InformationCustomer;
