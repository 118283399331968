import React from 'react';
import Table from 'components/ui-kits/Table';
import {
  ListOrderWrapper,
  NotDataYet,
  OrderCancelStyle
} from './ListOrder.style';
import ItemListOrder from '../ItemListOrder';
import { formatDollar } from 'utils';

const headerTable = [
  { label: 'Date et heure' },
  { label: 'Numéro de commande' },
  { label: 'Statut' },
  { label: 'Montant' },
  { label: 'Montant net' }
];

const EMPTY_DATA_TEXT = "Vous n'avez pas de commande pour cette période";

interface IProps {
  onData: any;
  isOpenPanelRight(status: boolean): void;
  passId(id: number): void;
}

const ListOrder = ({ onData, isOpenPanelRight, passId }: IProps) => {
  const listOrders = onData?.orders;

  return (
    <ListOrderWrapper>
      <Table header={headerTable}>
        {listOrders?.length ? (
          listOrders?.map((list: any) => {
            return (
              <ItemListOrder
                key={list?.orderNumber}
                id={Number(list?.orderNumber)}
                orderIds={list?.orderNumber}
                dateAndTime={list?.dateAndTime}
                attributes={
                  list?.attributes !== 'cancelled' ? (
                    list?.attributes === 'fulfilled' ? (
                      'COMMANDE TERMINÉE'
                    ) : (
                      ''
                    )
                  ) : (
                    <OrderCancelStyle />
                  )
                }
                soldAt={`${formatDollar(list?.soldAt)}€`}
                netRevenue={`${formatDollar(list?.netRevenue)}€`}
                isOpenPanelRight={isOpenPanelRight}
                passId={passId}
              />
            );
          })
        ) : (
          <tr>
            <NotDataYet colSpan={5}>{EMPTY_DATA_TEXT}</NotDataYet>
          </tr>
        )}
      </Table>
    </ListOrderWrapper>
  );
};

export default ListOrder;
