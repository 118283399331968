import React, { useState } from 'react';
import styled from 'styled-components';
import ByMonth from './ByMonth';
import ByCustomPeriod from './ByCustomPeriod';
import { COLORS } from 'styles/variables';

const FinancialReportStyle = styled.div`
  margin: 4rem 0;

  .report-option {
    position: relative;
    display: flex;
    align-items: center;
    background-color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.GREY_4};
    color: ${COLORS.BLACK_2};
    letter-spacing: 0px;
    min-width: 23rem;
    height: 4rem;
    margin-right: 1.4rem;
    border-radius: 2rem;

    svg {
      position: absolute;
      top: 50%;
      right: 1.7rem;
      transform: translateY(-50%);
    }
  }
`;

const FinancialReport = () => {
  const [mode, setMode] = useState<'month' | 'period'>('month');
  const setDisplayMode = (mode: 'month' | 'period') => {
    setMode(mode);
  };

  return (
    <FinancialReportStyle>
      {mode === 'month' && (
        <ByMonth mode={mode} setDisplayMode={setDisplayMode} />
      )}
      {mode === 'period' && (
        <ByCustomPeriod mode={mode} setDisplayMode={setDisplayMode} />
      )}
    </FinancialReportStyle>
  );
};

export default FinancialReport;
