import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';

interface IValidate {
  [key: string]: any;
}
interface IProps {
  register?: any;
  name: string;
  value: string;
  id: string;
  children: React.ReactNode;
  validate?: IValidate;
  className?: string;
  disabled?: boolean;
}

const RadioButton = styled.input`
  display: none;
  &:checked + div:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: ${COLORS.RED};
  }
`;
const LabelStyle = styled.label`
  display: flex;
  align-items: flex-end;
  color: ${COLORS.BLACK_2};
  font-size: 1.8rem;
`;
const RadioStyle = styled.div`
  position: relative;
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  border: 1px solid ${COLORS.GREY_6};
  margin-right: 1.8rem;
`;

const Radio = ({
  register,
  name,
  value,
  id,
  validate,
  children,
  className,
  disabled
}: IProps) => {
  return (
    <LabelStyle htmlFor={id} className={className}>
      <RadioButton
        type="radio"
        id={id}
        {...register?.(name, validate)}
        value={value}
        name={name}
        disabled={disabled}
      />
      <RadioStyle className="radio-icon"></RadioStyle>
      {children}
    </LabelStyle>
  );
};

export default Radio;
