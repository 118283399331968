import styled from 'styled-components';
import { COLORS } from 'styles/variables';

export const Title = styled.div`
  margin-top: 3.8rem;
  span {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.9rem;
  }
  span:first-child {
    color: ${COLORS.GREY_1};
  }
  span:last-child {
    color: ${COLORS.BLACK_2};
  }
`;

export const Actions = styled.div`
  text-align: right;
  button {
    width: auto;
    color: ${COLORS.BLACK_2};
    letter-spacing: 0;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.4rem;
    text-transform: capitalize;
  }
`;

export const OtpWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  input {
    text-align: center;
    width: 12rem;
    height: 12rem;
    border-radius: 1rem;
    border: 1px solid ${COLORS.GREY_2};
    font-size: 8rem;
    font-weight: 700;
    color: ${COLORS.BLACK_2};
  }
  input::placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 8rem;
    font-weight: 700;
    color: ${COLORS.GREY_1};
    opacity: 0.5;
  }
`;

export const FormWrapper = styled.div`
  margin: 2.4rem 0 3.4rem 0;
  button {
    margin-top: 3.4rem;
  }
`;
