import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../features/auth/authSlice';
import filterInvoiceReducer from '../features/filterInvoice/filterInvoiceSlice';
import pagePaginationReducer from '../features/pagePagination/pagePaginationSlice';
import keepSearchPaginationOptionReducer from '../features/keepSearchPaginationOption/keepSearchPaginationOptionSlice';
import keepSearchProductInCatalogReducer from '../features/keepSearchProductInCatalog/keepSearchProductInCatalogSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    user: userReducer,
    filterInvoice: filterInvoiceReducer,
    pagePagination: pagePaginationReducer,
    searchPaginationOption: keepSearchPaginationOptionReducer,
    keepSearchProductInCatalog: keepSearchProductInCatalogReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
