import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'components/Modal';
import Button from 'components/ui-kits/Button';
import { Checkbox } from 'components/ui-kits/Form/Checkbox';
import Table from 'components/ui-kits/Table';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';
import { useForm, useWatch } from 'react-hook-form';
import { TableCell } from 'components/ui-kits/Table/Table.style';
import { useInfiniteQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { ProductOptionV2 } from 'types/Products';
import DisplayText from 'components/ui-kits/DisplayText';
import { MultilineTrunc, ScrollbarCustom } from 'styles/common';
import Image from 'components/ImageDisplay';
import Loader from 'components/Loader';
import useIntersectionLoadmore from 'hooks/useIntersectionLoadmore';
import { getTaxonProducts } from 'services/productService';
import { SearchStyle } from 'styles/listing-page';
import TextInput from 'components/ui-kits/Form/TextInput';
import { SearchIcon } from 'components/ui-kits/Icon';
import { updateSearch } from 'features/keepSearchProductInCatalog/keepSearchProductInCatalogSlice';

const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.2rem 4.6rem;
  box-sizing: border-box;
  border-bottom: 1px solid ${COLORS.GREY_4};
`;

const Title = styled.div`
  color: ${COLORS.BLACK_2};
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.9rem;
`;

const Filter = styled.div`
  display: flex;
  padding: 2.6rem 4.6rem;
  .search-wrapper {
    min-width: 41.5rem;
    margin-right: 3.2rem;
    button {
      height: 100%;
      padding: 0 1.7rem;
    }
  }
`;

const ButtonStyle = styled.div`
  button {
    padding: 0 1.6rem;
    height: 100%;
  }
`;

const HeaderData = [
  { label: '', width: '10%' },
  { label: '', width: '13%' },
  { label: 'Nom', width: '25%' },
  { label: 'Catégories', width: '20%' },
  { label: `Groupes d'options`, width: '28%' }
];
const EmptyListWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

interface IProductModal {
  onClose(): void;
  editProduct(productRemove: any[], productAdd: any[]): void;
  listProductActive?: string[];
  onOpenCreateProduct?: () => void;
}

const ITEMS_PER_PAGE = 10;

function ModalAddProduct({
  onClose,
  editProduct,
  listProductActive = [],
  onOpenCreateProduct
}: IProductModal) {
  const { merchantId } = useSelector((state: RootState) => state.user);
  const { search } = useSelector(
    (state: RootState) => state.keepSearchProductInCatalog
  );
  const [listProductActiveInModal, setListProductActiveInModal] = useState([
    ...listProductActive
  ]);
  const [productDataWhenChecked, setProductDataWhenChecked] = useState<any[]>(
    []
  );
  const dispatch = useDispatch();

  const queryKeyInfinite = {
    'merchant.id': merchantId,
    isOption: false,
    itemsPerPage: ITEMS_PER_PAGE,
    searchByName: search
  };

  const {
    data: proData,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useInfiniteQuery(
    ['getListProducts', queryKeyInfinite],
    ({ pageParam, signal }) => {
      return getTaxonProducts({
        limit: ITEMS_PER_PAGE,
        page: pageParam?.page ?? 1,
        merchantId,
        isOption: false,
        signal,
        searchByName: search
      });
    },
    {
      getNextPageParam: (lastPage) => {
        const totalPage = Math.ceil(
          lastPage.data['hydra:totalItems'] / ITEMS_PER_PAGE
        );
        const currPage = lastPage.config.params.page + 1;
        if (currPage <= totalPage) {
          return { page: currPage };
        }
        return undefined;
      }
    }
  );

  const data = useMemo(() => {
    if (!proData?.pages) return [];
    return proData.pages.reduce(
      (acc: any, cur) => [...acc, ...cur.data['hydra:member']],
      []
    );
  }, [proData]);

  const { callbackRef } = useIntersectionLoadmore({
    itemsCount: data.length,
    fetchNextPage,
    hasNextPage
  });

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { isDirty }
  } = useForm({
    defaultValues: {
      product: data.reduce((acc: any, item: any) => {
        acc[`${item['@id']}`] = listProductActiveInModal.includes(item.id);
        return acc;
      }, {} as any),
      searchProduct: search
    }
  });

  const watchSearch = useWatch({
    control,
    name: 'searchProduct'
  });

  useEffect(() => {
    reset({
      product: data.reduce((acc: any, item: any) => {
        acc[`${item['@id']}`] = listProductActiveInModal.includes(item['@id']);
        return acc;
      }, {} as any),
      searchProduct: search
    });
  }, [data, listProductActiveInModal, reset, search]);

  const handleSearch = () => {
    dispatch(
      updateSearch({ search: getValues('searchProduct'), currentPage: 1 })
    );
  };

  useEffect(() => {
    if (!watchSearch && isDirty) {
      dispatch(
        updateSearch({
          currentPage: 1,
          search: ''
        })
      );
    }
  }, [watchSearch, dispatch, isDirty]);

  const onSave = () => {
    dispatch(
      updateSearch({
        currentPage: 1,
        search: ''
      })
    );
    if (!data) return;
    const productIdRemove: string[] = listProductActive.filter(
      (item: string) => {
        return !listProductActiveInModal.includes(item);
      }
    );

    const productIdAdd: string[] = listProductActiveInModal.filter(
      (item: string) => {
        return !listProductActive.includes(item);
      }
    );

    const productRemove = productIdRemove.map((item: string) => {
      const pro = productDataWhenChecked.find(
        (p: ProductOptionV2) => p['@id'] === item
      );
      return { id: item, products: pro, action: 'remove' };
    });

    const productAdd = productIdAdd.map((item: string) => {
      const pro = productDataWhenChecked.find(
        (p: ProductOptionV2) => p['@id'] === item
      );
      return { id: item, products: pro, action: 'add' };
    });

    editProduct(productRemove, productAdd);
  };

  const renderResult = (data: ProductOptionV2[]) => {
    if (!data) return;
    if (data.length === 0) {
      return (
        <tr>
          <td>
            <EmptyListWrapper>
              <DisplayText
                label="Vous n'avez actuellement aucune catégorie"
                fontSize="S"
                fontWeight="REGULAR"
                color="GREY_1"
              />
            </EmptyListWrapper>
          </td>
        </tr>
      );
    }
    if (data.length > 0) {
      return data.map((item, index) => {
        return (
          <tr key={item['@id'] + index} ref={callbackRef}>
            <TableCell>
              <Checkbox
                name={`product.${item['@id']}`}
                register={register}
                change={(e) => {
                  const newData = [...listProductActiveInModal];
                  const newProductDataWhenChecked = [...productDataWhenChecked];
                  if (e.target.checked) {
                    newData.push(item['@id']);
                    newProductDataWhenChecked.push(item);
                    setListProductActiveInModal(newData);
                    setProductDataWhenChecked(newProductDataWhenChecked);
                    return;
                  }
                  const index = newData.indexOf(item['@id']);
                  if (index > -1) {
                    newData.splice(index, 1);
                  }
                  setListProductActiveInModal(newData);
                }}
              />
            </TableCell>
            <TableCell>
              <Image src={item.latestImage?.path} />
            </TableCell>
            <TableCell>
              <div className="name">
                <MultilineTrunc line="2">{item.name}</MultilineTrunc>
              </div>
              <div className="ref">
                <MultilineTrunc line="2">{item.reference}</MultilineTrunc>
              </div>
            </TableCell>
            <TableCell>
              {item.taxonNames.length > 2 ? (
                <div>{item.taxonNames.length} Catégories</div>
              ) : (
                item.taxonNames.map((taxonItem: any, idx) => (
                  <React.Fragment key={idx}>
                    <div key={taxonItem.name + idx}>{taxonItem.name}</div>
                  </React.Fragment>
                ))
              )}
            </TableCell>
            <TableCell fontWeight="REGULAR">
              {!!item.groupOptionNames.length &&
                item.groupOptionNames.join(', ')}
            </TableCell>
          </tr>
        );
      });
    } else {
      return null;
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <Modal close={onClose}>
        <form onSubmit={handleSubmit(onSave)}>
          <HeaderTable>
            <Title>Ajout de produit(s)</Title>
            <Button
              type="submit"
              isFluid={false}
              variant="primary"
              label="Enregistrer"
              fontWeight="BOLD"
              letterSpacing="S"
            />
          </HeaderTable>
          <Filter>
            <SearchStyle className="search-wrapper">
              <TextInput
                register={register}
                name="searchProduct"
                placeholder="Rechercher un produit..."
                className="default__control"
                defaultValue={search}
              />
              <button
                type="button"
                className={`search-icon ${
                  watchSearch?.length > 2 ? 'active' : 'disabled'
                }`}
                disabled={Boolean(!watchSearch || watchSearch?.length <= 2)}
                onClick={handleSearch}>
                <SearchIcon />
              </button>
            </SearchStyle>
            <ButtonStyle>
              <Button
                type="button"
                label="+ CRÉER UN PRODUIT"
                variant="cancel"
                fontWeight="BOLD"
                onClick={onOpenCreateProduct}
              />
            </ButtonStyle>
          </Filter>
          <div>
            <Table
              header={HeaderData}
              borderHead={false}
              hasScroll={true}
              tFoot={isFetchingNextPage ? <Loader isFixed={false} /> : null}>
              <ScrollbarCustom>{renderResult(data)}</ScrollbarCustom>
            </Table>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ModalAddProduct;
