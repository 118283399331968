import React from 'react';

function CashIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.953"
      height="16"
      viewBox="0 0 23.953 16"
      {...props}>
      <path
        id="Icon_payment-cash"
        data-name="Icon payment-cash"
        d="M30.4,17.145V31.554a1.027,1.027,0,0,1-.234.561.764.764,0,0,1-.561.234H7.244a.762.762,0,0,1-.561-.234,1.023,1.023,0,0,1-.234-.561V17.145a1.025,1.025,0,0,1,.234-.561.766.766,0,0,1,.561-.234H29.607a.767.767,0,0,1,.561.234,1.03,1.03,0,0,1,.234.561ZM28.812,27.531V21.168a3.06,3.06,0,0,1-2.246-.936,3.115,3.115,0,0,1-.936-2.292H11.221a3.117,3.117,0,0,1-.936,2.292,3.062,3.062,0,0,1-2.246.936v6.363a3.065,3.065,0,0,1,2.246.936,3.119,3.119,0,0,1,.936,2.292H25.63a3.117,3.117,0,0,1,.936-2.292,3.064,3.064,0,0,1,2.246-.935ZM22.4,24.35a8.2,8.2,0,0,1-.234,1.778,6,6,0,0,1-.748,1.684,4.066,4.066,0,0,1-1.263,1.263,3.436,3.436,0,0,1-3.462,0,4.052,4.052,0,0,1-1.263-1.263,5.98,5.98,0,0,1-.748-1.684,8.16,8.16,0,0,1-.234-1.778,8.167,8.167,0,0,1,.234-1.778,5.977,5.977,0,0,1,.748-1.684A4.066,4.066,0,0,1,16.7,19.625a3.435,3.435,0,0,1,3.461,0,4.086,4.086,0,0,1,1.263,1.263,6,6,0,0,1,.748,1.684A8.191,8.191,0,0,1,22.4,24.35ZM16.04,27.531h4.772v-1.17H19.221V20.747h-1.4l-1.871,1.731.982.982a7.66,7.66,0,0,0,.655-.7h.047v3.6H16.04v1.17Z"
        transform="translate(-6.449 -16.35)"
        fill="#676767"
      />
    </svg>
  );
}

export default CashIcon;
