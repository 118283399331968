import React from 'react';
import { useQuery, useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import EditProductItemForm from '../EditProductItemForm';
import type { SendProduct, Product, UploadImageParams } from 'types/Products';
import Loader from 'components/Loader';
import { handleSuccess, handleError } from 'utils/index';
import {
  REFERENCE_EXISTS_LINE_ONE_MESSAGE,
  REFERENCE_EXISTS_LINE_TWO_MESSAGE,
  UPDATE_MESSAGE
} from 'const/Message';
import type { Catalog } from 'types/Catalog';
import {
  getProductByCode,
  updateProduct,
  uploadImageV2
} from 'services/productService';
import {
  createPromotion,
  updatePromotion,
  CreatePromotionParams
} from 'services/promotionService';
import { CHANNELS_PROMOTION } from 'const/Const';
import { handleMessageWhenAppliedPromotion } from 'utils/handleResponse';
import useInvalidateQuery from 'hooks/useInvalidateQuery';

interface IProps {
  code: string;
  onClose: (
    newPro?: any,
    categoryId?: string,
    mode?: 'create' | 'edit'
  ) => void;
  codeCategory: string;
  catalog: Catalog;
}

const REFERENCE_EXISTS_MESSAGE = (
  <>
    <div>{REFERENCE_EXISTS_LINE_ONE_MESSAGE}</div>
    <div>{REFERENCE_EXISTS_LINE_TWO_MESSAGE}</div>
  </>
);

const EditProductItem = ({ code, onClose, codeCategory }: IProps) => {
  const { inValidateQueryProductInCatalog } = useInvalidateQuery();
  const {
    data: resultProduct,
    isLoading: isLoadingProduct,
    isFetching
  } = useQuery(
    ['getProduct', { code }],
    ({ signal }) => getProductByCode({ signal, code }),
    {
      select: (res) => {
        if (!res.data.productTaxons?.length) {
          res.data.productTaxons = [{ taxon: { code: codeCategory } }];
        }
        return res.data;
      }
    }
  );

  const { isLoading: isUpdatingProduct, mutate: onUpdateProduct } = useMutation(
    ({ code, product }: { code: string; product: SendProduct }) =>
      updateProduct(code, product)
  );
  const { isLoading: isUploadingImage, mutateAsync: mutateUploadImage } =
    useMutation(({ code, formData }: UploadImageParams) =>
      uploadImageV2(code, formData)
    );
  const { isLoading: isUpdatingPromotion, mutateAsync: mutateUpdatePromotion } =
    useMutation(
      ({
        code,
        promotionData
      }: {
        code: string;
        promotionData: CreatePromotionParams;
      }) => updatePromotion(code, promotionData)
    );
  const { isLoading: isCreatingPromotion, mutateAsync: mutateCreatePromotion } =
    useMutation((data: CreatePromotionParams) => createPromotion(data));

  const onUpdate = (product: Product, imgCropped: any) => {
    const data: SendProduct = {
      name: product.name,
      isOption: false,
      maxAmount: product.maxAmount,
      description: product.description,
      categories: product.categories,
      includedTax: product.includedTax,
      isAlcoholicProduct: product.isAlcoholicProduct,
      dietaryAttributes: product.dietaryAttributes,
      groupOptionIRIs: product.groupOptionIRIs,
      sellingAsOption: product.sellingAsOption,
      allergenAttributes: product.allergenAttributes,
      notMultipleQuantityOption: product.notMultipleQuantityOption,
      isSellWithUnits: product.isSellProductWithUnits,
      ...(product.isSellProductWithUnits
        ? {
            pricePerUnit: product.pricePerUnit,
            quantityProductSoldByWeight: product.quantityProductSoldByWeight,
            unit: product.unit
          }
        : { price: product.price })
    };

    if (product.reference) {
      data.reference = product.reference;
    }

    const fd = new FormData();
    if (imgCropped) {
      fd.append('files[]', imgCropped);
    }
    if (!imgCropped && product.files) {
      for (let i = 0; i < product.files.length; i++) {
        fd.append('files[]', product.files[i]);
      }
    }

    onUpdateProduct(
      { code: code, product: data },
      {
        onSuccess: async (res: any) => {
          const newCode = res.data.code;

          res.data.oldCode = code;

          handleSuccess(UPDATE_MESSAGE.PRODUCT_SUCCESS);

          let promotionData;

          if (product.startDate && product.endDate && product.isReduction) {
            promotionData = {
              channels: [CHANNELS_PROMOTION],
              name: `Discount percent ${product.reductionPercent}`,
              startDate: product.startDate,
              endDate: product.endDate,
              priority: 1,
              exclusive: true,
              enabled: true,
              scopes: [
                {
                  type: 'for_variants',
                  configuration: {
                    variants: [res.data.variants[0].code as string]
                  }
                }
              ],
              actions: [
                {
                  type: 'percentage_discount',
                  configuration: {
                    amount: product.reductionPercent || 0
                  }
                }
              ],
              code: `discount-percent-${Math.floor(
                product.reductionPercent || 0
              )}-${uuidv4()}`
            };
          } else {
            promotionData = {
              enabled: false
            };
          }

          const listApi = [];

          if (product.files === null) {
            listApi.push(mutateUploadImage({ code: newCode, formData: null }));
          }

          if (fd.getAll('files[]').length > 0) {
            listApi.push(mutateUploadImage({ code: newCode, formData: fd }));
          }

          if (
            (product.isReduction || product.appliedPromotion) &&
            promotionData
          ) {
            if (product.promotions) {
              listApi.push(
                mutateUpdatePromotion({
                  code: product.promotions.code,
                  promotionData
                })
              );
            } else {
              listApi.push(mutateCreatePromotion(promotionData));
            }
          }

          await Promise.all(listApi);

          if (product.isReduction) {
            handleMessageWhenAppliedPromotion();
          }

          inValidateQueryProductInCatalog();
          onClose();
        },

        onError: (error: any) => {
          handleError(
            error.response.status,
            error.response.status === 422
              ? REFERENCE_EXISTS_MESSAGE
              : UPDATE_MESSAGE.PRODUCT_ERROR
          );
        }
      }
    );
  };

  return (
    <>
      {(isUpdatingProduct ||
        isCreatingPromotion ||
        isUpdatingPromotion ||
        isUploadingImage ||
        isLoadingProduct ||
        isFetching) && <Loader />}
      {resultProduct ? (
        <EditProductItemForm
          key={resultProduct?.id}
          onClose={onClose}
          dataEdit={resultProduct}
          edit={onUpdate}
          mode="edit"
        />
      ) : null}
    </>
  );
};

export default EditProductItem;
