import React, { useState } from 'react';
import Table from 'components/ui-kits/Table';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRouteMatch, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { SPACING_PRODUCT_OPTIONS } from 'styles/variables';
import {
  OptionTooltip,
  OptionTooltipItem
} from 'components/ui-kits/OptionTooltip';
import Title from 'components/ui-kits/Title';
import Sort from 'components/Sort';
import { SearchStyle, Wrapper, WrapperRight } from 'styles/listing-page';
import { TableCell } from 'components/ui-kits/Table/Table.style';
import {
  GroupOptionFormsFields,
  GroupOptionsProduct,
  IGroupOptions
} from 'types/GroupOptions';
import ConfirmDeleteModal from 'components/GroupOptionsLayout/ConfirmDeleteModal';
import Loader from 'components/Loader';
import { DELETE_MESSAGE, DONT_DATA_MESSAGE } from 'const/Message';
import { handleSuccess, handleError } from 'utils/index';
import ListLayout from 'components/ListLayout';
import Button from 'components/ui-kits/Button';
import { NotDataYet } from 'styles/common';
import TextInput from 'components/ui-kits/Form/TextInput';
import { SearchIcon } from 'components/ui-kits/Icon';
import {
  editGroupOptionV2,
  getGroupOptionsListV2
} from 'services/groupOptionService';

const TableRow = styled.tr`
  position: relative;
  cursor: pointer;
`;

const TableWrapper = styled.div`
  margin: ${SPACING_PRODUCT_OPTIONS.TABLE};
`;

const headerTableDefault = [
  { label: 'Nom', width: '23%' },
  { label: 'Nom affiché', width: '23%' },
  { label: 'Contient', width: '23%' },
  { label: 'Produits', width: '23%' },
  { label: '', width: '8%' }
];

const GroupOptions = () => {
  const queryClient = useQueryClient();
  const { path } = useRouteMatch();
  const history = useHistory();
  const [groupOptionToDelete, setGroupOptionToDelete] =
    useState<IGroupOptions | null>(null);
  const {
    data: result,
    isLoading: isLoadingGetGroupOptions,
    isFetching
  } = useQuery(
    ['getListGroupOptions'],
    ({ signal }) => getGroupOptionsListV2({ signal }),
    { staleTime: Infinity }
  );

  function handleDelete(groupOption: IGroupOptions | null) {
    if (!groupOption) return;
    const idGO = groupOption['@id'].split('/').pop();
    const options = Object.entries(groupOption.groupOptionProducts).map(
      (item: any) => {
        const id = item[0]; // key = id product
        const customPrice = item[1].customPrice;
        return { id: id, customPrice: customPrice };
      }
    );

    const dataUpdate: GroupOptionFormsFields = {
      name: groupOption.name,
      displayName: groupOption.displayName,
      description: groupOption.description,
      isMandatory: groupOption.isMandatory,
      isRange: groupOption.isRange,
      requiredAmount: groupOption.requiredAmount,
      minAmount: groupOption.minAmount,
      maxAmount: groupOption.maxAmount,
      optionMaxAmount: groupOption.optionMaxAmount,
      enabled: false, // enabled = false => delete group option
      options: options
    };

    if (dataUpdate && idGO) {
      mutateDelete({ data: dataUpdate, id: idGO });
    }
  }

  const { isLoading: isLoadingDeleteGroupOption, mutate: mutateDelete } =
    useMutation(
      ({ data, id }: Required<{ data: GroupOptionFormsFields; id: string }>) =>
        editGroupOptionV2(data, id),
      {
        onSuccess: () => {
          queryClient.invalidateQueries('getListGroupOptions');
          queryClient.invalidateQueries('getListProducts');
          handleSuccess(DELETE_MESSAGE.GROUP_OPTION_SUCCESS);
          setGroupOptionToDelete(null);
        },
        onError: (error: any) => {
          handleError(error.response.status, DELETE_MESSAGE.GROUP_OPTION_ERROR);
          setGroupOptionToDelete(null);
        }
      }
    );

  const redirecToEdit = (id?: string) => {
    history.push(`${path}/edit/${id}`);
  };

  const renderResult = () => {
    const listGroupOptions = result && result['hydra:member'];
    if (listGroupOptions && listGroupOptions.length > 0) {
      return listGroupOptions.map((item: IGroupOptions) => {
        const itemIndex = item['@id'].split('/').pop(); // temporarily get id
        const groupOptionProdArr = Object.values(item.groupOptionProducts);
        const groupOptionProdNameArr = groupOptionProdArr.map(
          (item: GroupOptionsProduct) => item.product.name
        );
        return (
          <TableRow key={item['@id']}>
            <TableCell color="RED" onClick={() => redirecToEdit(itemIndex)}>
              {item.name}
            </TableCell>
            <TableCell
              fontSize="M"
              fontWeight="REGULAR"
              onClick={() => redirecToEdit(itemIndex)}>
              {item.displayName}
            </TableCell>
            <TableCell
              fontSize="M"
              fontWeight="REGULAR"
              onClick={() => redirecToEdit(itemIndex)}>
              {!!groupOptionProdNameArr.length &&
                groupOptionProdNameArr.join(', ')}
            </TableCell>
            <TableCell
              fontSize="M"
              fontWeight="REGULAR"
              onClick={() => redirecToEdit(itemIndex)}>
              {!!item.products.length &&
                item.products
                  .map(
                    (item: any) =>
                      `${item.name} ${
                        item.reference ? `(${item.reference})` : ''
                      }`
                  )
                  .join(', ')}
            </TableCell>
            <TableCell>
              <OptionTooltip
                component={
                  <>
                    <OptionTooltipItem
                      label="Modifier"
                      to={`${path}/edit/${itemIndex}`}
                    />
                    <OptionTooltipItem
                      label="Supprimer"
                      className="list-option-item del"
                      onClick={() => setGroupOptionToDelete(item)}
                    />
                  </>
                }
              />
            </TableCell>
          </TableRow>
        );
      });
    } else {
      return (
        <tr>
          <NotDataYet colSpan={5}>
            {DONT_DATA_MESSAGE(`groupes d'options`)}
          </NotDataYet>
        </tr>
      );
    }
  };

  return (
    <>
      <ListLayout>
        <Wrapper>
          <Title label="Groupes d'options" hasSearch={true} />
          <WrapperRight>
            <Sort />
            <SearchStyle>
              <TextInput
                name="search"
                placeholder="Rechercher un groupe d'option..."
                className="default__control"
              />
              <button className="search-icon disabled" disabled>
                <SearchIcon />
              </button>
            </SearchStyle>
            <div>
              <Button
                variant="primary"
                fontWeight="BOLD"
                onClick={() => history.push(`${path}/create`)}
                label="+ CRÉER UN GROUPE D'OPTION"
              />
            </div>
          </WrapperRight>
        </Wrapper>

        {isLoadingGetGroupOptions || isFetching ? (
          <Loader isFixed={false} />
        ) : (
          <TableWrapper>
            <Table header={headerTableDefault}>{renderResult()}</Table>
          </TableWrapper>
        )}
      </ListLayout>

      <ConfirmDeleteModal
        isShow={Boolean(groupOptionToDelete)}
        onClose={() => setGroupOptionToDelete(null)}
        onDelete={() => handleDelete(groupOptionToDelete)}
      />
      {isLoadingDeleteGroupOption && <Loader />}
    </>
  );
};

export default GroupOptions;
