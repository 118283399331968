import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import TableMonth from 'components/FinancialReportLayout/TableMonth';
import CustomDatePicker from 'components/ui-kits/CustomDatePicker';
import styled from 'styled-components';
import { CalendarIcon } from 'components/ui-kits/Icon';
import DropdownFinancial from 'components/FinancialReportLayout/DropdownFinancial';
import { getFinancialReportV2 } from 'services/financialReportService';
import Loader from 'components/Loader';
import { Grid, GridItem } from 'components/ui-kits/Grid';

const ByMonthStyle = styled.div`
  margin: 3rem 0;

  .custom-datepicker {
    width: 28rem;

    input {
      text-align: center;
    }
  }
`;

const BlockWrapper = styled.div`
  display: flex;
  width: 100%;
  & > div {
    margin-right: 2.8rem;
  }
`;

interface ByMonth {
  mode: 'month' | 'period';
  setDisplayMode(mode: 'month' | 'period'): void;
}

const ByMonth = ({ mode, setDisplayMode }: ByMonth) => {
  const [startDate, setStartDate] = useState(new Date());

  const { data, refetch, isLoading } = useQuery('getByMonth', ({ signal }) =>
    getFinancialReportV2({
      filter: 'by_month',
      startDate: getDateFirstLastMonth(
        new Date(startDate).getFullYear(),
        'first'
      ),
      endDate: getDateFirstLastMonth(new Date(startDate).getFullYear(), 'last'),
      signal
    })
  );

  useEffect(() => {
    refetch();
  }, [startDate, refetch]);

  function getDateFirstLastMonth(year: number, type: 'first' | 'last') {
    if (type === 'first') {
      return `${year}-01-01`;
    } else {
      return `${year}-12-31`;
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <ByMonthStyle>
        <Grid col={11} start="large" marginRight={true}>
          <GridItem colStart={2} colEnd={8} nameStart="large" nameEnd="gutter">
            <BlockWrapper>
              <DropdownFinancial mode={mode} setDisplayMode={setDisplayMode} />
              <CustomDatePicker
                selected={startDate}
                onChange={(date: any) => setStartDate(date)}
                showYearPicker
                dateFormat="yyyy"
                variant="round"
                icon={<CalendarIcon />}
              />
            </BlockWrapper>
          </GridItem>
        </Grid>
        {data && (
          <TableMonth
            dataByMonth={data}
            year={new Date(startDate).getFullYear()}
          />
        )}
      </ByMonthStyle>
    </>
  );
};

export default ByMonth;
