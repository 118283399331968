import React, { useState, useEffect, useRef } from 'react';
import format from 'date-fns/format';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { Grid, GridItem } from 'components/ui-kits/Grid';
import rfdc from 'rfdc';
import xor from 'lodash.xor';
import {
  TextInputWrapper,
  MenuHourWrapper,
  CurrentCategoriesWrapper,
  OptionItemWrapper,
  BtnWrapper,
  LabelCatalogsRight,
  SectionNameWrapper,
  ContainerInput
} from './CatalogsForm.style';
import { MessageErrorName } from 'styles/common';
import TrashBinIcon from 'components/ui-kits/Icon/TrashBin';
import Button from 'components/ui-kits/Button';
import TextInput from 'components/ui-kits/Form/TextInput';
import MenuHour from '../MenuHour';
import CurrentCategories from '../CurrentCategories';
import EditProductItem from '../EditProductItem';
import ModalAddCategories from '../ModalAddCategories';
import {
  IOpeningHour,
  Catalog,
  CatalogEnabled,
  CategoryInCatalogSelected,
  CurrentTaxonInCatalog
} from 'types/Catalog';
import ModalAddProduct from '../ModalAddProduct';
import AddProductItem from '../AddProductItem';
import { CONFIRM_MESSAGE, DELETE_MESSAGE } from 'const/Message';
import ModalPrompt from 'components/ui-kits/ModalPrompt';
import CreateCategory from '../CreateCategory';
import GoBack from 'components/GoBack';
import FormCreateEditLayout from 'components/FormCreateEditLayout';
import PanelRightLayout from 'components/FormCreateEditLayout/PanelRightLayout';
import FormLeftLayout from 'components/FormCreateEditLayout/FormLeftLayout';
import {
  GridSectionName,
  GridItemInput,
  GridItemBtnSave,
  GridItemBtnDelete
} from 'styles/createEdit-page';
import {
  compare,
  handleError,
  handleSuccess,
  removeEleDuplicate,
  compareSort
} from 'utils';
import Loader from 'components/Loader';
import { TypeSort } from 'types/Sort';
import {
  EnabledOrDisabledProduct,
  ProductAddRemoveModal,
  ProductOptionV2
} from 'types/Products';
import { ListTaxonRes } from 'types/Taxon';
import { deleteCatalog } from 'services/catalogService';
import { getTaxonV2 } from 'services/categoryService';
import { SPECIAL_OFFER_LABEL } from 'const/Const';

interface IProps {
  onCreate?: (data: CatalogEnabled) => void;
  dataEdit?: Catalog;
  onEdit?: (data: CatalogEnabled) => void;
  mode: 'create' | 'edit';
}
const timelineDefault = [
  {
    started_at: format(new Date(), 'HH:mm'),
    closed_at: format(new Date(), 'HH:mm'),
    weekday: []
  }
];

const CatalogsForm = ({
  onCreate,
  dataEdit,
  onEdit,
  mode = 'create'
}: IProps) => {
  const funcCheckRef = useRef(() => {
    //Todo
  });
  const [listCategories, setListCategories] = useState([]);

  const [listCategorySelected, setListCategorySelected] = useState<
    CategoryInCatalogSelected[]
  >([]);
  const [currentListTaxonSelected, setCurrentListTaxonSelected] = useState<
    CurrentTaxonInCatalog[]
  >([]);
  const [code, setCode] = useState<string | undefined>();
  const { catalogId } = useParams<{ catalogId: string }>();
  const history = useHistory();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: dataEdit?.name,
      timeline:
        dataEdit && dataEdit.openingHours?.length > 0
          ? dataEdit?.openingHours
          : timelineDefault
    }
  });
  const queryClient = useQueryClient();

  const watchTitle = useWatch<any>({
    control,
    name: 'title'
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isOpenPanelRight, setIsOpenPanelRight] = useState<boolean>(false);
  const [openAddProModal, setOpenAddProModal] = useState<boolean>(false);
  const [listProductCategory, setListProductCategory] = useState<
    ProductOptionV2[]
  >([]);
  const [categoryItem, setCategoryItem] = useState<CurrentTaxonInCatalog>();
  const [modePanelRight, setModePanelRight] = useState<string>('edit');
  const [categoryCreate, setCategoryCreate] = useState<string>();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [codeCategory, setCodeCategory] = useState<string>('');

  useEffect(() => {
    reset({
      name: dataEdit?.name,
      timeline:
        dataEdit && dataEdit.openingHours?.length > 0
          ? dataEdit?.openingHours
          : timelineDefault
    });
  }, [dataEdit, reset]);

  const onModeModal = (status: boolean) => {
    setOpenModal(status);
  };

  const { data: listTaxon, isLoading: isLoadingTaxons } = useQuery(
    'getListTaxon',
    ({ signal }) => getTaxonV2(signal)
  );

  useEffect(() => {
    funcCheckRef.current = () => {
      const listIdModalSelected = listCategorySelected.map((item) => {
        const taxonCode = item.taxon.split('/');
        return taxonCode[taxonCode.length - 1];
      });
      const currentListTaxonSelectedId = currentListTaxonSelected.map(
        (item) => item.taxonCode
      );
      const diffId = xor(listIdModalSelected, currentListTaxonSelectedId);
      const listTaxonFull = listTaxon?.data['hydra:member'];
      const itemDiffSelected = diffId.map((item) =>
        listTaxonFull?.find(
          (taxonItem: ListTaxonRes) => taxonItem.code === item
        )
      );
      const newCurrentSelectedCategory = itemDiffSelected.reduce((acc, cur) => {
        if (!cur) {
          return acc;
        }
        return [
          ...acc,
          {
            ...cur,
            catalogId: catalogId,
            excludedProductIds: [],
            numberOfIncludedProducts: cur.numberOfProducts,
            numberOfProducts: cur.numberOfProducts,
            products: [],
            taxonCode: cur.code,
            taxonId: cur.id,
            taxonName: cur.name
          }
        ];
      }, []);
      const newSelectedCategoryItem = [
        ...currentListTaxonSelected,
        ...newCurrentSelectedCategory
      ];
      setCurrentListTaxonSelected(newSelectedCategoryItem);
    };
  }, [listCategorySelected, currentListTaxonSelected, listTaxon, catalogId]);

  useEffect(() => {
    const arrListTaxon = listTaxon?.data['hydra:member'].map(
      (item: ListTaxonRes) => {
        return {
          id: item['@id'],
          name: item.name,
          catalogs: item.catalogs.map((catalogItem) => catalogItem.name),
          code: item.code,
          numberOfProducts: item.numberOfProducts
        };
      }
    );
    setListCategories(arrListTaxon);
    funcCheckRef.current();
  }, [listTaxon]);

  useEffect(() => {
    if (dataEdit) {
      const categories = [...dataEdit.categories];
      const specialOfferCategories: CurrentTaxonInCatalog[] = [];
      const normalCategories: CurrentTaxonInCatalog[] = [];

      for (const item of categories) {
        if (item.taxonName === SPECIAL_OFFER_LABEL) {
          specialOfferCategories.push(item);
        } else {
          normalCategories.push(item);
        }
      }

      compare(normalCategories, 'increase', 'position');

      // Sort Special Offer item (if any) first, then the rest of the list.
      const formattedCategoryList = [
        ...specialOfferCategories,
        ...normalCategories
      ];
      const arrCate = formattedCategoryList.map(
        (item: CurrentTaxonInCatalog) => {
          const excludedProductId = item.excludedProductIds.map(
            (id: string | number) => Number(id)
          );
          return {
            taxon: `/api/v2/merchant/taxons/${item.taxonCode}`,
            excludedProductIds: excludedProductId,
            keepProductsData: true // tag to let BE know is product list inside category been edited
          };
        }
      );

      setListCategorySelected(arrCate);

      if (formattedCategoryList.length) {
        formattedCategoryList.map((cate) => {
          cate.catalogId = dataEdit.id;
          cate.products = []; // category unOpen -> temporary empty product
          return cate;
        });
      }

      setCurrentListTaxonSelected(formattedCategoryList);
    }
  }, [dataEdit]);

  const handleSelectCategories = (data: { addcategories: any }) => {
    if (typeof data.addcategories === 'string') {
      data.addcategories = [data.addcategories];
    }
    const listCategorySelectedClone = [...listCategorySelected].map(
      (cate) => cate.taxon
    );
    const remainCate = listCategorySelectedClone.filter((item: string) => {
      return data.addcategories.includes(item);
    });
    const add = data.addcategories.filter((item: string) => {
      return !listCategorySelectedClone.includes(item);
    });
    const newCategoriesId = [...remainCate, ...add];
    let arrCate = [];
    let arrCurrTaxon = [];
    if (listCategorySelected.length) {
      arrCate = newCategoriesId.map((id: string) => {
        const currItem = listCategorySelected.find(
          (cate: CategoryInCatalogSelected) => id === cate.taxon
        );
        if (currItem) {
          return currItem;
        }
        return {
          excludedProductIds: [],
          keepProductsData: true,
          taxon: id
        };
      });
      arrCurrTaxon = newCategoriesId.map((id: string) => {
        const currItem = currentListTaxonSelected.find(
          (taxon: CurrentTaxonInCatalog) => id.includes(taxon.taxonCode)
        );
        if (currItem) {
          return currItem;
        }
        const getTaxonById = listTaxon?.data['hydra:member'].find(
          (taxon: ListTaxonRes) => taxon['@id'] === id
        );
        return {
          excludedProductIds: [],
          numberOfIncludedProducts: getTaxonById.numberOfProducts,
          numberOfProducts: getTaxonById.numberOfProducts,
          products: [],
          taxonCode: getTaxonById.code,
          taxonId: getTaxonById.id,
          taxonName: getTaxonById.name,
          catalogId: Number(catalogId)
        };
      });
    } else {
      arrCate = data.addcategories.map((id: string) => {
        return { taxon: id, excludedProductIds: [], keepProductsData: true };
      });
      arrCurrTaxon = data.addcategories.map((id: string) => {
        const getTaxonById = listTaxon?.data['hydra:member'].find(
          (taxon: ListTaxonRes) => taxon['@id'] === id
        );
        return {
          excludedProductIds: [],
          numberOfIncludedProducts: getTaxonById.numberOfProducts,
          numberOfProducts: getTaxonById.numberOfProducts,
          products: [],
          taxonCode: getTaxonById.code,
          taxonId: getTaxonById.id,
          taxonName: getTaxonById.name,
          catalogId: Number(catalogId)
        };
      });
    }
    setListCategorySelected(arrCate);
    setCurrentListTaxonSelected(arrCurrTaxon);
    onModeModal(false);
  };

  const handleRemoveProductInCategory = ({
    data,
    action
  }: EnabledOrDisabledProduct) => {
    const newListCategory = [...listCategorySelected];
    const newCurrentTaxonSelected = [...currentListTaxonSelected];

    if (action === 'remove') {
      newListCategory.forEach((item) => {
        if (
          item.taxon.includes(data.categoryItem) &&
          !item.excludedProductIds.includes(data.productId)
        ) {
          item.excludedProductIds.push(data.productId);
        }
      });
      newCurrentTaxonSelected.forEach((item) => {
        if (
          item.taxonCode.includes(data.categoryItem) &&
          !item.excludedProductIds.includes(data.productId)
        ) {
          item.excludedProductIds.push(data.productId);
        }
      });
    }
    if (action === 'add') {
      newListCategory.forEach((item) => {
        if (item.taxon.includes(data.categoryItem)) {
          item.excludedProductIds = item.excludedProductIds.filter(
            (id: number) => id !== data.productId
          );
        }
      });
      newCurrentTaxonSelected.forEach((item) => {
        if (item.taxonCode.includes(data.categoryItem)) {
          item.excludedProductIds = item.excludedProductIds.filter(
            (id: number | string) => Number(id) !== data.productId
          );
        }
      });
    }
    setListCategorySelected(newListCategory);
    setCurrentListTaxonSelected(newCurrentTaxonSelected);
  };

  const handleRemoveCategoryInCatalog = (idx: number) => {
    if (!listCategorySelected?.length) return;
    setCurrentListTaxonSelected((oldData: CurrentTaxonInCatalog[]) => [
      ...oldData.slice(0, idx),
      ...oldData.slice(idx + 1, oldData.length)
    ]);
    setListCategorySelected((oldData: CategoryInCatalogSelected[]) => [
      ...oldData.slice(0, idx),
      ...oldData.slice(idx + 1, oldData.length)
    ]);
  };

  const handleDelete = (id?: number) => {
    if (id) {
      onDeleteCatalog(String(id), {
        onSuccess: () => {
          queryClient.invalidateQueries('getListCatalogs');
          handleSuccess(DELETE_MESSAGE.CATALOG_SUCCESS);
          history.push('/menu/catalogs');
        },
        onError: (error: any) => {
          handleError(error.response.status, DELETE_MESSAGE.CATALOG_ERROR);
          setConfirmDelete(false);
        }
      });
    }
  };

  const { isLoading: isLoadingDelete, mutate: onDeleteCatalog } = useMutation(
    (id: Required<string>) => deleteCatalog(id)
  );

  const handleIdEditProduct = (code: string, categoryId?: string) => {
    setCode(code); // code product
    setIsOpenPanelRight(true);
    setModePanelRight('edit');
    if (!categoryId) return;
    const lastIndex = categoryId.lastIndexOf('/');
    const codeCate = categoryId.slice(lastIndex + 1, categoryId.length);
    setCodeCategory(codeCate);
  };

  const handleOpenCreateProduct = () => {
    if (!categoryItem) return;
    setCategoryCreate(categoryItem.taxonCode);
    setIsOpenPanelRight(true);
    setModePanelRight('create');
    setOpenAddProModal(false);
  };

  const onCloseProductItem = (
    newPro: any,
    currentCateId: any,
    mode: 'create' | 'edit' | undefined
  ) => {
    if (newPro) {
      const listCategorySelectedClone = [...listCategorySelected];

      if (mode === 'create') {
        listCategorySelectedClone.forEach((item: CategoryInCatalogSelected) => {
          if (item.taxon.includes(currentCateId) && item.products) {
            newPro.price = newPro.variants[0].price;
            item.products.push(newPro);
          }
        });
        setListCategorySelected(listCategorySelectedClone);
      }

      if (mode === 'edit') {
        listCategorySelectedClone.forEach((item: CategoryInCatalogSelected) => {
          if (item.products) {
            item.products = item.products.map((pro: ProductOptionV2) => {
              if (pro?.['@id'].includes(newPro.oldCode)) {
                pro.defaultVariant = newPro.defaultVariant;
                pro.name = newPro.name;
                pro.description = newPro.description;
                pro.reference = newPro.reference;
                pro.price = newPro.variants[0].price;
                pro.latestImage =
                  newPro.images.length &&
                  newPro.images[newPro.images.length - 1];
                pro.code = newPro.code;
              }
              return pro;
            });
          }
        });

        setListCategorySelected(listCategorySelectedClone);
      }
    }
    setIsOpenPanelRight(false);
  };

  const handleOpenModalAddPro = (
    dataItem: CurrentTaxonInCatalog,
    listProduct: Array<ProductOptionV2>
  ) => {
    setListProductCategory(listProduct);
    setCategoryItem(dataItem);
    setOpenAddProModal(true);
  };

  const editProduct = (
    productRemove: ProductAddRemoveModal[],
    productAdd: ProductAddRemoveModal[]
  ) => {
    const cloneProductAdd = rfdc({ proto: false })(productAdd);
    const cloneProductRemove = rfdc({ proto: false })(productRemove);
    const listCategorySelectedClone = [...listCategorySelected];
    const currentListTaxonSelectedClone = [...currentListTaxonSelected];
    if (!categoryItem) {
      return;
    }
    const taxonCode = categoryItem.taxonCode;
    const currentCategoryItem = categoryItem['@id'];
    // handle add product into category
    currentListTaxonSelectedClone.forEach((item: any) => {
      const listPro = cloneProductAdd.map((item: any) => {
        item.products.price = item.products?.price;
        return rfdc({ proto: false })(item.products);
      });
      if (item['@id'] === currentCategoryItem) {
        item.products.push(...listPro);
        item.products = removeEleDuplicate(item.products, '@id');
      }
    });
    listCategorySelectedClone.forEach((cate: CategoryInCatalogSelected) => {
      const listPro = cloneProductAdd.map((pro: ProductAddRemoveModal) =>
        rfdc({ proto: false })(pro.products)
      );
      if (cate.taxon.includes(taxonCode) && cate.products) {
        cate.products.push(...listPro);
        cate.products = removeEleDuplicate(cate.products, '@id');
      }
    });

    currentListTaxonSelectedClone.forEach((item: any) => {
      const listIdProd = cloneProductRemove.map((item: any) => item.id);
      if (item['@id'] === currentCategoryItem) {
        item.products = item.products.filter((item: any) => {
          return !listIdProd.includes(item['@id']);
        });
      }
    });

    // handle remove product out of cateogry
    listCategorySelectedClone.forEach((cate: CategoryInCatalogSelected) => {
      const listIdProd = cloneProductRemove.map(
        (pro: ProductAddRemoveModal) => pro.id
      );
      if (cate.taxon.includes(taxonCode) && cate.products) {
        cate.products = cate.products.filter((pro) => {
          return !listIdProd.includes(pro['@id']);
        });
      }
    });

    setListCategorySelected(listCategorySelectedClone);
    setCurrentListTaxonSelected(currentListTaxonSelectedClone);
    setOpenAddProModal(false);
  };

  const hanldePanelCreateCategory = () => {
    setModePanelRight('create-category');
    setIsOpenPanelRight(true);
    setOpenModal(false);
  };

  const handleGetNewCreateCategory = (id: string) => {
    setListCategorySelected([
      ...listCategorySelected,
      {
        taxon: id,
        excludedProductIds: [],
        keepProductsData: true
      }
    ]);
  };

  const openPanelRight = () => {
    if (isOpenPanelRight) {
      if (modePanelRight === 'edit') {
        return (
          <>
            {code && dataEdit && (
              <EditProductItem
                code={code}
                onClose={onCloseProductItem}
                codeCategory={codeCategory}
                catalog={dataEdit}
              />
            )}
          </>
        );
      } else if (modePanelRight === 'create') {
        return (
          <>
            {categoryCreate && dataEdit && (
              <AddProductItem
                onClose={onCloseProductItem}
                categoryCreate={categoryCreate}
                catalog={dataEdit}
              />
            )}
          </>
        );
      } else {
        return (
          <CreateCategory
            onClose={onCloseProductItem}
            getNewCreateCategory={handleGetNewCreateCategory}
          />
        );
      }
    } else {
      return (
        <LabelCatalogsRight>
          Sélectionnez un produit à modifier
        </LabelCatalogsRight>
      );
    }
  };

  const handleDragDropCategories = (
    dataCategories: CurrentTaxonInCatalog[]
  ) => {
    setCurrentListTaxonSelected(dataCategories);
    const sortListCategorySelectedAgain = dataCategories.map(
      (item: CurrentTaxonInCatalog) => {
        let data: any = {};
        const check = listCategorySelected.some((cate: any) => {
          data = cate;
          return cate.taxon.includes(item.taxonCode);
        });
        if (check) {
          return data;
        }
      }
    );
    setListCategorySelected(sortListCategorySelectedAgain);
  };

  function handleSort(type: TypeSort, indexSort: number) {
    const listCategorySelectedClone = [...listCategorySelected];
    const currentListTaxonSelectedClone = [...currentListTaxonSelected];

    listCategorySelectedClone.forEach(
      (item: CategoryInCatalogSelected, index: number) => {
        if (index === indexSort) {
          item.products = compareSort(item.products, type, 'name');
        }
      }
    );
    currentListTaxonSelectedClone.forEach(
      (item: CurrentTaxonInCatalog, index: number) => {
        if (index === indexSort) {
          item.products = compareSort(item.products, type, 'name');
        }
      }
    );
    setListCategorySelected(listCategorySelectedClone);
    setCurrentListTaxonSelected(currentListTaxonSelectedClone);
  }

  function handleHaveDataProInCate(
    dataPro: ProductOptionV2[],
    dataCate: CurrentTaxonInCatalog
  ) {
    const currentListTaxonSelectedClone = rfdc({ proto: false })(
      currentListTaxonSelected
    );
    const listCategorySelectedClone = rfdc({ proto: false })(
      listCategorySelected
    );
    const dataProCloned = rfdc({ proto: false })(dataPro);
    // const taxonListSelected = listCategorySelectedClone.find((item) =>
    //   item.taxon.includes(dataCate.taxonCode)
    // );
    // because this function would run everytime the catalog open, make sure it only run on the first time
    // if (taxonListSelected && taxonListSelected.products) {
    //   return;
    // }
    const addProductIntoCurrentTaxons = currentListTaxonSelectedClone.map(
      (item: CurrentTaxonInCatalog) => {
        if (item.taxonCode === dataCate.taxonCode) {
          item.products = dataProCloned;
        }
        return item;
      }
    );
    const addProductIntoCategorySelected = listCategorySelectedClone.map(
      (item: CategoryInCatalogSelected) => {
        if (item.taxon.includes(dataCate.taxonCode)) {
          item.products = dataProCloned;
          item.keepProductsData = false;
        }
        return item;
      }
    );
    setListCategorySelected(addProductIntoCategorySelected);
    setCurrentListTaxonSelected(addProductIntoCurrentTaxons);
  }

  const onSave = (res: any) => {
    const hours: IOpeningHour[] = [];
    res.timeline?.forEach((timeline: any) => {
      if (timeline.weekday) {
        if (typeof timeline.weekday === 'string') {
          timeline.weekday = [timeline.weekday];
        }
        timeline.weekday.forEach((day: string) => {
          hours.push({
            started_at: format(new Date(timeline.started_at), 'HH:mm'),
            closed_at: format(new Date(timeline.closed_at), 'HH:mm'),
            weekday: Number(day)
          });
        });
      }
    });

    listCategorySelected.forEach((cate: CategoryInCatalogSelected) => {
      if (cate.products) {
        cate.products = cate.products.map((pro: any) => pro?.['@id'] || pro);
      }
    });

    const data = {
      name: res.name.trim(),
      openingHours: hours,
      categories: listCategorySelected,
      enabled: dataEdit ? dataEdit.enabled : true
    };

    if (onCreate) {
      onCreate(data);
    } else if (onEdit) {
      onEdit(data);
    }
  };

  return (
    <>
      {openModal ? (
        <ModalAddCategories
          listCategories={listCategories}
          onClose={onModeModal}
          onSelectedCategories={handleSelectCategories}
          listCategorySelected={listCategorySelected}
          openPanelCreateCategory={hanldePanelCreateCategory}
        />
      ) : null}

      {openAddProModal && (
        <ModalAddProduct
          onClose={() => setOpenAddProModal(false)}
          editProduct={editProduct}
          listProductActive={listProductCategory.map((item) => item['@id'])}
          onOpenCreateProduct={handleOpenCreateProduct}
        />
      )}

      <ModalPrompt
        isShow={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onConfirm={() => handleDelete(dataEdit?.id)}
        title={CONFIRM_MESSAGE.DELETE_CATALOG}
        confirmType="withTrashBin"
      />

      <FormCreateEditLayout>
        <FormLeftLayout>
          <form onSubmit={handleSubmit(onSave)}>
            <SectionNameWrapper
              col={GridSectionName.col}
              start={GridSectionName.start}>
              <GoBack />
              <ContainerInput
                colEnd={GridItemInput.colEnd}
                nameEnd={GridItemInput.nameEnd}>
                <TextInputWrapper>
                  <TextInput
                    placeholder="Nom"
                    variant="title"
                    name="name"
                    register={register}
                    watchValue={watchTitle}
                    validate={{
                      required: {
                        value: true,
                        message: 'Ce champ ne peut être vide'
                      },
                      pattern: {
                        value: /^[^\S]*\S/,
                        message: 'Invalid data'
                      }
                    }}
                    hasError={Boolean(errors.name)}
                  />
                </TextInputWrapper>
                {errors.name && (
                  <MessageErrorName>{errors.name.message}</MessageErrorName>
                )}
              </ContainerInput>
              <BtnWrapper
                colStart={GridItemBtnSave.colStart}
                colEnd={GridItemBtnSave.colEnd}
                nameStart={GridItemBtnSave.nameStart}
                nameEnd={GridItemBtnSave.nameEnd}>
                <Button
                  label="Enregistrer"
                  isFluid={true}
                  sizeType="s"
                  variant="primary"
                  fontWeight="BOLD"
                  type="submit"
                  letterSpacing="S"
                />
              </BtnWrapper>
              {mode === 'edit' ? (
                <BtnWrapper
                  colStart={GridItemBtnDelete.colStart}
                  colEnd={GridItemBtnDelete.colEnd}
                  nameStart={GridItemBtnDelete.nameStart}
                  nameEnd={GridItemBtnDelete.nameEnd}>
                  <Button
                    isFluid={false}
                    sizeType="s"
                    fontWeight="BOLD"
                    variant="cancel"
                    letterSpacing="S"
                    icon={<TrashBinIcon width="14" height="16" />}
                    onClick={() => setConfirmDelete(true)}>
                    Supprimer
                  </Button>
                </BtnWrapper>
              ) : null}
            </SectionNameWrapper>

            <Grid col={7} start="large">
              <GridItem colEnd={6} nameEnd="gutter">
                <MenuHourWrapper>
                  <MenuHour
                    register={register}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    mode={mode}
                  />
                </MenuHourWrapper>
                <CurrentCategoriesWrapper>
                  <CurrentCategories
                    onOpen={onModeModal}
                    currentListTaxonSelected={currentListTaxonSelected}
                    onRemoveProductInCategory={handleRemoveProductInCategory}
                    onRemoveCategoryInCatalog={handleRemoveCategoryInCatalog}
                    onGetIdEditProduct={handleIdEditProduct}
                    onOpenModalAddPro={handleOpenModalAddPro}
                    onDragAndDropCategories={handleDragDropCategories}
                    onSort={handleSort}
                    onDataProInCate={handleHaveDataProInCate}
                  />
                </CurrentCategoriesWrapper>
              </GridItem>
            </Grid>
          </form>
        </FormLeftLayout>
        <PanelRightLayout>
          <OptionItemWrapper>{openPanelRight()}</OptionItemWrapper>
        </PanelRightLayout>
      </FormCreateEditLayout>
      {(isLoadingDelete || isLoadingTaxons) && <Loader />}
    </>
  );
};

export default CatalogsForm;
