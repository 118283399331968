import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { format } from 'date-fns';
import { fr } from 'date-fns/esm/locale';
import Table from 'components/ui-kits/Table';
import styled from 'styled-components';
import {
  OptionTooltip,
  OptionTooltipItem
} from 'components/ui-kits/OptionTooltip';
import { TableCell } from 'components/ui-kits/Table/Table.style';
import DisplayText from 'components/ui-kits/DisplayText';
import ModalPrompt from 'components/ui-kits/ModalPrompt';
import {
  CONFIRM_MESSAGE,
  DELETE_MESSAGE,
  DONT_DATA_MESSAGE
} from 'const/Message';
import { formatDollar, handleError, handleSuccess } from 'utils';
import Loader from 'components/Loader';
import { ProductRes, TaxonName } from 'types/Products';
import { Dot, MultilineTrunc, NotDataYet } from 'styles/common';
import Image from 'components/ImageDisplay';
import { deleteProduct } from 'services/productService';
import { SPECIAL_OFFER_LABEL } from 'const/Const';

const headerTable = [
  { label: 'Photo', width: '10%' },
  { label: 'Nom' },
  { label: 'Description', width: '20%' },
  { label: 'Prix' },
  { label: 'Catégories', width: '15%' },
  { label: 'Dernière modification', width: '15%' },
  { label: '' }
];

const StyleTable = styled.div`
  [data-style='cate'] {
    line-height: 2.4rem;
  }
  .time {
    margin-bottom: 0.5rem;
  }
  .date {
    text-transform: capitalize;
  }
  .reference {
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0.5rem 0;
    width: 20rem;
    overflow-wrap: break-word;
  }
`;

const TableRow = styled.tr`
  cursor: pointer;
`;

const ShortUnit = {
  kilogram: 'Kg',
  lit: 'L',
  liter: 'L'
};

const ListProduct = ({ products }: { products: ProductRes[] }) => {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [codeDelete, setCodeDelete] = useState<string>();
  const { path } = useRouteMatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const onEdit = (code: string) => {
    history.push(`${path}/edit/${code}`);
  };

  const handleDelete = (res: boolean) => {
    if (res && codeDelete) {
      onDeleteProduct(
        { code: codeDelete, data: { enabled: false } },
        {
          onSuccess: () => {
            handleSuccess(DELETE_MESSAGE.PRODUCT_SUCCESS);
            queryClient.invalidateQueries('getListProducts');
            setConfirmDelete(false);
          },
          onError: (error: any) => {
            handleError(error.response.status, DELETE_MESSAGE.PRODUCT_ERROR);
            setConfirmDelete(false);
          }
        }
      );
    }
  };

  const openModalDelete = (code: string) => {
    setCodeDelete(code);
    setConfirmDelete(true);
  };

  const { isLoading, mutate: onDeleteProduct } = useMutation(
    ({ code, data }: Required<{ code: string; data: { enabled: boolean } }>) =>
      deleteProduct(code, data)
  );

  const renderCategories = (productTaxons: TaxonName[]) => {
    // Display the list with only one item of name "Offre(s) spéciale(s)"
    const listWithOneSpecialOffer: TaxonName[] = [];
    let isSpecialOfferFound = false;

    for (const element of productTaxons) {
      if (element.name === SPECIAL_OFFER_LABEL && !isSpecialOfferFound) {
        listWithOneSpecialOffer.push(element);
        isSpecialOfferFound = true;
      } else if (element.name !== SPECIAL_OFFER_LABEL) {
        listWithOneSpecialOffer.push(element);
      }
    }

    if (listWithOneSpecialOffer.length > 2) {
      return `${listWithOneSpecialOffer.length} Catégories`;
    } else if (
      listWithOneSpecialOffer.length <= 2 &&
      listWithOneSpecialOffer.length > 0
    ) {
      return listWithOneSpecialOffer.map((item, idx) => (
        <div key={item.code + idx}>{item.name}</div>
      ));
    } else {
      return '0 Catégorie';
    }
  };

  // this true meaning product sold by weight
  const shouldShowQuantityUnit = (product: ProductRes) => {
    return product.quantityProductSoldByWeight && product.unit;
  };

  // this true meaning product sold by weight
  const shouldShowPricePerUnit = (product: ProductRes) => {
    return product.pricePerUnit === 0 || product.pricePerUnit;
  };

  return (
    <StyleTable>
      <Table header={headerTable}>
        {products?.length > 0 ? (
          <>
            {products.map((product: ProductRes) => (
              <TableRow key={product.id}>
                <TableCell onClick={() => onEdit(product.code)}>
                  <Image src={product.latestImage?.path} />
                </TableCell>
                <TableCell onClick={() => onEdit(product.code)}>
                  <MultilineTrunc
                    line="2"
                    style={{ display: 'inline-flex', alignItems: 'center' }}>
                    {product.name}
                    {shouldShowQuantityUnit(product) && (
                      <>
                        <Dot />
                        {product.quantityProductSoldByWeight}&nbsp;
                        {ShortUnit[product.unit]}
                      </>
                    )}
                  </MultilineTrunc>
                  <div style={{ fontWeight: 300 }}>
                    {shouldShowPricePerUnit(product) && (
                      <>
                        {formatDollar(product.pricePerUnit)}€&nbsp;/&nbsp;
                        {ShortUnit[product.unit]}
                      </>
                    )}
                  </div>
                  <div className="reference">
                    <MultilineTrunc line="2">
                      {product.reference}
                    </MultilineTrunc>
                  </div>
                </TableCell>
                <TableCell
                  fontSize="M"
                  fontWeight="LIGHT"
                  onClick={() => onEdit(product.code)}>
                  <MultilineTrunc line="2">
                    {product.description}
                  </MultilineTrunc>
                </TableCell>
                <TableCell onClick={() => onEdit(product.code)}>
                  {formatDollar(product.price)}€
                </TableCell>
                <TableCell
                  data-style="cate"
                  onClick={() => onEdit(product.code)}>
                  {!!product.taxonNames && (
                    <>{renderCategories(product.taxonNames)}</>
                  )}
                </TableCell>
                <TableCell onClick={() => onEdit(product.code)}>
                  <DisplayText
                    fontSize="S"
                    fontWeight="LIGHT"
                    className="time"
                    label={format(new Date(product.updatedAt), 'HH : mm')}
                  />
                  <div className="date">
                    {format(new Date(product.updatedAt), 'cccc d MMM yyyy', {
                      locale: fr
                    })}
                  </div>
                </TableCell>
                <TableCell>
                  <OptionTooltip
                    component={
                      <>
                        <OptionTooltipItem
                          label="Modifier"
                          onClick={() => onEdit(product.code)}
                        />
                        <OptionTooltipItem
                          label="Supprimer"
                          className="list-option-item del"
                          onClick={() => openModalDelete(product.code)}
                        />
                      </>
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </>
        ) : (
          <tr>
            <NotDataYet colSpan={7}>{DONT_DATA_MESSAGE('produits')}</NotDataYet>
          </tr>
        )}
      </Table>
      <ModalPrompt
        isShow={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onConfirm={() => handleDelete(true)}
        title={CONFIRM_MESSAGE.DELETE_PRODUCT}
        confirmType="withTrashBin"
      />
      {isLoading ? <Loader /> : null}
    </StyleTable>
  );
};

export default ListProduct;
