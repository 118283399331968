import styled from 'styled-components';
import { COLORS } from './variables';

export const Title = styled.h2`
  color: ${COLORS.BLACK_2};
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 3.8rem;
  margin-bottom: 3.5rem;
`;

export const Actions = styled.div`
  margin-top: 3.5rem;
`;

export const MessageError = styled.div`
  margin-top: 1rem;
  font-size: 1.4rem;
  color: ${COLORS.RED_6};
`;

export const ShowPasswordWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ShowPasswordIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
`;

export const ForgotPasswordWrapper = styled.div`
  margin-top: 1.4rem;
  text-align: center;

  button {
    letter-spacing: 0px;
    font-size: 1.4rem;
    line-height: 1.7rem;
    padding: 1.2rem 1rem;
    margin: 0 0.5rem;
    min-width: auto;
  }
`;

export const FieldWrapper = styled.div`
  margin-bottom: 2.1rem;
  label {
    margin-bottom: 1.4rem;
  }
`;
