import React from 'react';
import { ListInvoicesMerchantTable } from 'types/InvoicesMerchantDetails';
import { ItemCustomerWapper } from './ItemCustomer.style';

const ItemCustomer = ({
  number,
  description,
  subDescription,
  qty,
  subQty,
  vat,
  subVat,
  vatAmount,
  subVatAmount,
  totalHT,
  subTotalHT,
  totalPrice,
  subTotalPrice,
  unitPrice,
  subUnitPrice,
  reduction,
  subReduction,
  netPriceHT,
  subNetPriceHT
}: ListInvoicesMerchantTable) => {
  return (
    <ItemCustomerWapper>
      <td className="descriptionWrapper">
        <span className="number">{number}</span>
        <div className="description">
          <div className="mainDescription">{description}</div>
          <div className="subDescription">{subDescription}</div>
        </div>
      </td>
      <td className="unitPriceWrapper">
        <div className="qty">{unitPrice}</div>
        <div className="subQty">{subUnitPrice}</div>
      </td>
      <td className="qtyWrapper">
        <div className="qty">{qty}</div>
        <div className="subQty">{subQty}</div>
      </td>
      <td className="totalHTWrapper">
        <div className="totalHT">{totalHT}</div>
        <div className="subTotalHT">{subTotalHT}</div>
      </td>
      <td className="col-wrapper">
        <div className="col-item">{reduction}</div>
        <div className="sub-col-item">{subReduction}</div>
      </td>
      <td className="col-wrapper">
        <div className="col-item">{netPriceHT}</div>
        <div className="sub-col-item">{subNetPriceHT}</div>
      </td>
      <td className="vatWrapper">
        <div className="qty">{vat}</div>
        <div className="subVat">{subVat}</div>
      </td>
      <td className="risingWrapper">
        <div className="vatAmount">{vatAmount}</div>
        <div className="subVatAmount">{subVatAmount}</div>
      </td>
      <td className="totalPriceWrapper">
        <div className="totalPrice">{totalPrice}</div>
        <div className="subTotalPrice">{subTotalPrice}</div>
      </td>
    </ItemCustomerWapper>
  );
};

export default ItemCustomer;
