import styled from 'styled-components';
import { COLORS } from 'styles/variables';

export const GripLines = styled.div`
  position: relative;
  width: 2.4rem;
  height: 0.3rem;
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 0.3rem;
    background-color: ${COLORS.GREY_1};
  }
  &::before {
    top: -0.3rem;
  }
  &::after {
    top: 0.3rem;
  }
`;

export const CardBodyItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLORS.GREY_4};
  padding: 1.3rem 2.8rem;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 2rem;
  button {
    padding: 0;
  }
  #remove {
    color: ${COLORS.GREY_1};
  }
  #add {
    color: ${COLORS.RED};
  }
  #edit {
    min-width: 9rem;
  }
  #cart-show {
    margin-right: 1rem;
    path {
      fill: #8d9598;
    }
  }
`;

export const GripAndImage = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  padding-left: 2.8rem;

  img {
    width: 6rem;
    height: 6rem;
    object-fit: cover;
    border-radius: 0.7rem;
    min-width: 6rem;
  }
`;

export const DescriptionWrapper = styled.div`
  flex-grow: 1;
  margin: 0 2.8rem;

  .ref {
    font-size: 1.2rem;
    margin: 0.6rem 0;
    font-weight: 300;
  }

  .desc {
    font-size: 1.2rem;
    width: 55%;
  }
`;
