import api from 'api/setupAxios';
import {
  MERCHANT_COVER_IMAGE,
  MERCHANT_SLUG,
  MERCHANT_UPLOAD
} from 'const/endpoint';
import { MerchantReq, Period } from 'types/Merchant';

export const getMerchantV2 = async (slug: string, signal?: AbortSignal) => {
  const res = await api.call(signal).get(MERCHANT_SLUG.replace(':slug', slug));
  return res.data;
};

export const uploadImageProfileV2 = async (fd: any) => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  };
  const res = await api.call().post(MERCHANT_UPLOAD, fd, config);
  return res.data;
};

export const uploadImageCover = async (fd: any) => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  };
  const res = await api.call().post(MERCHANT_COVER_IMAGE, fd, config);
  return res.data;
};

export const updateMerchantInfoV2 = async (slug: string, data: MerchantReq) => {
  const config = {
    headers: { 'Content-Type': 'application/merge-patch+json' }
  };
  const res = await api
    .call()
    .patch(MERCHANT_SLUG.replace(':slug', slug), data, config);
  return res.data;
};

export const getStatisticsDashboardV2 = async (
  period: Period,
  signal?: AbortSignal
) => {
  const res = await api
    .call(signal)
    .get(`merchant/statistics/dashboard?period=${period}`);
  return res.data;
};
