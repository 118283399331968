import React, { useState } from 'react';
import Title from 'components/ui-kits/Title';
import { SearchStyle, Wrapper, WrapperRight } from 'styles/listing-page';
import Button from 'components/ui-kits/Button';
import Sort from 'components/Sort';
import CategoryList from 'components/CategoryLayout/CategoryList';
import CategoryNameForm from 'components/CategoryLayout/CategoryNameForm';
import ListLayout from 'components/ListLayout';
import TextInput from 'components/ui-kits/Form/TextInput';
import { SearchIcon } from 'components/ui-kits/Icon';

const Categories = () => {
  const [isCreateNew, setIsCreateNew] = useState(false);

  const onClickCreate = () => {
    // TODO: toggle create form
    setIsCreateNew(true);
  };

  return (
    <ListLayout>
      <Wrapper>
        <Title label="Catégories" hasSearch={true} />
        <WrapperRight>
          <Sort />
          <SearchStyle>
            <TextInput
              name="search"
              placeholder="Rechercher un produit..."
              className="default__control"
            />
            <button className="search-icon disabled" disabled>
              <SearchIcon />
            </button>
          </SearchStyle>
          <div>
            <Button variant="primary" fontWeight="BOLD" onClick={onClickCreate}>
              + CRÉER UNE CATÉGORIE
            </Button>
          </div>
        </WrapperRight>
      </Wrapper>
      {isCreateNew && (
        <CategoryNameForm onCancel={() => setIsCreateNew(false)} />
      )}
      <div>
        <CategoryList />
      </div>
    </ListLayout>
  );
};

export default Categories;
