import React from 'react';
import ProductInGroupOption from '../ProductInGroupOption';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ListProductsCustom } from 'types/Products';

interface IProps {
  listProducts: ListProductsCustom[];
  indexGo: number;
}

const ListProductInGroupOption = ({ listProducts, indexGo }: IProps) => {
  return (
    <Droppable droppableId={`droppable-pro-${indexGo}`} type={String(indexGo)}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {listProducts.map((pro: ListProductsCustom, indexPro: number) => {
            return (
              <Draggable
                draggableId={`${indexGo}${indexPro}`}
                index={indexPro}
                key={`${indexGo}${indexPro}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      boxShadow: snapshot.isDragging
                        ? '0 0 .4rem #666'
                        : 'none',
                      backgroundColor: snapshot.isDragging
                        ? '#fff'
                        : 'transparent'
                    }}>
                    <ProductInGroupOption key={pro.id} productItem={pro} />
                  </div>
                )}
              </Draggable>
            );
          })}

          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default ListProductInGroupOption;
