import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Table from 'components/ui-kits/Table';
import Button from 'components/ui-kits/Button';
import { Search } from 'components/ui-kits/Form/Dropdown';
import Modal from 'components/Modal';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';
import { Checkbox } from 'components/ui-kits/Form/Checkbox';
import { TableCell } from 'components/ui-kits/Table/Table.style';
import { GroupOptionRes, IGroupOptions } from 'types/GroupOptions';
import { ScrollbarCustom } from 'styles/common';

const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.2rem 4.6rem;
  box-sizing: border-box;
  border-bottom: 1px solid ${COLORS.GREY_4};
`;

const Title = styled.div`
  color: ${COLORS.BLACK_2};
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.9rem;
`;

const Filter = styled.div`
  display: flex;
  padding: 2.6rem 4.6rem;
`;

const SearchWrapper = styled.div`
  min-width: 41.5rem;
  height: 3.8rem;
  margin-right: 3.2rem;
`;

const ButtonStyle = styled.div`
  button {
    padding: 0 1.6rem;
    height: 3.8rem;
  }
`;

const ContainsStyle = styled.span`
  &:after {
    content: ', ';
  }
  &:last-child:after {
    content: '';
  }
`;

const HeaderData = [
  { label: '', width: '8%' },
  { label: 'Nom', width: '20%' },
  { label: 'Nom affiché', width: '20%' },
  { label: `Type d'option`, width: '20%' },
  { label: 'Contient', width: '30%' }
];

interface IProps {
  onClose: () => void;
  listOption: GroupOptionRes[];
  onGroupOptionSelected: (
    data: { addgroupoption: string[] },
    idGOAdd?: string[],
    idGORemove?: string[]
  ) => void;
  currentGroupOptionSelected?: any;
  mode: 'catalog' | 'product';
}

const ModalAddOption = ({
  onClose,
  listOption,
  onGroupOptionSelected,
  currentGroupOptionSelected,
  mode
}: IProps) => {
  const history = useHistory();
  const { handleSubmit, register } = useForm();

  const onCloseModal = () => {
    onClose();
  };

  const onChangeSearch = (data: any) => {
    console.log(data);
  };

  const onSave = (data: any) => {
    const idGOSelected = currentGroupOptionSelected.map(
      (go: IGroupOptions) => go['@id']
    );
    let addRemoveGOModal = data.addgroupoption;

    if (!addRemoveGOModal) return;
    if (!Array.isArray(addRemoveGOModal)) {
      addRemoveGOModal = [addRemoveGOModal];
    }

    const idGOAdd = addRemoveGOModal.filter(
      (item: string) => !idGOSelected.includes(item)
    );
    const idGORemove = idGOSelected.filter(
      (item: string) => !addRemoveGOModal.includes(item)
    );

    onGroupOptionSelected(data, idGOAdd, idGORemove);
  };

  const groupOptionChecked = (id: string) => {
    return currentGroupOptionSelected?.some((item: any) => item['@id'] === id);
  };

  const renderResult = (data: GroupOptionRes[]) => {
    if (data.length > 0) {
      return data.map((item) => (
        <tr key={item.id}>
          <TableCell>
            <Checkbox
              name="addgroupoption"
              value={item.id}
              register={register}
              defaultChecked={groupOptionChecked(item.id)}
            />
          </TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell fontSize="M" fontWeight="REGULAR">
            {item.displayName}
          </TableCell>
          <TableCell fontSize="M" fontWeight="REGULAR">
            {item.isMandatory ? 'Obligatoire' : 'Optionnel'}
          </TableCell>
          <TableCell fontSize="M" fontWeight="REGULAR">
            {item.contains.length > 0
              ? item.contains?.map((item: any) => {
                  return (
                    <ContainsStyle key={item.product['@id']}>
                      {item.product.name}
                    </ContainsStyle>
                  );
                })
              : ''}
          </TableCell>
        </tr>
      ));
    } else {
      return null;
    }
  };

  return (
    <div>
      <Modal close={onCloseModal}>
        <form onSubmit={handleSubmit(onSave)}>
          <HeaderTable>
            <Title>Ajout de groupe(s) d&#39;option(s)</Title>
            <Button
              type="submit"
              isFluid={false}
              variant="primary"
              label="Enregistrer"
              fontWeight="BOLD"
              letterSpacing="S"
            />
          </HeaderTable>
          <Filter>
            <SearchWrapper>
              <Search
                change={onChangeSearch}
                placeholder="Rechercher"
                variant="default"
              />
            </SearchWrapper>
            {mode === 'product' && (
              <ButtonStyle>
                <Button
                  type="button"
                  label="+ CRÉER UN GROUPE D'OPTION"
                  variant="cancel"
                  fontWeight="BOLD"
                  onClick={() => history.push('/menu/groupOptions/create')}
                />
              </ButtonStyle>
            )}
          </Filter>
          <div>
            <Table header={HeaderData} borderHead={false} hasScroll={true}>
              <ScrollbarCustom>{renderResult(listOption)}</ScrollbarCustom>
            </Table>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ModalAddOption;
