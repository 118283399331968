import React, { useState } from 'react';
import Button from 'components/ui-kits/Button';
import TextInput from 'components/ui-kits/Form/TextInput';
import TrashBinIcon from 'components/ui-kits/Icon/TrashBin';
import {
  BtnWrapper,
  SectionNameWrapper,
  TextInputWrapper,
  TextInputGridWrapper
} from './SectionTitle.style';
import {
  FieldValues,
  UseFormGetValues,
  UseFormRegister
} from 'react-hook-form';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import GoBack from 'components/GoBack';
import {
  GridItemBtnDelete,
  GridItemBtnSave,
  GridItemInput,
  GridSectionName
} from 'styles/createEdit-page';
import { REGEX_NO_SPACE_FIRST } from 'const/Const';
import { MessageErrorName } from 'styles/common';

interface ISectionTitle {
  register?: UseFormRegister<FieldValues>;
  getValues?: UseFormGetValues<FieldValues>;
  showDeleteBtn: boolean;
  onSave?(): void;
  onDelete(): void;
  errorTitle?: { [x: string]: any };
  name?: string;
}

function SectionTitle({
  register,
  getValues,
  onSave,
  onDelete,
  errorTitle,
  showDeleteBtn,
  name = 'name'
}: ISectionTitle) {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  return (
    <>
      <SectionNameWrapper
        col={GridSectionName.col}
        start={GridSectionName.start}>
        <GoBack />
        <TextInputGridWrapper
          colEnd={GridItemInput.colEnd}
          nameEnd={GridItemInput.nameEnd}>
          <TextInputWrapper>
            <TextInput
              placeholder="Nom"
              variant="title"
              name={name}
              register={register}
              getValues={getValues}
              hasError={Boolean(errorTitle)}
              validate={{
                required: {
                  value: true,
                  message: `Un nom pour le groupe d'option est requis`
                },
                pattern: {
                  value: REGEX_NO_SPACE_FIRST,
                  message: 'Le format est invalide'
                }
              }}
            />
          </TextInputWrapper>
          {errorTitle?.message && (
            <MessageErrorName>{errorTitle?.message}</MessageErrorName>
          )}
        </TextInputGridWrapper>
        <BtnWrapper
          colStart={GridItemBtnSave.colStart}
          colEnd={GridItemBtnSave.colEnd}
          nameStart={GridItemBtnSave.nameStart}
          nameEnd={GridItemBtnSave.nameEnd}>
          <Button
            label="Enregistrer"
            isFluid={true}
            sizeType="s"
            variant="primary"
            fontWeight="BOLD"
            type="submit"
            letterSpacing="S"
            onClick={onSave}
          />
        </BtnWrapper>
        {showDeleteBtn && (
          <BtnWrapper
            colStart={GridItemBtnDelete.colStart}
            colEnd={GridItemBtnDelete.colEnd}
            nameStart={GridItemBtnDelete.nameStart}
            nameEnd={GridItemBtnDelete.nameEnd}>
            <Button
              isFluid={false}
              sizeType="s"
              fontWeight="BOLD"
              variant="cancel"
              letterSpacing="S"
              onClick={() => setConfirmDelete(true)}
              icon={<TrashBinIcon width="14" height="16" />}
              label="Supprimer"
            />
          </BtnWrapper>
        )}
      </SectionNameWrapper>
      <ConfirmDeleteModal
        isShow={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onDelete={onDelete}
      />
    </>
  );
}

export default SectionTitle;
