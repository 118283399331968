import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ListLayout from 'components/ListLayout';
import MerchantInfo from 'components/PaymentParamsLayout/MerchantInfo';
import BankInfo from 'components/PaymentParamsLayout/BankInfo';
import { COLORS } from 'styles/variables';
import { RootState } from 'app/store';
import { getMerchantV2 } from 'services/merchantService';

const PaymentParamsWrapper = styled.div`
  display: flex;
  margin: 5.6rem 0 14.3rem 0;
  border: 1px solid ${COLORS.GREY_4};
  border-radius: 0.7rem;
`;

const PaymentParams = () => {
  const { merchantSlug } = useSelector((state: RootState) => state.user);

  const { data } = useQuery('getPaymentParams', ({ signal }) =>
    getMerchantV2(merchantSlug, signal)
  );

  return (
    <ListLayout>
      <PaymentParamsWrapper>
        {data && (
          <>
            <MerchantInfo data={data} />
            <BankInfo data={data} />
          </>
        )}
      </PaymentParamsWrapper>
    </ListLayout>
  );
};

export default PaymentParams;
