import React from 'react';

function LogoIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="achil-logo"
      width="384"
      height="159.967"
      viewBox="0 0 384 159.967"
      {...props}>
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1965"
            data-name="Rectangle 1965"
            width="384"
            height="159.967"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_2172" data-name="Group 2172" clipPath="url(#clip-path)">
        <path
          id="Path_652"
          data-name="Path 652"
          d="M603.212,197.583c-5.761,5.4-13.228,6.534-18.478,6.534-11.336,0-24.573-5.869-24.573-22.411,0-13.381,10.176-22.727,24.747-22.727a26.72,26.72,0,0,1,17.5,6.276l1.845,1.623,15.358-14.6-2.178-1.943c-9.336-8.327-19.725-12.205-32.7-12.205-13.216,0-25.153,3.909-33.612,11.008-9.3,7.807-14.222,19.068-14.222,32.564s4.941,24.742,14.289,32.566c8.48,7.1,20.393,11.005,33.544,11.005,13.376,0,24.582-4.2,34.26-12.837l2.242-2L605.009,195.9Z"
          transform="translate(-416.648 -107.196)"
          fill="#ff7062"
        />
        <path
          id="Path_653"
          data-name="Path 653"
          d="M1003.108,32.275c-10.071,0-18.483,2.891-25.095,8.609V.7h-23.26V116.577h23.26V73.321c0-11.7,9-20.517,20.927-20.517,12.327,0,18.843,6.658,18.843,19.254v44.519h23.26V71.9c0-12.143-3.116-21.769-9.261-28.611-6.564-7.308-16.212-11.014-28.675-11.014"
          transform="translate(-740.911 -0.547)"
          fill="#ff7062"
        />
        <path
          id="Path_654"
          data-name="Path 654"
          d="M1423.627,0c-6.858,0-13.8,4.339-13.8,12.632,0,8.371,6.943,12.751,13.8,12.751s13.8-4.38,13.8-12.751c0-8.293-6.943-12.632-13.8-12.632"
          transform="translate(-1094.058 0)"
          fill="#ff7062"
        />
        <rect
          id="Rectangle_1963"
          data-name="Rectangle 1963"
          width="23.26"
          height="83.513"
          transform="translate(317.851 32.518)"
          fill="#ff7062"
        />
        <rect
          id="Rectangle_1964"
          data-name="Rectangle 1964"
          width="23.26"
          height="115.714"
          transform="translate(360.74 0.316)"
          fill="#ff7062"
        />
        <path
          id="Path_655"
          data-name="Path 655"
          d="M478.547,598.087a11.6,11.6,0,0,1-8.118,3.038c-6.164,0-11.287-3.512-11.287-10.221s5.079-10.221,11.287-10.221a10.944,10.944,0,0,1,7.727,2.881l-2.865,2.723a7.329,7.329,0,0,0-4.818-1.737c-3.864,0-6.859,2.487-6.859,6.354,0,4.1,3.126,6.275,6.815,6.275a7.324,7.324,0,0,0,5.079-1.815Z"
          transform="translate(-356.305 -450.624)"
          fill="#7db4c4"
        />
        <path
          id="Path_656"
          data-name="Path 656"
          d="M577.411,591.178c0,5.762-4.385,10.1-11.113,10.1-6.685,0-11.026-4.341-11.026-10.1,0-5.722,4.384-10.142,11.026-10.142,6.6,0,11.113,4.42,11.113,10.142m-17.668,0a6.172,6.172,0,0,0,6.555,6.314c4.124,0,6.6-2.841,6.6-6.314a6.4,6.4,0,0,0-6.6-6.393,6.255,6.255,0,0,0-6.555,6.393"
          transform="translate(-430.904 -450.898)"
          fill="#7db4c4"
        />
        <path
          id="Path_657"
          data-name="Path 657"
          d="M689.872,601.022V590.328c0-2.881-1.823-5.288-4.992-5.288a5.058,5.058,0,0,0-5.166,5.288v10.694h-4.471V581.528h4.167l.3,2.6c1.216-2.131,3.733-2.92,5.991-2.92,2.735,0,5.513,1.026,6.728,3.907a7.983,7.983,0,0,1,7.336-3.867c5.9,0,9.073,3.394,9.073,9v10.773h-4.515V590.249c0-2.881-1.563-5.17-4.732-5.17a5.062,5.062,0,0,0-5.209,5.249v10.694Z"
          transform="translate(-524.004 -451.034)"
          fill="#7db4c4"
        />
        <path
          id="Path_658"
          data-name="Path 658"
          d="M865.66,601.022V590.328c0-2.881-1.823-5.288-4.992-5.288a5.058,5.058,0,0,0-5.166,5.288v10.694h-4.471V581.528H855.2l.3,2.6c1.216-2.131,3.733-2.92,5.991-2.92,2.735,0,5.513,1.026,6.729,3.907a7.983,7.983,0,0,1,7.336-3.867c5.9,0,9.073,3.394,9.073,9v10.773h-4.514V590.249c0-2.881-1.563-5.17-4.732-5.17a5.062,5.062,0,0,0-5.209,5.249v10.694Z"
          transform="translate(-660.42 -451.034)"
          fill="#7db4c4"
        />
        <path
          id="Path_659"
          data-name="Path 659"
          d="M1025.651,592.268c.391,2.96,3.082,4.894,6.989,4.894,2.171,0,4.906-.75,6.208-2.092l2.909,2.565c-2.214,2.21-5.9,3.315-9.2,3.315-7.033,0-11.547-4.065-11.547-10.3,0-5.919,4.558-10.142,11.243-10.142,6.9,0,11.591,3.828,10.766,11.76Zm13.067-3.433c-.26-3.078-2.735-4.7-6.338-4.7-3.256,0-5.947,1.618-6.642,4.7Z"
          transform="translate(-792.325 -450.488)"
          fill="#7db4c4"
        />
        <path
          id="Path_660"
          data-name="Path 660"
          d="M1143.784,581.295l.3,2.565c1.563-2.486,3.994-3,6.295-3a8.731,8.731,0,0,1,5.3,1.657l-2.04,3.552a5.82,5.82,0,0,0-3.777-1.184c-3.082,0-5.774,1.737-5.774,5.328V600.75h-4.471V581.295Z"
          transform="translate(-884.37 -450.762)"
          fill="#7db4c4"
        />
        <path
          id="Path_661"
          data-name="Path 661"
          d="M1222.964,606.374c1.3,1.7,4.949,2.171,5.643.2.521-1.5-2.127-2.407-4.037-1.5l-1-.71,1.172-3.552c-4.818-.908-8.378-4.262-8.378-9.905,0-6.709,5.079-10.221,11.287-10.221a10.945,10.945,0,0,1,7.727,2.881l-2.865,2.723a7.329,7.329,0,0,0-4.819-1.737c-3.864,0-6.859,2.487-6.859,6.354,0,4.1,3.126,6.275,6.816,6.275a7.323,7.323,0,0,0,5.079-1.815l3.039,2.723a11.353,11.353,0,0,1-7.814,3.038l-.478,1.578c3.863.04,4.775,2.368,4.341,4.3-1.042,4.42-8.118,4.775-10.853.947Z"
          transform="translate(-943.929 -450.624)"
          fill="#7db4c4"
        />
        <path
          id="Path_662"
          data-name="Path 662"
          d="M1331.765,581.294h4.341v19.456h-4.254l-.217-3.157c-1.432,2.486-4.732,3.67-7.38,3.67-6.251.039-10.983-3.63-10.983-10.261,0-6.511,4.905-10.142,11.07-10.142,3.039,0,5.947,1.3,7.293,3.512ZM1317.743,591c0,4.026,3.126,6.472,6.946,6.472a6.469,6.469,0,1,0,0-12.9c-3.82,0-6.946,2.447-6.946,6.432"
          transform="translate(-1019.13 -450.761)"
          fill="#7db4c4"
        />
        <path
          id="Path_663"
          data-name="Path 663"
          d="M1458.585,601.161V590.743c0-3.354-2.084-5.6-5.773-5.6a5.78,5.78,0,0,0-5.991,5.84v10.181h-4.471V581.666h4.08l.261,2.841a9.7,9.7,0,0,1,7.206-3.117c5.209,0,9.2,3.354,9.2,9.313v10.458Z"
          transform="translate(-1119.297 -451.172)"
          fill="#7db4c4"
        />
        <path
          id="Path_664"
          data-name="Path 664"
          d="M1559.718,557.778v5.643h6.251v3.512h-6.294v9.274c0,2.052,1,3.2,3.082,3.2a6.969,6.969,0,0,0,2.735-.671l1.3,3.473a10.992,10.992,0,0,1-4.3.868c-4.645.118-7.293-2.289-7.293-6.867v-9.274h-4.124v-3.512h4.124v-5.209Z"
          transform="translate(-1203.674 -432.849)"
          fill="#7db4c4"
        />
        <path
          id="Path_665"
          data-name="Path 665"
          d="M1645.522,585.8a8.164,8.164,0,0,0-5.643-1.815c-2.735-.039-4.558.789-4.558,2.328.043,1.618,1.91,2.249,4.645,2.486,3.95.315,9.116,1.1,9.073,6.117-.043,3.63-3.559,6.156-9.116,6.156-3.473,0-6.946-.71-9.767-3.591l2.344-2.881a11.005,11.005,0,0,0,7.51,2.881c2.127,0,4.515-.71,4.558-2.525.044-1.737-1.78-2.407-4.949-2.684-4.037-.355-8.682-1.539-8.726-5.841,0-4.3,4.905-5.919,8.9-5.919a12.163,12.163,0,0,1,8.248,2.6Z"
          transform="translate(-1265.039 -450.489)"
          fill="#7db4c4"
        />
        <path
          id="Path_666"
          data-name="Path 666"
          d="M220.345,120.477h0a37.945,37.945,0,0,1-49.608-20.45l-36.312-87.19a37.946,37.946,0,0,1,49.608,20.45Z"
          transform="translate(-104.317 -7.695)"
          fill="#7db4c4"
        />
        <path
          id="Path_667"
          data-name="Path 667"
          d="M43.051,332.751C41.028,327.927,39.4,322.961,37.8,318A19.328,19.328,0,0,0,.856,329.362a22.334,22.334,0,0,0,.894,2.377,18.923,18.923,0,0,0,2.895,4.636,37.933,37.933,0,0,0,45.231,12.59Z"
          transform="translate(0 -236.183)"
          fill="#ff6f61"
        />
      </g>
    </svg>
  );
}

export default LogoIcon;
