import React, { useState } from 'react';
import Header from 'components/Header';
import Nav from 'components/Nav';
import { NavItem } from 'types/NavItem';
import SecondNav from 'components/SecondNav';
import { Grid, GridItem } from 'components/ui-kits/Grid/index';
import styled from 'styled-components';
import { MEDIA_BREAKPOINT } from 'styles/variables';

interface LayoutProps {
  secondNavs?: NavItem[];
  children?: React.ReactNode;
}

const NavWrapper = styled(GridItem)`
  @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
    position: relative;
  }
`;

const ContentWrapper = styled(GridItem)`
  @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
    grid-column-start: 1;
  }
`;

const Layout = ({ secondNavs, children }: LayoutProps) => {
  const [isOpenNav, setIsOpenNav] = useState<boolean>(false);
  const [isCloseBars, setIsCloseBars] = useState<boolean>(false);

  const handleCloseBarsMobile = () => {
    if (isOpenNav) document.body.style.overflow = 'unset';
    setIsOpenNav(false);
    setIsCloseBars(true);
  };

  const handleToggleNavMobile = (status: boolean) => {
    setIsOpenNav(status);
    setIsCloseBars(!status);
    if (status) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };

  return (
    <>
      <Header
        toggleNavMobile={handleToggleNavMobile}
        closeBarsMobile={isCloseBars}
      />
      <Grid col={12} start="large" marginLeft={true} marginRight={true}>
        <NavWrapper colStart={1} colEnd={2} nameEnd="large">
          <Nav
            closeNavMobile={handleCloseBarsMobile}
            statusNavMobile={isOpenNav}
          />
        </NavWrapper>
        <ContentWrapper
          colStart={2}
          colEnd={12}
          nameStart="large"
          nameEnd="margin">
          <div className="content">
            {secondNavs && <SecondNav secondNavs={secondNavs} />}
            {children}
          </div>
        </ContentWrapper>
      </Grid>
    </>
  );
};

export default Layout;
