import { useQueryClient } from 'react-query';

export default function useInvalidateQuery() {
  const queryClient = useQueryClient();
  return {
    inValidateQueryProductInCatalog: () => {
      queryClient.invalidateQueries('getListTaxon');
      queryClient.invalidateQueries('getListCatalogs');
      queryClient.invalidateQueries('getListProducts');
      queryClient.invalidateQueries(['getProducts']);
    }
  };
}
