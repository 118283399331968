import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useRouteMatch, useHistory } from 'react-router-dom';
import Button from 'components/ui-kits/Button';
import styled from 'styled-components';
import { Grid, GridItem } from 'components/ui-kits/Grid';
import Title from 'components/ui-kits/Title';
import CatalogsList from './List/List';
import { SPACING_PRODUCT_OPTIONS } from 'styles/variables';
import {
  IOpeningHour,
  IMenuHour,
  ListCatalogRender,
  CatalogList
} from 'types/Catalog';
import ListLayout from 'components/ListLayout';
import { formatDayOfWeek } from 'utils';
import Loader from 'components/Loader';
import { getCatalogs } from 'services/catalogService';

const ButtonStyle = styled(Button)`
  padding: 1.2rem 1.4rem;
`;

const TableWrapper = styled.div`
  margin: ${SPACING_PRODUCT_OPTIONS.TABLE};
  margin-top: 6rem;
  overflow-x: auto;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6rem;
`;

const Catalogs = () => {
  const [dataList, setDataList] = useState<ListCatalogRender[]>([]);
  const { path } = useRouteMatch();
  const history = useHistory();

  const { data: resultListCatalogs, isLoading } = useQuery(
    'getListCatalogs',
    ({ signal }) => getCatalogs(signal),
    { staleTime: Infinity }
  );

  useEffect(() => {
    const listCatalogs = resultListCatalogs?.data['hydra:member'];
    if (listCatalogs && listCatalogs.length > 0) {
      const data: ListCatalogRender[] = listCatalogs.map(
        (item: CatalogList) => {
          const listTimeline: IMenuHour[] = [];
          if (item.openingHours?.length > 0) {
            item.openingHours.forEach((wd: IOpeningHour) => {
              const check = listTimeline.some((item) => {
                return (
                  item.closed_at === wd.closed_at &&
                  item.started_at === wd.started_at
                );
              });
              if (!check) {
                const strDay = formatDayOfWeek(wd.weekday);
                const timelineItem: IMenuHour = {
                  weekday: [strDay],
                  closed_at: wd.closed_at,
                  started_at: wd.started_at
                };
                listTimeline.push(timelineItem);
              } else {
                listTimeline.forEach((item) => {
                  if (
                    item.started_at === wd.started_at &&
                    item.closed_at === wd.closed_at
                  ) {
                    const strDay = formatDayOfWeek(wd.weekday);
                    item.weekday.push(strDay);
                  }
                });
              }
            });
          }
          return {
            categoryName: item.name,
            menuHours: listTimeline,
            numberOfCategories: item.numberOfCategories,
            numberOfProducts: item.numberOfProducts,
            id: item.id,
            enabled: item.enabled
          };
        }
      );
      setDataList(data);
    } else {
      setDataList([]);
    }
  }, [resultListCatalogs]);

  return (
    <ListLayout>
      <Title label="Catalogues" />
      {isLoading ? (
        <Loader isFixed={false} />
      ) : (
        <>
          <TableWrapper>
            {resultListCatalogs && (
              <CatalogsList
                listCatalog={dataList}
                listCatalogOriginal={resultListCatalogs.data['hydra:member']}
              />
            )}
          </TableWrapper>
          <Grid col={10} start="gutter" end="large" marginRight={true}>
            <GridItem
              colStart={9}
              colEnd={12}
              nameStart="large"
              nameEnd="gutter">
              <Wrapper>
                <ButtonStyle
                  variant="primary"
                  label="+ CRÉER UN CATALOGUE"
                  isFluid={false}
                  onClick={() => history.push(`${path}/create`)}
                  fontWeight="BOLD"
                />
              </Wrapper>
            </GridItem>
          </Grid>
        </>
      )}
    </ListLayout>
  );
};

export default Catalogs;
