import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Logo, MetroExitIcon, QuestionIcon } from 'components/ui-kits/Icon';
import { JWT_TOKEN } from 'const/Const';
import { Grid, GridItem } from 'components/ui-kits/Grid/index';
import { HeaderStyle, ImageStyle, MenuBars } from './Header.style';
import { RootState } from 'app/store';
import { getMerchantV2 } from 'services/merchantService';

const Header = ({
  toggleNavMobile,
  closeBarsMobile
}: {
  toggleNavMobile?(status: boolean): void;
  closeBarsMobile: boolean;
}) => {
  const history = useHistory();
  const { merchantSlug } = useSelector((state: RootState) => state.user);
  const [isOpenNav, setIsOpenNav] = useState<boolean>(false);

  const logout = () => {
    localStorage.removeItem(JWT_TOKEN);
    history.push('/login');
    window.location.reload();
  };

  const handleOpenNavMobile = (status: boolean) => {
    setIsOpenNav(!isOpenNav);
    toggleNavMobile?.(status);
  };

  useEffect(() => {
    closeBarsMobile && setIsOpenNav(false);
  }, [closeBarsMobile]);

  const { data: dataMerchant } = useQuery(
    'getMerchant',
    ({ signal }) => getMerchantV2(merchantSlug, signal),
    { staleTime: Infinity }
  );

  return (
    <HeaderStyle>
      <Grid
        className="container"
        col={12}
        start="large"
        marginLeft={true}
        marginRight={true}>
        <GridItem className="bars-mobile" colStart={2} colEnd={3}>
          <MenuBars
            onClick={() => handleOpenNavMobile(!isOpenNav)}
            className={isOpenNav ? 'active' : ''}>
            <div></div>
            <div></div>
            <div></div>
          </MenuBars>
        </GridItem>
        <GridItem className="brand" colStart={2} colEnd={3}>
          <div className="logo" onClick={() => history.push('/home')}>
            <ImageStyle>
              <Logo width="80" height="30" />
            </ImageStyle>
          </div>
        </GridItem>
        <GridItem className="help item-inline" colStart={22} colEnd={23}>
          <button>
            <QuestionIcon />
            <a
              href={dataMerchant ? dataMerchant.channelHelperURL : '#'}
              target="_blank"
              rel="noreferrer">
              Aide
            </a>
          </button>
        </GridItem>
        <GridItem className="logout item-inline" colStart={24} colEnd={25}>
          <button onClick={logout}>
            <MetroExitIcon />
            Déconnexion
          </button>
        </GridItem>
      </Grid>
    </HeaderStyle>
  );
};

export default Header;
