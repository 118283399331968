import api from 'api/setupAxios';
import {
  TAXON,
  TAXON_BY_CODE,
  TAXON_CODE_PRODUCT,
  TAXON_PRODUCT,
  TAXON_UPLOAD_IMAGE
} from 'const/endpoint';
import {
  CategoryItemData,
  CategoryNameForm,
  CategoryNameResponse,
  CategoryProductItem
} from 'types/Category';
import { BaseResponseList } from 'types/general';

const ITEM_PER_PATH = 100;

export const createCategoryV2 = async (
  data: CategoryNameForm
): Promise<CategoryNameResponse> => {
  const res = await api.call().post(TAXON, {
    ...data
  });
  return res.data;
};

export const editCategoryV2 = async ({
  data,
  code
}: EditCategoryParams): Promise<CategoryNameResponse> => {
  const res = await api.call().put(TAXON_BY_CODE.replace(':code', code), {
    ...data
  });
  return res.data;
};

export const addRemoveProdInCategoryV2 = async ({
  data,
  code
}: AddRemoveProInCateParams): Promise<CategoryItemData> => {
  const res = await api.call().put(TAXON_CODE_PRODUCT.replace(':code', code), {
    ...data
  });
  return res.data;
};

export const deleteCategoryV2 = async (code: string): Promise<any> => {
  const res = await api.call().delete(TAXON_BY_CODE.replace(':code', code));
  return res.data;
};

export const getListCategoriesV2 = async (
  queryKey: (string | { itemsPerPage: number })[],
  signal?: AbortSignal
): Promise<BaseResponseList<CategoryItemData>> => {
  const res = await api.call(signal).get(TAXON, { params: queryKey[1] });
  return res.data;
};

export const getListCategoryProductsV2 = async ({
  signal,
  isGetAll,
  totalProducts,
  ...queryParam
}: ListCategoryParams): Promise<BaseResponseList<CategoryProductItem>> => {
  if (!isGetAll) {
    const res = await api.call(signal).get(TAXON_PRODUCT, {
      params: { ...queryParam, itemsPerPage: 8 }
    });
    return res.data;
  } else {
    const promiseArray = [];
    const numberOfPath = Math.ceil(totalProducts / ITEM_PER_PATH);
    for (let i = 1; i <= numberOfPath; i++) {
      promiseArray.push(
        api
          .call(signal)
          .get<BaseResponseList<CategoryProductItem>>(TAXON_PRODUCT, {
            params: { ...queryParam, page: i, itemsPerPage: ITEM_PER_PATH }
          })
      );
    }
    const res = await Promise.all(promiseArray);
    const returnData = res.reduce((acc, cur) => {
      if (!cur || !cur.data) {
        return acc;
      }
      if (acc['hydra:member']) {
        acc['hydra:member'] = [
          ...acc['hydra:member'],
          ...cur.data['hydra:member']
        ];
      } else {
        acc['hydra:member'] = cur.data['hydra:member'];
      }
      return acc;
    }, {} as BaseResponseList<CategoryProductItem>);
    return returnData;
  }
};

export const getTaxonV2 = (signal?: AbortSignal) => {
  return api.call(signal).get(TAXON);
};

export const uploadImageCategory = ({
  code,
  fd
}: UploadImageCategoryParams) => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  };
  return api.call().post(TAXON_UPLOAD_IMAGE.replace(':code', code), fd, config);
};

type UploadImageCategoryParams = {
  code: string;
  fd: any;
};
type EditCategoryParams = {
  data: CategoryNameForm;
  code: string;
};
type AddRemoveProInCateParams = {
  data: { productIRIs: string[] };
  code: string;
};
type ListCategoryParams = {
  signal?: AbortSignal;
  'merchant.id': number;
  'productTaxons.taxon.id': number | string;
  isOption: boolean;
  page: number;
  itemsPerPage: number;
  isGetAll: boolean;
  totalProducts: number;
};
