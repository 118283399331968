import api from 'api/setupAxios';
import {
  LOGIN,
  REQUEST_EMAIL,
  RESET_PASSWORD,
  VERIFY_OTP
} from 'const/endpoint';
import { ResetPassword } from 'types/ResetPassword';

type LoginParams = {
  email: string;
  password: string;
};

export const loginV2 = ({ email, password }: LoginParams) => {
  return api.call().post(LOGIN, {
    email,
    password
  });
};

export const requestEmailV2 = async (email: string) => {
  const res = await api.call().post(REQUEST_EMAIL, {
    email
  });
  return res;
};

export const verifyOtpV2 = async (data: Omit<ResetPassword, 'newPassword'>) => {
  const res = await api.call().post(VERIFY_OTP, data);
  return res;
};

export const resetPasswordV2 = async (data: ResetPassword) => {
  const res = await api.call().post(RESET_PASSWORD, data);
  return res;
};
