import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoginForm from 'components/LoginLayout/LoginForm';
import { useMutation } from 'react-query';
import { JWT_TOKEN } from 'const/Const';
import Loader from 'components/Loader';
import { handleSuccess, decodeJwt } from 'utils';
import { addUser } from 'features/user/userSlice';
import { isAfter } from 'date-fns';
import AuthLayout from 'components/AuthLayout';
import SendEmail from 'components/LoginLayout/SendEmail';
import { LOGIN_MESSAGE } from 'const/Message';
import { Step } from 'types/ResetPassword';
import ForgotPassword from 'components/LoginLayout/ForgotPassword';
import { loginV2 } from 'services/authService';

type FormInputs = {
  email: string;
  password: string;
};

const Login = () => {
  const [step, setStep] = useState<Step>('login');
  const [messageLogin, setMessageLogin] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const token = localStorage.getItem(JWT_TOKEN);

  if (token) {
    const decode = decodeJwt(token);
    // check token is expired yet
    if (
      !Boolean(decode.exp) ||
      isAfter(new Date(decode.exp * 1000), Date.now())
    ) {
      history.push('/home');
    } else {
      localStorage.removeItem(JWT_TOKEN);
      history.push('/login');
    }
  }

  const handleLogin = (data: FormInputs) => {
    loginMutate(data, {
      onSuccess: (res: any) => {
        handleSuccess(LOGIN_MESSAGE.SUCCESS);
        localStorage.setItem(JWT_TOKEN, res.data.token);

        const decode = decodeJwt(res.data.token);
        dispatch(
          addUser({
            exp: decode.exp,
            iat: decode.iat,
            merchantId: decode.merchant_id,
            merchantSlug: decode.merchant_slug,
            roles: decode.roles,
            username: decode.username
          })
        );
        history.push('/home');
      },
      onError: () => {
        setMessageLogin('Le mot de passe saisi est incorrect');
      }
    });
  };

  const handleSendEmail = (status: Step) => {
    setMessageLogin('');
    setStep(status);
  };

  const { isLoading: isLoadingLogin, mutate: loginMutate } = useMutation(
    ({ email, password }: Required<FormInputs>) => loginV2({ email, password })
  );

  return (
    <AuthLayout>
      {isLoadingLogin && <Loader />}

      {step === 'login' && (
        <LoginForm
          title="Ravis de vous revoir !"
          errorLogin={messageLogin}
          formSubmit={handleLogin}
          setForgotPassword={() => setStep('sendEmail')}
        />
      )}

      {step === 'sendEmail' && (
        <SendEmail statusStep={handleSendEmail} title="Mot de passe oublié" />
      )}

      {step === 'forgotPassword' && (
        <ForgotPassword
          title="Réinitialisez votre mot de passe"
          statusStep={(status: Step) => setStep(status)}
          onBackSendEmail={() => setStep('sendEmail')}
        />
      )}
    </AuthLayout>
  );
};

export default Login;
