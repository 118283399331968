import styled from 'styled-components';
import { COLORS, FONT_WEIGHT } from 'styles/variables';

export const DatePickerStyled = styled.div<{ variant: 'default' | 'round' }>`
  position: relative;
  .react-datepicker-wrapper {
    input {
      padding: 0.9rem 2rem;
      background-color: ${COLORS.WHITE};
      font-size: 1.8rem;
      width: 100%;
      border: 1px solid ${COLORS.GREY_4};
      border-radius: ${({ variant }) => variant === 'default' && '0.7rem'};
      border-radius: ${({ variant }) => variant === 'round' && '2rem'};
      height: 4rem;
      cursor: pointer;
    }
  }
  .icon {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
  }
  &.custom-datepicker {
    .react-datepicker,
    .react-datepicker__header,
    .react-datepicker__current-month {
      font-size: 1.6rem;
      color: ${COLORS.BLACK_2};
    }
    .react-datepicker__year--container {
      width: 26rem;
    }
    .react-datepicker__year-wrapper {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
    .react-datepicker__month-container {
      width: 44rem;
    }
    .react-datepicker__day-names {
      display: flex;
      justify-content: space-between;
      margin: 2rem 0 0;
      padding: 0 1.6rem;
      font-size: 1.4rem;
    }
    .react-datepicker__week {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 0 1.4rem;
    }
  }
  .react-datepicker {
    border-radius: 0.5rem;
    border: 1px solid ${COLORS.GREY_4};

    &__triangle {
      display: none;
    }

    &__header:not(.react-datepicker__header--has-time-select) {
      border-top-right-radius: 0.5rem;
      border-bottom: none;
      background: ${COLORS.WHITE};
    }

    &__year-text {
      padding: 0.3rem;
      margin: 0.5rem;
      width: 5rem;
      height: 2.6rem;
      position: relative;
      z-index: 999;
      font-weight: ${FONT_WEIGHT.MEDIUM};

      &:hover {
        color: ${COLORS.WHITE};
        background-color: ${COLORS.GREY_3};
        border-radius: 2rem;
      }

      &--selected,
      &--keyboard-selected {
        background-color: ${COLORS.GREY_3};
        color: ${COLORS.BLACK_3};
        border-radius: 2rem;
      }

      &--today {
        font-weight: ${FONT_WEIGHT.MEDIUM};
      }
    }

    &__day {
      padding: 0.5rem 0;
      margin: 0;
      width: 6rem;
      height: 3rem;
      position: relative;
      z-index: 999;
      font-weight: ${FONT_WEIGHT.MEDIUM};
      border-radius: 0;

      &:hover {
        color: ${COLORS.WHITE};
        background-color: ${COLORS.GREY_3};
        border-radius: 2rem;
      }

      &-name {
        width: 6rem;
      }

      &--in-range,
      &--in-selecting-range {
        color: ${COLORS.BLACK_3};
        background-color: ${COLORS.GREY_5};

        &:not(.react-datepicker__day--in-selecting-range) {
          color: ${COLORS.BLACK_3};
        }

        &:hover {
          color: ${COLORS.WHITE};
        }
      }

      &--selecting-range-end,
      &--selecting-range-start {
        background-color: ${COLORS.GREY_5};
        color: ${COLORS.BLACK_3};

        &::after {
          content: '';
          color: ${COLORS.BLACK_3};
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: ${COLORS.GREY_3};
          left: 0;
          top: 0;
          z-index: -1;
          border-radius: 2rem;
        }
      }

      &--selecting-range-start {
        border-top-left-radius: 2rem;
        border-bottom-left-radius: 2rem;
      }

      &--selecting-range-end {
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
      }
    }
  }
`;
