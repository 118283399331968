import React, { useEffect, useState } from 'react';
import {
  CardItem,
  CardHeader,
  CardFooter,
  CollapseIcon,
  CardHeaderLeft,
  CardHeaderRight,
  GripLines,
  NameCategory,
  TotalProductWrapper,
  CloseIconStyle
} from './Collapse.style';
import { ChevronTopIcon, CloseIcon } from 'components/ui-kits/Icon';
import Button from 'components/ui-kits/Button';
import { TypeSort } from 'types/Sort';
import { DoubleArrowIcon } from 'components/ui-kits/Icon';
import { useQuery } from 'react-query';
import Loader from 'components/Loader';
import { CurrentTaxonInCatalog } from 'types/Catalog';
import { ProductOptionV2 } from 'types/Products';
import { getProductsInCategoryV2 } from 'services/productService';
import { SPECIAL_OFFER_LABEL } from 'const/Const';

interface ICollapse {
  children?: React.ReactNode;
  title?: string;
  isOpen?: boolean;
  toggle?: () => void;
  onRemoveCategory?: (idx: number) => void;
  index: number;
  dataItem: CurrentTaxonInCatalog;
  listProduct: Array<ProductOptionV2>;
  openModalAddPro: (
    dataItem: CurrentTaxonInCatalog,
    listProduct: Array<ProductOptionV2>
  ) => void;
  onSort?: (type: 'increase' | 'decrease', indexSort: number) => void;
  isSort?: boolean;
  currentCategory?: CurrentTaxonInCatalog;
  onDataProInCate?: (
    dataPro: ProductOptionV2[],
    dataCate: CurrentTaxonInCatalog
  ) => void;
}

const Collapse = ({
  title,
  children,
  isOpen,
  toggle,
  onRemoveCategory,
  index,
  dataItem,
  listProduct,
  openModalAddPro,
  onSort,
  isSort,
  currentCategory,
  onDataProInCate
}: ICollapse) => {
  const [typeSort, setTypeSort] = useState<TypeSort>('increase');
  const handleOpenModalAddPro = () => {
    openModalAddPro(dataItem, listProduct);
  };
  const queryKey = {
    'catalog[catalogId]': currentCategory?.catalogId,
    'catalog[taxonId]': currentCategory?.taxonId
  };
  const isRegularCategory = dataItem.taxonName !== SPECIAL_OFFER_LABEL;

  function handleSort(type: TypeSort, indexSort: number) {
    onSort?.(type, indexSort);
    setTypeSort(type);
  }

  const {
    isLoading,
    isFetching,
    data: products
  } = useQuery(
    ['getProducts', queryKey],
    (context) =>
      getProductsInCategoryV2(
        {
          ...(context.queryKey[1] as { [key: string]: number }),
          page: 1,
          itemsPerPage: 500
        },
        currentCategory ? currentCategory.numberOfProducts : 0,
        context.signal
      ),
    {
      enabled: isOpen
    }
  );

  useEffect(() => {
    if (!!products && !!currentCategory && isOpen) {
      onDataProInCate?.(products, currentCategory);
    }
    // eslint-disable-next-line
  }, [products, isOpen]);

  return (
    <CardItem>
      {isRegularCategory && (
        <CloseIconStyle
          isFluid={false}
          icon={<CloseIcon />}
          borderRadius="50%"
          onClick={() => onRemoveCategory?.(index)}
        />
      )}
      <CardHeader>
        <CardHeaderLeft>
          <GripLines></GripLines>
          <NameCategory label={title} fontWeight="BOLD" fontSize="XL" />
          {isSort && isOpen && (
            <div className="sort">
              {typeSort === 'increase' && (
                <button
                  type="button"
                  onClick={() => handleSort('decrease', index)}>
                  <DoubleArrowIcon />
                </button>
              )}
              {typeSort === 'decrease' && (
                <button
                  type="button"
                  onClick={() => handleSort('increase', index)}>
                  <DoubleArrowIcon />
                </button>
              )}
            </div>
          )}
        </CardHeaderLeft>
        <CardHeaderRight>
          <TotalProductWrapper fontSize="S">
            {currentCategory?.products?.length
              ? currentCategory.products.length -
                currentCategory.excludedProductIds.length
              : currentCategory?.numberOfIncludedProducts}
            /
            {currentCategory?.products?.length
              ? currentCategory.products.length
              : currentCategory?.numberOfProducts}{' '}
            Produits
          </TotalProductWrapper>
          <CollapseIcon onClick={toggle} className={isOpen ? 'active' : ''}>
            <ChevronTopIcon />
          </CollapseIcon>
        </CardHeaderRight>
      </CardHeader>
      {isOpen ? (
        <>
          {isLoading || isFetching ? <Loader /> : <div>{children}</div>}
          <CardFooter>
            <Button
              fontWeight="BOLD"
              label="+ AJOUTER UN PRODUIT"
              variant="link"
              isFluid={false}
              onClick={handleOpenModalAddPro}
            />
          </CardFooter>
        </>
      ) : null}
    </CardItem>
  );
};

export default Collapse;
