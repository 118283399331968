import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import ListLayout from 'components/ListLayout';
import {
  CashIcon,
  VacationTicketIcon,
  RestaurantTicketIcon
} from 'components/ui-kits/Icon';
import { RootState } from 'app/store';
import PaymentMethodForm from 'components/PaymentMethodLayout';
import { IPaymentMethod } from 'types/PaymentMethod';
import { AllowPaymentMethods, Merchant } from 'types/Merchant';
import Loader from 'components/Loader';
import { handleError, handleSuccess } from 'utils';
import { UPDATE_MESSAGE } from 'const/Message';
import { getMerchantV2 } from 'services/merchantService';
import { updatePaymentMethodV2 } from 'services/paymentMethodService';

const PAYMENT_METHOD: IPaymentMethod[] = [
  { label: 'Espèce', icon: <CashIcon />, paymentCode: 'by_cash' },
  {
    label: 'Chèque-vacances',
    icon: <VacationTicketIcon />,
    paymentCode: 'by_vacation_ticket'
  },
  {
    label: 'Titres Restaurant',
    icon: <RestaurantTicketIcon />,
    paymentCode: 'by_restaurant_ticket'
  }
];

const PaymentMethod = () => {
  const [mode, setMode] = useState<'list' | 'edit'>('list');
  const { merchantSlug } = useSelector((state: RootState) => state.user);
  const queryClient = useQueryClient();

  const handleSave = (dataSave: AllowPaymentMethods[]) => {
    dataSave.push('by_card'); // set default by_card
    const allowPayment: Pick<Merchant, 'allowPaymentMethods'> = {
      allowPaymentMethods: dataSave
    };
    updatePaymentMethodMutate(
      { slug: merchantSlug, data: allowPayment },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('getListAllowPaymentMethod');
          setMode('list');
          handleSuccess(UPDATE_MESSAGE.UPDATE_PAYMENT_METHOD_SUCCESS);
        },
        onError: (error: any) => {
          handleError(
            error.response.status,
            UPDATE_MESSAGE.UPDATE_PAYMENT_METHOD_ERROR
          );
        }
      }
    );
  };

  const { data: dataMerchant } = useQuery(
    'getListAllowPaymentMethod',
    ({ signal }) => getMerchantV2(merchantSlug, signal)
  );
  const { isLoading: isLoadingUpdate, mutate: updatePaymentMethodMutate } =
    useMutation(
      ({
        slug,
        data
      }: Required<{
        slug: string;
        data: { allowPaymentMethods: AllowPaymentMethods[] };
      }>) => updatePaymentMethodV2(slug, data)
    );

  return (
    <ListLayout>
      {dataMerchant && (
        <PaymentMethodForm
          mode={mode}
          onChangeMode={(m: 'list' | 'edit') => setMode(m)}
          listPaymentMethod={PAYMENT_METHOD}
          allowPaymentMethod={dataMerchant.allowPaymentMethods}
          onSave={handleSave}
        />
      )}
      {isLoadingUpdate && <Loader />}
    </ListLayout>
  );
};

export default PaymentMethod;
