import api from 'api/setupAxios';
import { GROUP_OPTION, GROUP_OPTION_BY_ID, PRODUCT } from 'const/endpoint';
import {
  GroupOptionFormsFields,
  IGroupOptions,
  OptionsFormFields
} from 'types/GroupOptions';
import { BaseResponseList } from 'types/general';

export const createOptionItemsV2 = (data: OptionsFormFields) => {
  return api.call().post(PRODUCT, {
    ...data
  });
};

export const createGroupOptionV2 = async (
  data: GroupOptionFormsFields
): Promise<IGroupOptions> => {
  const res = await api.call().post(GROUP_OPTION, {
    ...data
  });
  return res.data;
};

export const editGroupOptionV2 = async (
  data: GroupOptionFormsFields,
  id: string
): Promise<IGroupOptions> => {
  const res = await api.call().put(GROUP_OPTION_BY_ID.replace(':id', id), {
    ...data
  });
  return res.data;
};

export const deleteGroupOptionV2 = async (id: string) => {
  const res = await api.call().delete(GROUP_OPTION_BY_ID.replace(':id', id));
  return res.data;
};

export const getGroupOptionsListV2 = async ({
  signal,
  page,
  limit
}: GetGroupOptionListV2Params): Promise<BaseResponseList<IGroupOptions>> => {
  const params = {
    page,
    itemsPerPage: limit
  };
  const res = await api.call(signal).get(GROUP_OPTION, { params });
  return res.data;
};

export const getGroupOptionsItemV2 = async (
  id: string,
  signal?: AbortSignal
): Promise<IGroupOptions> => {
  const res = await api.call(signal).get(GROUP_OPTION_BY_ID.replace(':id', id));
  return res.data;
};

type GetGroupOptionListV2Params = {
  signal?: AbortSignal;
  page?: number;
  limit?: number;
};
