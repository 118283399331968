import DisplayText from 'components/ui-kits/DisplayText';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';

export const MerchantHeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const MerchantHeadTitle = styled(DisplayText)`
  margin-top: 2.5rem;
`;
export const MerchantHeadButton = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: ${COLORS.WHITE};
  border-radius: 0.5rem;
  border: 1px solid ${COLORS.GREY_4};
  margin-top: 2.2rem;

  &:hover {
    cursor: pointer;
    background: ${COLORS.GREY_5};
  }
`;
export const ButtonText = styled(DisplayText)`
  align-self: center;
  margin-left: 0.8rem;
`;
