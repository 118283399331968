import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { ShowEyeIcon, HideEyeIcon } from 'components/ui-kits/Icon';
import { TableCell } from 'components/ui-kits/Table/Table.style';
import DisplayText from 'components/ui-kits/DisplayText';
import {
  OptionTooltip,
  OptionTooltipItem
} from 'components/ui-kits/OptionTooltip';
import styled, { css } from 'styled-components';
import ModalPrompt from 'components/ui-kits/ModalPrompt';
import { CONFIRM_MESSAGE, UPDATE_MESSAGE, DELETE_MESSAGE } from 'const/Message';
import Loader from 'components/Loader';
import { handleSuccess, handleError } from 'utils/index';
import { Catalog, CatalogEnabled } from 'types/Catalog';
import { deleteCatalog, updateCatalog } from 'services/catalogService';

const TimeStyle = styled.div`
  display: flex;
  span {
    margin: 0 0.5rem;
    font-weight: 300;
  }
`;

const MoreTimelineStyle = styled(DisplayText)`
  margin-top: 1.9rem;
`;

const TableRow = styled.tr<{ isDisabled: boolean }>`
  cursor: pointer;

  td {
    opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
  }
  td:first-child,
  td:last-child {
    opacity: 1;
  }
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      &:hover td {
        opacity: 0.8;
      }
    `}
`;

const ToggleBtn = styled.button`
  width: 2rem;
  height: 2rem;
`;

interface IProps {
  catalogItem: any;
  catalogOriginalItem: Catalog;
}

const CatalogItemOfList = ({ catalogItem, catalogOriginalItem }: IProps) => {
  const history = useHistory();
  const path = useRouteMatch().path;
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<number>();

  const onEdit = (id: number) => {
    history.push(`${path}/edit/${id}`);
  };
  const queryClient = useQueryClient();

  const handleDelete = (res: boolean) => {
    if (res) {
      onDeleteCatalog(String(idDelete), {
        onSuccess: () => {
          handleSuccess(DELETE_MESSAGE.CATALOG_SUCCESS);
          queryClient.invalidateQueries('getListCatalogs');
          setIsOpenModal(false);
        },
        onError: (error: any) => {
          handleError(error.response.status, DELETE_MESSAGE.CATALOG_ERROR);
          setIsOpenModal(false);
        }
      });
    }
  };

  const openModalDelete = (id: number) => {
    setIdDelete(id);
    setIsOpenModal(true);
  };

  const toggleEnabledCatalog = (id: number) => {
    const dataUpdate = {
      enabled: !catalogOriginalItem.enabled
    };
    onUpdateEnabled(
      { id: String(id), data: dataUpdate },
      {
        onSuccess: (res: any) => {
          if (res.data?.enabled) {
            handleSuccess(UPDATE_MESSAGE.ENABLED_SHOW);
          } else {
            handleSuccess(UPDATE_MESSAGE.ENABLED_HIDE);
          }
          queryClient.invalidateQueries('getListCatalogs');
        },
        onError: (errors: any) => {
          handleError(errors.response.status, UPDATE_MESSAGE.ENABLED_ERROR);
        }
      }
    );
  };

  const { isLoading: isLoadingDelete, mutate: onDeleteCatalog } = useMutation(
    (id: Required<string>) => deleteCatalog(id)
  );

  const { isLoading: isLoadingUpdateEnabled, mutate: onUpdateEnabled } =
    useMutation(
      ({ id, data }: Required<{ id: string; data: CatalogEnabled }>) =>
        updateCatalog(id, data)
    );

  return (
    <>
      {(isLoadingDelete || isLoadingUpdateEnabled) && <Loader />}
      <ModalPrompt
        isShow={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onConfirm={() => handleDelete(true)}
        title={CONFIRM_MESSAGE.DELETE_CATALOG}
      />
      <TableRow isDisabled={!catalogItem.enabled}>
        <TableCell>
          <ToggleBtn onClick={() => toggleEnabledCatalog(catalogItem.id)}>
            {catalogItem.enabled ? <ShowEyeIcon /> : <HideEyeIcon />}
          </ToggleBtn>
        </TableCell>
        <TableCell onClick={() => onEdit(catalogItem.id)}>
          {catalogItem.categoryName}
        </TableCell>
        {catalogItem.menuHours?.length > 0 ? (
          <TableCell onClick={() => onEdit(catalogItem.id)}>
            <TimeStyle>
              <DisplayText label={catalogItem.menuHours[0].started_at} />
              <span>-</span>
              <DisplayText label={catalogItem.menuHours[0].closed_at} />
            </TimeStyle>
            <DisplayText
              fontWeight="BOLD"
              label={catalogItem.menuHours[0].weekday.join(', ')}
            />
            {catalogItem.menuHours.length > 1 && (
              <MoreTimelineStyle
                label={`${
                  catalogItem.menuHours.length - 1
                } disponibilité(s) en plus`}
                fontWeight="BOLD"
              />
            )}
          </TableCell>
        ) : (
          <TableCell
            fontWeight="LIGHT"
            fontSize="S"
            onClick={() => onEdit(catalogItem.id)}>
            Aucune disponibilité
          </TableCell>
        )}
        <TableCell onClick={() => onEdit(catalogItem.id)}>
          {`${catalogItem.numberOfCategories} Catégorie(s)`}
        </TableCell>
        <TableCell onClick={() => onEdit(catalogItem.id)}>
          {`${catalogItem.numberOfProducts} Produit(s)`}
        </TableCell>
        <TableCell>
          <OptionTooltip
            component={
              <>
                <OptionTooltipItem
                  label="Modifier"
                  onClick={() => onEdit(catalogItem.id)}
                />
                <OptionTooltipItem
                  label="Supprimer"
                  className="list-option-item del"
                  onClick={() => openModalDelete(catalogItem.id)}
                />
              </>
            }
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default CatalogItemOfList;
