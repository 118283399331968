import React from 'react';

function CalendarIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.4rem"
      height="2.4rem"
      viewBox="0 0 32 32"
      {...props}>
      <g>
        <g fill="#929292" id="icon-calendar">
          <path
            d="M7.167 13.333h17.667c0.276 0.001 0.499 0.224 0.5 0.5v10.833c0 1.105-0.895 2-2 2v0h-14.667c-1.105 0-2-0.895-2-2v0-10.833c0.001-0.276 0.224-0.499 0.5-0.5h0zM25.333 11.5v-1.5c0-1.105-0.895-2-2-2v0h-2v-2.167c-0.001-0.276-0.224-0.499-0.5-0.5h-1.667c-0.276 0.001-0.499 0.224-0.5 0.5v2.167h-5.333v-2.167c-0.001-0.276-0.224-0.499-0.5-0.5h-1.667c-0.276 0.001-0.499 0.224-0.5 0.5v2.167h-2c-1.105 0-2 0.895-2 2v0 1.5c0.001 0.276 0.224 0.499 0.5 0.5h17.667c0.276-0.001 0.499-0.224 0.5-0.5v-0z"
            id="Calendar"
          />
        </g>
      </g>
    </svg>
  );
}

export default CalendarIcon;
