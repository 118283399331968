import styled from 'styled-components';

export const PriceVatWrapper = styled.div`
  display: flex;
  width: 100%;
  font-size: 1.6rem;
  h2 {
    margin-bottom: 0;
  }
  & > div {
    display: flex;
    align-items: center;
    width: calc(100% / 3);
  }
`;
export const PriceWrapper = styled.div`
  & > div:first-child {
    margin-right: 2rem;
  }
  & > div:last-child {
    min-width: 12.6rem;
    width: 12.6rem;
  }
`;

export const VatWrapper = styled.div`
  & > h2 {
    margin-right: 2rem;
  }
  box-sizing: border-box;
  div.selectSm__control {
    width: 100%;
    height: 4rem;
  }
`;
