import React, { useState, useEffect } from 'react';
import { endOfWeek, format, startOfWeek } from 'date-fns';
import { useQuery, useMutation } from 'react-query';
import TablePeriod from 'components/FinancialReportLayout/TablePeriod';
import Button from 'components/ui-kits/Button';
import { CalendarIcon, DownloadIcon } from 'components/ui-kits/Icon';
import { Grid, GridItem } from 'components/ui-kits/Grid';
import {
  BlockWrapper,
  CustomPeriodStyle,
  DateWrapper
} from './ByCustomPeriod.style';
import CustomDatePicker from 'components/ui-kits/CustomDatePicker';
import DropdownFinancial from 'components/FinancialReportLayout/DropdownFinancial';
import Loader from 'components/Loader';
import { downLoadFileNameFinancialReport, downLoadPdfFile } from 'utils';
import {
  financialDownloadPDFV2,
  getFinancialReportV2
} from 'services/financialReportService';

interface ByCustomPeriod {
  mode: 'month' | 'period';
  setDisplayMode(mode: 'month' | 'period'): void;
}

const ByCustomPeriod = ({ mode, setDisplayMode }: ByCustomPeriod) => {
  const [startDate, setStartDate] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [endDate, setEndDate] = useState(
    endOfWeek(new Date(), { weekStartsOn: 1 })
  );

  const { data, refetch, isLoading } = useQuery('getByPeriod', ({ signal }) =>
    getFinancialReportV2({
      filter: 'by_month_period',
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      signal
    })
  );

  useEffect(() => {
    refetch();
  }, [startDate, endDate, refetch]);

  const { isLoading: isLoadingDownload, mutate } = useMutation(
    financialDownloadPDFV2,
    {
      onSuccess: (blob: any, data: any) => {
        const startDate = format(new Date(data?.startDate), 'dd_MM_yyyy');
        const endDate = format(new Date(data?.endDate), 'dd_MM_yyyy');
        downLoadPdfFile(
          blob,
          downLoadFileNameFinancialReport(startDate, endDate)
        );
      }
    }
  );

  const handleDownloadPDF = () => {
    const start = formatDate(startDate);
    const end = formatDate(endDate);
    mutate({ startDate: start, endDate: end });
  };

  function formatDate(date: Date) {
    return format(new Date(date), 'yyyy-MM-dd');
  }

  return (
    <>
      {isLoading && <Loader />}
      <CustomPeriodStyle>
        <Grid col={11} start="large" marginRight={true}>
          <GridItem colStart={2} colEnd={8} nameStart="large" nameEnd="gutter">
            <BlockWrapper>
              <DropdownFinancial mode={mode} setDisplayMode={setDisplayMode} />
              <DateWrapper>
                <CustomDatePicker
                  selected={startDate}
                  onChange={(dates: any) => {
                    const [start, end] = dates;
                    setStartDate(start);
                    setEndDate(end);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  variant="round"
                  icon={<CalendarIcon />}
                  selectsRange
                />
              </DateWrapper>
            </BlockWrapper>
          </GridItem>
          <GridItem colStart={9} colEnd={10} nameStart="large" nameEnd="gutter">
            <Button
              className="download"
              icon={<DownloadIcon />}
              isUpperCase={false}
              isFluid={false}
              onClick={handleDownloadPDF}>
              Télécharger (PDF)
            </Button>
          </GridItem>
        </Grid>
        {data && (
          <TablePeriod
            dataPeriod={data}
            startMonth={format(new Date(startDate), 'LLL')}
            endMonth={format(new Date(endDate), 'LLL')}
          />
        )}
        {isLoadingDownload && <Loader />}
      </CustomPeriodStyle>
    </>
  );
};

export default ByCustomPeriod;
