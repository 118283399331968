import React from 'react';
import { Grid, GridItem } from 'components/ui-kits/Grid';

interface ListLayout {
  children: React.ReactNode;
}

const ListLayout = ({ children }: ListLayout) => {
  return (
    <Grid col={11} start="large" marginRight={true}>
      <GridItem colStart={2} colEnd={10} nameStart="large" nameEnd="gutter">
        {children}
      </GridItem>
    </Grid>
  );
};

export default ListLayout;
