import React from 'react';

function DeliveryIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2rem"
      height="1.626rem"
      viewBox="0 0 20 16.26"
      {...props}>
      <g id="delivery" transform="translate(0 -48.319)">
        <path
          id="Path_306"
          data-name="Path 306"
          d="M1.4,54.107H7.58V48.319H0V55.9H1.4Z"
          fill="#878c97"
        />
        <path
          id="Path_307"
          data-name="Path 307"
          d="M71.558,226.057a1.195,1.195,0,1,0,0-2.389H65.173v2.389Z"
          transform="translate(-62.578 -168.629)"
          fill="#878c97"
        />
        <path
          id="Path_308"
          data-name="Path 308"
          d="M115.345,403.677a2.389,2.389,0,0,0,4.779,0Z"
          transform="translate(-110.945 -341.75)"
          fill="#878c97"
        />
        <path
          id="Path_309"
          data-name="Path 309"
          d="M84.9,145.229c.111,0,.22.005.328.016l-.826-4.33H85.55v-1.125H84.192l-.215-1.125H79.5v1.125h1.764l1.086,6.565h-4.19l-.665-1.478a2.3,2.3,0,0,1-1.227.352H70.732a3.861,3.861,0,0,0-.387,1.688v.563H81.666A3.436,3.436,0,0,1,84.9,145.229Z"
          transform="translate(-67.544 -86.747)"
          fill="#878c97"
        />
        <circle
          id="Ellipse_325"
          data-name="Ellipse 325"
          cx="2.389"
          cy="2.389"
          r="2.389"
          transform="translate(14.672 63.996) rotate(-82.996)"
          fill="#878c97"
        />
      </g>
    </svg>
  );
}

export default DeliveryIcon;
