import React from 'react';
import { Link } from 'react-router-dom';

interface IOptionTooltipItem {
  onClick?(): void;
  label: string;
  className?: string;
  to?: string;
}

interface IWrapper extends Omit<IOptionTooltipItem, 'label'> {
  children: JSX.Element | string;
}

function OptionTooltipItem({
  label,
  className = 'list-option-item',
  ...props
}: IOptionTooltipItem) {
  return (
    <Wrapper className={className} {...props}>
      {label}
    </Wrapper>
  );
}

function Wrapper({ to, children, ...props }: IWrapper) {
  if (to) {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    );
  }
  return <div {...props}>{children}</div>;
}

export default OptionTooltipItem;
