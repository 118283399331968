import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';

export const WrapperSearch = styled.div`
  position: relative;
  width: 100%;

  .search-icon {
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
  }
  .label-price {
    &__clear-indicator {
      display: none;
    }
    &__menu {
      position: relative;
      box-shadow: none;
      border: 1px solid ${COLORS.GREY_4};
      margin-bottom: 0;
      border-radius: 0.4rem 0.4rem 0 0;
      border-bottom: none;
      &-list {
        max-height: 21rem;
      }
    }
    &__option {
      border-bottom: 1px solid ${COLORS.GREY_4};
      &--is-focused {
        background-color: ${COLORS.TRANSPARENT};
      }

      &:hover {
        background-color: ${COLORS.GREY_5};
      }
      &:last-child {
        border-bottom: none;
      }
    }
    &__multi-value {
      display: none;
    }

    &__placeholder {
      color: ${COLORS.GREY_7};
      font-size: ${FONT_SIZE.L};
    }
  }
  .default {
    &__menu {
      border: 1px solid ${COLORS.GREY_4};
      &-list {
        max-height: 22rem;
      }
    }
    &__option {
      font-size: ${FONT_SIZE.M};
      font-weight: ${FONT_WEIGHT.REGULAR};
      padding: 1.8rem 2.1rem;
      border-bottom: 1px solid ${COLORS.GREY_4};
      &--is-focused {
        background-color: ${COLORS.TRANSPARENT};
      }

      &:hover {
        background-color: ${COLORS.GREY_5};
      }
    }
    &__placeholder {
      color: ${COLORS.GREY_7};
      font-size: ${FONT_SIZE.L};
    }
    &__input {
      font-size: 1.8rem;
    }
    &__indicators {
      width: 5rem;
    }
  }
  .group-option {
    &__menu {
      position: relative;
      border: 1px solid ${COLORS.GREY_4};
      &-list {
        max-height: 22rem;
      }
    }
    &__option {
      font-size: ${FONT_SIZE.M};
      font-weight: ${FONT_WEIGHT.REGULAR};
      padding: 1.8rem 2.1rem;
      border-bottom: 1px solid ${COLORS.GREY_4};
      &--is-focused {
        background-color: ${COLORS.TRANSPARENT};
      }

      &:hover {
        background-color: ${COLORS.GREY_5};
      }
    }
    &__placeholder {
      color: ${COLORS.GREY_7};
      font-size: ${FONT_SIZE.L};
    }
    &__input {
      font-size: 1.8rem;
    }
    &__indicators {
      width: 5rem;
    }
  }
`;
