import React from 'react';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import GroupOptionsForm from 'components/GroupOptionsLayout/GroupOptionsForm';
import { GroupOptionFormsFields, IGroupOptions } from 'types/GroupOptions';
import Loader from 'components/Loader';
import { handleSuccess, handleError } from 'utils/index';
import { CREATE_MESSAGE } from 'const/Message';
import { createGroupOptionV2 } from 'services/groupOptionService';

const GroupOptionsCreate = () => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const {
    mutate,
    isLoading
  }: UseMutationResult<IGroupOptions, Error, GroupOptionFormsFields, unknown> =
    useMutation<IGroupOptions, Error, GroupOptionFormsFields, unknown>(
      'createGroupOptions',
      (data: GroupOptionFormsFields) => createGroupOptionV2(data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries('getListGroupOptions');
          handleSuccess(CREATE_MESSAGE.GROUP_OPTION_SUCCESS);
          history.goBack();
        },
        onError: (error: any) => {
          handleError(error.response.status, CREATE_MESSAGE.GROUP_OPTION_ERROR);
        }
      }
    );
  return (
    <>
      {isLoading ? <Loader /> : null}
      <GroupOptionsForm mutate={mutate} />
    </>
  );
};

export default GroupOptionsCreate;
