import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDateMonthYear } from 'utils';
import { DatePickerStyled } from './CustomDatePicker.style';
import fr from 'date-fns/locale/fr';

interface Custom {
  selected: any;
  onChange: (date: any) => void;
  dateFormat?: string;
  variant?: 'default' | 'round';
  id: string;
  icon?: React.ReactNode;
}

const CustomDatePicker = ({
  selected,
  onChange,
  dateFormat = formatDateMonthYear,
  variant = 'default',
  icon,
  id,
  ...props
}: Custom | any) => {
  return (
    <DatePickerStyled className="custom-datepicker" variant={variant}>
      <DatePicker
        id={id}
        selected={selected}
        onChange={(date: any) => onChange(date)}
        dateFormat={dateFormat}
        locale={fr}
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)?.toUpperCase()}
        {...props}
      />
      <label htmlFor={id} className="icon">
        {icon}
      </label>
    </DatePickerStyled>
  );
};

export default CustomDatePicker;
