import React, { useState } from 'react';
import Select, { components } from 'react-select';
import SearchIcon from 'components/ui-kits/Icon/SearchIcon';
import { WrapperSearch } from './Search.style';
import useIntersectionLoadmore from 'hooks/useIntersectionLoadmore';
import { FetchNextPageOptions, UseInfiniteQueryResult } from 'react-query';

export type OptionType = { [key: string]: any };
export type OptionsType = Array<OptionType>;

interface IProps {
  options?: OptionsType;
  placeholder?: string;
  change: (data: any) => void;
  isMulti?: boolean;
  formatOptionLabel?(option: OptionType): JSX.Element;
  value?: OptionType[] | OptionType;
  classNameWrapper?: string;
  ComponentOnOpen?: JSX.Element;
  styles?: any;
  variant?: 'default' | 'labelNPrice' | 'groupOption';
  isLazyLoading?: boolean;
  fetchNextPage?: (
    options?: FetchNextPageOptions
  ) => Promise<UseInfiniteQueryResult>;
  hasNextPage?: boolean;
  onInputChange?: (data: string) => void;
}

const classNameSelect = {
  default: {
    className: 'default-container',
    classNamePrefix: 'default'
  },
  labelNPrice: {
    className: 'label-price-container',
    classNamePrefix: 'label-price'
  },
  groupOption: {
    className: 'group-option-container',
    classNamePrefix: 'group-option'
  }
};

const Search = ({
  options,
  placeholder = 'Search...',
  change,
  isMulti = false,
  formatOptionLabel,
  classNameWrapper,
  variant = 'default',
  ComponentOnOpen,
  value,
  styles,
  isLazyLoading = false,
  fetchNextPage,
  hasNextPage,
  onInputChange
}: IProps) => {
  // const refSelect = useRef(null);
  const [menuIsOpen, setMenuOpen] = useState(false);
  const onChangeSearch = (result: any) => {
    change(result);
  };
  function handleBlur() {
    setMenuOpen(false);
  }
  function handleFocus() {
    setMenuOpen(true);
  }

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">Aucune option</span>
      </components.NoOptionsMessage>
    );
  };

  const CustomOption = (props: any) => {
    if (isLazyLoading) {
      return (
        <components.Option
          {...props}
          innerRef={callbackRef}></components.Option>
      );
    }
    return <components.Option {...props}></components.Option>;
  };

  const { callbackRef } = useIntersectionLoadmore({
    itemsCount: options?.length || 0,
    fetchNextPage,
    hasNextPage,
    isMenuOpen: menuIsOpen
  });

  return (
    <>
      <WrapperSearch className={classNameWrapper}>
        <Select
          components={{
            DropdownIndicator: () => <SearchIcon className="search-icon" />,
            IndicatorSeparator: () => null,
            NoOptionsMessage,
            Option: CustomOption
          }}
          options={options}
          isSearchable={true}
          onChange={onChangeSearch}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          styles={styles}
          placeholder={placeholder}
          clearIndicator
          isMulti={isMulti}
          className={classNameSelect[variant].className}
          classNamePrefix={classNameSelect[variant].classNamePrefix}
          formatOptionLabel={formatOptionLabel}
          menuIsOpen={menuIsOpen}
          onInputChange={onInputChange}
        />
        {menuIsOpen && ComponentOnOpen}
      </WrapperSearch>
    </>
  );
};
export default Search;
