import styled from 'styled-components';
import { COLORS, MEDIA_BREAKPOINT } from 'styles/variables';

export const ModalHeader = styled.div`
  padding: 2rem 2.8rem 2rem 4.6rem;
  border-bottom: 1px solid ${COLORS.GREY_4};

  @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
    padding: 1.5rem 2.8rem 1.5rem 4.6rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      height: 4rem;
      padding-top: 0;
      padding-bottom: 0;
    }

    @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
      font-size: 1.8rem;
    }
  }
`;

export const ModalContent = styled.div`
  padding: 0 4.8rem;

  .label {
    text-align: center;
    margin: 2.8rem 0;
    color: ${COLORS.BLACK_3};
    font-size: 1.6rem;
    letter-spacing: 0.32px;

    @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
      margin: 1.5rem 0;
    }
  }

  .container-image {
    width: 100%;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.8rem 0;
    margin-right: -3.4rem;

    @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
      padding: 2.8rem 0 1.5rem 0;
    }

    & > * {
      margin-right: 1.5rem;
    }
  }

  .reactEasyCrop_Container {
    position: relative !important;
    height: 33rem;
    width: 100%;
  }

  .slider {
    max-width: 25.1rem;
    width: 100%;
    height: 2rem;
  }

  .rc-slider-rail {
    height: 1rem;
  }

  .rc-slider-track {
    height: 1rem;
    background-color: ${COLORS.GREY_1};
  }

  .rc-slider-handle {
    top: 50%;
    transform: translate(-50%, -50%) !important;
    margin-top: 0;
    width: 0.9rem;
    height: 2.5rem;
    background-color: ${COLORS.RED};
    border-radius: 0.5rem;
    border: none;
  }

  .reactEasyCrop_CropArea {
    color: rgba(255, 255, 255, 0.5);
  }

  .custom-button {
    background-color: ${COLORS.WHITE};
    color: ${COLORS.BLACK_3};
    border: 1px solid ${COLORS.GREY_4};
    height: 4rem;
  }
`;

export const DropdownStyle = styled.div`
  font-size: 1.4rem;
  .default {
    min-width: 14rem;

    &__indicator {
      padding: 0 0.8rem;
    }

    &__single-value {
      color: ${COLORS.GREY_9};
    }
  }

  .default,
  .default__control {
    height: 4rem;
    min-height: 4rem;
  }
`;
