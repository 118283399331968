import React from 'react';
import styled, { css } from 'styled-components';
import {
  COLORS,
  FONT_WEIGHT,
  FONT_SIZE,
  MEDIA_BREAKPOINT,
  GRID_COLUMN_WIDTH,
  LETTER_SPACING
} from 'styles/variables';

const bgColor = {
  primary: COLORS.RED,
  secondary: COLORS.GREY_7,
  default: COLORS.GREY_2,
  cancel: COLORS.GREY_5,
  link: COLORS.TRANSPARENT,
  linkPrimary: COLORS.TRANSPARENT,
  text: COLORS.TRANSPARENT,
  redActive: COLORS.RED_5
};

const variantColor = {
  primary: COLORS.WHITE,
  secondary: COLORS.BLACK_2,
  default: COLORS.WHITE_2,
  cancel: COLORS.BLACK_2,
  link: COLORS.BLUE_1,
  linkPrimary: COLORS.RED,
  text: COLORS.BLUE_1,
  redActive: COLORS.WHITE
};

const sizeFontSize = {
  s: FONT_SIZE.S,
  m: FONT_SIZE.M,
  l: FONT_SIZE.L
};
export interface IButton {
  variant?: keyof typeof bgColor;
  isDisabled?: boolean;
  onClick?(params?: any): void;
  children?: React.ReactNode;
  isFluid?: boolean;
  isUpperCase?: boolean;
  sizeType?: keyof typeof sizeFontSize;
  label?: string;
  fontWeight?: keyof typeof FONT_WEIGHT;
  icon?: React.ReactElement;
  type?: 'submit' | 'button';
  className?: string;
  id?: string;
  borderRadius?: string;
  letterSpacing?: keyof typeof LETTER_SPACING;
  [key: string]: any;
}

const ButtonStyle = styled.button<
  Required<
    Pick<
      IButton,
      | 'variant'
      | 'isFluid'
      | 'isUpperCase'
      | 'sizeType'
      | 'fontWeight'
      | 'borderRadius'
      | 'letterSpacing'
    >
  > & { icon?: boolean }
>`
  ${({
    variant,
    isFluid,
    isUpperCase,
    sizeType,
    fontWeight,
    borderRadius,
    letterSpacing
  }) => css`
    color: ${variantColor[variant]};
    font-size: ${sizeFontSize[sizeType]};
    width: ${isFluid ? '100%' : 'auto'};
    min-width: ${isFluid ? 'initial' : '12.6rem'};
    letter-spacing: ${LETTER_SPACING[letterSpacing]};
    background-color: ${bgColor[variant]};
    text-transform: ${isUpperCase ? 'uppercase' : 'initial'};
    font-weight: ${FONT_WEIGHT[fontWeight]};
    border-radius: ${borderRadius};

    @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
      min-width: ${isFluid ? 'initial' : `${GRID_COLUMN_WIDTH.DESKTOP_S}`};
    }

    @media ${MEDIA_BREAKPOINT.DESKTOP_M} {
      min-width: ${isFluid ? 'initial' : `${GRID_COLUMN_WIDTH.DESKTOP_M}`};
    }

    @media ${MEDIA_BREAKPOINT.DESKTOP_L} {
      min-width: ${isFluid ? 'initial' : `${GRID_COLUMN_WIDTH.DESKTOP_L}`};
    }
  `}
  ${({ icon }) =>
    icon &&
    css`
      display: inline-flex;
      justify-content: center;
      align-items: center;
      & > svg {
        margin-right: 1rem;
      }
    `}
  padding: 1.2rem 1.7rem;
  &:hover {
    transition: all 0.4s;
    text-decoration: ${(props) =>
      props.variant === 'text' ? 'underline' : 'none'};

    background-color: ${({ variant }) =>
      variant === 'cancel' && bgColor['secondary']};

    background-color: ${({ variant }) =>
      variant === 'primary' && bgColor['redActive']};
  }
  transition: all 0.4s;
  &:disabled {
    background-color: ${COLORS.RED_4};
    cursor: default;
  }
`;

const Button = ({
  variant = 'default',
  isDisabled = false,
  onClick,
  children,
  isFluid = true,
  sizeType = 's',
  isUpperCase = true,
  label,
  fontWeight = 'REGULAR',
  icon,
  type = 'button',
  id,
  className,
  borderRadius = '0.7rem',
  letterSpacing = 'XS',
  ...props
}: IButton) => {
  return (
    <ButtonStyle
      type={type}
      isFluid={isFluid}
      variant={variant}
      disabled={isDisabled}
      sizeType={sizeType}
      id={id}
      isUpperCase={isUpperCase}
      fontWeight={fontWeight}
      onClick={onClick}
      icon={icon ? true : false}
      borderRadius={borderRadius}
      className={className}
      letterSpacing={letterSpacing}
      {...props}>
      {icon}
      {label}
      {children}
    </ButtonStyle>
  );
};

export default Button;
