import React from 'react';

function PhoneIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2rem"
      height="1.2rem"
      viewBox="0 0 12 12"
      {...props}>
      <path
        id="Phone"
        d="M11.658,8.48,9.033,7.355a.563.563,0,0,0-.656.162L7.214,8.937A8.687,8.687,0,0,1,3.061,4.784l1.42-1.163a.561.561,0,0,0,.162-.656L3.518.34A.566.566,0,0,0,2.873.015L.436.577A.563.563,0,0,0,0,1.125,10.874,10.874,0,0,0,10.875,12a.563.563,0,0,0,.548-.436l.563-2.438A.569.569,0,0,0,11.658,8.48Z"
        fill="#464a51"
      />
    </svg>
  );
}

export default PhoneIcon;
