import React, { FunctionComponent } from 'react';
import * as Components from './index';

export enum IconNames {
  ArrowLeftIcon = 'ArrowLeftIcon',
  CaretDown = 'CaretDown',
  CheckIcon = 'CheckIcon',
  ChevronDown = 'ChevronDown',
  ChevronTopIcon = 'ChevronTopIcon',
  CreditCardIcon = 'CreditCardIcon',
  Cross = 'Cross',
  DeliveryIcon = 'DeliveryIcon',
  DotMenuIcon = 'DotMenuIcon',
  DrawPolygonIcon = 'DrawPolygonIcon',
  EllipsisVertical = 'EllipsisVertical',
  GripIcon = 'GripIcon',
  HandPaper = 'HandPaper',
  HomeIcon = 'HomeIcon',
  InfoIcon = 'InfoIcon',
  MetroExitIcon = 'MetroExitIcon',
  PlusIcon = 'PlusIcon',
  QuestionIcon = 'QuestionIcon',
  SearchIcon = 'SearchIcon',
  SpoonForkIcon = 'SpoonForkIcon',
  StopWatchIcon = 'StopWatchIcon',
  TrashBin = 'TrashBin',
  Close = 'CloseIcon',
  ShowEye = 'ShowEyeIcon',
  hideEye = 'HideEyeIcon',
  EditPen = 'EditPenIcon',
  MinusStrokeIcon = 'MinusStrokeIcon',
  PlusStrokeIcon = 'PlusStrokeIcon',
  CropIcon = 'CropIcon',
  CalendarIcon = 'CalendarIcon',
  DownloadIcon = 'DownloadIcon',
  Cart = 'Cart',
  Remove = 'RemoveCart'
}

interface IconProps {
  name: IconNames;
  [key: string]: any;
}

export default function Icon({ name, ...props }: IconProps) {
  const Component: FunctionComponent<any> = Components[name];

  return <Component {...props} />;
}
