import DisplayText from 'components/ui-kits/DisplayText';
import { CheckboxSwitch } from 'components/ui-kits/Form/Checkbox';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';
import { ZoneInfo } from 'types/Delivery';
import {
  Control,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useWatch
} from 'react-hook-form';
import NumberInput from 'components/ui-kits/Form/NumberInput';
import Button from 'components/ui-kits/Button';
import Icon, { IconNames } from 'components/ui-kits/Icon/Icon';
import { NameForm } from 'types/general';

interface IZoneCard {
  zoneItem: ZoneInfo;
  onClickZone?(): void;
  isEdit: boolean;
  polygon?: google.maps.Polygon;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  handleClickZoneOnMap(): void;
  onPollute(): void;
  onSaveZone(): void;
  onDeleteZone(): void;
  mapRef: any;
  onCancel(): void;
  getValues?: UseFormGetValues<FieldValues>;
}

const Zone = styled.div<{ isEdit: boolean; colorActive: string }>`
  border-radius: 0.7rem;
  border: ${(props) =>
    props.isEdit
      ? `2px solid ${props.colorActive}`
      : `1px solid ${COLORS.GREY_4}`};
  padding: 1.6rem 1.4rem;
  flex: 1;
  margin: 1rem;
  .zone-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .zone-name {
    margin-right: 2rem;
  }
  .zone-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    flex-wrap: wrap;
    &.show {
      display: flex;
    }
    &.hide {
      display: none;
    }
  }
  .zone-footer {
    display: flex;
    align-items: center;
    margin-top: 2rem;
  }
  .zone-input {
    min-width: 12rem;
    width: 12rem;
  }
  .save-btn {
    margin-right: 1.5rem;
  }
`;

function ZoneCard({
  zoneItem,
  onClickZone,
  isEdit,
  polygon,
  register,
  control,
  setValue,
  handleClickZoneOnMap,
  onSaveZone,
  onDeleteZone,
  onCancel,
  mapRef,
  onPollute,
  getValues
}: IZoneCard) {
  const [triggerEnableOnComplete, setTriggerEnable] = useState(false);
  const isActive = zoneItem.coords.length > 0;
  const isEnable = useWatch<any>({
    control,
    name: `${zoneItem.name}.enabled`
  });

  useEffect(() => {
    if (!polygon) {
      return;
    }
    if (isEdit) {
      polygon.setOptions({
        editable: true,
        draggable: true
      });
    } else {
      polygon.setOptions({
        editable: false,
        draggable: false
      });
    }
    const insertListenner = google.maps.event.addListener(
      polygon.getPath(),
      'insert_at',
      function () {
        onPollute();
      }
    );
    const dragListener = google.maps.event.addListener(
      polygon.getPath(),
      'set_at',
      function () {
        onPollute();
      }
    );
    const clickListener = google.maps.event.addListener(
      polygon,
      'click',
      function () {
        handleClickZoneOnMap();
      }
    );
    return () => {
      insertListenner.remove();
      dragListener.remove();
      clickListener.remove();
    };
  }, [isEdit, polygon, onPollute, handleClickZoneOnMap]);

  useEffect(() => {
    // To automatically trigger enable when new zone has been drawn
    if (!isEdit) {
      return;
    }
    if (!polygon) {
      setTriggerEnable(true);
      return;
    }
    if (triggerEnableOnComplete) {
      setValue(`${zoneItem.name}.enabled` as NameForm, true);
      setTriggerEnable(false);
      polygon.setMap(mapRef as any);
    }
  }, [
    isEdit,
    polygon,
    setValue,
    mapRef,
    zoneItem.name,
    triggerEnableOnComplete
  ]);

  useEffect(() => {
    if (!polygon || !mapRef) {
      return;
    }
    if (isEnable) {
      polygon.setMap(mapRef as any);
    } else {
      polygon.setMap(null);
    }
  }, [isEnable, mapRef, polygon]);

  return (
    <Zone isEdit={isEdit} colorActive={zoneItem.color} onClick={onClickZone}>
      <div className="zone-heading">
        <CheckboxSwitch
          name={`${zoneItem.name}.enabled`}
          id={`${zoneItem.name}.enabled`}
          disabled={!isEdit}
          isChildLeft={true}
          switchSize="small"
          register={register}>
          <DisplayText
            className="zone-name"
            label={zoneItem.name.toUpperCase()}
            fontSize="L"
            fontWeight="BOLD"
            color="GREY_1"
            colorExternal={isActive ? zoneItem.color : ''}
          />
        </CheckboxSwitch>
      </div>
      <div className={`zone-body ${isEnable ? 'show' : 'hide'}`}>
        <DisplayText
          className="zone-label"
          fontSize="M"
          fontWeight="BOLD"
          label="Frais de livraison"
        />
        <NumberInput
          className="zone-input"
          name={`${zoneItem.name}.deliFee`}
          id={`${zoneItem.name}.deliFee`}
          variant="unit"
          register={register}
          setValue={setValue}
          getValues={getValues}
          type="text"
        />
      </div>
      {isEdit && (
        <div className="zone-footer">
          <Button
            className="save-btn"
            label="Enregistrer"
            isFluid={true}
            sizeType="s"
            variant="primary"
            fontWeight="BOLD"
            type="button"
            onClick={onSaveZone}
          />
          {!!polygon ? (
            <Button
              className="delete-btn"
              isFluid={true}
              sizeType="s"
              fontWeight="BOLD"
              variant="cancel"
              label="Supprimer"
              onClick={onDeleteZone}
              icon={
                <Icon name={IconNames.TrashBin} width="14" height="16" />
              }></Button>
          ) : (
            <Button
              className="cancel-btn"
              isFluid={true}
              sizeType="s"
              fontWeight="BOLD"
              variant="cancel"
              label="ANNULER"
              onClick={onCancel}></Button>
          )}
        </div>
      )}
    </Zone>
  );
}

export default ZoneCard;
