import React, { useState } from 'react';
import OTP from '../OTP';
import ConfirmPassword from '../ConfirmPassword';
import { ResetPassword, Step } from 'types/ResetPassword';
import { Title } from 'styles/login-reset-page';

interface ForgotPassword {
  title: string;
  statusStep?(status: Step): void;
  onBackSendEmail?(): void;
}

const ForgotPassword = ({
  statusStep,
  title,
  onBackSendEmail
}: ForgotPassword) => {
  const [isConfirmPassword, setIsConfirmPassword] = useState<boolean>(false);
  const [verifyOtp, setVerifyOtp] = useState<any>(null);
  const getResVerifyOtp = (data: Omit<ResetPassword, 'newPassword'>) => {
    setVerifyOtp(data);
    setIsConfirmPassword(true);
  };

  return (
    <>
      <Title>{title}</Title>
      {isConfirmPassword ? (
        verifyOtp && (
          <ConfirmPassword
            onBackSendEmail={onBackSendEmail}
            dataVerifyOtp={verifyOtp}
          />
        )
      ) : (
        <OTP
          setModeConfirmPassword={getResVerifyOtp}
          onBackLoginPw={() => statusStep?.('sendEmail')}
        />
      )}
    </>
  );
};

export default ForgotPassword;
