import styled, { keyframes } from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';

export const ListOrderDetailWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
`;

const leftToRight = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(0); }
`;

export const ListDetailBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${COLORS.BLACK};
  z-index: 980;
  opacity: 0.8;
`;

export const ListDetailLayout = styled.div`
  position: relative;
  width: 64rem;
  background-color: ${COLORS.WHITE};
  z-index: 990;
  padding: 5.4rem 2.4rem;
  animation-name: ${leftToRight};
  animation-duration: 0.8s;
  animation-iteration-count: 1;

  .headWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .orderNumber {
    font-size: ${FONT_SIZE.XL};
    font-weight: ${FONT_WEIGHT.BOLD};
  }

  .closeButton {
    cursor: pointer;

    svg {
      fill: ${COLORS.BLACK_2};
    }
  }
  table {
    width: 100%;
    font-size: ${FONT_SIZE.S};
    color: ${COLORS.GREY_10};

    thead {
      border-bottom: 1px solid ${COLORS.GREY_7};

      th {
        padding: 4.7rem 0 2.8rem;
        vertical-align: top;
      }
    }

    tbody {
      tr {
        td {
          .itemNameWrapper {
            &:last-of-type {
              padding-bottom: 2.1rem;
            }
          }
        }

        &:last-of-type {
          td {
            border-top: 1px solid ${COLORS.GREY_7};
            padding-top: 2.8rem;
          }
        }
      }
    }

    .timeOrder,
    .timeWrapper,
    .name {
      text-align: left;
      font-weight: ${FONT_WEIGHT.LIGHT};

      .day {
        font-weight: ${FONT_WEIGHT.BOLD};
      }
    }

    .qty,
    .qtyWrapper {
      text-align: center;
    }

    .priceWrapper {
      text-align: right;

      .price {
        font-weight: ${FONT_WEIGHT.BOLD};
      }
    }

    .headerTitle {
      padding: 2.8rem 0 1.4rem;
      font-weight: ${FONT_WEIGHT.LIGHT};
    }

    .totalLabel,
    .totalPrice {
      color: ${COLORS.BLACK_3};
      font-size: ${FONT_SIZE.S};
      text-align: right;
      padding: 0.7rem 0;
    }

    .totalLabel {
      padding-right: 6rem;
    }

    .netPayment {
      font-weight: ${FONT_WEIGHT.BOLD};
      padding-top: 2.1rem;
    }
  }
`;
