import React, { useEffect, useState } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Layout from 'components/Layout';
import PrepTimeEdit from './Edit';
import styled from 'styled-components';
import DisplayText from 'components/ui-kits/DisplayText';
import Button from 'components/ui-kits/Button';
import NumberInput from 'components/ui-kits/Form/NumberInput';
import { RootState } from 'app/store';
import { COLORS } from 'styles/variables';
import ListLayout from 'components/ListLayout';
import { getPrepTimeV2 } from 'services/prepTimeService';

const PrepTimeWrapper = styled.div`
  padding: 5.6rem 0;
`;

const DisplayTitle = styled(DisplayText)`
  margin-bottom: 5.6rem;
`;

const MinuteWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 30rem;
  width: 100%;

  button {
    min-width: auto;
    padding: 0;
  }

  input[type='number']:disabled {
    background-color: ${COLORS.WHITE};
  }
`;

const PrepTime = () => {
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const { merchantSlug } = useSelector((state: RootState) => state.user);

  const { data } = useQuery('getPrepTime', ({ signal }) =>
    getPrepTimeV2(merchantSlug, signal)
  );
  const { register, reset } = useForm<FieldValues>({
    defaultValues: {
      minute: data?.preparationTime || 0
    }
  });

  useEffect(() => {
    reset({
      minute: data?.preparationTime || 0
    });
  }, [data, reset]);

  return (
    <Layout>
      <ListLayout>
        <PrepTimeWrapper>
          <DisplayTitle
            label="Temps de préparation"
            fontSize="XXL"
            fontWeight="LIGHT"
          />
          {isOpenEdit ? (
            <PrepTimeEdit
              onClose={() => setIsOpenEdit(false)}
              minute={data?.preparationTime || 0}
              slug={merchantSlug}
            />
          ) : (
            <MinuteWrapper>
              <DisplayText label="Minutes" fontSize="M" fontWeight="BOLD" />
              <NumberInput
                name="minute"
                variant="default"
                disabled={true}
                register={register}
              />
              <Button
                variant="link"
                isUpperCase={false}
                sizeType="s"
                fontWeight="BOLD"
                isFluid={false}
                onClick={() => setIsOpenEdit(true)}>
                Modifier
              </Button>
            </MinuteWrapper>
          )}
        </PrepTimeWrapper>
      </ListLayout>
    </Layout>
  );
};

export default PrepTime;
