import React from 'react';
import Layout from 'components/Layout';
import { NavItem } from 'types/NavItem';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import Revenues from './Revenues';
import PaymentParams from './PaymentParams';
import Invoices from './Invoices';
import InvoicesMerchantDetail from './Invoices/InvoicesMerchant/InvoicesMerchantDetail/InvoicesMerchantDetail';
import FinancialReport from './FinancialReport';

const secondNavsData: NavItem[] = [
  { label: 'Revenus', path: '/revenues' },
  { label: 'Factures', path: '/invoices', exact: true },
  { label: 'Paramètres de paiement', path: '/payment-params' },
  { label: 'Méthode de paiement', path: '/payment-method' },
  { label: 'Journal comptable', path: '/financial-report' }
];

const Payment = () => {
  const { path } = useRouteMatch();

  return (
    <Layout secondNavs={secondNavsData}>
      <Switch>
        <Redirect from={`${path}/`} to={`${path}/revenues`} exact />
        <Route path={`${path}/revenues`}>
          <Revenues />
        </Route>
        <Route path={`${path}/invoices/detail/:id`} exact>
          <InvoicesMerchantDetail />
        </Route>
        <Route path={`${path}/invoices`}>
          <Invoices />
        </Route>
        <Route path={`${path}/payment-params`}>
          <PaymentParams />
        </Route>
        <Route path={`${path}/payment-method`}>
          <PaymentMethod />
        </Route>
        <Route path={`${path}/financial-report`}>
          <FinancialReport />
        </Route>
      </Switch>
    </Layout>
  );
};

export default Payment;
