import styled, { css } from 'styled-components';
import { COLORS } from 'styles/variables';

export const BlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLORS.GREY_4};
  padding: 1.4rem 2.8rem;
`;

export const BlockContent = styled.div`
  padding: 0 1.4rem 0 2.8rem;
`;

export const FieldWrapper = styled.div`
  margin: 3rem 10rem 2.7rem 0;
`;

export const TitleLarge = styled.span`
  font-size: 1.6rem;
  font-weight: bold;
  color: ${COLORS.BLACK_2};
  margin-right: 0.7rem;
`;

export const Description = styled.span`
  font-size: 1.2rem;
  color: ${COLORS.GREY_11};
`;

export const InputStyle = styled.div`
  position: relative;
  margin: 0.5rem 0;
  input {
    font-size: 1.4rem;
    font-weight: 400;
    color: ${COLORS.BLACK_4};
    height: 2rem;
    border-bottom: 1px solid ${COLORS.GREY_4};
  }
`;

export const InputPhoneStyle = styled(InputStyle)`
  input {
    padding: 0 2rem 0 6rem;
  }
`;

export const InputLocationStyled = styled(InputStyle)<{ hasError?: boolean }>`
  input {
    width: 100%;
    padding: 0 2rem;

    &:focus {
      outline: none;
    }

    ${({ hasError }) =>
      hasError &&
      css`
        border-bottom: 2px solid ${COLORS.RED};
      `}
  }
`;

export const IconLabel = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  display: flex;
  align-items: center;

  span {
    font-size: 1.4rem;
    font-weight: 400;
    color: ${COLORS.BLACK_4};
    margin-left: 1rem;
  }
`;
