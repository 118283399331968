import React, { useState } from 'react';
import DisplayText from 'components/ui-kits/DisplayText';
import Button from 'components/ui-kits/Button';
import {
  OptionTooltip,
  OptionTooltipItem
} from 'components/ui-kits/OptionTooltip';
import {
  CategoryItemWrapper,
  EmptyImage,
  ImageStyle
} from './CategoryItem.style';
import ProductModal from 'components/ProductModal';
import CategoryNameForm from 'components/CategoryLayout/CategoryNameForm';
import { Grid, GridItem } from 'components/ui-kits/Grid';
import {
  CategoryItemData,
  CategoryProductItem,
  ProductInCategory
} from 'types/Category';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ModalPrompt from 'components/ui-kits/ModalPrompt';
import { CONFIRM_MESSAGE, CREATE_MESSAGE, DELETE_MESSAGE } from 'const/Message';
import Loader from 'components/Loader';
import { handleSuccess, handleError, formatDollar } from 'utils/index';
import Image from 'components/ImageDisplay';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import Spinner from 'components/ui-kits/Spinner';
import {
  addRemoveProdInCategoryV2,
  deleteCategoryV2,
  getListCategoryProductsV2
} from 'services/categoryService';
import { Dot } from 'styles/common';
import { format } from 'date-fns';
import { SHORT_UNIT } from 'const/Const';

interface ICategoryItem {
  data: CategoryItemData;
  listProduct?: Array<ProductInCategory>;
  totalProducts: number;
}

const CategoryItem = ({ data: dataItem, totalProducts }: ICategoryItem) => {
  const [openProdModal, setOpenProdModal] = useState(false);
  const [isEditName, setIsEditName] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [listProduct, setListProduct] = useState<CategoryProductItem[]>([]);
  const queryClient = useQueryClient();
  const { merchantId } = useSelector((state: RootState) => state.user);
  const [queryKeyProduct, setQueryKeyProduct] = useState({
    'merchant.id': merchantId,
    'productTaxons.taxon.id': dataItem.id,
    isOption: false,
    page: 1,
    itemsPerPage: 8,
    isGetAll: false,
    totalProducts
  });
  const { isLoading: isLoadingProduct, isFetching: isFetchingProduct } =
    useQuery(
      ['getListCategoryProducts', dataItem.id, queryKeyProduct],
      ({ signal }) => getListCategoryProductsV2({ signal, ...queryKeyProduct }),
      {
        onSuccess: (data) => {
          if (data) {
            setListProduct(data['hydra:member']);
          }
        },
        keepPreviousData: true
      }
    );
  const { isLoading: loadingDeleteCategory, mutate: deleteCategoryItem } =
    useMutation(deleteCategoryV2, {
      onSuccess: () => {
        queryClient.invalidateQueries('getListCategories');
        queryClient.invalidateQueries('getListProducts');
        handleSuccess(DELETE_MESSAGE.CATEGORY_SUCCESS);
        setIsDelete(false);
      },
      onError: (err: any) => {
        handleError(err.response.status, DELETE_MESSAGE.CATEGORY_ERROR);
        setIsDelete(false);
      }
    });
  const { isLoading: loadingRemoveProduct, mutate: addRemoveProducts } =
    useMutation(addRemoveProdInCategoryV2);

  function editProduct(newProducts: string[], status: 'add' | 'remove') {
    if (dataItem) {
      addRemoveProducts(
        {
          data: { productIRIs: newProducts },
          code: dataItem.code
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries('getListCategories');
            queryClient.invalidateQueries('getListProducts');
            queryClient.invalidateQueries('getListCategory');
            queryClient.invalidateQueries([
              'getListCategoryProducts',
              dataItem.id
            ]);
            if (status === 'add') {
              handleSuccess(CREATE_MESSAGE.ADD_PRODUCT_INTO_CATEGORY_SUCCESS);
            } else {
              handleSuccess(
                DELETE_MESSAGE.REMOVE_PRODUCT_FROM_CATEGORY_SUCCESS
              );
            }
            setOpenProdModal(false);
          },
          onError: (err: any) => {
            if (status === 'add') {
              handleError(
                err.response.status,
                CREATE_MESSAGE.ADD_PRODUCT_INTO_CATEGORY_ERROR
              );
            } else {
              handleError(
                err.response.status,
                DELETE_MESSAGE.REMOVE_PRODUCT_FROM_CATEGORY_ERROR
              );
            }
            setIsDelete(false);
          }
        }
      );
    }
  }
  function handleDelete() {
    if (dataItem) {
      deleteCategoryItem(dataItem.code);
    }
  }
  function handleDeleteNameForm() {
    if (dataItem) {
      setIsDelete(true);
    } else {
      setIsEditName(false);
    }
  }

  function handleRemoveProduct(id: string) {
    const listCodeProduct = listProduct
      ?.map((item) => item['@id'])
      .filter((item) => item !== id);
    editProduct(listCodeProduct!, 'remove');
  }

  function renderProductItem() {
    return (
      <>
        {listProduct?.map((item, index: number) => (
          <div key={index} className="product-item">
            <Image
              src={item.latestImage?.path}
              className="product-img"
              id={item['@id']}
            />
            <div className="product-item__right">
              <div className="product-item__heading">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}>
                  <DisplayText
                    className="product-name"
                    label={item.name}
                    fontSize="M"
                    fontWeight="BOLD"
                    color="BLACK_2"
                  />
                  {item.unit &&
                    (item.quantityProductSoldByWeight ||
                      item.quantityProductSoldByWeight === 0) && (
                      <>
                        <Dot />
                        <DisplayText
                          className="product-name"
                          label={`${item.quantityProductSoldByWeight}${
                            SHORT_UNIT[item.unit]
                          }`}
                          fontSize="S"
                          fontWeight="MEDIUM"
                          color="BLACK_2"
                        />
                      </>
                    )}
                </div>
                <DisplayText
                  className="product-ref"
                  label={item?.reference}
                  fontSize="XS"
                  fontWeight="REGULAR"
                  color="BLACK_2"
                />
                <DisplayText
                  className="product-des"
                  label={item.description || ''}
                  fontSize="XS"
                  fontWeight="REGULAR"
                  color="BLACK_2"
                />
              </div>
              <DisplayText
                className="product-price"
                label={`${formatDollar(item.price)}€`}
                fontSize="S"
                fontWeight="REGULAR"
                color="BLACK_2"
              />
            </div>
            <div className="product-option-tooltip">
              <OptionTooltip
                component={
                  <>
                    <OptionTooltipItem
                      label="Modifier"
                      to={`/menu/products/edit/${item.code}`}
                    />
                    <OptionTooltipItem
                      label="Retirer"
                      className="list-option-item del"
                      onClick={() => handleRemoveProduct(item['@id'])}
                    />
                  </>
                }
              />
            </div>
          </div>
        ))}
        {isLoadingProduct && !queryKeyProduct.isGetAll && (
          <div className="product-loader">
            <Spinner />
          </div>
        )}
      </>
    );
  }

  return (
    <CategoryItemWrapper>
      {!isEditName && (
        <>
          <div className="header">
            <div className="image-wrapper">
              {dataItem?.latestImage?.path ? (
                <ImageStyle src={dataItem.latestImage.path} />
              ) : (
                <EmptyImage></EmptyImage>
              )}
            </div>
            <DisplayText
              className="title"
              label={dataItem?.name}
              fontSize="XXL"
              fontWeight="BOLD"
              color="BLACK_2"
            />
            <DisplayText
              className="title"
              label={`${totalProducts} produit(s)`}
              fontSize="S"
              color="GREY_1"
            />
            <div className="action">
              <DisplayText
                className="modified"
                label="Dernière modification"
                fontSize="S"
                fontWeight="BOLD"
                color="BLACK_2"
              />
              <DisplayText
                className="last-updated-time"
                label={format(new Date(dataItem?.updatedAt), 'dd/MM/yyyy')}
                fontSize="S"
                color="GREY_1"
              />
              <OptionTooltip
                component={
                  <>
                    <OptionTooltipItem
                      label="Modifier"
                      onClick={() => setIsEditName(true)}
                    />
                    <OptionTooltipItem
                      label="Supprimer"
                      onClick={() => setIsDelete(true)}
                      className="list-option-item del"
                    />
                  </>
                }
              />
            </div>
          </div>
        </>
      )}
      {isEditName && (
        <Grid col={10} start="large">
          <GridItem nameStart="large" nameEnd="gutter" colEnd={10}>
            <CategoryNameForm
              defaultName={dataItem?.name}
              defaultImage={dataItem?.latestImage?.path}
              code={dataItem?.code}
              onCancel={() => setIsEditName(false)}
              onDelete={() => handleDeleteNameForm()}
            />
          </GridItem>
        </Grid>
      )}

      <div className="product-list">{renderProductItem()}</div>
      {isFetchingProduct && queryKeyProduct.isGetAll && (
        <div className="product-list">
          <div className="product-loader">
            <Spinner />
          </div>
        </div>
      )}
      <Button
        className="btn-add"
        isFluid={false}
        variant="cancel"
        fontWeight="BOLD"
        onClick={() => setOpenProdModal(true)}>
        + AJOUTER UN PRODUIT
      </Button>
      {listProduct && listProduct.length < totalProducts && !isFetchingProduct && (
        <Button
          className="btn-add"
          isFluid={false}
          variant="link"
          fontWeight="BOLD"
          onClick={() => setQueryKeyProduct((u) => ({ ...u, isGetAll: true }))}>
          Afficher tout
        </Button>
      )}
      {openProdModal && (
        <ProductModal
          onClose={() => setOpenProdModal(false)}
          editProduct={editProduct}
          listProductActive={listProduct?.map((item) => item['@id'])}
        />
      )}
      <ModalPrompt
        isShow={isDelete}
        onClose={() => setIsDelete(false)}
        title={CONFIRM_MESSAGE.DELETE_CATEGORY}
        onConfirm={handleDelete}
      />
      {loadingDeleteCategory || loadingRemoveProduct ? <Loader /> : null}
    </CategoryItemWrapper>
  );
};

export default CategoryItem;
