import { createSlice } from '@reduxjs/toolkit';

type FormInput = {
  email: string;
};

const initialState: FormInput = {
  email: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateEmail(state, action) {
      return (state = action.payload);
    }
  }
});

const { actions, reducer } = authSlice;
export const { updateEmail } = actions;
export default reducer;
