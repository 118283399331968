import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarIcon } from 'components/ui-kits/Icon';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { CalendarStyle } from './DateRangePicker.style';
import { RootState } from 'app/store';
import { queryFilter } from 'features/filterInvoice/filterInvoiceSlice';
import { periodWeekly } from 'utils';
interface Iprops {
  onData?(date: []): void;
  haveSaveFilter?: boolean;
}

const DateRange = ({ onData, haveSaveFilter }: Iprops) => {
  const { filter, startDate, endDate } = useSelector(
    (state: RootState) => state.filterInvoice
  );

  const dispatch = useDispatch();
  const [date, setDate] = useState<any>(
    haveSaveFilter ? [new Date(startDate), new Date(endDate)] : periodWeekly
  );
  useEffect(() => {
    if (haveSaveFilter) {
      dispatch(
        queryFilter({
          filter: filter,
          startDate: new Date(date[0]).toISOString(),
          endDate: new Date(date[1]).toISOString()
        })
      );
    } else {
      onData?.(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <div>
      <CalendarStyle>
        <DateTimeRangePicker
          locale="fr"
          onChange={setDate}
          value={date}
          format="dd/MM/y"
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder="yyyy"
          calendarIcon={<CalendarIcon className="calendarStyle" />}
          clearIcon=""
          formatShortWeekday={(_locale: string, value) =>
            ['D', 'L', 'M', 'M', 'J', 'V', 'S'][value.getDay()]
          }
        />
      </CalendarStyle>
    </div>
  );
};

export default DateRange;
