import React from 'react';
import ListLayout from 'components/ListLayout';
import { InformationOperationCostWrapper } from './InformationOperationCost.style';
import CardInformationOperationCost from './CardInformationOperationCost';
import TableOperationCost from '../../InvoicesMerchantTable/TableOperationCost';
import { format } from 'date-fns';
import { formatDateMonthYear } from 'utils';

interface Iprops {
  onData: any;
}

const InformationOperationCost = ({ onData }: Iprops) => {
  return (
    <ListLayout>
      <InformationOperationCostWrapper>
        <CardInformationOperationCost
          invoiceNumber={onData?.number}
          invoiceDate={
            onData?.dateOfInvoice
              ? `${format(
                  new Date(onData?.dateOfInvoice),
                  formatDateMonthYear
                )}`
              : ''
          }
          mainName={onData?.merchantName}
          address={onData?.merchantBillingAddress}
          vatNumber={onData?.merchantTaxNumber}
          invoicePeriod={
            onData?.createdAt
              ? `${format(new Date(onData?.startAt), formatDateMonthYear)}` +
                ' - ' +
                `${format(new Date(onData?.endAt), formatDateMonthYear)}`
              : ''
          }
          completedOrders={onData?.completedOrdersNumber}
          typeOfInvoice={`Frais de service Achil`}
          description={'Facture pour les frais de service Achil du '}
          startAt={
            onData?.startAt
              ? `${format(new Date(onData?.startAt), formatDateMonthYear)}`
              : ''
          }
          endAt={
            onData?.endAt
              ? `${format(new Date(onData?.endAt), formatDateMonthYear)}`
              : ''
          }
        />
        <TableOperationCost onDataTable={onData} />
      </InformationOperationCostWrapper>
    </ListLayout>
  );
};

export default InformationOperationCost;
