import React from 'react';
import { useMutation } from 'react-query';
import ListLayout from 'components/ListLayout';
import {
  MerchantHeadTitle,
  MerchantHeadButton,
  ButtonText,
  MerchantHeadWrapper
} from './MerchantHead.style';
import { DownloadIcon } from 'components/ui-kits/Icon';
import Loader from 'components/Loader';
import { downLoadFileNameInvoices, downLoadPdfFile } from 'utils';
import { invoicesDownloadPDFV3 } from 'services/invoicesService';

interface IMerchantHead {
  onData: any;
  label?: string;
}

const MerchantHead = ({ onData, label }: IMerchantHead) => {
  const { isLoading, mutate } = useMutation(invoicesDownloadPDFV3, {
    onSuccess: (blob: any, data: any) => {
      downLoadPdfFile(
        blob,
        downLoadFileNameInvoices(data?.invoiceType, data?.id)
      );
    }
  });

  const handleDownloadPDF = (type: string, id: number) => {
    const invoiceType = getInvoiceType(type) || '';
    mutate({ invoiceType, id });
  };

  function getInvoiceType(type: string) {
    switch (type) {
      case 'CustomerInvoice':
        return `customer_invoice`;
      case 'MonthlyInvoice':
        return `merchant_subscription_invoice`;
      case 'WeeklyInvoice':
        return `merchant_operation_invoice`;
      default:
        break;
    }
  }

  return (
    <ListLayout>
      <MerchantHeadWrapper>
        <MerchantHeadTitle
          label={label}
          fontSize="XL"
          fontWeight="MEDIUM"
          color="BLACK_2"
        />
        <MerchantHeadButton
          onClick={() => handleDownloadPDF(onData['@type'], onData?.id)}>
          <DownloadIcon />
          <ButtonText
            label="Télécharger le PDF"
            fontSize="S"
            fontWeight="MEDIUM"
            color="BLACK_2"
          />
          {isLoading && <Loader />}
        </MerchantHeadButton>
      </MerchantHeadWrapper>
    </ListLayout>
  );
};

export default MerchantHead;
