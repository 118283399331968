import api from 'api/setupAxios';
import { PRODUCT_ATTR, PRODUCT_ATTR_BY_ID } from 'const/endpoint';

export const getProductAttrV2 = (signal?: AbortSignal) => {
  return api.call(signal).get(PRODUCT_ATTR);
};

export const getProductAttrByIdV2 = (id: string, signal?: AbortSignal) => {
  return api.call(signal).get(PRODUCT_ATTR_BY_ID.replace(':id', id));
};
