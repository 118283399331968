import { useCallback, useEffect, useRef } from 'react';
import { FetchNextPageOptions, UseInfiniteQueryResult } from 'react-query';

type intersectionObserverParams = {
  itemsCount: number;
  fetchNextPage?: (
    options?: FetchNextPageOptions
  ) => Promise<UseInfiniteQueryResult>;
  hasNextPage?: boolean;
  isMenuOpen?: boolean;
};

export default function useIntersectionLoadmore({
  itemsCount,
  fetchNextPage,
  hasNextPage,
  isMenuOpen
}: intersectionObserverParams) {
  const loadRef = useRef<Element | null>(null);
  const callbackRef = useCallback((ref) => {
    loadRef.current = ref;
  }, []);
  useEffect(() => {
    if (!loadRef?.current) return;
    const options = {
      threshold: 0.8
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          fetchNextPage?.();
          observer.disconnect();
        }
      });
    }, options);
    observer.observe(loadRef.current);
    return () => {
      observer.disconnect();
    };
  }, [itemsCount, fetchNextPage, hasNextPage, isMenuOpen]);

  return { callbackRef };
}
