import React from 'react';
import ListLayout from 'components/ListLayout';
import { InformationSubscriptionFeeWrapper } from './InformationSubscriptionFee.style';
import CardInformationSubscriptionFee from './CardInformationSubscriptionFee';
import TableSubscriptionFee from '../../InvoicesMerchantTable/TableSubscriptionFee';
import { format } from 'date-fns';
import { formatDateMonthYear, getDateInMonth } from 'utils';

interface Iprops {
  onData: any;
}

const InformationSubscriptionFee = ({ onData }: Iprops) => {
  const d = new Date(onData?.startAt);
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  const dateInMonth = getDateInMonth(year, month);
  const startPeriod = `01/${month < 10 ? '0' + month : month}/${year}`;
  const endPeriod = `${dateInMonth}/${
    month < 10 ? '0' + month : month
  }/${year}`;

  return (
    <ListLayout>
      <InformationSubscriptionFeeWrapper>
        {onData && (
          <>
            <CardInformationSubscriptionFee
              invoiceNumber={onData.number}
              invoiceDate={
                onData.createdAt
                  ? `${format(new Date(onData.createdAt), formatDateMonthYear)}`
                  : ''
              }
              mainName={onData?.merchantName}
              address={onData?.merchantBillingAddress}
              vatNumber={onData?.merchantTaxNumber}
              typeOfInvoice={`Abonnement Achil`}
              description={`Facture pour l'abonnement Achil du`}
              startAt={startPeriod}
              endAt={endPeriod}
              dateOfBankDebit={format(new Date(onData.debitDate), 'dd/MM/yyyy')}
              bankAccount={onData.bankAccount}
            />
            <TableSubscriptionFee onDataTable={onData} />
          </>
        )}
      </InformationSubscriptionFeeWrapper>
    </ListLayout>
  );
};

export default InformationSubscriptionFee;
