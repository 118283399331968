import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'components/ui-kits/Button';
import Label from 'components/ui-kits/Form/Label';
import TextInput from 'components/ui-kits/Form/TextInput';
import ButtonIcon from 'components/ui-kits/ButtonIcon';
import { IconNames } from 'components/ui-kits/Icon/Icon';
import {
  Actions,
  FieldWrapper,
  ForgotPasswordWrapper,
  MessageError,
  ShowPasswordIcon,
  ShowPasswordWrapper,
  Title
} from 'styles/login-reset-page';
import { REGEX_VALIDATE_EMAIL, REGEX_VALIDATE_PASSWORD } from 'const/Const';
import { EMAIL_INVALID_MESSAGE, PASSWORD_INVALID_MESSAGE } from 'const/Message';

interface ILoginForm {
  title: string;
  formSubmit: (data: FormInputs) => void;
  errorLogin?: string;
  setForgotPassword?(): void;
}

type FormInputs = {
  email: string;
  password: string;
};

const LoginForm = ({
  title,
  errorLogin,
  formSubmit,
  setForgotPassword
}: ILoginForm) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm();
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const onSubmit = (value: FormInputs) => {
    formSubmit(value);
  };

  const onTogglePassword = (status: boolean) => {
    setIsShowPassword(status);
  };

  return (
    <>
      <Title>{title}</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldWrapper>
          <Label forId="email" fontWeight="MEDIUM" textTransform="none">
            Entrez votre adresse email
          </Label>
          <TextInput
            id="email"
            name="email"
            register={register}
            placeholder="Email"
            validate={{
              required: true,
              pattern: {
                value: REGEX_VALIDATE_EMAIL,
                message: EMAIL_INVALID_MESSAGE
              }
            }}
            hasError={Boolean(errors['email'])}
          />
          {errors['email'] && (
            <MessageError>{errors['email'].message}</MessageError>
          )}
        </FieldWrapper>

        <FieldWrapper>
          <Label forId="password" fontWeight="MEDIUM" textTransform="none">
            Entrez votre mot de passe
          </Label>
          <ShowPasswordWrapper>
            <TextInput
              type={isShowPassword ? 'text' : 'password'}
              id="password"
              name="password"
              register={register}
              validate={{
                required: true,
                pattern: {
                  value: REGEX_VALIDATE_PASSWORD,
                  message: PASSWORD_INVALID_MESSAGE
                }
              }}
              placeholder="Mot de passe"
              hasError={Boolean(errors['password']) || Boolean(errorLogin)}
            />
            <ShowPasswordIcon>
              {!isShowPassword && (
                <ButtonIcon
                  onClick={() => onTogglePassword(true)}
                  icon={IconNames.ShowEye}
                  widthIcon={18}
                />
              )}
              {isShowPassword && (
                <ButtonIcon
                  onClick={() => onTogglePassword(false)}
                  icon={IconNames.hideEye}
                  widthIcon={18}
                />
              )}
            </ShowPasswordIcon>
          </ShowPasswordWrapper>
          {(errors['password'] && (
            <MessageError>{errors['password'].message}</MessageError>
          )) || <MessageError>{errorLogin}</MessageError>}
        </FieldWrapper>
        <Actions>
          <Button
            type="submit"
            isDisabled={!isDirty}
            variant="primary"
            isFluid={true}
            sizeType="l"
            fontWeight="BOLD">
            SUIVANT
          </Button>
          <ForgotPasswordWrapper>
            <Button
              label="Mot de passe oublié ?"
              variant="link"
              isFluid={false}
              isUpperCase={false}
              onClick={setForgotPassword}
              fontWeight="MEDIUM"
            />
          </ForgotPasswordWrapper>
        </Actions>
      </form>
    </>
  );
};

export default LoginForm;
