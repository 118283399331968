import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';

const InputStyle = styled.input`
  display: none;
  &:checked ~ label {
    background: ${COLORS.RED};
    color: ${COLORS.WHITE};
  }
`;

const LabelStyle = styled.label<{ width: string }>`
  position: relative;
  display: block;
  height: 4.8rem;
  border-radius: 3.3rem;
  margin-bottom: 1rem;
  width: ${({ width }) => width};
  background-color: ${COLORS.GREY_5};
  color: ${COLORS.BLACK_2};
  cursor: pointer;
`;

const LabelText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 1.6rem;
`;

interface IProps {
  id: string;
  width?: string;
  register?: any;
  name: string;
  value: number | string;
  label: string;
  checked?: boolean;
}

const CheckboxText = ({
  id,
  width = '100%',
  register,
  name,
  value,
  label,
  checked
}: IProps) => {
  return (
    <div>
      <InputStyle
        id={id}
        type="checkbox"
        name={name}
        {...register?.(name)}
        value={value}
        defaultChecked={checked}
      />
      <LabelStyle width={width} htmlFor={id}>
        <LabelText>{label}</LabelText>
      </LabelStyle>
    </div>
  );
};

export default CheckboxText;
