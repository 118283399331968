import React from 'react';
import ListOrderDetail from './ListOrderDetail';

interface IProps {
  id: number;
  onData?: any;
  isClosePanelRight(status: boolean): void;
}

const ListOrderTable = ({ onData, id, isClosePanelRight }: IProps) => {
  const currentList = onData?.orders?.find(
    (list: any) => +list?.orderNumber === id
  );

  return (
    <ListOrderDetail
      isClosePanelRight={isClosePanelRight}
      orderNumber={currentList?.orderNumber}
      dateAndTime={currentList?.dateAndTime}
      name={currentList?.name}
      subTotalPrice={currentList?.subtotal}
      operationCosts={currentList?.operationCost}
      refund={currentList?.refund || null} // if data is 0, convert 0 to null so that same as other data
      refundCost={currentList?.refundCost}
      taxRevenues={currentList?.soldAt}
      netPaymentPrice={currentList?.netPayment}
      netRevenues={currentList?.netRevenue}
      deliveryFee={currentList?.deliveryFee}
      items={currentList?.items}
    />
  );
};

export default ListOrderTable;
