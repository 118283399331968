import React from 'react';

function SpoonForkIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.499rem"
      height="1.7766rem"
      viewBox="0 0 14.99 17.766"
      {...props}>
      <path
        id="Icon_metro-spoon-fork"
        data-name="Icon metro-spoon-fork"
        d="M4.9.857A3.641,3.641,0,0,0,1.571,4.743,3.813,3.813,0,0,0,4.134,8.525l-.552,8.989a1.034,1.034,0,0,0,1.042,1.108H5.18a1.034,1.034,0,0,0,1.042-1.108L5.671,8.525A3.813,3.813,0,0,0,8.233,4.743,3.641,3.641,0,0,0,4.9.857Zm11.2,0-.925,5.552h-.694L14.016.857h-.463l-.463,5.552H12.4L11.472.857h-.463V8.074a.555.555,0,0,0,.555.555H13.01l-.545,8.885a1.034,1.034,0,0,0,1.042,1.108h.555A1.034,1.034,0,0,0,15.1,17.514L14.56,8.629h1.446a.555.555,0,0,0,.555-.555V.857Z"
        transform="translate(-1.571 -0.857)"
        fill="#878c97"
      />
    </svg>
  );
}

export default SpoonForkIcon;
