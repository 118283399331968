import styled from 'styled-components';
import { COLORS, FONT_SIZE } from 'styles/variables';

export const TableSubscriptionFeeWrapper = styled.div`
  padding: 0 3.6rem;

  background-color: ${COLORS.WHITE};
  > div {
    border: none;
  }
  table {
    th {
      border-bottom: none !important;
      text-align: center !important;
      color: ${COLORS.BLACK_3};

      &:last-child {
        text-align: right !important;
        padding-right: 0;
      }
    }

    .row-total td {
      padding: 2rem 0;
      font-size: 1.6rem;
      color: ${COLORS.BLACK_3};
      text-align: center;

      &:last-child {
        text-align: right;
      }
    }
  }
  .row-total td {
    border-top: 1px solid ${COLORS.BLACK_2} !important;
  }
  .total {
    text-align: right;
  }
  .totalContent {
    display: flex;
    justify-content: flex-end;

    .label,
    .value {
      margin-left: 5.2rem;
      font-size: ${FONT_SIZE.M};
      padding-top: 1rem;
      color: ${COLORS.GREY_6};
      p {
        padding: 1.05rem 0;
      }
    }
  }
  .footer {
    td {
      padding: 21.7rem 0 25.2rem;
      text-align: center;
      font-size: ${FONT_SIZE.M};

      .footerContent {
        padding: 0.6rem 0;
        color: ${COLORS.GREY_10};
      }
    }
  }
`;
