import React from 'react';
import NumberInput from 'components/ui-kits/Form/NumberInput';

import {
  Control,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
  useWatch
} from 'react-hook-form';
import DisplayText from 'components/ui-kits/DisplayText';
import { FieldWrapper, Price } from './EditProductItemForm.style';

export function FieldProductFinalPrice({
  control,
  register,
  getValues,
  setValue,
  defaultValue
}: {
  control: Control;
  register: any;
  setValue?: UseFormSetValue<FieldValues>;
  getValues?: UseFormGetValues<FieldValues>;
  // dataEdit && dataEdit.variants[0].price / 100
  defaultValue?: string | number | undefined;
}) {
  const watchIsSellProductWithUnits = useWatch({
    control,
    name: 'isSellProductWithUnits'
  });

  const watchIsReduction = useWatch({
    control,
    name: 'isReduction'
  });

  const normalProduct = !watchIsSellProductWithUnits && !watchIsReduction;

  return normalProduct ? null : (
    <FieldWrapper>
      <Price>
        <DisplayText
          as="label"
          fontWeight="BOLD"
          fontSize="M"
          label="Prix final TTC"
        />
      </Price>
      <NumberInput
        name="proFinalPrice"
        variant="unit"
        register={register}
        setValue={setValue}
        getValues={getValues}
        type="text"
        defaultValue={defaultValue}
        disabled
      />
    </FieldWrapper>
  );
}
