import React from 'react';
import styled from 'styled-components';
import { Logo } from 'components/ui-kits/Icon';

interface IAuthLayout {
  children: React.ReactNode;
}

const Wrapper = styled.div`
  max-width: 54rem;
  width: 100%;
  margin: 13rem auto 0 auto;
`;
const BlockImage = styled.div`
  width: 100%;
  margin-bottom: 4.3rem;

  svg {
    max-width: 32.4rem;
    width: 100%;
    margin: 0 auto;
  }
`;

const AuthLayout = ({ children }: IAuthLayout) => {
  return (
    <Wrapper>
      <BlockImage>
        <Logo />
      </BlockImage>
      {children}
    </Wrapper>
  );
};

export default AuthLayout;
