import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { COLORS, HEADER_HEIGHT, MEDIA_BREAKPOINT } from 'styles/variables';
import { NavItem } from 'types/NavItem';
import {
  HomeIcon,
  DeliveryIcon,
  SpoonForkIcon,
  StopWatchIcon,
  CreditCardIcon
} from 'components/ui-kits/Icon';
import { Grid, GridItem } from 'components/ui-kits/Grid/index';

const NavWrapper = styled.div`
  width: auto;
  position: sticky;
  top: ${HEADER_HEIGHT};
  left: 0;

  @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
    position: relative;
  }
`;

const NavStyle = styled(Grid)<{ openNavMobile: boolean }>`
  height: calc(100vh - ${HEADER_HEIGHT});
  background: ${COLORS.GREY_5};
  padding-top: 5.4rem;
  top: ${HEADER_HEIGHT};
  ul {
    list-style-type: none;
    width: inherit;
    box-sizing: border-box;
    margin-right: 2.5rem;

    li {
      margin-top: 4.5rem;

      a {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        font-weight: 500;
        color: ${COLORS.GREY_3};

        &.active {
          color: ${COLORS.RED};

          svg path,
          #delivery circle {
            fill: ${COLORS.RED};
          }
          #home {
            #Path_668 {
              fill: ${COLORS.BLUE_1};
            }
            #Path_669 {
              fill: ${COLORS.RED};
            }
          }
        }

        svg {
          margin-right: 10%;
          width: 2rem;
        }
      }
    }
  }

  @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
    position: fixed;
    bottom: 0;
    left: -100%;
    height: calc(100vh - 5.4rem);
    width: 25rem;
    padding: 3rem 0 0 3rem;
    transition: all 0.5s;
    z-index: 990;
    animation-duration: 0.8s;

    & + .screen {
      position: fixed;
      height: calc(100vh - 5.4rem);
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${COLORS.BLACK};
      opacity: 0.8;
      z-index: 980;
      display: none;
    }

    ${({ openNavMobile }) =>
      openNavMobile &&
      css`
        left: 0;
        transition: all 0.4s;

        & + .screen {
          display: block;
        }
      `}
  }
`;

const navList: NavItem[] = [
  { label: 'Accueil', path: '/home', icon: <HomeIcon id="home" /> },
  { label: 'Produits', path: '/menu', icon: <SpoonForkIcon id="spoon-fork" /> },
  {
    label: 'Livraison',
    path: '/delivery',
    icon: <DeliveryIcon id="delivery" />
  },
  {
    label: 'Temps de préparation',
    path: '/prep-time',
    icon: <StopWatchIcon id="stop-watch" />
  },
  { label: 'Paiements', path: '/payment', icon: <CreditCardIcon id="card" /> }
];

const Nav = ({
  closeNavMobile,
  statusNavMobile
}: {
  closeNavMobile?(): void;
  statusNavMobile: boolean;
}) => {
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <NavWrapper>
      <NavStyle
        openNavMobile={statusNavMobile}
        col={2}
        start="large"
        marginLeft={true}>
        <GridItem colStart={1} colEnd={2} nameStart="large" nameEnd="gutter">
          <ul>
            {navList.map(({ path, label, icon }) => (
              <li key={path}>
                <NavLink to={path} activeClassName="active">
                  {icon} {label}
                </NavLink>
              </li>
            ))}
          </ul>
        </GridItem>
      </NavStyle>
      <div className="screen" onClick={closeNavMobile}></div>
    </NavWrapper>
  );
};

export default Nav;
