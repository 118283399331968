import { AxiosError } from 'axios';
import Loader from 'components/Loader';
import { CREATE_MESSAGE, DELETE_MESSAGE, UPDATE_MESSAGE } from 'const/Message';
import React, { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import rfdc from 'rfdc';
import { COLORS } from 'styles/variables';
import {
  CoordsWithoutKey,
  ZoneField,
  ZoneInfo,
  ZoneItemsByName
} from 'types/Delivery';
import { handleError, handleSuccess } from 'utils/handleResponse';
import MapZoneBlock from './MapZoneBlock';
import {
  createDeliveryZoneV2,
  deleteDeliveryZoneV2,
  editDeliveryZoneV2,
  getListDeliveryZoneV2
} from 'services/deliveryService';

const listZoneNameVisible = ['Zone 1', 'Zone 2', 'Zone 3', 'Zone 4', 'Zone 5'];
const deliveryZone: Array<ZoneInfo> = [
  {
    name: 'Zone 1',
    coords: [],
    deliveryFee: 0,
    enabled: false,
    unit: '€',
    color: COLORS.ZONE_1
  },
  {
    name: 'Zone 2',
    coords: [],
    deliveryFee: 0,
    enabled: false,
    unit: '€',
    color: COLORS.ZONE_2
  },
  {
    name: 'Zone 3',
    coords: [],
    deliveryFee: 0,
    enabled: false,
    unit: '€',
    color: COLORS.ZONE_3
  },
  {
    name: 'Zone 4',
    coords: [],
    deliveryFee: 0,
    enabled: false,
    unit: '€',
    color: COLORS.ZONE_4
  },
  {
    name: 'Zone 5',
    coords: [],
    deliveryFee: 0,
    enabled: false,
    unit: '€',
    color: COLORS.ZONE_5
  }
];

export default function ZoneSettings() {
  const queryClient = useQueryClient();
  const { data: rawDeliZone, isLoading: isLoadingList } = useQuery(
    'getListDeliveryZone',
    ({ signal }) => getListDeliveryZoneV2(signal)
  );

  const { mutate: mutateCreateZone, isLoading: isLoadingCreate } = useMutation(
    createDeliveryZoneV2,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getListDeliveryZone'); // to make sure getListDeliveryZone called again
        handleSuccess(CREATE_MESSAGE.DELI_ZONE_SUCCESS);
      },
      onError: (err: AxiosError) => {
        handleError(err.response?.status, CREATE_MESSAGE.DELI_ZONE_ERROR);
      }
    }
  );
  const { mutate: mutateEditZone, isLoading: isLoadingEdit } = useMutation(
    editDeliveryZoneV2,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getListDeliveryZone');
        handleSuccess(UPDATE_MESSAGE.DELI_ZONE_SUCCESS);
      },
      onError: (err: AxiosError) => {
        handleError(err.response?.status, UPDATE_MESSAGE.DELI_ZONE_ERROR);
      }
    }
  );
  const { mutate: mutateDeleteZone, isLoading: isLoadingDelete } = useMutation(
    deleteDeliveryZoneV2,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getListDeliveryZone');
        handleSuccess(DELETE_MESSAGE.DELI_ZONE_SUCCESS);
      },
      onError: (err: AxiosError) => {
        handleError(err.response?.status, DELETE_MESSAGE.DELI_ZONE_ERROR);
      }
    }
  );
  const refinedDeliZone = useMemo(() => {
    if (!rawDeliZone) {
      return deliveryZone;
    }
    const zoneVisibleByName = rawDeliZone['hydra:member'].filter((item) =>
      listZoneNameVisible.includes(item.name)
    );
    const coordsByNameVisible = zoneVisibleByName.reduce((acc, cur) => {
      acc[cur.name] = {
        id: cur.id,
        deliveryFee: cur.deliveryFee,
        enabled: cur.enabled,
        coords: formatCoordsFromAPItoMap(cur.polygon.coordinates[0] || [])
      };
      return acc;
    }, {} as ZoneItemsByName);
    const newDeliveryZone = deliveryZone.map((item) => {
      if (!coordsByNameVisible[item.name]) {
        return item;
      }
      return {
        ...item,
        ...coordsByNameVisible[item.name]
      };
    });
    return newDeliveryZone;
  }, [rawDeliZone]);

  function handleMutation(data: ZoneField) {
    if (typeof data.id === 'undefined') {
      mutateCreateZone(data);
    } else {
      const id = data.id;
      delete data.id;
      mutateEditZone({ data, id });
    }
  }
  function handleDelete(id: number) {
    //Todo
    mutateDeleteZone(id);
  }

  return (
    <>
      {(isLoadingList ||
        isLoadingCreate ||
        isLoadingEdit ||
        isLoadingDelete) && <Loader />}
      <MapZoneBlock
        deliveryZone={refinedDeliZone}
        mutateSave={handleMutation}
        mutateDelete={handleDelete}
      />
    </>
  );
}

function formatCoordsFromAPItoMap(coords: Array<CoordsWithoutKey>) {
  if (coords.length < 2) {
    return [
      {
        lng: coords[0][0],
        lat: coords[0][1]
      }
    ];
  }
  return toCoordsWithKey(toRemoveLastPoint(coords));
}

function toRemoveLastPoint(coords: Array<CoordsWithoutKey>) {
  const newCoords = rfdc({ proto: false })(coords);
  newCoords.pop();
  return newCoords;
}

function toCoordsWithKey(coords: Array<CoordsWithoutKey>) {
  return coords.map((item) => ({ lng: item[0], lat: item[1] }));
}
