import api from 'api/setupAxios';

export const invoicesApiV2 = async ({
  filter,
  startDate,
  endDate,
  signal
}: InvoicesParams) => {
  const res = await api
    .call(signal)
    .get(
      `/merchant/invoices?filter=${filter}&startDate=${startDate}&endDate=${endDate}`
    );
  return res.data;
};

export const customerInvoicesV2 = async (id: number, signal?: AbortSignal) => {
  const res = await api.call(signal).get(`/merchant/customer-invoices/${id}`);
  return res.data;
};

export const weeklyInvoicesV2 = async (id: number, signal?: AbortSignal) => {
  const res = await api.call(signal).get(`/merchant/weekly-invoices/${id}`);
  return res.data;
};

export const monthlyInvoicesV2 = async (id: number, signal?: AbortSignal) => {
  const res = await api.call(signal).get(`/merchant/monthly-invoices/${id}`);
  return res.data;
};

// export const invoicesDownloadPDFV2 = async ({
//   invoiceType,
//   invoiceNumber
// }: InvoicesDownloadPDFParams) => {
//   const res = await api
//     .call()
//     .get(
//       `/merchant/download-invoice-pdf?invoiceType=${invoiceType}&invoiceNumber=${invoiceNumber}`,
//       { responseType: 'blob' }
//     );
//   return res.data;
// };

export const invoicesDownloadPDFV3 = async ({
  invoiceType,
  id
}: InvoicesDownloadPDFV3Params) => {
  const params = {
    invoiceType: invoiceType
  };
  const name = getInvoiceNameByType(invoiceType);
  const res = await api.call().get(`/merchant/${name}/${id}/download-pdf`, {
    responseType: 'blob',
    params
  });
  return res.data;
};

type InvoicesParams = {
  signal?: AbortSignal;
  filter: string;
  startDate: string;
  endDate: string;
};

// type InvoicesDownloadPDFParams = {
//   invoiceType: string;
//   invoiceNumber: string;
// };

type InvoicesDownloadPDFV3Params = {
  invoiceType: string;
  id?: number;
};

function getInvoiceNameByType(type: string) {
  switch (type) {
    case 'customer_invoice':
      return 'customer-invoices';
    case 'merchant_subscription_invoice':
      return 'monthly-invoices';
    case 'merchant_operation_invoice':
      return 'weekly-invoices';
    default:
      return '';
  }
}
