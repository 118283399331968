import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  DefaultPayment,
  FieldPaymentWrapper,
  JsFieldPaymentWrapper,
  PaymentMethodWrapper,
  SelectPayment,
  TitleWrapper,
  BlockWrapper
} from './PaymentMethodForm.style';
import Button from 'components/ui-kits/Button';
import { Checkbox } from 'components/ui-kits/Form/Checkbox';
import DisplayText from 'components/ui-kits/DisplayText';
import { CreditCardIcon } from 'components/ui-kits/Icon';
import { IPaymentMethod } from 'types/PaymentMethod';
import { AllowPaymentMethods, Merchant } from 'types/Merchant';
import ButtonIcon from 'components/ui-kits/ButtonIcon';
import { IconNames } from 'components/ui-kits/Icon/Icon';

interface IPaymentMethodForm {
  mode: 'list' | 'edit';
  onChangeMode(m: 'list' | 'edit'): void;
  listPaymentMethod: IPaymentMethod[];
  allowPaymentMethod: Pick<Merchant, 'allowPaymentMethods'>;
  onSave(dataSave: AllowPaymentMethods[]): void;
}

const PaymentMethodForm = ({
  mode,
  onChangeMode,
  listPaymentMethod,
  allowPaymentMethod,
  onSave
}: IPaymentMethodForm) => {
  const {
    register,
    getValues,
    formState: { isDirty },
    reset
  } = useForm();

  useEffect(() => {
    reset();
  }, [allowPaymentMethod, reset]);

  const handleSubmit = () => {
    onSave(getValues('selectPayment'));
  };

  const displayListPayment = (arrPayment: any) => {
    if (Array.isArray(allowPaymentMethod)) {
      return arrPayment.map((item: any, index: number) => {
        const check = allowPaymentMethod.some(
          (res) => res === item.paymentCode
        );

        if (mode === 'list') {
          return (
            <BlockWrapper key={index}>
              <JsFieldPaymentWrapper mode={mode} selected={check}>
                {item.icon}
                <DisplayText label={item.label} fontSize="M" className="text" />
              </JsFieldPaymentWrapper>
            </BlockWrapper>
          );
        } else {
          return (
            <BlockWrapper key={index}>
              <Checkbox
                name="selectPayment"
                register={register}
                defaultChecked={check}
                value={item.paymentCode}>
                <JsFieldPaymentWrapper mode={mode} selected={check}>
                  {item.icon}
                  <DisplayText label={item.label} fontSize="M" />
                </JsFieldPaymentWrapper>
              </Checkbox>
            </BlockWrapper>
          );
        }
      });
    }
  };

  return (
    <PaymentMethodWrapper>
      <TitleWrapper>
        <div className="wrapper">
          <DisplayText
            label="Méthode de paiement"
            fontSize="XXL"
            fontWeight="LIGHT"
          />
          {mode === 'list' && (
            <Button
              className="edit"
              label="Modifier"
              variant="link"
              isFluid={false}
              isUpperCase={false}
              fontWeight="BOLD"
              onClick={() => onChangeMode('edit')}
            />
          )}
        </div>
        {mode === 'edit' && (
          <div className="action-wrapper">
            <Button
              label="Enregistrer"
              isFluid={false}
              variant="primary"
              fontWeight="BOLD"
              letterSpacing="S"
              onClick={handleSubmit}
              isDisabled={!isDirty}
            />
            <ButtonIcon
              onClick={() => onChangeMode('list')}
              icon={IconNames.Close}
              widthIcon={50}
              variant="square"
              type="button"
            />
          </div>
        )}
      </TitleWrapper>
      <DefaultPayment>
        <DisplayText
          label="Méthodes de paiement par défaut"
          fontSize="S"
          fontWeight="BOLD"
          color="BLACK_4"
          className="title"
        />
        <FieldPaymentWrapper>
          <CreditCardIcon />
          <DisplayText label="Carte Bancaire" fontSize="M" />
        </FieldPaymentWrapper>
      </DefaultPayment>
      <SelectPayment>
        <DisplayText
          label="Sélectionnez les autre méthodes de paiement que vous souhaitez accepter"
          fontSize="S"
          fontWeight="BOLD"
          color="BLACK_4"
          className="title"
        />
        {displayListPayment(listPaymentMethod)}
      </SelectPayment>
    </PaymentMethodWrapper>
  );
};

export default PaymentMethodForm;
