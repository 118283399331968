import React from 'react';

function DownloadIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.4rem"
      height="2.4rem"
      viewBox="0 0 24 24"
      {...props}>
      <path
        id="Icon_awesome-download"
        data-name="Icon awesome-download"
        d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"
        transform="translate(-0.5)"
        fill="#464A51"
      />
    </svg>
  );
}

export default DownloadIcon;
