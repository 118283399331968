import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { NavItem } from 'types/NavItem';
import { useRouteMatch } from 'react-router-dom';
import { COLORS, HEADER_HEIGHT } from 'styles/variables';
import { Grid, GridItem } from 'components/ui-kits/Grid';

const SecondNavWrapper = styled(Grid)`
  position: sticky;
  top: ${HEADER_HEIGHT};
  left: 0;
  z-index: 10;
  border-bottom: 1px solid ${COLORS.GREY_4};
  background-color: ${COLORS.WHITE_1};
`;

const SecondNavStyle = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT};
  display: flex;
  align-items: center;

  ul {
    display: flex;
    align-items: center;
    list-style-type: none;

    li {
      margin-right: 6.2rem;

      a {
        font-family: Roboto;
        font-weight: 500;
        font-size: 1.6rem;
        color: ${COLORS.GREY_4};
      }

      a.active {
        color: ${COLORS.BLACK_2};
      }
    }
  }
`;

const SecondNav = ({ secondNavs }: { secondNavs: NavItem[] }) => {
  const { url } = useRouteMatch();

  return (
    <SecondNavWrapper asType="nav" col={11} start="large" marginRight={true}>
      <GridItem colStart={2} colEnd={11} nameStart="large" nameEnd="margin">
        <SecondNavStyle>
          <ul>
            {secondNavs.map(({ path: nestedPath, label }) => (
              <li key={nestedPath}>
                <NavLink to={`${url}${nestedPath}`} activeClassName="active">
                  {label}
                </NavLink>
              </li>
            ))}
          </ul>
        </SecondNavStyle>
      </GridItem>
    </SecondNavWrapper>
  );
};

export default SecondNav;
