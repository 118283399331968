import React from 'react';

function InfoIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      width="1.8rem"
      height="1.8rem"
      {...props}>
      <g id="surface211103253">
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: '#878C97'
          }}
          d="M 9 0.71875 C 4.425781 0.71875 0.71875 4.425781 0.71875 9 C 0.71875 13.574219 4.425781 17.28125 9 17.28125 C 13.574219 17.28125 17.28125 13.574219 17.28125 9 C 17.28125 4.425781 13.574219 0.71875 9 0.71875 Z M 9 3.960938 C 9.597656 3.960938 10.078125 4.445312 10.078125 5.039062 C 10.078125 5.636719 9.597656 6.121094 9 6.121094 C 8.402344 6.121094 7.921875 5.636719 7.921875 5.039062 C 7.921875 4.445312 8.402344 3.960938 9 3.960938 Z M 10.441406 13.679688 L 7.558594 13.679688 L 7.558594 12.960938 L 8.28125 12.960938 L 8.28125 8.28125 L 7.558594 8.28125 L 7.558594 7.558594 L 9.71875 7.558594 L 9.71875 12.960938 L 10.441406 12.960938 Z M 10.441406 13.679688 "
        />
      </g>
    </svg>
  );
}

export default InfoIcon;
