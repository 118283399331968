import React from 'react';
import DisplayText from 'components/ui-kits/DisplayText';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';

const ProcessRatingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ProcessStyle = styled.div`
  position: relative;
  width: 70%;
  height: 1rem;
  background-color: ${COLORS.GREY_5};
  border-radius: 1rem;
`;
const CurrentStyle = styled.div<{ percent: string }>`
  position: absolute;
  width: ${({ percent }) => percent};
  height: 1rem;
  background-color: ${COLORS.GREEN_1};
  border-radius: 1rem;
`;

interface IProps {
  labelStar: string;
  numbersOfRating: number;
  percentProcess: string;
}

const ProcessRating = ({
  labelStar,
  numbersOfRating,
  percentProcess
}: IProps) => {
  return (
    <ProcessRatingWrapper>
      <DisplayText label={labelStar} fontSize="S" />
      <ProcessStyle>
        <CurrentStyle percent={percentProcess}></CurrentStyle>
      </ProcessStyle>
      <DisplayText
        label={
          numbersOfRating > 1
            ? `(${numbersOfRating} avis)`
            : `(${numbersOfRating} avis)`
        }
        fontSize="XS"
        color="GREY_7"
      />
    </ProcessRatingWrapper>
  );
};

export default ProcessRating;
