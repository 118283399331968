import React from 'react';
import {
  TitleLarge,
  CollapseWrapper,
  TitleWrapper,
  CollapseIcon,
  SubTitle
} from './Collapse.style';
import { ChevronTopIcon } from 'components/ui-kits/Icon';

interface ICollapse {
  children: React.ReactNode;
  title: string;
  isOpen: boolean;
  toggle: () => void;
  subtitle?: React.ReactNode;
}

const Collapse = ({ children, title, isOpen, toggle, subtitle }: ICollapse) => {
  return (
    <CollapseWrapper>
      <TitleWrapper>
        <div>
          <TitleLarge>{title}</TitleLarge>
          <SubTitle>{subtitle}</SubTitle>
        </div>
        <CollapseIcon onClick={toggle} className={isOpen ? '' : 'active'}>
          <ChevronTopIcon />
        </CollapseIcon>
      </TitleWrapper>
      {isOpen ? <>{children}</> : null}
    </CollapseWrapper>
  );
};

export default Collapse;
