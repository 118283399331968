import DisplayText from 'components/ui-kits/DisplayText';
import React from 'react';
import { formatDollar } from 'utils';
import {
  GripLines,
  CardBodyItem,
  GripAndDesc,
  DescriptionWrapper
} from './ProductInGroupOption.style';

interface IProps {
  productItem: { name: string; price: number };
}

const ProductInGroupOption = ({ productItem }: IProps) => {
  return (
    <CardBodyItem>
      <GripAndDesc>
        <GripLines></GripLines>
        <DescriptionWrapper>
          <DisplayText
            label={productItem.name}
            fontWeight="REGULAR"
            fontSize="M"
          />
        </DescriptionWrapper>
      </GripAndDesc>
      <DisplayText
        label={`${formatDollar(productItem.price)}€`}
        fontSize="M"
        fontWeight="BOLD"
      />
    </CardBodyItem>
  );
};

export default ProductInGroupOption;
