import classNames from 'classnames';
import {
  GroupOptionsWrapper,
  TitleSmall,
  SearchWidth,
  LoadMore,
  SearchResult,
  SearchWrapperVariant
} from 'components/ProductLayout/ProductForm/ProductForm.style';
import { Search } from 'components/ui-kits/Form/Dropdown';
import Tag from 'components/ui-kits/Tag';
import React, { useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getGroupOptionsListV2 } from 'services/groupOptionService';
import { IGroupOptions } from 'types/GroupOptions';
import getInfoPageParams from 'utils';

export function GroupOptionsSelect({
  initialValues,
  onSelect,
  variant,
  ignoredGroupOptions
}: {
  initialValues: string[];
  onSelect: (values: string[]) => void;
  variant: 'horizontalLayout' | 'verticalLayout';
  ignoredGroupOptions: string[];
}) {
  const {
    groupOptionFullData,
    groupOptionSelected,
    handleDeleteTag,
    onChangeOption,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useSearchAndSelectGroupOptions(initialValues, onSelect);

  return (
    <GroupOptionsWrapper>
      <TitleSmall>
        Groupe d&apos;option dans lequel l&apos;option est utilisé
      </TitleSmall>
      <SearchWrapperVariant variant={variant}>
        <SearchWidth>
          <Search
            change={onChangeOption}
            placeholder="Ajouter un groupe d'option"
            variant="groupOption"
            options={groupOptionFullData
              .filter((item) => groupOptionSelected.indexOf(item['@id']) === -1)
              .filter((item) => !ignoredGroupOptions.includes(item['@id']))
              .map((item) => ({
                label: item.name,
                value: item['@id']
              }))}
            isMulti={true}
            value={[]}
            isLazyLoading={true}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            ComponentOnOpen={
              isFetchingNextPage ? <LoadMore>Chargement...</LoadMore> : <></>
            }
            classNameWrapper={classNames(
              { loading: isFetchingNextPage },
              'search-go-wrapper'
            )}
          />
        </SearchWidth>
        <SearchResult>
          {groupOptionFullData
            .filter((item) => groupOptionSelected.includes(item['@id']))
            .map((item) => (
              <Tag
                key={item['@id']}
                label={item.name}
                className="tag"
                onDelete={() => handleDeleteTag(item['@id'])}
              />
            ))}
        </SearchResult>
      </SearchWrapperVariant>
    </GroupOptionsWrapper>
  );
}

const ITEMS_PER_PAGE = 10;

export function useSearchAndSelectGroupOptions(
  initialSelectedValues: string[],
  onSelect: (values: string[]) => void
) {
  const {
    data: groupOptionsData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useInfiniteQuery(
    ['getGroupOptionList', ITEMS_PER_PAGE],
    ({ pageParam, signal }) =>
      getGroupOptionsListV2({
        signal,
        limit: ITEMS_PER_PAGE,
        page: pageParam?.page ?? 1
      }),
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage['hydra:view']['hydra:last']) return undefined;
        const nextPageParams = getInfoPageParams(
          lastPage['hydra:view']['@id'],
          lastPage['hydra:view']['hydra:last']
        );
        if (!nextPageParams?.currentPage || !nextPageParams?.lastPage)
          return undefined;
        if (nextPageParams.currentPage < nextPageParams.lastPage) {
          return { page: nextPageParams.currentPage + 1 };
        }
        return undefined;
      }
    }
  );

  const [groupOptionSelected, setGroupOptionSelected] = useState<string[]>(
    initialSelectedValues
  );

  const groupOptionFullData: IGroupOptions[] = useMemo(() => {
    if (!groupOptionsData?.pages) return [];
    return groupOptionsData.pages.reduce(
      (acc: any, cur) => [...acc, ...cur['hydra:member']],
      []
    );
  }, [groupOptionsData]);

  const onChangeOption = (data: { label: string; value: string }[]) => {
    if (!data?.length) return;
    const nextValues = [...groupOptionSelected, data[0].value];
    setGroupOptionSelected(nextValues);
    onSelect(nextValues);
  };

  const handleDeleteTag = (code: string) => {
    const nextValues = groupOptionSelected.filter(
      (item: string) => item !== code
    );

    setGroupOptionSelected(nextValues);

    onSelect(nextValues);
  };

  return {
    groupOptionFullData,
    groupOptionSelected,
    handleDeleteTag,
    onChangeOption,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  };
}
