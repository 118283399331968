import api from 'api/setupAxios';
import { MERCHANT_SLUG } from 'const/endpoint';
import { PrepTime } from 'types/PrepTime';

export const getPrepTimeV2 = async (slug: string, signal?: AbortSignal) => {
  const res = await api.call(signal).get(MERCHANT_SLUG.replace(':slug', slug));
  return res.data;
};

export const updatePrepTimeV2 = async (slug: string, data: PrepTime) => {
  const res = await api.call().put(MERCHANT_SLUG.replace(':slug', slug), data);
  return res.data;
};
