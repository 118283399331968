import React from 'react';
import { CheckIcon, CloseIcon } from 'components/ui-kits/Icon';
import { toast } from 'react-toastify';

export const handleSuccess = (
  messageSuccess: React.ReactNode,
  autoClose?: number
) => {
  toast.success(messageSuccess, {
    autoClose: autoClose || 3000,
    icon: <CheckIcon />
  });
};

export const handleError = (
  statusCode?: number,
  messageError: React.ReactNode | null = ''
) => {
  if (statusCode === 500) {
    toast.error('Internal Server Error', {
      autoClose: 5000,
      icon: <CloseIcon className="icon-error" />
    });
  } else {
    toast.error(messageError || 'Some thing went wrong, please try again', {
      autoClose: 5000,
      icon: <CloseIcon className="icon-error" />
    });
  }
};

export function handleMessageWhenAppliedPromotion() {
  handleSuccess(
    <React.Fragment>
      <div>
        L&apos;activation de la reduction pour ce produit est en cours de
        traitement.
      </div>
      <div>
        Veuillez attendre 1 à 2 minutes jusqu&apos;à ce que le processus soit
        terminé.
      </div>
    </React.Fragment>,
    5000
  );
}
