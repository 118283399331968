import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import Table from 'components/ui-kits/Table';
import { TableCell } from 'components/ui-kits/Table/Table.style';
import { TableMonthStyle } from './TableMonth.style';
import { DownloadIcon } from 'components/ui-kits/Icon';
import { Financial } from 'types/FinancialReport';
import {
  downLoadFileNameFinancialReport,
  downLoadPdfFile,
  formatDollar,
  getDateInMonth,
  MONTHS
} from 'utils';
import Button from 'components/ui-kits/Button';
import Loader from 'components/Loader';
import { format } from 'date-fns';
import { financialDownloadPDFV2 } from 'services/financialReportService';
import { CommonTableHeader } from '../TablePeriod/TablePeriod';
import { COLORS } from 'styles/variables';

const TableHeader = [
  { label: 'Mois' },
  ...CommonTableHeader,
  { label: 'Télécharger (PDF)' }
];
interface IProps {
  dataByMonth: Financial;
  year: number;
}

const changeMonth = (month: string) => {
  // Change month to French
  switch (month) {
    case 'Jan':
      return 'Janvier';
    case 'Feb':
      return 'Février';
    case 'Mar':
      return 'Mars';
    case 'Apr':
      return 'Avril';
    case 'May':
      return 'Mai';
    case 'Jun':
      return 'Juin';
    case 'Jul':
      return 'Juillet';
    case 'Aug':
      return 'Août';
    case 'Sep':
      return 'Septembre';
    case 'Oct':
      return 'Octobre';
    case 'Nov':
      return 'Novembre';
    case 'Dec':
      return 'Décembre';
    case 'total':
      return 'Total';
    default:
      break;
  }
};

const TableMonth = ({ dataByMonth, year }: IProps) => {
  const [dataDisplay, setDataDisplay] = useState<any>();

  useEffect(() => {
    formatDataDisplay(dataByMonth);
  }, [dataByMonth]);

  function formatDataDisplay(data: Financial) {
    const newData = [];
    for (const k in data) {
      const obj = { month: k, ...data[k] };
      newData.push(obj);
    }
    setDataDisplay(newData);
  }

  const { isLoading: isLoadingDownload, mutate } = useMutation(
    financialDownloadPDFV2,
    {
      onSuccess: (blob: any, data: any) => {
        const startDate = format(new Date(data?.startDate), 'dd_MM_yyyy');
        const endDate = format(new Date(data?.endDate), 'dd_MM_yyyy');
        downLoadPdfFile(
          blob,
          downLoadFileNameFinancialReport(startDate, endDate)
        );
      }
    }
  );

  const handleDownloadPDF = (monthEn: string) => {
    const month = MONTHS[monthEn];
    const daysInMonth = getDateInMonth(year, month);
    const start = `${year}-${month}-01`;
    const end = `${year}-${month}-${daysInMonth}`;
    mutate({ startDate: start, endDate: end });
  };

  return (
    <TableMonthStyle>
      <Table header={TableHeader}>
        {dataDisplay?.map((item: any, index: number) => {
          return (
            <tr
              key={index}
              style={{
                backgroundColor: item.month === 'total' ? COLORS.GREY_13 : ''
              }}>
              <TableCell className="cell-month">
                {changeMonth(item.month)}
              </TableCell>
              <TableCell>
                {formatDollar(item.tax['0.021'].taxExcluded)}€
              </TableCell>
              <TableCell>
                {formatDollar(item.tax['0.021'].taxAmount)}€
              </TableCell>
              <TableCell>
                {formatDollar(item.tax['0.055'].taxExcluded)}€
              </TableCell>
              <TableCell>
                {formatDollar(item.tax['0.055'].taxAmount)}€
              </TableCell>
              <TableCell>
                {formatDollar(item.tax['0.085'].taxExcluded)}€
              </TableCell>
              <TableCell>
                {formatDollar(item.tax['0.085'].taxAmount)}€
              </TableCell>
              <TableCell>
                {formatDollar(item.tax['0.1'].taxExcluded)}€
              </TableCell>
              <TableCell>{formatDollar(item.tax['0.1'].taxAmount)}€</TableCell>
              <TableCell>
                {formatDollar(item.tax['0.2'].taxExcluded)}€
              </TableCell>
              <TableCell>{formatDollar(item.tax['0.2'].taxAmount)}€</TableCell>
              <TableCell style={{ backgroundColor: COLORS.GREY_13 }}>
                {formatDollar(item.totalOfSales)}€
              </TableCell>
              <TableCell>{formatDollar(item.cashPayment)}€</TableCell>
              <TableCell>{formatDollar(item.cardPayment)}€</TableCell>
              <TableCell>{formatDollar(item.checkPayment)}€</TableCell>
              <TableCell>
                {formatDollar(item.restaurantTicketPayment)}€
              </TableCell>
              <TableCell>{formatDollar(item.vacationTicketPayment)}€</TableCell>
              <TableCell style={{ backgroundColor: COLORS.GREY_13 }}>
                {formatDollar(item.totalPayment)}€
              </TableCell>
              <TableCell className="cell-download">
                <Button
                  variant="text"
                  isFluid={false}
                  onClick={() => handleDownloadPDF(item.month)}>
                  <DownloadIcon />
                </Button>
              </TableCell>
            </tr>
          );
        })}
      </Table>
      {isLoadingDownload && <Loader />}
    </TableMonthStyle>
  );
};

export default TableMonth;
