import React from 'react';

function VacationTicketIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="16.996"
      viewBox="0 0 25 16.996"
      {...props}>
      <path
        id="vacation_ticket"
        data-name="vacation ticket"
        d="M19901,11876h-20a2,2,0,0,1-2-2v-4a2,2,0,1,0,0-4v-4a2,2,0,0,1,2-2h20a2,2,0,0,1,2,2v4a2,2,0,0,0,0,4v4A2,2,0,0,1,19901,11876Zm-15.221-5.113a.26.26,0,0,0-.262.262v.523a.262.262,0,0,0,.262.264h8.9a.264.264,0,0,0,.264-.264v-.523a.263.263,0,0,0-.264-.262h-3.91l1.063-2.92-.982-.358-1.193,3.278Zm8.271-6.338a3.611,3.611,0,0,1,.215.9,7.01,7.01,0,0,1-.312,2.731l1.68.611a.265.265,0,0,0,.094.018.258.258,0,0,0,.26-.249A4.9,4.9,0,0,0,19894.051,11864.547Zm-1.654-.762a2.257,2.257,0,0,0-1.5.774,6.518,6.518,0,0,0-1.328,2.024l3.9,1.422c.588-2.01.289-3.8-.7-4.155A1.063,1.063,0,0,0,19892.4,11863.785Zm-1.295-.229a4.926,4.926,0,0,0-3.818,1.834.248.248,0,0,0-.043.227.27.27,0,0,0,.164.18l1.67.607a5.907,5.907,0,0,1,2.277-2.842C19891.277,11863.559,19891.2,11863.557,19891.1,11863.557Z"
        transform="translate(-19878.496 -11859.502)"
        fill="#676767"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </svg>
  );
}

export default VacationTicketIcon;
