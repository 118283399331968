import { IGrid, IGridItem } from 'types/Grid';

export const GridSectionName: Pick<IGrid, 'col' | 'start'> = {
  col: 7,
  start: 'large'
};

export const GridItemInput: Pick<IGridItem, 'colEnd' | 'nameEnd'> = {
  colEnd: 5,
  nameEnd: 'gutter'
};

export const GridItemBtnSave: Pick<
  IGridItem,
  'colStart' | 'colEnd' | 'nameStart' | 'nameEnd'
> = {
  colStart: 6,
  colEnd: 6,
  nameStart: 'large',
  nameEnd: 'gutter'
};

export const GridItemBtnDelete: Pick<
  IGridItem,
  'colStart' | 'colEnd' | 'nameStart' | 'nameEnd'
> = {
  colStart: 7,
  colEnd: 7,
  nameStart: 'large',
  nameEnd: 'gutter'
};

export const Margin = {
  sectionName: '1.9rem 0 0.5rem 0',
  containerInput: '8rem'
};
