import styled from 'styled-components';
import { DisplayListProductsItem } from 'styles/categories-page';
import { COLORS, MEDIA_BREAKPOINT } from 'styles/variables';

export const CategoryItemWrapper = styled.div`
  margin-bottom: 5.5rem;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-right: 2.3rem;

    @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
      margin-right: 3.3rem;
    }
  }

  .image-wrapper {
    margin-right: 2rem;
  }

  .action {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }

  .title {
    margin-right: 2rem;
  }

  .modified {
    margin-right: 2rem;
  }

  .product-list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    margin-left: -${DisplayListProductsItem.marginLeftDesktopM};
    @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
      margin-left: -${DisplayListProductsItem.marginLeftDesktopS};
    }
  }

  .last-updated-time {
    margin-right: 1.7rem;
  }
  .product-loader {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 1.4rem 0.5rem 1.4rem 1.4rem;
    margin-left: ${DisplayListProductsItem.marginLeftDesktopM};
    margin-bottom: 1.4rem;
    width: calc(
      100% / ${DisplayListProductsItem.numberOfProductsDesktopM} -
        ${DisplayListProductsItem.marginLeftDesktopM}
    );
    @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
      margin-left: ${DisplayListProductsItem.marginLeftDesktopS};
      width: calc(
        100% / ${DisplayListProductsItem.numberOfProductsDesktopS} -
          ${DisplayListProductsItem.marginLeftDesktopS}
      );
    }
  }
  .product-item {
    position: relative;
    background-color: ${COLORS.WHITE};
    border-radius: 1.2rem;
    border: 1px solid ${COLORS.GREY_5};
    display: flex;
    align-items: flex-start;
    padding: 1.4rem 0.5rem 1.4rem 1.4rem;
    margin-left: ${DisplayListProductsItem.marginLeftDesktopM};
    margin-bottom: 1.4rem;
    width: calc(
      100% / ${DisplayListProductsItem.numberOfProductsDesktopM} -
        ${DisplayListProductsItem.marginLeftDesktopM}
    );

    @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
      margin-left: ${DisplayListProductsItem.marginLeftDesktopS};
      width: calc(
        100% / ${DisplayListProductsItem.numberOfProductsDesktopS} -
          ${DisplayListProductsItem.marginLeftDesktopS}
      );
    }
  }

  .product-img {
    min-width: 6rem;
    width: 6rem;
    height: 6rem;
    border-radius: 0.7rem;
    object-fit: cover;
  }

  .product-item {
    &__heading {
      min-height: 4rem;
    }
    &__right {
      margin-left: 1.4rem;
      flex: 1;
    }
  }
  .product-option-tooltip {
    align-self: center;
  }
  .product-price {
    margin-top: 0.5rem;
  }
  .btn-add {
    margin-top: 1.4rem;
    margin-right: 2rem;
  }
  .product-des {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.24px;
  }
  .product-ref {
    margin: 0.5rem 0;
  }

  .product-name {
    max-width: max-content;
  }

  .product-name,
  .product-des,
  .product-ref {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;

    @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
      width: 150px;
    }

    @media (max-width: 1440px) {
      width: 110px;
    }

    @media (max-width: 1280px) {
      width: 100px;
    }

    @media (max-width: 1150px) {
      width: 80px;
    }

    @media ${MEDIA_BREAKPOINT.DESKTOP_L} {
      width: 140px;
    }
  }
`;

export const DeleteConfirmWrapper = styled.div`
  padding: 3.5rem 2.8rem;
  text-align: center;
  .note {
    margin-top: 1rem;
  }

  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
  }
  .btn {
    margin: 0 1.5rem;
  }
`;

export const EmptyImage = styled.div`
  width: 6rem;
  height: 6rem;
  border: 1px solid #c9bfbf;
  background-color: #fff;
  border-radius: 0.7rem;
`;
export const ImageStyle = styled.img`
  width: 6rem;
  height: 6rem;
  border: 1px solid #c9bfbf;
  border-radius: 0.7rem;
`;
