const ON_DELETE_MESSAGE = (
  item: string | TemplateStringsArray,
  type?: 'ce' | 'cette'
) => `Voulez-vous supprimer ${type || 'cette'} ${item} ?`;

export const CONFIRM_MESSAGE = {
  DELETE_CATALOG: ON_DELETE_MESSAGE('catalogue', 'ce'),
  DELETE_GROUP_OPTION: ON_DELETE_MESSAGE("groupe d'options", 'ce'),
  DELETE_CATEGORY: ON_DELETE_MESSAGE`catégorie`,
  DELETE_PRODUCT: ON_DELETE_MESSAGE('produit', 'ce'),
  DELETE_ZONE: ON_DELETE_MESSAGE`zone`,
  SAVE_ZONE:
    'Vous devez enregistrer votre zone actuelle avant de modifier une autre zone',
  DELETE_OPTION: ON_DELETE_MESSAGE`option`
};

const ON_RESPONSE_MESSAGE = (
  item: string,
  type: string,
  isSuccess: boolean
) => {
  return `${item} a été ${type} ${isSuccess ? 'succès' : 'échec'}`;
};

const ON_TOGGLE_MESSAGE = (item: string, status: boolean) => {
  return `${item} ${status ? 'activé' : 'désactivé'}`;
};

export const CREATE_MESSAGE = {
  CATALOG_SUCCESS: ON_RESPONSE_MESSAGE('Le catalogue', 'créé avec', true),
  CATALOG_ERROR: ON_RESPONSE_MESSAGE('Le catalogue', 'créé avec', false),
  PRODUCT_SUCCESS: ON_RESPONSE_MESSAGE('Le produit', 'créé avec', true),
  PRODUCT_ERROR: ON_RESPONSE_MESSAGE('Le produit', 'créé avec', false),
  CATEGORY_SUCCESS: ON_RESPONSE_MESSAGE('La catégorie', 'créé avec', true),
  CATEGORY_ERROR: ON_RESPONSE_MESSAGE('La catégorie', 'créé avec', false),
  GROUP_OPTION_SUCCESS: ON_RESPONSE_MESSAGE(
    `Le groupe d'option`,
    'créé avec',
    true
  ),
  GROUP_OPTION_ERROR: ON_RESPONSE_MESSAGE(
    `Le groupe d'option`,
    'créé avec',
    false
  ),
  OPTION_SUCCESS: ON_RESPONSE_MESSAGE(`L'option`, 'créé avec', true),
  OPTION_ERROR: ON_RESPONSE_MESSAGE(`L'option`, 'créé avec', false),
  DELI_ZONE_SUCCESS: ON_RESPONSE_MESSAGE(
    'La zone de livraison',
    'créée avec',
    true
  ),
  DELI_ZONE_ERROR: 'Fail to create a new zone',
  ADD_PRODUCT_INTO_CATEGORY_SUCCESS: ON_RESPONSE_MESSAGE(
    'Le produit',
    'ajouté avec',
    true
  ),
  ADD_PRODUCT_INTO_CATEGORY_ERROR: ON_RESPONSE_MESSAGE(
    'Le produit',
    'ajouté avec',
    false
  ),
  ADD_CATEGORY_INTO_PRODUCT_SUCCESS: ON_RESPONSE_MESSAGE(
    'La catégorie',
    'ajoutée avec',
    true
  ),
  ADD_CATEGORY_INTO_PRODUCT_ERROR: ON_RESPONSE_MESSAGE(
    'La catégorie',
    'ajoutée avec',
    false
  ),
  ADD_GO_INTO_PRODUCT_SUCCESS: ON_RESPONSE_MESSAGE(
    `Le groupe d'option`,
    'ajoutée avec',
    true
  ),
  ADD_GO_INTO_PRODUCT_ERROR: ON_RESPONSE_MESSAGE(
    `Le groupe d'option`,
    'ajoutée avec',
    false
  )
};

export const UPDATE_MESSAGE = {
  CATALOG_SUCCESS: ON_RESPONSE_MESSAGE('Le catalogue', 'mis à jour avec', true),
  CATALOG_ERROR: ON_RESPONSE_MESSAGE('Le catalogue', 'mis à jour avec', false),
  PRODUCT_SUCCESS: ON_RESPONSE_MESSAGE('Le produit', 'mis à jour avec', true),
  PRODUCT_ERROR: ON_RESPONSE_MESSAGE('Le produit', 'mis à jour avec', false),
  CATEGORY_SUCCESS: ON_RESPONSE_MESSAGE(
    'La catégorie',
    'mis à jour avec',
    true
  ),
  CATEGORY_ERROR: ON_RESPONSE_MESSAGE('La catégorie', 'mis à jour avec', false),
  GROUP_OPTION_SUCCESS: ON_RESPONSE_MESSAGE(
    `Le groupe d'option`,
    'mis à jour avec',
    true
  ),
  GROUP_OPTION_ERROR: ON_RESPONSE_MESSAGE(
    `Le groupe d'option`,
    'mis à jour avec',
    false
  ),
  DELI_ZONE_SUCCESS: ON_RESPONSE_MESSAGE(
    'La zone de livraison',
    'mise à jour avec',
    true
  ),
  DELI_ZONE_ERROR: 'Échec de la mise à jour de la zone',
  PREP_TIME_SUCCESS: ON_RESPONSE_MESSAGE(
    'Le temps de préparation',
    'mis à jour avec',
    true
  ),
  PREP_TIME_ERROR: ON_RESPONSE_MESSAGE(
    'Le temps de préparation',
    'mis à jour avec',
    false
  ),
  DELI_GENERAL_SUCCESS: ON_RESPONSE_MESSAGE(
    'La configuration ',
    'mise à jour avec',
    true
  ),
  DELI_GENERAL_ERROR: ON_RESPONSE_MESSAGE(
    'La configuration ',
    'mise à jour avec',
    false
  ),
  DELI_TOGGLE_ERROR: 'Échec de la mise à jour bascule',
  ENABLED_SHOW: ON_TOGGLE_MESSAGE('Enabled', true),
  ENABLED_HIDE: ON_TOGGLE_MESSAGE('Enabled', false),
  ENABLED_ERROR: ON_RESPONSE_MESSAGE('Enabled', 'mis à jour avec', false),
  DELIVERY_TOGGLE_ON: ON_TOGGLE_MESSAGE('Livraison', true),
  DELIVERY_TOGGLE_OFF: ON_TOGGLE_MESSAGE('Livraison', false),
  OPTION_SUCCESS: ON_RESPONSE_MESSAGE(`L'option`, 'mise à jour avec', true),
  OPTION_ERROR: ON_RESPONSE_MESSAGE(`L'option`, 'mise à jour avec', false),
  UPDATE_MERCHANT_IMAGE_SUCCESS: ON_RESPONSE_MESSAGE(
    'Votre photo de',
    'mise à jour avec',
    true
  ),
  UPDATE_MARCHANT_IMAGE_ERROR: ON_RESPONSE_MESSAGE(
    'Votre photo de couverture',
    'mise à jour avec',
    false
  ),
  UPDATE_MERCHANT_INFO_SUCCESS: `Vos informations ont été mise à jour avec succès`,
  UPDATE_MERCHANT_INFO_ERROR: `Vos informations ont été mise à jour avec échec`,
  UPDATE_PAYMENT_METHOD_SUCCESS: ON_RESPONSE_MESSAGE(
    'Méthode de paiement',
    'mis à jour avec',
    true
  ),
  UPDATE_PAYMENT_METHOD_ERROR: ON_RESPONSE_MESSAGE(
    'Méthode de paiement',
    'mis à jour avec',
    false
  )
};

export const DELETE_MESSAGE = {
  CATALOG_SUCCESS: ON_RESPONSE_MESSAGE('Le catalogue', 'supprimé avec', true),
  CATALOG_ERROR: ON_RESPONSE_MESSAGE('Le catalogue', 'supprimé avec', false),
  PRODUCT_SUCCESS: ON_RESPONSE_MESSAGE('Le produit', 'supprimé avec', true),
  PRODUCT_ERROR: ON_RESPONSE_MESSAGE('Le produit', 'supprimé avec', false),
  CATEGORY_SUCCESS: ON_RESPONSE_MESSAGE('La catégorie', 'supprimé avec', true),
  CATEGORY_ERROR: ON_RESPONSE_MESSAGE('La catégorie', 'supprimé avec', false),
  GROUP_OPTION_SUCCESS: ON_RESPONSE_MESSAGE(
    `Le groupe d'option`,
    'supprimé avec',
    true
  ),
  GROUP_OPTION_ERROR: ON_RESPONSE_MESSAGE(
    `Le groupe d'option`,
    'supprimé avec',
    false
  ),
  DELI_ZONE_SUCCESS: ON_RESPONSE_MESSAGE(
    'La zone de livraison',
    'supprimée avec',
    true
  ),
  DELI_ZONE_ERROR: 'Fail to deleted zone',
  REMOVE_PRODUCT_FROM_CATEGORY_SUCCESS: ON_RESPONSE_MESSAGE(
    'Le produit',
    'retiré avec',
    true
  ),
  REMOVE_PRODUCT_FROM_CATEGORY_ERROR: ON_RESPONSE_MESSAGE(
    'Le produit',
    'retiré avec',
    false
  ),
  REMOVE_CATEGORY_FROM_PRODUCT_SUCCESS: ON_RESPONSE_MESSAGE(
    'La catégorie',
    'retiré avec',
    true
  ),
  REMOVE_CATEGORY_FROM_PRODUCT_ERROR: ON_RESPONSE_MESSAGE(
    'La catégorie',
    'retiré avec',
    false
  ),
  REMOVE_GO_FROM_PRODUCT_SUCCESS: ON_RESPONSE_MESSAGE(
    `Le groupe d'option`,
    'retiré avec',
    true
  ),
  REMOVE_GO_FROM_PRODUCT_ERROR: ON_RESPONSE_MESSAGE(
    `Le groupe d'option`,
    'retiré avec',
    false
  ),
  OPTION_SUCCESS: ON_RESPONSE_MESSAGE(`L'option`, 'supprimée avec', true),
  OPTION_ERROR: ON_RESPONSE_MESSAGE(`L'option`, 'supprimée avec', false)
};

export const SIZE_IS_TOO_BIG =
  'La taille est supérieure à 10 Mo, veuillez télécharger à nouveau !';

export const CROP_IMAGE_FIRST = `Devrait d'abord recadrer l'image`;
export const FAILED_CROP_IMAGE = `Échec du recadrage de l'image`;

export const REQUEST_EMAIL_MESSAGE = {
  ERROR: `L'e-mail est introuvable. Veuillez entrer un autre email et réessayer`
};

export const LOGIN_MESSAGE = {
  SUCCESS: 'Connexion réussie',
  ERROR: 'Le mot de passe saisi est incorrect'
};

export const VERIFY_OTP_MESSAGE = {
  SUCCESS: ON_RESPONSE_MESSAGE('Le code saisi', 'validé avec', true),
  ERROR: `Le code saisi n'est pas valide`
};

export const RESET_PASSWORD_MESSAGE = {
  SUCCESS: ON_RESPONSE_MESSAGE('Votre mot de passe', 'mis à jour avec', true),
  ERROR: ON_RESPONSE_MESSAGE('Votre mot de passe', 'mis à jour avec', false)
};

export const RESEND_OTP_MESSAGE = `L'OTP a été envoyé à nouveau à votre adresse e-mail`;

export const DONT_DATA_MESSAGE = (item: string) =>
  `Vous n'avez actuellement aucun ${item}`;

export const EMAIL_INVALID_MESSAGE = `L'adresse e-mail saisi n'est pas valide`;

export const PASSWORD_INVALID_MESSAGE = `Le mot de passe doit contenir au minimum 8 caractères, à savoir : au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre.`;

export const TITLE_CONFIRM_IMPORT_PRODUCT_MESSAGE =
  'Souhaitez-vous importer cette liste de produit ?';

export const IMPORT_PRODUCT_ERROR_MESSAGE = `Échec de l'importation. Merci de réessayer ou d'importer un autre fichier.`;

export const REFERENCE_EXISTS_LINE_ONE_MESSAGE = `La référence saisit pour ce produit existe déjà dans le système.`;

export const REFERENCE_EXISTS_LINE_TWO_MESSAGE = `Veuillez s'il vous plaît modifier la référence.`;
