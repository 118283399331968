import React from 'react';
import {
  BlockLeftLayout,
  BlockRightLayout,
  CardInformationOperationCostWrapper
} from './CardInformationOperationCost.style';
import { InvoicesInformationOperationCostRender } from 'types/InvoicesMerchantDetails';
import Logo from '../../../../../../../assets/Logo/Logo.png';

const CardInformationOperationCost = ({
  invoiceNumber,
  invoiceDate,
  mainName,
  address,
  vatNumber,
  invoicePeriod,
  completedOrders,
  typeOfInvoice
}: InvoicesInformationOperationCostRender) => {
  return (
    <CardInformationOperationCostWrapper>
      <BlockLeftLayout>
        <div className="mainName">{mainName}</div>
        <div className="inforWrapper">
          <div className="address">{address}</div>
        </div>
        <div className="vatWrapper">
          <div className="vatLabel">
            <div className="vatNumber">Numéro de TVA</div>
          </div>
          <div className="vatLabel">
            <div className="vatNumber vatNumber-value">{vatNumber}</div>
          </div>
        </div>
        <div className="leftBottomWapper">
          <div className="leftBottom">
            <div className="labelBottom">Numéro de Facture</div>
            <div className="labelBottom">Date de la facture</div>
            <div className="labelBottom">Période de facturation</div>
            <div className="labelBottom">Commandes terminées</div>
            <div className="labelBottom">Type de facture</div>
            <div className="labelBottom">Date de paiement des revenues</div>
          </div>
          <div className="leftBottom">
            <div className="valueBottom">{invoiceNumber}</div>
            <div className="valueBottom">{invoiceDate}</div>
            <div className="valueBottom">{invoicePeriod}</div>
            <div className="valueBottom">{completedOrders}</div>
            <div className="valueBottom">{typeOfInvoice}</div>
            <div className="valueBottom">{invoiceDate}</div>
          </div>
        </div>
      </BlockLeftLayout>
      <BlockRightLayout>
        <img src={Logo} alt="" />
      </BlockRightLayout>
    </CardInformationOperationCostWrapper>
  );
};

export default CardInformationOperationCost;
