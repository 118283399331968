import React from 'react';
import { ProductOptionsForm } from 'components/ProductOptionsLayout';
import { ProductOptionFormParamsV3 } from 'types/ProductOption';
import { useMutation, useQueryClient } from 'react-query';
import Loader from 'components/Loader';
import { CREATE_MESSAGE } from 'const/Message';
import { handleError, handleSuccess } from 'utils';
import { useHistory } from 'react-router-dom';
import { createProductOptionV2 } from 'services/productOptionService';

export default function OptionCreate() {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(createProductOptionV2);
  function handleCreate(data: ProductOptionFormParamsV3) {
    const dataRequest = { ...data, isOption: true };
    mutate(dataRequest, {
      onSuccess: () => {
        handleSuccess(CREATE_MESSAGE.OPTION_SUCCESS);
        queryClient.invalidateQueries('options');
        queryClient.invalidateQueries('getListGroupOptions');
        history.push('/menu/options');
      },
      onError: (error: any) => {
        handleError(error.response.status, CREATE_MESSAGE.OPTION_ERROR);
      }
    });
  }
  return (
    <React.Fragment>
      <ProductOptionsForm mode="create" onCreate={handleCreate} />
      {isLoading && <Loader />}
    </React.Fragment>
  );
}
