import styled from 'styled-components';
import { ContentPadding } from 'styles/paymentParams-tab';

export const BankInfoWrapper = styled(ContentPadding)`
  flex-grow: 1;
`;

export const FieldWrapper = styled.div`
  display: flex;
  margin-bottom: 2.6rem;

  .col-name {
    min-width: 18rem;
    margin-right: 8rem;
  }

  .col-val {
    flex-grow: 1;
  }
`;
