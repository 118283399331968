import styled from 'styled-components';
import { COLORS } from 'styles/variables';
import DisplayText from 'components/ui-kits/DisplayText';
import Button from 'components/ui-kits/Button';

export const CardItem = styled.div`
  position: relative;
  border: 1px solid ${COLORS.GREY_7};
  border-radius: 0.7rem;
  background-color: ${COLORS.WHITE};
  margin-bottom: 2.8rem;

  &.default {
    .infoGO {
      display: flex;
      align-items: center;
    }
    .mandatory {
      align-self: center;
    }
  }

  &.config {
    .infoGO {
      padding-left: 2.8rem;
      margin-right: 1rem;
      p {
        padding: 0;
        margin: 0;
      }
      p:first-child {
        font-size: 2rem;
      }
      p:last-child {
        font-size: 1.2rem;
      }
    }
    .mandatory {
      align-self: flex-end;
      font-size: 1.2rem;
      margin-right: 1.5rem;
    }
  }
`;
export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 2.8rem;
  border-bottom: 1px solid ${COLORS.GREY_4};
`;
export const NameCategory = styled(DisplayText)`
  padding-left: 2.8rem;
  margin-right: 1rem;
`;

export const CollapseIcon = styled.div`
  transform: rotate(0);
  transition: all 0.4s;
  cursor: pointer;
  svg {
    fill: ${COLORS.BLACK_2};
  }
  &.active {
    transform: rotate(180deg);
    transition: all 0.4s;
  }
`;
export const CardHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;
export const CardHeaderRight = styled.div`
  display: flex;
  align-items: center;
`;
export const GripLines = styled.div`
  position: relative;
  width: 24px;
  height: 3px;
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 3px;
    background-color: ${COLORS.GREY_1};
  }
  &::before {
    top: -3px;
  }
  &::after {
    top: 3px;
  }
`;
export const MandatoryStyle = styled(DisplayText)`
  color: ${COLORS.GREY_1};
  margin-right: 40px;
`;

export const CloseIconStyle = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  z-index: 2;
  margin-right: 0 !important;

  padding: 0;
  width: 30px;
  height: 30px;
  min-width: auto;
  border-radius: 50%;
  background-color: ${COLORS.BLACK_3};
  svg {
    width: 20px;
    margin-right: 0;
    fill: ${COLORS.WHITE} !important;
  }
`;
