import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from 'styles/variables';
interface IProps {
  register?: any;
  name: string;
  id: string;
  children: React.ReactNode;
  isChildLeft?: boolean;
  checked?: boolean;
  defaultValue?: boolean;
  falseColor?: 'green';
  disabled?: boolean;
  switchSize?: switchSize;
  handleChange?(params?: any): void;
}

type switchSize = 'medium' | 'small';

const CheckboxInput = styled.input<{ switchSize: switchSize }>`
  display: none;
  &:checked + label {
    span {
      background-color: ${COLORS.RED};
      ${({ switchSize }) => sizeActive[switchSize]}
    }
  }
  &:checked + div:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.RED};
    color: ${COLORS.WHITE};
  }
`;

const sizeTrack = {
  small: css`
    width: 4.8rem;
    padding: 0 0.3rem;
    height: 2.4rem;
    border-radius: 2.4rem;
  `,
  medium: css`
    width: 7.7rem;
    padding: 0 0.6rem;
    height: 4rem;
    border-radius: 2rem;
  `
};
const sizeThumb = {
  small: css`
    width: 1.8rem;
    height: 1.8rem;
    border: 1px solid ${COLORS.WHITE};
    border-radius: 50%;
    transform: translateX(0);
  `,
  medium: css`
    width: 3.2rem;
    height: 3.2rem;
    border: 2px solid ${COLORS.WHITE};
    border-radius: 50%;
    transform: translateX(0);
  `
};
const sizeActive = {
  small: css`
    transform: translateX(130%);
  `,
  medium: css`
    transform: translateX(100%);
  `
};

const SwitchTrack = styled.label<{ switchSize: switchSize }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  border: none;
  background-color: ${COLORS.GREY_5};
  cursor: pointer;
  ${({ switchSize }) => sizeTrack[switchSize]}
`;

const SwitchThumb = styled.span<{
  falseColor?: 'green';
  switchSize: switchSize;
}>`
  display: inline-block;
  background-color: ${(props) =>
    props.falseColor ? COLORS.GREEN_1 : COLORS.GREY_1};
  transition: all 0.15s ease;
  cursor: pointer;
  ${({ switchSize }) => sizeThumb[switchSize]}
`;

const CheckboxSwitch = ({
  register,
  name,
  id,
  children,
  isChildLeft = false,
  falseColor,
  disabled,
  switchSize = 'medium',
  handleChange
}: IProps) => {
  const registerField = register?.(name);
  return (
    <>
      {isChildLeft && children}
      <CheckboxInput
        switchSize={switchSize}
        type="checkbox"
        id={id}
        {...registerField}
        name={name}
        disabled={disabled}
        onChange={(e) => {
          registerField.onChange(e);
          handleChange?.(e);
        }}
      />
      <SwitchTrack htmlFor={id} switchSize={switchSize}>
        <SwitchThumb falseColor={falseColor} switchSize={switchSize} />
      </SwitchTrack>
      {!isChildLeft && children}
    </>
  );
};

export default CheckboxSwitch;
