import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';

const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body'
})`
  .Toastify__close-button {
    display: none;
  }
  .toast {
    min-height: 3.4rem;
    min-width: 22rem;
    padding: 0;
    margin: 0 auto;
  }
  .body {
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.4rem;
    padding: 1rem 1.2rem;
  }

  .Toastify__toast-theme--light.Toastify__toast--success {
    background-color: ${COLORS.GREEN_1};
    .body {
      color: ${COLORS.WHITE};
    }
  }

  .Toastify__toast-theme--light.Toastify__toast--error {
    background-color: ${COLORS.WHITE};
    .body {
      color: ${COLORS.BLACK_3};
    }
  }

  .icon-error {
    width: 1.6rem;
    height: 1.6rem;
    fill: red;
  }
`;

const ToastMessage = () => {
  return (
    <StyledToastContainer
      position="top-center"
      hideProgressBar
      pauseOnHover={false}
      limit={1}
    />
  );
};

export default ToastMessage;
