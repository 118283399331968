import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';

export const TableCustomerWrapper = styled.div`
  padding: 0 4.6rem;
  background-color: ${COLORS.WHITE};
  > div {
    border: none;
  }
  .col-border {
    border-top: 1px solid ${COLORS.BLACK_2};
    padding: 1rem 0;
  }
  thead {
    tr {
      th {
        padding: 0 0 0.5rem !important;
        color: ${COLORS.GREY_6};
        font-size: ${FONT_SIZE.L};
        border-bottom: 1px solid ${COLORS.BLACK_2} !important;
        text-align: center !important;

        &:first-of-type {
          text-align: left !important;
          width: 35%;
        }

        &:last-child {
          text-align: right !important;
        }
      }
    }
  }
  .totalAmount {
    color: ${COLORS.GREY_12};
    font-size: ${FONT_SIZE.L};
    text-align: center;
    &.bold {
      font-weight: ${FONT_WEIGHT.BOLD};
    }
  }
  .totalPrice {
    text-align: right;
  }
  .totalRefund {
    div {
      text-align: right;
      font-size: 1.8rem;
      color: ${COLORS.GREY_6};
      font-weight: ${FONT_WEIGHT.BOLD};
      margin: 2rem 0;
    }
    &-item span:last-child {
      display: inline-block;
      width: 10rem;
    }
  }
  .total {
    td {
      color: #ff6f61;
    }
  }
  .total-wrapper {
    td {
      padding: 1rem 0;
      vertical-align: initial;
    }
    &__title {
      font-size: 1.8rem;
      color: #727883;
      text-align: right;
    }
  }
  .footer {
    td {
      padding-top: 35.2rem;
      text-align: center;
      font-size: ${FONT_SIZE.M};

      .footerContent {
        padding: 0.6rem 0;
        color: ${COLORS.GREY_10};
      }
    }
  }
`;
