import React from 'react';
import { useLocation } from 'react-router-dom';
import MerchantCustomer from './MerchantCustomer';
import MerchantOperationCost from './MerchantOperationCost';
import MerchantSubscriptionFee from './MerchantSubscriptionFee';

const InvoicesMerchantDetail = () => {
  /* use useLocation read data */
  const location = useLocation<any>();
  const currentInvoice = location.state;

  if (currentInvoice.type === `Commerçant pour le client`) {
    return <MerchantCustomer onId={currentInvoice.id} />;
  }
  if (currentInvoice.type === `Frais de service Achil`) {
    return <MerchantOperationCost onId={currentInvoice.id} />;
  }
  if (currentInvoice.type === `Abonnement Achil`) {
    return <MerchantSubscriptionFee onId={currentInvoice.id} />;
  }

  return <div>Opps! Page is not found</div>;
};

export default InvoicesMerchantDetail;
