import React, { useState, useCallback, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import Cropper from 'react-easy-crop';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Modal from 'components/Modal';
import { ModalContent, ModalHeader, DropdownStyle } from './CropImage.style';
import DisplayText from 'components/ui-kits/DisplayText';
import Button from 'components/ui-kits/Button';
import { handleError, getCroppedImg } from 'utils';
import ButtonIcon from 'components/ui-kits/ButtonIcon';
import { CropIcon } from 'components/ui-kits/Icon';
import { IconNames } from 'components/ui-kits/Icon/Icon';
import { FAILED_CROP_IMAGE } from 'const/Message';
import { DropdownList } from 'components/ui-kits/Form/Dropdown';
import { ASPECT_RATIO } from 'const/Const';

interface CropImage {
  srcImage: string;
  onCloseCropImage(): void;
  file: File[];
  onModeSave?(fd: any): void;
  mode: 'info' | 'product' | 'coverImage';
}

const CropImage = ({
  srcImage,
  file,
  onCloseCropImage,
  onModeSave,
  mode
}: CropImage) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [fileImageCrop, setFileImageCrop] = useState<File | null>(null);
  const [aspect, setAspect] = useState<number>();
  const [whImage, setWHImage] = useState<number[]>([]);

  const { control, setValue } = useForm();

  const watchAspectRatio = useWatch({
    control,
    name: 'aspectRatio'
  });

  useEffect(() => {
    const img = new Image();
    img.src = srcImage;
    img.onload = (e: any) => {
      ASPECT_RATIO.unshift({
        label: 'Plein écran',
        value: e.target.width / e.target.height
      });
      setValue('aspectRatio', ASPECT_RATIO[0]);
      setWHImage([e.target.width, e.target.height]);
      setAspect(e.target.width / e.target.height);
    };
    return () => {
      ASPECT_RATIO.shift();
    };
  }, [srcImage, setValue]);

  useEffect(() => {
    if (whImage.length) {
      setAspect(watchAspectRatio.value);
    }
  }, [watchAspectRatio, whImage]);

  const onSave = () => {
    if (mode === 'info') {
      const fd = new FormData();
      if (fileImageCrop) {
        fd.append('file', fileImageCrop);
      } else {
        fd.append('file', file[0]);
      }
      onModeSave?.(fd);
      return;
    }

    if (mode === 'coverImage') {
      const fd = new FormData();
      if (fileImageCrop) {
        fd.append('mobileFile', fileImageCrop);
      } else {
        fd.append('mobileFile', file[0]);
      }
      onModeSave?.(fd);
      return;
    }

    if (mode === 'product') {
      if (fileImageCrop) {
        onModeSave?.(fileImageCrop);
      } else {
        onModeSave?.(file[0]);
      }
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(srcImage, croppedAreaPixels);
      fetch(croppedImage)
        .then((res) => res.blob())
        .then((blob) => {
          const convertFile = new File([blob], file[0].name, {
            type: file[0].type
          });
          setFileImageCrop(convertFile);
        });
      setCroppedImage(croppedImage);
    } catch (e) {
      handleError(undefined, FAILED_CROP_IMAGE);
      console.error(e);
    }
  }, [croppedAreaPixels, srcImage, file]);

  const actionZoom = (point: number) => {
    if (zoom === 1 && point < 0) {
      setZoom(1);
      return;
    }
    if (zoom === 3 && point > 0) {
      setZoom(3);
      return;
    }
    setZoom(zoom + point);
  };

  const setCropImage = () => {
    showCroppedImage();
    setZoom(1);
  };

  return (
    <>
      <Modal close={onCloseCropImage} minWidth="89.6rem">
        <ModalHeader>
          <div className="header">
            <DisplayText
              label={`Changez votre photo de ${
                mode === 'info' ? 'profil' : 'couverture'
              }`}
              fontSize="XL"
              fontWeight="BOLD"
            />
            <Button
              label="Enregistrer"
              variant="primary"
              isFluid={false}
              onClick={onSave}
              fontWeight="BOLD"
              letterSpacing="S"
            />
          </div>
        </ModalHeader>
        <ModalContent>
          <div className="label">Glisser l&#39;image pour la positionner</div>
          <div className="container-image">
            <Cropper
              image={croppedImage || srcImage}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              aspect={aspect}
            />
          </div>
          <div className="action">
            <ButtonIcon
              icon={IconNames.MinusStrokeIcon}
              widthIcon={16}
              onClick={() => actionZoom(-0.020001220703125)}
            />
            <Slider
              className="slider"
              min={1}
              max={3}
              step={0.020001220703125}
              onChange={setZoom}
              value={zoom}
            />
            <ButtonIcon
              icon={IconNames.PlusStrokeIcon}
              widthIcon={16}
              onClick={() => actionZoom(0.020001220703125)}
            />
            <DropdownStyle>
              <DropdownList
                options={ASPECT_RATIO}
                isFluid={false}
                name="aspectRatio"
                control={control}
              />
            </DropdownStyle>

            <Button
              label="Rogner l'image"
              isFluid={false}
              className="custom-button"
              icon={<CropIcon />}
              isUpperCase={false}
              onClick={setCropImage}
            />
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CropImage;
