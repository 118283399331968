import React, { useCallback } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

interface IProps {
  children: React.ReactNode;
  onParamsDragEnd?: (params: any, data?: any) => void;
  droppableId: string;
}

const Dnd = ({ children, onParamsDragEnd, droppableId }: IProps) => {
  const onDragEnd = useCallback(
    (params: any) => {
      onParamsDragEnd?.(params);
    },
    [onParamsDragEnd]
  );
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Dnd;
