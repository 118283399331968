import { COLORS, MEDIA_BREAKPOINT, SPACING_PRODUCT_OPTIONS } from './variables';
import styled from 'styled-components';

const HeightFilter = '4rem';

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Wrapper = styled(Flex)`
  width: 100%;
  margin: ${SPACING_PRODUCT_OPTIONS.FILTER};

  .hide {
    display: none;
  }
`;

export const WrapperRight = styled(Flex)`
  width: 73%;
  a {
    margin: 0;
  }

  button {
    height: ${HeightFilter};
    min-height: 38px;
    padding: 0 1.7rem;
  }

  .import-product {
    margin-left: 1rem;

    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 4rem;
      min-height: 38px;
      padding: 0 1.7rem;
      background-color: ${COLORS.RED};
      font-size: 1.4rem;
      text-transform: uppercase;
      font-weight: 700;
      border-radius: 0.7rem;
      color: ${COLORS.WHITE};
    }
  }
`;

export const SortStyle = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.2rem;
  color: ${COLORS.BLACK_2};
  & > div {
    min-width: 21rem;
  }
  .rounded__control {
    height: ${HeightFilter};
  }
  .rounded__single-value {
    margin-left: 1.7rem;
  }
`;

export const SearchStyle = styled.div`
  position: relative;
  flex-grow: 1;
  margin: 0 2.8rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.2rem;

  .default__control {
    height: ${HeightFilter};
    font-size: 1.6rem;
    padding-right: 15.3rem;
  }

  .search-icon {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    top: 0;
    background-color: ${COLORS.RED};
    border-radius: 0 0.7rem 0.7rem 0;

    &.active {
      color: ${COLORS.WHITE};
      box-shadow: 0.2rem 0.3rem 0.4rem 0.2rem rgb(0 0 0 / 10%);
    }

    &.disabled {
      cursor: default;
      opacity: 0.7;
    }

    svg {
      width: 2.4rem;
      height: 2.4rem;

      path {
        fill: ${COLORS.WHITE};
      }
    }
  }

  @media ${MEDIA_BREAKPOINT.DESKTOP_M} {
    .default__control {
      height: 4.8rem;
    }
  }

  @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
    width: 30%;
    .default__control {
      height: 4.8rem;
    }
  }
`;
