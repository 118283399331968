import React from 'react';
import styled from 'styled-components';
import { FONT_WEIGHT, FONT_SIZE, COLORS } from 'styles/variables';

interface ILabel {
  forId?: string;
  children: React.ReactNode;
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
  fontWeight?: keyof typeof FONT_WEIGHT;
  fontSize?: keyof typeof FONT_SIZE;
}

const LabelStyle = styled.label<
  Required<Pick<ILabel, 'fontSize' | 'textTransform' | 'fontWeight'>>
>`
  display: block;
  color: ${COLORS.GREY_1};
  font-size: ${({ fontSize }) => FONT_SIZE[fontSize]};
  font-weight: ${({ fontWeight }) => FONT_WEIGHT[fontWeight]};
  line-height: 1.9rem;
  margin-bottom: 1rem;
  text-transform: ${({ textTransform }) => textTransform};
`;

const Label = ({
  forId,
  children,
  textTransform = 'capitalize',
  fontSize = 'M',
  fontWeight = 'REGULAR'
}: ILabel) => {
  return (
    <LabelStyle
      htmlFor={forId}
      textTransform={textTransform}
      fontSize={fontSize}
      fontWeight={fontWeight}>
      {children}
    </LabelStyle>
  );
};

export default Label;
