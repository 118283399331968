import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Search } from 'components/ui-kits/Form/Dropdown';
import { OptionType } from 'components/ui-kits/Form/Dropdown/Search';
import {
  PickedItem,
  OptionsWrap,
  OptionLabel,
  OptionPrice,
  DndZone,
  LeftWrapper,
  RightWrapper,
  SelectedItemWrapper,
  CreatOptionStyle,
  SearchAddItemWrapper
} from './SelectDnd.style';
import type { ProductOptionListData } from 'types/Products';
import type { IGroupOptions, ListOptionsSelected } from 'types/GroupOptions';
import {
  OptionTooltip,
  OptionTooltipItem
} from 'components/ui-kits/OptionTooltip';
import Icon, { IconNames } from 'components/ui-kits/Icon/Icon';
import DisplayText from 'components/ui-kits/DisplayText';
import { formatDollar, handleError, handleSuccess } from 'utils';
import Dnd from 'components/Dnd';
import { Draggable } from 'react-beautiful-dnd';
import ModalPrompt from 'components/ui-kits/ModalPrompt';
import { CONFIRM_MESSAGE, DELETE_MESSAGE } from 'const/Message';
import Loader from 'components/Loader';
import { deleteProduct } from 'services/productService';
import type { ControllerRenderProps, FieldValues } from 'react-hook-form';

interface SelectDndProps {
  setupOptionForm(item?: ListOptionsSelected): void;
  optionsSelected: ListOptionsSelected[];
  editItem?: IGroupOptions;
  field: ControllerRenderProps<FieldValues, 'optionsSelected'>;
  handleChangeSearch: (data: ListOptionsSelected[]) => void;
  handleRemove: (index: number | undefined) => void;
  productOptionListData: ProductOptionListData | undefined;
  isFetching: boolean;
}

function SelectDnd({
  setupOptionForm,
  optionsSelected,
  field,
  handleChangeSearch,
  handleRemove,
  productOptionListData,
  isFetching
}: SelectDndProps) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [codeProductOption, setCodeProductOption] = useState('');
  const [idxOptionSelected, setIdxOptionSelected] = useState<number>();

  const queryClient = useQueryClient();

  const listIdOptionsSelected = optionsSelected.map((item) => item.id);
  const searchOptions = productOptionListData?.['hydra:member']
    .filter((item) => listIdOptionsSelected.indexOf(item.id) === -1)
    .map((item) => {
      return {
        id: item.id,
        name: item.name,
        price: item.price,
        unit: '€',
        originalItem: item,
        customPrice: item.customPrice ?? item.price
      };
    });

  const onDragEnd = (params: any) => {
    const srcI = params.source.index;
    const destI = params.destination?.index;
    if (params.destination) {
      const newOption: ListOptionsSelected[] = [...field.value];
      const [removedItem] = newOption.splice(srcI, 1);
      newOption.splice(destI, 0, removedItem);
      field.onChange(newOption);
    }
  };

  const { isLoading, mutate: onDeleteOption } = useMutation(
    ({ code, data }: Required<{ code: string; data: { enabled: boolean } }>) =>
      deleteProduct(code, data)
  );

  const handleDelete = (status: boolean) => {
    if (status) {
      onDeleteOption(
        { code: codeProductOption, data: { enabled: false } },
        {
          onSuccess: () => {
            handleRemove(idxOptionSelected);
            handleSuccess(DELETE_MESSAGE.OPTION_SUCCESS);
            queryClient.invalidateQueries('getGrOptionItem');
            queryClient.invalidateQueries('getGroupOptionsItem');
            queryClient.invalidateQueries('options');
            setConfirmDelete(false);
          },
          onError: (error: any) => {
            handleError(error.response.status, DELETE_MESSAGE.OPTION_ERROR);
            setConfirmDelete(false);
          }
        }
      );
    }
  };

  const openModalDelete = (code: string, idx: number) => {
    setCodeProductOption(code);
    setConfirmDelete(true);
    setIdxOptionSelected(idx);
  };

  return (
    <>
      <ModalPrompt
        title={CONFIRM_MESSAGE.DELETE_OPTION}
        confirmType="withTrashBin"
        isShow={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onConfirm={() => handleDelete(true)}></ModalPrompt>
      <SearchAddItemWrapper>
        <Search
          change={handleChangeSearch}
          options={searchOptions}
          placeholder="Ajoutez une option à ce groupe"
          isMulti={true}
          value={[]}
          variant="labelNPrice"
          formatOptionLabel={formatOptionLabel}
          classNameWrapper="search-item-wrapper"
          ComponentOnOpen={
            <CreatOptionStyle onMouseDown={() => setupOptionForm()}>
              + Créer une option
            </CreatOptionStyle>
          }
        />
      </SearchAddItemWrapper>
      {!!field.value.length && (
        <SelectedItemWrapper {...field}>
          <Dnd droppableId="droppableOptionItem" onParamsDragEnd={onDragEnd}>
            {(field.value as ListOptionsSelected[]).map((item, index) => (
              <Draggable
                draggableId={`draggable-${index}`}
                key={index}
                index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      boxShadow: snapshot.isDragging
                        ? '0 0 .4rem #666'
                        : 'none',
                      backgroundColor: snapshot.isDragging ? '#fff' : '#fff',
                      borderRadius: '7px'
                    }}>
                    <PickedItem key={item.name}>
                      <LeftWrapper>
                        <DndZone>
                          <Icon name={IconNames.GripIcon} />
                        </DndZone>
                        {item.name}
                      </LeftWrapper>
                      <RightWrapper>
                        <DisplayText
                          className="price"
                          label={`${formatDollar(item.customPrice)}${
                            item.unit
                          }`}
                          fontSize="M"
                          fontWeight="BOLD"
                          color="BLACK_2"
                        />
                        <OptionTooltip
                          component={
                            <>
                              <OptionTooltipItem
                                label="Modifier"
                                onClick={() => setupOptionForm(item)}
                              />
                              <OptionTooltipItem
                                label="Retirer"
                                className="list-option-item del"
                                onClick={() => handleRemove(index)}
                              />
                              <OptionTooltipItem
                                label="Supprimer"
                                className="list-option-item del"
                                onClick={() =>
                                  openModalDelete(item.originalItem.code, index)
                                }
                              />
                            </>
                          }
                        />
                      </RightWrapper>
                    </PickedItem>
                  </div>
                )}
              </Draggable>
            ))}
          </Dnd>
        </SelectedItemWrapper>
      )}
      {(isLoading || isFetching) && <Loader />}
    </>
  );
}

function formatOptionLabel(option: OptionType) {
  return (
    <OptionsWrap>
      <OptionLabel>{option.name}</OptionLabel>
      <OptionPrice>
        {formatDollar(option.price)}
        {option.unit}
      </OptionPrice>
    </OptionsWrap>
  );
}
export default SelectDnd;
