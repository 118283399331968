import React from 'react';

function ArrowDownIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0.8rem"
      height="0.8299rem"
      viewBox="0 0 8.09 8.299"
      {...props}>
      <path
        id="Down"
        d="M7.723,4.528l.411.411a.443.443,0,0,1,0,.628l-3.6,3.6a.443.443,0,0,1-.628,0l-3.6-3.6a.443.443,0,0,1,0-.628l.411-.411a.445.445,0,0,1,.635.007L3.48,6.768V1.445A.443.443,0,0,1,3.924,1h.593a.443.443,0,0,1,.445.445V6.768L7.088,4.536a.442.442,0,0,1,.635-.007Z"
        transform="translate(-0.176 -1)"
        fill="#f8420b"
      />
    </svg>
  );
}

export default ArrowDownIcon;
