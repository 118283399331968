import React from 'react';
import DisplayText from 'components/ui-kits/DisplayText';
import {
  CardReviewWrapper,
  Title,
  AverageRating,
  BlockCenter,
  ListProcessRating,
  ListStartWrapper,
  BlockListStar
} from './CardReviewStyle';
import ProcessRating from '../ProcessRating';
import { StarIcon } from 'components/ui-kits/Icon';
import { COLORS } from 'styles/variables';
import { Review } from 'types/Merchant';

const CardReview = ({ dataReview }: { dataReview: Review }) => {
  const totalRating = () => {
    return (
      dataReview.starCount.oneStar +
      dataReview.starCount.twoStars +
      dataReview.starCount.threeStars +
      dataReview.starCount.fourStars +
      dataReview.starCount.fiveStars
    );
  };
  const convertRatingToPer = (numbersOf: number) => {
    return `${(numbersOf * 100) / totalRating()}%`;
  };

  const convertPointRatingToPer = (point: number) => {
    return `${(point * 100) / 5}%`;
  };

  return (
    <CardReviewWrapper>
      <Title label="Avis sur le commerce" fontSize="XL" fontWeight="BOLD" />
      <DisplayText
        label={`(${dataReview.total} avis)`}
        fontSize="S"
        fontWeight="MEDIUM"
        color="GREY_1"
      />
      <BlockCenter>
        <div>
          <AverageRating>{dataReview.average}</AverageRating>
          <BlockListStar>
            <ListStartWrapper
              className="star-checked"
              style={{ width: convertPointRatingToPer(dataReview.average) }}>
              <StarIcon fill={COLORS.GREEN_1} />
              <StarIcon fill={COLORS.GREEN_1} />
              <StarIcon fill={COLORS.GREEN_1} />
              <StarIcon fill={COLORS.GREEN_1} />
              <StarIcon fill={COLORS.GREEN_1} />
            </ListStartWrapper>
            <ListStartWrapper>
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
            </ListStartWrapper>
          </BlockListStar>
        </div>
      </BlockCenter>
      <ListProcessRating>
        <ProcessRating
          labelStar="5"
          percentProcess={convertRatingToPer(dataReview.starCount.fiveStars)}
          numbersOfRating={dataReview.starCount.fiveStars}
        />
        <ProcessRating
          labelStar="4"
          percentProcess={convertRatingToPer(dataReview.starCount.fourStars)}
          numbersOfRating={dataReview.starCount.fourStars}
        />
        <ProcessRating
          labelStar="3"
          percentProcess={convertRatingToPer(dataReview.starCount.threeStars)}
          numbersOfRating={dataReview.starCount.threeStars}
        />
        <ProcessRating
          labelStar="2"
          percentProcess={convertRatingToPer(dataReview.starCount.twoStars)}
          numbersOfRating={dataReview.starCount.twoStars}
        />
        <ProcessRating
          labelStar="1"
          percentProcess={convertRatingToPer(dataReview.starCount.oneStar)}
          numbersOfRating={dataReview.starCount.oneStar}
        />
      </ListProcessRating>
    </CardReviewWrapper>
  );
};

export default CardReview;
