import { getDaysInMonth } from 'date-fns';
import jwt_decode from 'jwt-decode';

interface IDecodeJwt {
  exp: number;
  iat: number;
  merchant_id: number;
  merchant_slug: string;
  roles: string[];
  username: string;
}

export const formatDateMonthYear = 'dd/MM/yyyy';

export const MONTHS: { [key: string]: number } = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12
};

export const MONTHSINFRENCH: string[] = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
];

export const MONTHS_IN_FRENCH_SHORT: string[] = [
  'Janv.',
  'Févr.',
  'Mars',
  'Avr.',
  'Mai',
  'Juin',
  'Juill.',
  'Août',
  'Sept.',
  'Oct.',
  'Nov.',
  'Déc.'
];

export const decodeJwt = (token: string, option?: { [key: string]: any }) => {
  return jwt_decode<IDecodeJwt>(token, option);
};

export { handleError, handleSuccess } from './handleResponse';

export const replaceCharacter = (
  str: string,
  regex: string,
  newStr: string
) => {
  if (!str.includes('.') && !str.includes(',')) {
    str = str + '.00';
  }
  return str.replace(new RegExp(regex, 'g'), newStr);
};

export const compare = (
  arr: any,
  type: 'increase' | 'decrease',
  sortByField?: string
) => {
  return arr.sort((a: any, b: any) => {
    if (typeof a === 'number' && typeof b === 'number') {
      if (type === 'increase') {
        return a - b;
      } else {
        return b - a;
      }
    }
    if (typeof a === 'string' && typeof b === 'string') {
      if (type === 'increase') {
        return a.localeCompare(b);
      } else {
        return b.localeCompare(a);
      }
    }
    if (typeof a === 'object' && typeof b === 'object') {
      if (!sortByField) return;

      if (
        typeof a[sortByField] === 'number' &&
        typeof b[sortByField] === 'number'
      ) {
        if (type === 'increase') {
          return a[sortByField] - b[sortByField];
        } else {
          return b[sortByField] - a[sortByField];
        }
      }

      if (
        typeof a[sortByField] === 'string' &&
        typeof b[sortByField] === 'string'
      ) {
        if (type === 'increase') {
          return a[sortByField].localeCompare(b[sortByField]);
        } else {
          return b[sortByField].localeCompare(a[sortByField]);
        }
      }
    }
  });
};

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

export async function getCroppedImg(imageSrc: string, pixelCrop: any) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx: any = canvas.getContext('2d');

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As Base64 string
  return canvas.toDataURL('image/jpeg');
}

export function formatThousand(n: number) {
  if (String(n).length <= 3) {
    return `${n},000`;
  }
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function convertHMS(second: number) {
  const sec = parseInt(String(second), 10);
  let hours: number | string = Math.floor(sec / 3600);
  let minutes: number | string = Math.floor((sec - hours * 3600) / 60);
  let seconds: number | string = sec - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  if (hours === '00' && minutes === '00') {
    return `${seconds}s`;
  }

  if (hours === '00') {
    return `${minutes}m ${seconds}s`;
  }

  return `${hours}h ${minutes}m ${seconds}s`;
}

export function periodWeekly() {
  const currentDay = new Date();
  const day = currentDay.getDay() || 7;
  const first = currentDay.getDate() - day + 1;
  const last = first + 6;
  const firstDayThisWeek = new Date(currentDay.setDate(first));
  const lastDayThisWeek = new Date(new Date().setDate(last)); // apply for 2 month different

  return [firstDayThisWeek, lastDayThisWeek];
}

export function formatDollar(price: number) {
  return replaceCharacter(String((price / 100).toFixed(2)), '[.]', ',');
}

export function replaceDotToComma(data: string | number) {
  if (!data) return '';
  return data.toString().replace('.', ',');
}

export function downLoadPdfFile(dataPdf: any, nameFile: any) {
  const blob = new Blob([dataPdf], {
    type: 'application/pdf;charset=utf-8'
  });
  const fileURL = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = fileURL;
  downloadLink.download = nameFile;
  downloadLink.click();
}

export function downLoadFileNameInvoices(type: string, id: string) {
  switch (type) {
    case 'customer_invoice':
      return `Achil_Customer_invoice_${id}.pdf`;
    case 'merchant_subscription_invoice':
      return `Achil_Subscription_fee_${id}.pdf`;
    case 'merchant_operation_invoice':
      return `Achil_Operation_cost_${id}.pdf`;
    default:
      break;
  }
}

export function downLoadFileNameFinancialReport(
  startDate: string,
  endDate: string
) {
  return `Financial_Report_from_${startDate}_to_${endDate}.pdf`;
}

export function getDateInMonth(year: number, month: number) {
  return getDaysInMonth(new Date(year, month - 1));
}

export function formatDayOfWeek(day: string | number) {
  switch (day) {
    case typeof day === 'string' ? 'Mon' : 0:
      return 'Lundi';
    case typeof day === 'string' ? 'Tue' : 1:
      return 'Mardi';
    case typeof day === 'string' ? 'Wed' : 2:
      return 'Mercredi';
    case typeof day === 'string' ? 'Thu' : 3:
      return 'Jeudi';
    case typeof day === 'string' ? 'Fri' : 4:
      return 'Vendredi';
    case typeof day === 'string' ? 'Sat' : 5:
      return 'Samedi';
    case typeof day === 'string' ? 'Sun' : 6:
      return 'Dimanche';
    default:
      return '';
  }
}

export function removeEleDuplicate(arr: any, keyNeedRemove: string) {
  const result: any = [];
  arr.map((item: any) => {
    const check = result.some(
      (itemCheck: any) => itemCheck[keyNeedRemove] === item[keyNeedRemove]
    );
    if (!check) {
      result.push(item);
    }
    return item;
  });
  return result;
}

export function formatPercent(price: string) {
  if (!price.includes('.') && !price.includes(',')) {
    return price;
  }
  const formatPrice = Number(price).toFixed(2);
  return String(formatPrice).replace('.', ',');
}

export function convertTZ(date: string | Date, tzString: string) {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString
    })
  );
}

export function getTimeZoneRegion() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function compareSort(
  arr: any,
  type: 'increase' | 'decrease',
  fieldSort: string
) {
  return arr.sort((a: any, b: any) => {
    if (type === 'increase') {
      return a[fieldSort].localeCompare(b[fieldSort]);
    }
    if (type === 'decrease') {
      return b[fieldSort].localeCompare(a[fieldSort]);
    }
  });
}

export default function getInfoPageParams(currentURI: string, lastURI: string) {
  if (currentURI.indexOf('?') === -1 || lastURI.indexOf('?') === -1) return;
  const currentURIQueryString = currentURI.slice(
    currentURI.indexOf('?') + 1,
    currentURI.length
  );
  const lastURIQueryString = lastURI.slice(
    lastURI.indexOf('?') + 1,
    lastURI.length
  );
  const currentPage = getPageWithSearchParams(currentURIQueryString);
  const lastPage = getPageWithSearchParams(lastURIQueryString);
  return { currentPage, lastPage };
}

function getPageWithSearchParams(queryString: string) {
  const searchParams = new URLSearchParams(queryString);
  return Number(searchParams.get('page'));
}
