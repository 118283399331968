import React, { useEffect, useState } from 'react';
import ItemOrderDetail from './ItemOrderDetail';
import {
  ListOrderDetailWrapper,
  ListDetailBackground,
  ListDetailLayout
} from './ListOrderDetail.style';
import { IOrderDetail } from 'types/OrderList';
import { CloseIcon } from 'components/ui-kits/Icon';
import {
  convertTZ,
  formatDateMonthYear,
  formatDollar,
  getTimeZoneRegion
} from 'utils';
import { format } from 'date-fns';

const ListOrderDetail = ({
  orderNumber,
  dateAndTime,
  name,
  subTotalPrice,
  taxRevenues,
  operationCosts,
  items,
  refund,
  refundCost,
  deliveryFee,
  netRevenues,
  isClosePanelRight
}: IOrderDetail) => {
  const [isClose, setIsClose] = useState(false);
  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>();

  useEffect(() => {
    if (!dateAndTime) return;
    const region = getTimeZoneRegion();
    const newDateTimeZone = convertTZ(dateAndTime, region);
    const d = format(newDateTimeZone, formatDateMonthYear);
    const t = format(newDateTimeZone, 'HH:mm');
    setDate(d);
    setTime(t);
  }, [dateAndTime]);

  useEffect(() => {
    document.body.classList.add('not-scroll-body');
    return () => {
      document.body.classList.remove('not-scroll-body');
    };
  }, [isClose]);

  const handleCloseModal = () => {
    setIsClose(isClose);
    isClosePanelRight?.(false);
  };

  return (
    <ListOrderDetailWrapper>
      <ListDetailBackground onClick={handleCloseModal} />
      <ListDetailLayout>
        <div className="headWrapper">
          <div className="orderNumber">Numéro de commande {orderNumber}</div>
          <div className="closeButton" onClick={handleCloseModal}>
            <CloseIcon />
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th className="timeOrder">Date et heure</th>
              <th>
                <div className="timeWrapper">
                  <div className="hour">{time}</div>
                  <div className="day">{date}</div>
                </div>
              </th>
              <th className="name">Nom</th>
              <th className="dylanKose">{name}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="headerTitle" colSpan={3}>
                Produits
              </td>
              <td className="headerTitle qty">Qté.</td>
              <td className="headerTitle priceWrapper">Prix</td>
            </tr>
            {items?.length ? (
              items?.map((item: any) => (
                <ItemOrderDetail
                  key={item?.name}
                  mainItem={item?.name}
                  qty={item?.quantity}
                  price={`${formatDollar(item?.price)}€`}
                  subItem={item?.options?.map((subItem: any) => (
                    <div key={subItem?.name}>{subItem?.name}</div>
                  ))}
                  subQty={item?.options?.map((subQty: any) => (
                    <div key={subQty?.quantity}>{subQty?.quantity}</div>
                  ))}
                  subPrice={item?.options?.map((subPrice: any) => (
                    <div key={subPrice?.price}>{`${formatDollar(
                      subPrice?.price
                    )}€`}</div>
                  ))}
                />
              ))
            ) : (
              <tr>
                <td></td>
              </tr>
            )}
            <tr>
              <td colSpan={4}>
                <div className="totalLabel">Sous-total (TTC)</div>
                {deliveryFee ? (
                  <div className="totalLabel">Frais de livraison</div>
                ) : null}
                <div className="totalLabel">Total (TTC)</div>
                <div className="totalLabel">Frais de service</div>
                {refund && (
                  <>
                    <div className="totalLabel">Remboursement</div>
                    <div className="totalLabel">
                      Frais de remboursement Achil
                    </div>
                  </>
                )}
                <div className="totalLabel netPayment">Revenus net</div>
              </td>
              <td>
                <div className="totalPrice">
                  {formatDollar(subTotalPrice || 0)}€
                </div>
                {deliveryFee ? (
                  <div className="totalPrice">{formatDollar(deliveryFee)}€</div>
                ) : null}
                <div className="totalPrice">
                  {formatDollar(taxRevenues || 0)}€
                </div>
                <div className="totalPrice">
                  {formatDollar(operationCosts || 0)}€
                </div>
                {refund && (
                  <div className="totalPrice">{formatDollar(refund)}€</div>
                )}
                {refund && refundCost !== undefined && (
                  <div className="totalPrice">{formatDollar(refundCost)}€</div>
                )}

                <div className="totalPrice netPayment">
                  {formatDollar(netRevenues || 0)}€
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ListDetailLayout>
    </ListOrderDetailWrapper>
  );
};

export default ListOrderDetail;
