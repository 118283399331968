import styled from 'styled-components';

export const DeliveryMapWrapper = styled.div`
  width: 100%;
  height: 60rem;
  position: relative;
  .toolbar {
    position: absolute;
    width: 100%;
    height: 4.5rem;
    top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tool-btn {
    margin: 1rem;
  }
  .drawing-toolbar {
    /* position: absolute; */
    /* top: 0; */
    /* left: 50%; */
  }
  .action-toolbar {
    position: absolute;
    top: 0;
    right: 6rem;
    display: flex;
  }
`;
export const DeliveryZoneWrapper = styled.div`
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
  display: flex;
  align-items: flex-start;
  position: relative;
`;
