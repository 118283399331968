import React, { useEffect, useState } from 'react';
import ListLayout from 'components/ListLayout';
import styled from 'styled-components';
import DateRange from './DateRange';
import SummaryTable from './SummaryTable/SummaryTable';
import ListOrder from './ListOrderTable/ListOrder';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import Loader from 'components/Loader';
import ListOrderTable from './ListOrderTable/ListOrderTable';
import { periodWeekly } from 'utils';
import { revenuesApiV2 } from 'services/revenuesService';

const PaymentRevenuesWrapper = styled.div`
  margin: 0;
`;

const PaymentRevenues = () => {
  const [startEnd, setStartEnd] = useState<any>(periodWeekly);
  const startEndDate = (date: any) => {
    setStartEnd([date[0], date[1]]);
  };
  const startDate = format(startEnd[0], 'yyyy-MM-dd');
  const endDate = format(startEnd[1], 'yyyy-MM-dd');
  const [isOpenPanelRight, setIsOpenPanelRight] = useState<boolean>(false);
  const [getId, setGetId] = useState<number>();

  const {
    isLoading: loadingData,
    data: dataRevenues,
    refetch
  } = useQuery('getListOrder', ({ signal }) =>
    revenuesApiV2({ startDate, endDate, signal })
  );

  useEffect(() => {
    refetch();
    /* eslint-disable */
  }, [startEnd]);

  return (
    <ListLayout>
      <PaymentRevenuesWrapper>
        <DateRange onData={startEndDate} />
        <SummaryTable onData={dataRevenues} />
        <ListOrder
          isOpenPanelRight={(status: boolean) => setIsOpenPanelRight(status)}
          passId={(id: number) => setGetId(id)}
          onData={dataRevenues}
        />
        {isOpenPanelRight && getId && (
          <ListOrderTable
            isClosePanelRight={(status) => setIsOpenPanelRight(status)}
            id={getId}
            onData={dataRevenues}
          />
        )}
      </PaymentRevenuesWrapper>
      {loadingData ? <Loader /> : null}
    </ListLayout>
  );
};

export default PaymentRevenues;
