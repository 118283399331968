import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  Control,
  FieldValues,
  UseFormSetValue,
  useForm,
  useWatch
} from 'react-hook-form';
import {
  CoverImage,
  EditAbsolute,
  FieldWrapper,
  InfoDetail,
  InfoImage,
  InfoWrapper,
  Name,
  TextMargin
} from './Info.style';
import { PhoneIcon, LocatorIcon, EditPenIcon } from 'components/ui-kits/Icon';
import ButtonIcon from 'components/ui-kits/ButtonIcon';
import { IconNames } from 'components/ui-kits/Icon/Icon';
import { Merchant } from 'types/Merchant';
import Label from 'components/ui-kits/Form/Label';
import CropImage from 'components/CropImage';
import Modal from 'components/Modal';
import EditInfo from '../EditInfo';
import { handleError, handleSuccess } from 'utils';
import { SIZE_IS_TOO_BIG, UPDATE_MESSAGE } from 'const/Message';
import Loader from 'components/Loader';
import {
  uploadImageCover,
  uploadImageProfileV2
} from 'services/merchantService';

interface IProps {
  dataMerchant: Merchant;
  merchantSlug: string;
}

const Info = ({ dataMerchant, merchantSlug }: IProps) => {
  const [isOpenEditInfo, setIsOpenEditInfo] = useState<boolean>(false);
  const { register, control, getValues, setValue } = useForm();
  const queryClient = useQueryClient();

  const { src, openModalCropImage, closeModalUpdateImage } = useUploadImage(
    control,
    setValue,
    'upload'
  );

  const {
    src: srcCoverImage,
    openModalCropImage: openModalCropCoverImage,
    closeModalUpdateImage: closeModalUpdateCoverImage
  } = useUploadImage(control, setValue, 'coverImage');

  function onSave(fd: any) {
    onUpdateImage(fd, {
      onSuccess: () => {
        queryClient.invalidateQueries('getMerchant');
        handleSuccess(UPDATE_MESSAGE.UPDATE_MERCHANT_IMAGE_SUCCESS);
        closeModalUpdateImage();
      },
      onError: (error: any) => {
        handleError(
          error.response.status,
          UPDATE_MESSAGE.UPDATE_MARCHANT_IMAGE_ERROR
        );
      }
    });
  }

  function onSaveCoverImage(fd: any) {
    updateCoverImage(fd, {
      onSuccess: () => {
        queryClient.invalidateQueries('getMerchant');
        handleSuccess(UPDATE_MESSAGE.UPDATE_MERCHANT_IMAGE_SUCCESS);
        closeModalUpdateCoverImage();
      },
      onError: (error: any) => {
        handleError(
          error.response.status,
          UPDATE_MESSAGE.UPDATE_MARCHANT_IMAGE_ERROR
        );
      }
    });
  }

  const { isLoading, mutate: onUpdateImage } = useMutation(
    (fd: Required<any>) => uploadImageProfileV2(fd)
  );

  const { isLoading: isUploadingCoverImage, mutate: updateCoverImage } =
    useMutation((fd: Required<any>) => uploadImageCover(fd));

  return (
    <>
      <InfoWrapper>
        <div
          style={{
            position: 'absolute',
            height: '25rem',
            width: '100%',
            top: 0,
            left: 0
          }}>
          <CoverImage src={dataMerchant?.coverImageDesktopFile} />
          <EditAbsolute
            style={{
              display: 'flex',
              bottom: '-80%',
              left: 'calc(87% * 1.109)'
            }}>
            <Label forId="coverImage">
              <div
                style={{
                  display: 'flex',
                  padding: 10,
                  backgroundColor: 'white',
                  gap: 4,
                  borderRadius: 4,
                  cursor: 'pointer'
                }}>
                <input
                  type="file"
                  id="coverImage"
                  {...register('coverImage')}
                />
                <EditPenIcon fill="#464A51" />
                <div>Modifiez</div>
              </div>
            </Label>
          </EditAbsolute>
        </div>
        <InfoImage>
          <form>
            <img src={dataMerchant?.logoFile} />
            <EditAbsolute>
              <input type="file" id="upload" {...register('upload')} />
              <Label forId="upload">
                <div className="custom-icon">
                  <EditPenIcon />
                </div>
              </Label>
            </EditAbsolute>
          </form>
        </InfoImage>
        <InfoDetail>
          <Name label={dataMerchant?.name} fontSize="XXXL" fontWeight="BOLD" />
          <FieldWrapper>
            <PhoneIcon />
            <TextMargin label={dataMerchant?.phoneNumber} fontSize="M" />
            <ButtonIcon
              icon={IconNames.EditPen}
              widthIcon={10}
              onClick={() => setIsOpenEditInfo(true)}
            />
          </FieldWrapper>
          <FieldWrapper>
            <LocatorIcon />
            <TextMargin label={dataMerchant?.address} fontSize="M" />
            <ButtonIcon
              icon={IconNames.EditPen}
              widthIcon={10}
              onClick={() => setIsOpenEditInfo(true)}
            />
          </FieldWrapper>
        </InfoDetail>
      </InfoWrapper>
      {openModalCropImage && src && getValues('upload') && (
        <CropImage
          srcImage={src}
          file={getValues('upload')}
          onCloseCropImage={closeModalUpdateImage}
          onModeSave={onSave}
          mode={'info'}
        />
      )}
      {openModalCropCoverImage && srcCoverImage && getValues('coverImage') && (
        <CropImage
          srcImage={srcCoverImage}
          file={getValues('coverImage')}
          onCloseCropImage={closeModalUpdateCoverImage}
          onModeSave={onSaveCoverImage}
          mode={'coverImage'}
        />
      )}
      {(isLoading || isUploadingCoverImage) && <Loader />}
      {isOpenEditInfo && (
        <Modal
          close={() => setIsOpenEditInfo(false)}
          borderRadius="7px"
          closeSize="M"
          minWidth="434px"
          topClose="-36px"
          rightClose="30px">
          {merchantSlug && dataMerchant && (
            <EditInfo
              slug={merchantSlug}
              onClose={() => setIsOpenEditInfo(false)}
              dataEdit={dataMerchant}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default Info;

function useUploadImage(
  control: Control,
  setValue: UseFormSetValue<FieldValues>,
  fieldName: string
) {
  const toastErrorFileSizeLarge = () => {
    handleError(undefined, SIZE_IS_TOO_BIG);
  };

  const watchUpload = useWatch({
    control,
    name: fieldName as any
  });
  const [src, setSrcImage] = useState<string>('');
  const [openModalCropImage, setOpenModalCropImage] = useState<boolean>(false);

  useEffect(() => {
    if (watchUpload) {
      const file = watchUpload[0];
      if (file) {
        if (file.size > 10485760) {
          // 10485760 B <=> 10 MB
          toastErrorFileSizeLarge();
        } else {
          const fileReader = new FileReader();
          fileReader.onload = (e: any) => {
            const result = e.target.result as string;
            setSrcImage(result);
          };
          fileReader.readAsDataURL(file);
        }
      }

      setOpenModalCropImage(true);
    }
  }, [watchUpload]);

  function closeModalUpdateImage() {
    setValue(fieldName as any, null);
    setSrcImage('');
    setOpenModalCropImage(false);
  }

  return {
    src,
    openModalCropImage,
    closeModalUpdateImage
  };
}
