import React, { useState } from 'react';
import { ChevronTopIcon } from 'components/ui-kits/Icon';
import { CollapseWapper } from './Collapse.style';
import cls from 'classnames';
import { formatDollar } from 'utils';

type Collapse = {
  titleCollapse: string;
  priceCollapse: number;
  asNormalLi?: boolean;
  children?: React.ReactNode;
};

export default function Collapse({
  titleCollapse,
  priceCollapse,
  asNormalLi,
  children
}: Collapse) {
  const [toggle, setToggle] = useState(false);
  return (
    <CollapseWapper>
      <div className="collapse-header">
        <button
          onClick={() => setToggle(!toggle)}
          className={cls({ active: toggle })}>
          <ChevronTopIcon color="#464a51" width="20" />
        </button>
        <h2>{titleCollapse}</h2>
        <div>{formatDollar(priceCollapse)} €</div>
      </div>
      {toggle && (
        <div className={cls('collapse-body', { normal: asNormalLi })}>
          {children}
        </div>
      )}
    </CollapseWapper>
  );
}
