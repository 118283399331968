import React from 'react';
import { ImageDefault } from 'styles/common';

interface IProps {
  src: string | undefined;
  className?: string;
  id?: string;
  [key: string]: any;
}

const Image = ({ src, id, className, ...props }: IProps) => {
  return (
    <>
      {src ? (
        <img src={src} id={id} className={className} {...props} />
      ) : (
        <ImageDefault />
      )}
    </>
  );
};

export default Image;
