import styled from 'styled-components';
import DisplayText from 'components/ui-kits/DisplayText';

export const BlockLeftWrapper = styled.div`
  margin: 3rem 0 0 4.6rem;
`;

export const CardLabelStyle = styled(DisplayText)`
  margin-bottom: 0.6rem;
`;

export const CardValueStyle = styled(DisplayText)`
  margin-bottom: 3rem;
  text-transform: capitalize;
`;
