import React, { useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Button from 'components/ui-kits/Button';
import { Title, FormWrapper, Actions, OtpWrapper } from './OTP.style';
import Loader from 'components/Loader';
import { handleError, handleSuccess } from 'utils';
import { RESEND_OTP_MESSAGE, VERIFY_OTP_MESSAGE } from 'const/Message';
import { ResetPassword } from 'types/ResetPassword';
import { KeyBoard } from 'const/Enum';
import { requestEmailV2, verifyOtpV2 } from 'services/authService';

const Otp = ({
  setModeConfirmPassword,
  onBackLoginPw
}: {
  setModeConfirmPassword?(data: Omit<ResetPassword, 'newPassword'>): void;
  onBackLoginPw?(): void;
}) => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { isDirty }
  } = useForm();
  const digitRef = useRef<any>([]);
  const {
    ref: ref1,
    name: name1,
    onChange: onChange1
  } = register('digit-0', { required: true });
  const {
    ref: ref2,
    name: name2,
    onChange: onChange2
  } = register('digit-1', { required: true });
  const {
    ref: ref3,
    name: name3,
    onChange: onChange3
  } = register('digit-2', { required: true });
  const {
    ref: ref4,
    name: name4,
    onChange: onChange4
  } = register('digit-3', { required: true });

  const history = useHistory();
  const email = useSelector(
    (state: { [key: string]: any }) => state.auth.email
  );
  if (!email) history.push('/login');
  useEffect(() => {
    let next = 0;
    let prev = next;
    digitRef.current[0].focus();
    function focusOtp(e: any) {
      const getId = e.target.getAttribute('id');
      if (e.which === KeyBoard.Backspace) {
        if (prev > 0) {
          prev--;
          digitRef.current[prev].focus();
        }
      }
      if (e.which === KeyBoard.E) {
        const current = Number(getId);
        digitRef.current[current].value = '';
      }
      if (e.which >= KeyBoard.Zero && e.which <= KeyBoard.Nine) {
        if (Number(digitRef.current[Number(getId)].value) >= 10) {
          digitRef.current[Number(getId)].value = e.key;
          setValue(digitRef.current[Number(getId)].name, e.key);
        }
        if (/\D/.test(digitRef.current[Number(getId)].value)) {
          digitRef.current[Number(getId)].value = '';
          setValue(digitRef.current[Number(getId)].name, '');
        }
        next = Number(getId) + 1;
        if (next < digitRef.current.length) {
          digitRef.current[next].focus();
        } else {
          next = Number(getId);
        }
        prev = next;
      }
    }
    window.addEventListener('keyup', focusOtp);
    return () => {
      window.removeEventListener('keyup', focusOtp, false);
    };
  }, [setValue]);

  const onSubmit = (res: any) => {
    const data = {
      email: email,
      otp: `${res['digit-0']}${res['digit-1']}${res['digit-2']}${res['digit-3']}`
    };
    verifyOtpMutate(data, {
      onSuccess: () => {
        handleSuccess(VERIFY_OTP_MESSAGE.SUCCESS);
        setModeConfirmPassword?.(data);
      },
      onError: (errors: any) => {
        handleError(errors.response.status, VERIFY_OTP_MESSAGE.ERROR);
      }
    });
  };

  const resendOtp = () => {
    requestEmailMutate(
      { email: email },
      {
        onSuccess: () => {
          handleSuccess(RESEND_OTP_MESSAGE);
        },
        onError: (errors: any) => {
          handleError(errors.response.status);
        }
      }
    );
  };

  const { isLoading: isLoadingVerifyOtp, mutate: verifyOtpMutate } =
    useMutation((data: Required<Omit<ResetPassword, 'newPassword'>>) =>
      verifyOtpV2(data)
    );

  const { isLoading: isLoadingRequestEmail, mutate: requestEmailMutate } =
    useMutation(({ email }: Required<{ email: string }>) =>
      requestEmailV2(email)
    );

  return (
    <>
      <Title>
        <span>Merci d&#39;entrer le code reçu sur l&#39;adresse email </span>
        <span>{email}</span>
      </Title>
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <OtpWrapper>
            <input
              name={name1}
              onChange={onChange1}
              placeholder="0"
              id="0"
              ref={(e: any) => {
                ref1(e);
                digitRef.current[0] = e;
              }}
              type="number"
            />
            <input
              name={name2}
              onChange={onChange2}
              placeholder="0"
              id="1"
              ref={(e: any) => {
                ref2(e);
                digitRef.current[1] = e;
              }}
              type="number"
            />
            <input
              name={name3}
              onChange={onChange3}
              placeholder="0"
              id="2"
              ref={(e: any) => {
                ref3(e);
                digitRef.current[2] = e;
              }}
              type="number"
            />
            <input
              name={name4}
              onChange={onChange4}
              placeholder="0"
              id="3"
              ref={(e: any) => {
                ref4(e);
                digitRef.current[3] = e;
              }}
              type="number"
            />
          </OtpWrapper>
          <Button
            variant="primary"
            isFluid={true}
            sizeType="l"
            type="submit"
            isDisabled={!isDirty}
            fontWeight="BOLD">
            SUIVANT
          </Button>
        </form>
      </FormWrapper>
      <Actions>
        <Button variant="link" onClick={onBackLoginPw}>
          Retour
        </Button>
        <Button variant="link" onClick={resendOtp}>
          Renvoyer le code
        </Button>
      </Actions>
      {(isLoadingRequestEmail || isLoadingVerifyOtp) && <Loader />}
    </>
  );
};

export default Otp;
