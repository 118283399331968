import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  currentPage: 1,
  search: ''
};

const pagePaginationSlice = createSlice({
  name: 'pagePagination',
  initialState,
  reducers: {
    statusPageProduct(
      state,
      action: PayloadAction<{
        currentPage: number;
        search: string;
      }>
    ) {
      return (state = action.payload);
    }
  }
});

const { actions, reducer } = pagePaginationSlice;
export const { statusPageProduct } = actions;
export default reducer;
