import React, { useEffect, useMemo } from 'react';
import Modal from 'components/Modal';
import Button from 'components/ui-kits/Button';
import { Checkbox } from 'components/ui-kits/Form/Checkbox';
import { Search } from 'components/ui-kits/Form/Dropdown';
import Table from 'components/ui-kits/Table';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { TableCell } from 'components/ui-kits/Table/Table.style';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { ProductOptionV2 } from 'types/Products';
import DisplayText from 'components/ui-kits/DisplayText';
import { MultilineTrunc, ScrollbarCustom } from 'styles/common';
import Image from 'components/ImageDisplay';
import {
  ButtonStyle,
  EmptyListWrapper,
  Filter,
  HeaderData,
  HeaderTable,
  SearchStyle,
  StyleSearch,
  Title
} from './ProductModal.style';
import Spinner from 'components/ui-kits/Spinner';
import { getProductListByChunkingV2 } from 'services/productService';

interface IProductModal {
  onClose(): void;
  editProduct?(newProducts: string[], status: 'add' | 'remove'): void;
  listProductActive?: Array<string | number>;
}

function ProductModal({
  onClose,
  editProduct,
  listProductActive = []
}: IProductModal) {
  const history = useHistory();
  const { merchantId } = useSelector((state: RootState) => state.user);
  const queryKey = useMemo(
    () => ({
      'merchant.id': merchantId,
      isOption: false
    }),
    [merchantId]
  );
  const { data: listAllProduct, isLoading } = useQuery(
    ['getListProducts', queryKey],
    ({ signal }) => getProductListByChunkingV2(queryKey, signal)
  );
  const { register, handleSubmit, getValues, reset } = useForm({
    defaultValues: {
      product: listAllProduct?.['hydra:member'].reduce((acc, item) => {
        acc[`${item['@id']}`] = listProductActive.includes(item.id);
        return acc;
      }, {} as any)
    }
  });
  useEffect(() => {
    reset({
      product: listAllProduct?.['hydra:member'].reduce((acc, item) => {
        acc[`${item['@id']}`] = listProductActive.includes(item['@id']);
        return acc;
      }, {} as any)
    });
  }, [listAllProduct, listProductActive, reset]);
  const onChangeSearch = (data: any) => {
    console.log(data);
  };
  const onSubmit = () => {
    const newData = getValues('product');
    const newListProduct = Object.keys(newData).reduce((acc, key) => {
      if (newData[key]) {
        acc.push(key);
      }
      return acc;
    }, [] as string[]);
    editProduct?.(newListProduct, 'add');
  };
  const renderResult = (data: ProductOptionV2[]) => {
    if (isLoading) {
      return (
        <tr>
          <td>
            <EmptyListWrapper>
              <Spinner />
            </EmptyListWrapper>
          </td>
        </tr>
      );
    }
    if (data.length === 0) {
      return (
        <tr>
          <td>
            <EmptyListWrapper>
              <DisplayText
                label="Vous n'avez actuellement aucune catégorie"
                fontSize="S"
                fontWeight="REGULAR"
                color="GREY_1"
                className="product-modal-empty"
              />
            </EmptyListWrapper>
          </td>
        </tr>
      );
    }
    if (data.length > 0) {
      return data.map((item) => {
        const { groupOptionNames } = item;
        return (
          <tr key={item['@id']}>
            <TableCell>
              <Checkbox name={`product.${item['@id']}`} register={register} />
            </TableCell>
            <TableCell>
              <Image src={item?.latestImage?.path} />
            </TableCell>
            <TableCell>
              <div className="name">
                <MultilineTrunc line="2">{item.name}</MultilineTrunc>
              </div>
              <div className="ref">
                <MultilineTrunc line="2">{item?.reference}</MultilineTrunc>
              </div>
            </TableCell>
            <TableCell>
              {item?.taxonNames?.length > 2 ? (
                <div>{item?.taxonNames?.length} Catégories</div>
              ) : (
                item?.taxonNames?.map((taxon) => (
                  <>
                    <div>{taxon?.name}</div>
                  </>
                ))
              )}
            </TableCell>
            <TableCell fontWeight="REGULAR">
              {!!groupOptionNames?.length && groupOptionNames?.join(', ')}
            </TableCell>
          </tr>
        );
      });
    } else {
      return null;
    }
  };

  return (
    <Modal close={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HeaderTable>
          <Title>Ajout de produit(s)</Title>
          <Button
            type="submit"
            isFluid={false}
            variant="primary"
            label="Enregistrer"
            fontWeight="BOLD"
            letterSpacing="S"
          />
        </HeaderTable>
        <Filter>
          <SearchStyle>
            <Search
              change={onChangeSearch}
              placeholder="Rechercher"
              styles={StyleSearch}
            />
          </SearchStyle>
          <ButtonStyle>
            <Button
              type="button"
              label="+ CRÉER UN PRODUIT"
              variant="cancel"
              fontWeight="BOLD"
              onClick={() => history.push('/menu/products/create')}
            />
          </ButtonStyle>
        </Filter>

        <Table header={HeaderData} borderHead={false} hasScroll={true}>
          <ScrollbarCustom>
            {renderResult(listAllProduct?.['hydra:member'] || [])}
          </ScrollbarCustom>
        </Table>
      </form>
    </Modal>
  );
}

export default ProductModal;
