import React from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/esm/locale';
import { formatDollar } from 'utils';
import CardSummaryLeft from '../CardSummaryTable/CardSummaryLeft';
import {
  RevenusNetWrapper,
  SummaryTableBlockLeft,
  SummaryTableBlockRight,
  SummaryTableWrapper
} from './SummaryTable.style';
import Collapse from '../CardSummaryTable/Collapse';

const SummaryTable = (data: { onData: SummaryTableData }) => {
  const summary = data.onData;
  const $fixedAmount = summary?.fixedAmount;
  const $minimumAmount = summary?.minimumAmount;
  const $serviceFee = toPercent(summary?.serviceFee);
  const $onlineRevenueTtc = summary?.onlineRevenueTtc;
  const $cardWithFixedAmountRevenue = $onlineRevenueTtc?.cardWithFixedAmount;
  const $cardWithFixedAmountQuantity =
    $onlineRevenueTtc?.cardWithFixedAmountQuantity;
  const $cardWithFixedAmountLabel = generateServiceFeeLabel(
    $minimumAmount,
    '>',
    $cardWithFixedAmountQuantity,
    $serviceFee,
    $fixedAmount
  );
  const $cardWithoutFixedAmountRevenue =
    $onlineRevenueTtc?.cardWithoutFixedAmount;
  const $cardWithoutFixedAmountQuantity =
    $onlineRevenueTtc?.cardWithoutFixedAmountQuantity;
  const $cardWithoutFixedAmountLabel = generateServiceFeeLabel(
    $minimumAmount,
    '≤',
    $cardWithoutFixedAmountQuantity,
    $serviceFee,
    0
  );
  const $refund = summary?.totalRefund;
  const $cardWithFixedAmountRefund = $refund?.cardWithFixedAmount;
  const $cardWithFixedAmountRefundQuantity =
    $refund?.cardWithFixedAmountQuantity;
  const $cardWithFixedAmountRefundLabel = generateServiceFeeLabel(
    $minimumAmount,
    '>',
    $cardWithFixedAmountRefundQuantity,
    $serviceFee,
    $fixedAmount
  );
  const $cardWithoutFixedAmountRefund = $refund?.cardWithoutFixedAmount;
  const $cardWithoutFixedAmountRefundQuantity =
    $refund?.cardWithoutFixedAmountQuantity;
  const $cardWithoutFixedAmountRefundLabel = generateServiceFeeLabel(
    $minimumAmount,
    '≤',
    $cardWithoutFixedAmountRefundQuantity,
    $serviceFee,
    0
  );

  return (
    <SummaryTableWrapper>
      <SummaryTableBlockLeft>
        <CardSummaryLeft
          label="Revenus Net"
          value={`${formatDollar(summary?.netRevenue)} €`}
        />
        <CardSummaryLeft
          label="Commandes terminées"
          value={summary?.terminatedOrders}
        />
        <CardSummaryLeft
          label="Fréquence des versements"
          value={summary?.paymentPeriod === 'Weekly' ? 'Hebdomadaire' : ''}
        />
        <CardSummaryLeft
          label="Date du paiement"
          value={
            summary?.paymentDate
              ? format(new Date(summary?.paymentDate), 'cccc d MMM yyyy', {
                  locale: fr
                })
              : '-'
          }
        />
        {summary?.transactionNumber && (
          <CardSummaryLeft
            label="Numéro de transaction"
            value={summary?.transactionNumber}
          />
        )}
      </SummaryTableBlockLeft>
      <SummaryTableBlockRight>
        <Collapse
          titleCollapse="Revenus en ligne TTC"
          priceCollapse={summary?.onlineRevenueTtc?.total || 0}>
          <ul>
            {0 < $minimumAmount ? (
              <li>
                <span>{$cardWithoutFixedAmountLabel}</span>
                <span>{formatDollar($cardWithoutFixedAmountRevenue)} €</span>
              </li>
            ) : null}
            <li>
              <span>{$cardWithFixedAmountLabel}</span>
              <span>{formatDollar($cardWithFixedAmountRevenue)} €</span>
            </li>
            <li>
              <span>
                Espèce ({$onlineRevenueTtc?.cashQuantity} commande(s))
              </span>
              <span>{formatDollar($onlineRevenueTtc?.cash)} €</span>
            </li>
            <li>
              <span>
                Titres restaurant ({$onlineRevenueTtc?.restaurantTicketQuantity}{' '}
                commande(s))
              </span>
              <span>{formatDollar($onlineRevenueTtc?.restaurantTicket)} €</span>
            </li>
            <li>
              <span>
                Chèque-vacances ({$onlineRevenueTtc?.vacationTicketQuantity}{' '}
                commande(s))
              </span>
              <span>{formatDollar($onlineRevenueTtc?.vacationTicket)} €</span>
            </li>
            <li>
              <span>Frais de service Achil (HT)</span>
              <span>{formatDollar($onlineRevenueTtc?.serviceFeeHt)} €</span>
            </li>
          </ul>
        </Collapse>
        <Collapse
          titleCollapse="Réduction total"
          priceCollapse={summary?.totalDiscount?.total || 0}
          asNormalLi={true}>
          <ul>
            <li>
              <span>Réduction sur produit</span>
              <span>{formatDollar(summary?.totalDiscount?.onProduct)} €</span>
            </li>
          </ul>
        </Collapse>
        <Collapse
          titleCollapse="Total remboursé"
          priceCollapse={summary?.totalRefund?.total || 0}>
          <ul>
            {0 < $minimumAmount ? (
              <li>
                <span>{$cardWithoutFixedAmountRefundLabel}</span>
                <span>{formatDollar($cardWithoutFixedAmountRefund)} €</span>
              </li>
            ) : null}
            <li>
              <span>{$cardWithFixedAmountRefundLabel}</span>
              <span>{formatDollar($cardWithFixedAmountRefund)} €</span>
            </li>
            <li>
              <span>Espèce ({$refund?.cashQuantity} commande(s))</span>
              <span>{formatDollar($refund?.cash)} €</span>
            </li>
            <li>
              <span>Frais de service Achil (HT)</span>
              <span>{formatDollar($refund?.serviceFeeHt)} €</span>
            </li>
          </ul>
        </Collapse>
        <Collapse
          titleCollapse="Total frais de service Achil (HT)"
          priceCollapse={summary?.totalServiceFeeHt?.total || 0}>
          <ul>
            <li>
              <span>TVA sur les frains de service Achil (20%)</span>
              <span>
                {formatDollar(summary?.totalServiceFeeHt?.vatAmount || 0)} €
              </span>
            </li>
            <li>
              <span>Total frais de service Achil (TTC)</span>
              <span>
                {formatDollar(summary?.totalServiceFeeHt.serviceFeeTtc || 0)} €
              </span>
            </li>
          </ul>
        </Collapse>
        <RevenusNetWrapper>
          <span>Revenus Net TTC</span>
          <span>{formatDollar(summary?.netRevenue || 0)} €</span>
        </RevenusNetWrapper>
      </SummaryTableBlockRight>
    </SummaryTableWrapper>
  );
};

export default SummaryTable;

function toPercent(decimal: number) {
  return decimal * 100;
}

const generateServiceFeeLabel = (
  $minimumAmount: number,
  $operator: string,
  $quantity: number,
  $serviceFee: number,
  $fixedAmount: number
): string => {
  const $orderLabel = 'commande(s)';
  const $formattedFixedAmount = formatDollar($fixedAmount);
  const $formattedMinimumAmount = formatDollar($minimumAmount);
  const $prefix =
    0 === $minimumAmount
      ? 'Carte bancaire'
      : `Carte bancaire ${$operator} ${$formattedMinimumAmount}€`;
  const $postfix = 0 === $fixedAmount ? '' : ` + ${$formattedFixedAmount}€`;

  return `${$prefix} (${$quantity} ${$orderLabel} à ${$serviceFee}%${$postfix})`;
};

type SummaryTableData = {
  minimumAmount: number;
  fixedAmount: number;
  serviceFee: number;
  paymentPeriod: string;
  paymentDate: string;
  transactionNumber: string;
  netPayment: number;
  netRevenue: number;
  terminatedOrders: number;
  onlineRevenueTtc: {
    cardWithFixedAmount: number;
    cardWithFixedAmountQuantity: number;
    cardWithoutFixedAmount: number;
    cardWithoutFixedAmountQuantity: number;
    cash: number;
    cashQuantity: number;
    restaurantTicket: number;
    restaurantTicketQuantity: number;
    vacationTicket: number;
    vacationTicketQuantity: number;
    serviceFeeHt: number;
    total: number;
  };
  totalDiscount: {
    onProduct: number;
    total: number;
  };
  totalRefund: {
    cardWithFixedAmount: number;
    cardWithFixedAmountQuantity: number;
    cardWithoutFixedAmount: number;
    cardWithoutFixedAmountQuantity: number;
    cash: number;
    cashQuantity: number;
    serviceFeeHt: number;
    total: number;
  };
  totalServiceFeeHt: {
    vatAmount: number;
    serviceFeeTtc: number;
    total: number;
  };
  orders: OrderRevenueData;
};

type OrderRevenueData = {
  tokenValue: string;
  dateAndTime: string;
  orderNumber: string;
  attributes: string;
  subtotal: number;
  soldAt: number;
  netPayment: number;
  netRevenue: number;
  name: OrderItemRevenueData;
  items: any;
  deliveryFee: number;
  operationCost: number;
  refund: number;
  refundCost: number;
  totalDiscount: number;
};

type OrderItemRevenueData = {
  name: string;
  quantity: number;
  price: number;
};
