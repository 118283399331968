import React from 'react';
import {
  BlockLeftWrapper,
  CardLabelStyle,
  CardValueStyle
} from './CardSummaryLeft.style';

interface IProps {
  label?: string;
  value?: any;
}

const CardRevenues = ({ label, value }: IProps) => {
  return (
    <BlockLeftWrapper>
      <CardLabelStyle
        label={label}
        fontSize="S"
        fontWeight="MEDIUM"
        color="GREY_1"
      />
      <CardValueStyle
        label={value}
        fontSize="M"
        fontWeight="MEDIUM"
        color="BLACK"
      />
    </BlockLeftWrapper>
  );
};

export default CardRevenues;
