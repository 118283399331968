import React from 'react';
import { ItemOrderDetailWrapper } from './ItemOrderDetail.style';
import { IOrderDetail } from 'types/OrderList';

const ItemOrderDetail = ({
  mainItem,
  subItem,
  qty,
  subQty,
  price,
  subPrice
}: IOrderDetail) => {
  return (
    <ItemOrderDetailWrapper>
      <td colSpan={3}>
        <div className="itemNameWrapper">
          <div className="mainItem">{mainItem}</div>
          <div className="subItem">{subItem}</div>
        </div>
      </td>
      <td>
        <div className="qtyWrapper">
          <div className="qty">{qty}</div>
          <div className="subQty">{subQty}</div>
        </div>
      </td>
      <td>
        <div className="priceWrapper">
          <div className="price">{price}</div>
          <div className="subPrice">{subPrice}</div>
        </div>
      </td>
    </ItemOrderDetailWrapper>
  );
};

export default ItemOrderDetail;
