import React, { useCallback, useReducer, useEffect, useState } from 'react';
import Button from 'components/ui-kits/Button';
import {
  BtnWrapper,
  ListGroupOptionWrapper,
  GroupOptionItem
} from './CurrentGroupOption.style';
import { CollapseDrag } from 'components/ProductLayout/Collapse';
import { GroupOptionsProduct, IGroupOptions } from 'types/GroupOptions';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ListProductInGroupOption from '../ListProductInGroupOption';
import { compare } from 'utils';

interface IProps {
  onOpenModal: () => void;
  currentGroupOptionSelected: any;
  onRemoveGroupOptionItem: (id: string) => void;
  mode?: 'go' | 'catalog';
  className?: string;
  onHandleDragGO?: (go: any[]) => void;
}

const CurrentGroupOption = ({
  onOpenModal,
  currentGroupOptionSelected,
  onRemoveGroupOptionItem,
  mode = 'go',
  className,
  onHandleDragGO
}: IProps) => {
  const reducer = (
    state: boolean[],
    { type, idx }: { type: string; idx?: number }
  ) => {
    switch (type) {
      case 'expand-all':
        return currentGroupOptionSelected.map(() => false);
      case 'collapse-all':
        return currentGroupOptionSelected.map(() => true);
      case 'toggle':
        const newArr = [...state];
        if (idx || idx === 0) {
          newArr[idx] = !newArr[idx];
        }
        return newArr;
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, []);
  const [listGOption, setListGOption] = useState<any>([]);

  useEffect(() => {
    const filterGOEmpty = currentGroupOptionSelected.filter(
      (item: any) => Object.keys(item).length > 0
    ); // remove object is empty in array
    const newGO = filterGOEmpty.map((item: any) => {
      item.groupOptionProducts = compare(
        Object.values(item.groupOptionProducts),
        'increase',
        'position'
      );
      return item;
    });
    setListGOption(newGO);
  }, [currentGroupOptionSelected]);

  const transformDataToProduct = (groupOption: GroupOptionsProduct[]) => {
    if (groupOption.length > 0) {
      return groupOption.map((item: GroupOptionsProduct) => {
        return {
          name: item.product.name,
          price: item.customPrice,
          id: item.product['@id'],
          position: item.position
        };
      });
    } else {
      return [];
    }
  };

  const handleRemoveOptionItem = (id: string) => {
    onRemoveGroupOptionItem(id);
  };

  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        return;
      }
      const srcI = result.source.index;
      const destI = result.destination.index;
      let gOptionDrag: any[] = [];
      if (result.type === 'drop-go') {
        setListGOption((oldOption: any) => {
          const newOption = [...oldOption];
          const [removed] = newOption.splice(srcI, 1);
          newOption.splice(destI, 0, removed);
          gOptionDrag = newOption;
          return newOption;
        });
      } else {
        const getIndex = Number(result.type);
        setListGOption((oldOption: any) => {
          const newOption = [...oldOption];
          const [removed] = newOption[getIndex].groupOptionProducts.splice(
            srcI,
            1
          );
          newOption[getIndex].groupOptionProducts.splice(destI, 0, removed);
          gOptionDrag = newOption;
          return newOption;
        });
      }
      onHandleDragGO?.(gOptionDrag);
    },
    [onHandleDragGO]
  );

  return (
    <div>
      <BtnWrapper>
        {mode === 'go' && (
          <>
            <Button
              type="button"
              variant="link"
              label="Tout afficher"
              isFluid={false}
              sizeType="l"
              fontWeight="BOLD"
              onClick={() => dispatch({ type: 'expand-all' })}
            />
            <Button
              type="button"
              variant="link"
              label="Tout réduire"
              isFluid={false}
              sizeType="l"
              fontWeight="BOLD"
              onClick={() => dispatch({ type: 'collapse-all' })}
            />
            <Button
              variant="primary"
              label="+ AJOUTER UN GROUPE D'OPTION"
              isFluid={false}
              sizeType="l"
              fontWeight="BOLD"
              onClick={() => onOpenModal()}
            />
          </>
        )}
        {mode === 'catalog' && (
          <Button
            variant="cancel"
            label="+ AJOUTER UN GROUPE D'OPTION"
            isFluid={false}
            sizeType="s"
            fontWeight="BOLD"
            onClick={() => onOpenModal()}
          />
        )}
      </BtnWrapper>
      <ListGroupOptionWrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-go" type="drop-go">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {listGOption?.length > 0 &&
                  listGOption.map((go: IGroupOptions, indexGo: number) => {
                    const filterData = transformDataToProduct(
                      Object.values(go.groupOptionProducts)
                    );
                    return (
                      <Draggable
                        draggableId={`draggable-go-${indexGo}`}
                        index={indexGo}
                        key={indexGo}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <GroupOptionItem key={indexGo}>
                              <CollapseDrag
                                title={go.name}
                                idGO={go['@id']}
                                isOpen={state[indexGo]}
                                onRemoveOptionItem={handleRemoveOptionItem}
                                isMandatory={go.isMandatory}
                                requiredAmount={go.requiredAmount}
                                maxAmount={go.maxAmount}
                                className={className}
                                isRange={go.isRange}
                                toggle={() =>
                                  dispatch({ type: 'toggle', idx: indexGo })
                                }>
                                <ListProductInGroupOption
                                  listProducts={filterData}
                                  indexGo={indexGo}
                                />
                              </CollapseDrag>
                            </GroupOptionItem>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ListGroupOptionWrapper>
    </div>
  );
};

export default CurrentGroupOption;
