import React from 'react';
import GroupOptionsForm from 'components/GroupOptionsLayout/GroupOptionsForm';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { GroupOptionFormsFields, IGroupOptions } from 'types/GroupOptions';
import Loader from 'components/Loader';
import { DELETE_MESSAGE, UPDATE_MESSAGE } from 'const/Message';
import { handleSuccess, handleError } from 'utils/index';
import {
  editGroupOptionV2,
  getGroupOptionsItemV2
} from 'services/groupOptionService';

function EditGroupOptions() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const queryClient = useQueryClient();
  const {
    isLoading: isLoadingGroupOptionItem,
    data: editItem
  }: UseQueryResult<IGroupOptions, Error> = useQuery(
    ['getGroupOptionsItem', { id }],
    () => getGroupOptionsItemV2(id)
  );
  const {
    mutate: mutateEdit,
    isLoading: isLoadingEdit
  }: UseMutationResult<IGroupOptions, Error, GroupOptionFormsFields, unknown> =
    useMutation<IGroupOptions, Error, GroupOptionFormsFields, unknown>(
      (data: GroupOptionFormsFields) => editGroupOptionV2(data, id),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['options']);
          queryClient.invalidateQueries('getListGroupOptions');
          queryClient.invalidateQueries(['getGroupOptionsItem', { id }]);
          queryClient.invalidateQueries('getGrOptionItem');
          handleSuccess(UPDATE_MESSAGE.GROUP_OPTION_SUCCESS);
          history.push('/menu/groupOptions');
        },
        onError: (error: any) => {
          handleError(error.response.status, UPDATE_MESSAGE.GROUP_OPTION_ERROR);
        }
      }
    );
  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    ({ data, id }: Required<{ data: GroupOptionFormsFields; id: string }>) =>
      editGroupOptionV2(data, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['options']);
        queryClient.invalidateQueries('getListGroupOptions');
        queryClient.invalidateQueries('getGroupOptionsItem');
        queryClient.invalidateQueries('getListProducts');
        handleSuccess(DELETE_MESSAGE.GROUP_OPTION_SUCCESS);
        history.push('/menu/groupOptions');
      },
      onError: (error: any) => {
        handleError(error.response.status, DELETE_MESSAGE.GROUP_OPTION_ERROR);
      }
    }
  );
  return (
    <>
      {isLoadingGroupOptionItem || isLoadingEdit || isLoadingDelete ? (
        <Loader />
      ) : null}
      {editItem ? (
        <GroupOptionsForm
          editItem={editItem}
          mutate={mutateEdit}
          mutateDelete={mutateDelete}
        />
      ) : null}
    </>
  );
}

export default EditGroupOptions;
