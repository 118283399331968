import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Layout from 'components/Layout';
import DisplayText from 'components/ui-kits/DisplayText';
import { CheckboxSwitch } from 'components/ui-kits/Form/Checkbox';
import { FieldValues, useForm } from '../../../node_modules/react-hook-form';
import { DeliveryWrapper } from './Delivery.style';
import ZoneSettings from 'components/DeliveryLayout/ZoneSettings';
import DeliverySettings from 'components/DeliveryLayout/DeliverySettings';
import { RootState } from 'app/store';
import { MerchantDeliveryToggle } from 'types/Merchant';
import { handleError, handleSuccess } from 'utils';
import { UPDATE_MESSAGE } from 'const/Message';
import Loader from 'components/Loader';
import ListLayout from 'components/ListLayout';
import {
  getDeliveryGeneralV2,
  updateDeliveryGeneralV2
} from 'services/deliveryService';

type MerchantDeliveryTogglePayload = Partial<MerchantDeliveryToggle> & {
  deliveryTimeMode?: 'opening_hour';
};

const Delivery = () => {
  const { merchantSlug } = useSelector((state: RootState) => state.user);
  const {
    data,
    isLoading: isLoadingGetDeliveryGeneral,
    isFetching: isFetchingGetDeliveryGeneral
  } = useQuery('getDeliveryGeneral', ({ signal }) =>
    getDeliveryGeneralV2(merchantSlug, signal)
  );

  const { register, setValue } = useForm<FieldValues>({
    defaultValues: { isDelivery: data?.allowDelivery || false }
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    setValue('isDelivery', data?.allowDelivery);
  }, [data, setValue]);

  const onToggle = (status: boolean) => {
    const isNewMerchant =
      !data.deliveryTimes.length && data.deliveryTimeMode === undefined;
    const payload: MerchantDeliveryTogglePayload = { allowDelivery: status };

    if (isNewMerchant) {
      payload.deliveryTimeMode = 'opening_hour';
    }

    onToggleDelivery(
      { slug: merchantSlug, data: payload },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('getDeliveryGeneral');
          status
            ? handleSuccess(UPDATE_MESSAGE.DELIVERY_TOGGLE_ON)
            : handleSuccess(UPDATE_MESSAGE.DELIVERY_TOGGLE_OFF);
        },
        onError: (errors: any) => {
          handleError(errors.response.status, UPDATE_MESSAGE.DELI_TOGGLE_ERROR);
        }
      }
    );
  };

  const { isLoading: isLoadingToggle, mutate: onToggleDelivery } = useMutation(
    ({
      slug,
      data
    }: Required<{ slug: string; data: MerchantDeliveryTogglePayload }>) =>
      updateDeliveryGeneralV2(slug, data as any)
  );

  const handleChange = (e: any) => {
    onToggle(e.target.checked);
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    // for case new merchant enable allow delivery and go
    // to Delivery screen for the first time.
    if (
      data.allowDelivery &&
      !data.deliveryTimes.length &&
      data.deliveryTimeMode === undefined
    ) {
      onToggleDelivery(
        {
          slug: merchantSlug,
          data: { deliveryTimeMode: 'opening_hour' }
        },
        { onSuccess: () => queryClient.invalidateQueries('getDeliveryGeneral') }
      );
    }
  }, [data, merchantSlug, onToggleDelivery, queryClient]);

  return (
    <Layout>
      {(isLoadingToggle ||
        isLoadingGetDeliveryGeneral ||
        isFetchingGetDeliveryGeneral) && <Loader />}
      <ListLayout>
        <DeliveryWrapper>
          <div className="title-wrapper">
            <CheckboxSwitch
              name="isDelivery"
              id="isDelivery"
              isChildLeft={true}
              handleChange={handleChange}
              register={register}>
              <DisplayText
                label="Livraison"
                fontSize="XXLS"
                fontWeight="LIGHT"
                className="title"
              />
            </CheckboxSwitch>
          </div>
          {data?.allowDelivery && (
            <div className="form-block">
              <DeliverySettings slug={merchantSlug} dataMerchant={data} />
            </div>
          )}
          {data?.allowDelivery && (
            <div className="form-block zone">
              <DisplayText
                className="form-block__title-deli"
                label="Zone de livraison"
                fontSize="XL"
                fontWeight="BOLD"
              />
              <DisplayText
                className="form-block__title"
                label="Définissez jusqu'à 5 zones de livraisons.
                Si des zones se supperposent, les frais de livraison de la zone la moins onéreuse s'appliqueront automatiquement à la partie supperposée."
                fontSize="XS"
                fontWeight="REGULAR"
                color="GREY_7"
              />
              <ZoneSettings />
            </div>
          )}
        </DeliveryWrapper>
      </ListLayout>
    </Layout>
  );
};

export default Delivery;
