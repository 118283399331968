export const LOGIN = '/merchant/authentication-token';
export const REQUEST_EMAIL = '/merchant/reset-password/request';
export const VERIFY_OTP = '/merchant/reset-password/verify';
export const RESET_PASSWORD = '/merchant/reset-password/update';

export const PRODUCT = '/merchant/products';
export const PRODUCT_BY_CODE = '/merchant/products/:code';
export const PRODUCT_UPLOAD_IMAGE = '/merchant/products/:code/upload-images';
export const PRODUCT_IMPORT = '/merchant/products/import';

export const PRODUCT_ATTR = '/merchant/product-attributes';
export const PRODUCT_ATTR_BY_ID = '/merchant/product-attributes/:id';

export const GROUP_OPTION = '/merchant/group-options';
export const GROUP_OPTION_BY_ID = '/merchant/group-options/:id';

export const TAXON = '/merchant/taxons';
export const TAXON_BY_CODE = '/merchant/taxons/:code';
export const TAXON_CODE_PRODUCT = '/merchant/taxons/:code/products';
export const TAXON_PRODUCT = '/merchant/taxon/products';
export const TAXON_UPLOAD_IMAGE = '/merchant/taxons/:code/upload-images';

export const CATALOG = '/merchant/catalogs';
export const CATALOG_BY_ID = '/merchant/catalogs/:id';
export const CATALOG_PRODUCT = '/merchant/catalog/products';

export const DELIVERY = '/merchant/delivery-zones';
export const DELIVERY_BY_ID = '/merchant/delivery-zones/:id';

export const MERCHANT_SLUG = '/merchant/merchants/:slug';
export const MERCHANT_UPLOAD = '/merchant/merchants/upload-image';
export const MERCHANT_COVER_IMAGE = 'merchant/merchants/upload-cover-image';

export const PROMOTION = '/merchant/catalog-promotions';
export const PROMOTION_BY_CODE = '/merchant/catalog-promotions/:code';
