import React from 'react';
import Layout from 'components/Layout';
import { NavItem } from 'types/NavItem';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import GroupOptions from './GroupOptions';
import CreateGroupOptions from './GroupOptions/Create';
import EditGroupOptions from './GroupOptions/Edit';
import Products from './Products';
import ProductsCreate from './Products/Create';
import ProductsEdit from './Products/Edit';
import Categories from './Categories';
import Catalogs from './Catalogs';
import CatalogsCreate from './Catalogs/Create';
import CatalogsEdit from './Catalogs/Edit/Edit';
import Options from './Options';
import OptionCreate from './Options/Create/Create';
import OptionEdit from './Options/Edit/Edit';

const secondNavsData: NavItem[] = [
  { label: 'Catalogues', path: '/catalogs', Component: Catalogs, exact: true },
  { label: 'Catégories', path: '/categories', Component: Categories },
  { label: 'Produits', path: '/products', Component: Products, exact: true },
  {
    label: `Groupes d'options`,
    path: '/groupOptions',
    Component: GroupOptions,
    exact: true
  },
  { label: 'Options', path: '/options', Component: Options, exact: true }
];

const Menu = () => {
  const { path } = useRouteMatch();
  return (
    <Layout secondNavs={secondNavsData}>
      <Switch>
        {secondNavsData.map((item) => (
          <Route
            key={item.path}
            path={`${path}${item.path}`}
            exact={item.exact}>
            {item.Component ? <item.Component /> : <h3>{item.label}</h3>}
          </Route>
        ))}
        <Redirect from={path} to={`${path}/catalogs`} exact />
        <Route key="groupOptions/create" path={`${path}/groupOptions/create`}>
          <CreateGroupOptions />
        </Route>
        <Route key="groupOptions/edit" path={`${path}/groupOptions/edit/:id`}>
          <EditGroupOptions />
        </Route>
        <Route path={`${path}/products/create`}>
          <ProductsCreate />
        </Route>
        <Route path={`${path}/products/edit/:code`} exact>
          <ProductsEdit />
        </Route>
        <Route path={`${path}/catalogs/create`}>
          <CatalogsCreate />
        </Route>
        <Route path={`${path}/catalogs/edit/:catalogId`} exact>
          <CatalogsEdit />
        </Route>
        <Route path={`${path}/options/create`}>
          <OptionCreate />
        </Route>
        <Route path={`${path}/options/edit/:optionCode`} exact>
          <OptionEdit />
        </Route>
      </Switch>
    </Layout>
  );
};

export default Menu;
