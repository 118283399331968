import React from 'react';
import NumberInput from 'components/ui-kits/Form/NumberInput';

import {
  Control,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
  useWatch
} from 'react-hook-form';
import { FieldWrapper, Price } from './EditProductItemForm.style';
import DisplayText from 'components/ui-kits/DisplayText';

export function FieldPrice({
  control,
  register,
  getValues,
  setValue,
  defaultValue,
  onBlur
}: {
  control: Control;
  register: any;
  setValue?: UseFormSetValue<FieldValues>;
  getValues?: UseFormGetValues<FieldValues>;
  defaultValue?: string | number | undefined;
  onBlur?: () => void;
}) {
  const watchIsSellProductWithUnits = useWatch({
    control,
    name: 'isSellProductWithUnits'
  });

  return watchIsSellProductWithUnits ? null : (
    <FieldWrapper>
      <Price>
        <DisplayText as="label" fontWeight="BOLD" fontSize="M" label="Prix" />
        <span>(TTC)</span>
      </Price>
      <NumberInput
        name="price"
        validate={{ required: true }}
        register={register}
        variant="unit"
        defaultValue={defaultValue}
        setValue={setValue}
        getValues={getValues}
        type="text"
        disabled={watchIsSellProductWithUnits}
        onBlur={onBlur}
      />
    </FieldWrapper>
  );
}
