import React, { useEffect, useState } from 'react';
import Button from 'components/ui-kits/Button';
import DisplayText from 'components/ui-kits/DisplayText';
import TextInput from 'components/ui-kits/Form/TextInput';
import { FieldValues, useForm } from 'react-hook-form';
import styled from 'styled-components';
import NumberInput from 'components/ui-kits/Form/NumberInput';
import { DropdownList } from 'components/ui-kits/Form/Dropdown';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { COLORS } from 'styles/variables';
import Loader from 'components/Loader';
import ButtonIcon from 'components/ui-kits/ButtonIcon';
import { IconNames } from 'components/ui-kits/Icon/Icon';
import { CREATE_MESSAGE, UPDATE_MESSAGE } from 'const/Message';
import { handleSuccess, handleError } from 'utils/index';
import { VAT_OPTIONS } from 'const/Const';
import { MessageErrorName } from 'styles/common';
import { Regulation } from 'components/ProductLayout/ProductForm/ProductForm.style';
import { updateProduct } from 'services/productService';
import { createOptionItemsV2 } from 'services/groupOptionService';
import { GroupOptionsSelect } from 'components/ProductOptionsLayout/ProductOptionsForm/GroupOptionsSelect';
import { getProductOptionByCodeV2 } from 'services/productOptionService';
import type {
  IGroupOptions,
  ListOptionsSelected,
  OptionsFormFields
} from 'types/GroupOptions';
import type { AxiosResponse } from 'axios';
import type { ProductOption } from 'types/Products';

const BlockWrapper = styled.div`
  padding: 4rem 1rem 4rem 1.5rem;
  position: fixed;

  .action-wrapper {
    display: flex;
    align-items: center;
  }
  .btn-submit {
    margin-right: 1.5rem;
  }
  .close {
    svg {
      fill: ${COLORS.BLACK_3};
    }
  }
`;

const NameWrapper = styled.div`
  margin: 3.6rem 0 3.6rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.2rem 0;
`;

const RegulationWrapper = styled.div`
  padding-top: 2rem;
`;

interface ICreateOption {
  onClose(): void;
  optionEdit?: ListOptionsSelected;
  handleEditOption?({
    data,
    newCode,
    isNewOptionCreated,
    formValues
  }: {
    data: any;
    newCode: string;
    isNewOptionCreated: boolean;
    formValues?: Record<string, any>;
  }): void;
  groupOption?: IGroupOptions;
}

function CreateOption({
  onClose,
  optionEdit,
  handleEditOption,
  groupOption
}: ICreateOption) {
  const [groupOptionSelected, setGroupOptionSelected] = useState<string[]>(
    groupOption ? (groupOption['@id'] ? [groupOption['@id']] : []) : []
  );
  const queryClient = useQueryClient();
  const code = optionEdit?.originalItem.code || '';
  const { data: result, isLoading: isLoadingGetOptions } = useQuery(
    ['option', code],
    ({ signal }) =>
      getProductOptionByCodeV2({
        code,
        signal,
        params: { isProductScreen: false }
      }),
    { enabled: !!code }
  );
  const {
    register,
    getValues,
    setValue,
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FieldValues>({
    defaultValues: {
      name: optionEdit?.name || '',
      description: optionEdit?.originalItem.description || '',
      price: optionEdit && optionEdit.price / 100,
      originalPrice: result && result.data.originalPrice / 100,
      includedTax: VAT_OPTIONS.filter(
        (item) => item.value === optionEdit?.originalItem.includedTax
      )[0]
    }
  });

  useEffect(() => {
    reset({
      name: optionEdit?.name || '',
      description: optionEdit?.originalItem?.description || '',
      price: optionEdit?.customPrice && optionEdit.customPrice / 100,
      originalPrice: result && result.data.originalPrice / 100,
      includedTax: VAT_OPTIONS.filter(
        (item) => item.value === optionEdit?.originalItem?.includedTax
      )[0]
    });
  }, [optionEdit, reset, result]);

  const {
    isLoading: isLoadingEditCreateOption,
    mutate: mutateEditCreateOption
  } = useMutation(({ code, data }: any) => updateProduct(code, data), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('options');
      queryClient.invalidateQueries('getListGroupOptions');
      queryClient.removeQueries('getGrOptionItem');

      const formattedOption = {
        id: res.data.id,
        name: res.data.name,
        originalItem: res.data,
        unit: '€',
        price: res.data.price,
        customPrice: Math.round(
          Number(String(getValues('price')).replace(/,/g, '.')) * 100
        )
      };

      handleEditOption?.({
        data: formattedOption,
        newCode: res.data.code,
        isNewOptionCreated: false,
        formValues: getValues()
      });
    }
  });
  const { mutate, isLoading } = useMutation<
    any,
    Error,
    OptionsFormFields,
    unknown
  >((data: OptionsFormFields) => createOptionItemsV2(data), {
    onSuccess: (
      res: AxiosResponse<
        ProductOption & { '@context': string; '@id': string; '@type': string }
      >
    ) => {
      queryClient.invalidateQueries('getGrOptionItem');
      queryClient.invalidateQueries('getGroupOptionsItem');
      queryClient.invalidateQueries('options');
      handleSuccess(CREATE_MESSAGE.OPTION_SUCCESS);
      const formattedOption = {
        id: res.data.id,
        name: res.data.name,
        originalItem: res.data,
        unit: '€',
        price: res.data.price
      };

      handleEditOption?.({
        data: formattedOption,
        newCode: res.data.code,
        isNewOptionCreated: true
      });
      onClose();
    },
    onError: (error: any) => {
      handleError(error.response.status, CREATE_MESSAGE.OPTION_ERROR);
    }
  });

  const groupOptionValues =
    result?.data.groupOptions.map((item) => item['@id']) ?? [];
  const isOption = result?.data.isOption;

  const onSubmit = () => {
    if (!optionEdit) {
      mutate({
        name: getValues('name').trim(),
        isOption: true,
        inAction: 'create',
        description: getValues('description'),
        price: Math.round(
          Number(String(getValues('price')).replace(/,/g, '.')) * 100
        ),
        includedTax: getValues('includedTax')
          ? getValues('includedTax').value
          : 0,
        groupOptionIRIs: groupOptionSelected
      });
    } else {
      setValue(
        'includedTax',
        getValues('includedTax') ? getValues('includedTax').value : 0
      );

      const data: Partial<OptionsFormFields> = {
        includedTax: getValues('includedTax'),
        name: getValues('name').trim(),
        groupOptionIRIs: groupOptionSelected,
        inAction: 'edit'
      };

      if (isOption) {
        data.isOption = isOption;
      }

      mutateEditCreateOption(
        { code: optionEdit.originalItem.code, data: data },
        {
          onSuccess: () => {
            queryClient.removeQueries('getGrOptionItem');
            queryClient.invalidateQueries('getGroupOptionsItem');
            queryClient.invalidateQueries('getListGroupOptions');
            handleSuccess(UPDATE_MESSAGE.OPTION_SUCCESS);
            onClose();
          }
        }
      );
    }
  };

  return (
    <BlockWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TitleWrapper>
          <DisplayText
            label={!optionEdit ? 'Nouvelle Option' : `Editer l'option`}
            fontSize="XL"
            fontWeight="BOLD"
          />
          <div className="action-wrapper">
            <Button
              variant="primary"
              label="Enregistrer"
              isFluid={false}
              type="submit"
              className="btn-submit"
              fontWeight="BOLD"
              letterSpacing="S"
            />
            <ButtonIcon
              icon={IconNames.Close}
              widthIcon={50}
              variant="square"
              onClick={onClose}
              className="close"
            />
          </div>
        </TitleWrapper>
        <RegulationWrapper>
          <Regulation>
            <p>
              En cliquant sur &#34;Enregistrer&#34; vous confirmez prendre la
              responsabilitée des informations fournies (TVA...)
            </p>
          </Regulation>
        </RegulationWrapper>
        <NameWrapper>
          <TextInput
            placeholder="Nom de l'option"
            variant="title"
            name="name"
            register={register}
            validate={{
              required: {
                value: true,
                message: `Un nom d'option est requis`
              },
              pattern: {
                value: /^[^\S]*\S/,
                message: 'Invalid data'
              }
            }}
            hasError={Boolean(errors.name)}
          />
          {errors.name && (
            <MessageErrorName>{errors.name.message}</MessageErrorName>
          )}
        </NameWrapper>
        <GroupOptionsSelect
          key={groupOptionValues.join('')}
          variant="verticalLayout"
          ignoredGroupOptions={[groupOption ? groupOption['@id'] : '']}
          initialValues={groupOptionValues}
          onSelect={(values) => setGroupOptionSelected([...values])}
        />
        {optionEdit && (
          <FieldWrapper>
            <DisplayText
              as="label"
              fontWeight="BOLD"
              fontSize="M"
              label="Prix d'origine"
              htmlFor="originalP"
            />
            <NumberInput
              name="originalPrice"
              id="originalP"
              variant="unit"
              register={register}
              setValue={setValue}
              getValues={getValues}
              disabled={!!optionEdit}
              type="text"
            />
          </FieldWrapper>
        )}
        <FieldWrapper>
          <DisplayText
            as="label"
            fontWeight="BOLD"
            fontSize="M"
            label="Prix TTC"
          />
          <NumberInput
            name="price"
            validate={{ required: true }}
            register={register}
            variant="unit"
            setValue={setValue}
            getValues={getValues}
            type="text"
          />
        </FieldWrapper>
        <FieldWrapper>
          <DisplayText as="label" fontWeight="BOLD" fontSize="M" label="TVA" />
          <div>
            <DropdownList
              options={VAT_OPTIONS}
              control={control}
              name="includedTax"
              variant="selectSm"
              isDisabled={optionEdit?.originalItem.sellingAsOption}
              placeholder=""
              hasError={Boolean(errors.includedTax)}
              validate={{
                required: {
                  value: true,
                  message: 'La TVA est obligatoire'
                }
              }}
            />
            {Boolean(errors.includedTax) && (
              <MessageErrorName>{errors.includedTax.message}</MessageErrorName>
            )}
          </div>
        </FieldWrapper>
        {(isLoading || isLoadingEditCreateOption || isLoadingGetOptions) && (
          <Loader />
        )}
      </form>
    </BlockWrapper>
  );
}

export default CreateOption;
