import React, { useState, useEffect } from 'react';
import Button from 'components/ui-kits/Button';
import DisplayText from 'components/ui-kits/DisplayText';
import {
  GripLines,
  CardBodyItem,
  Actions,
  ImageWrapper,
  GripAndImage,
  DescriptionWrapper
} from './ProductInCategoryItem.style';
import styled, { css } from 'styled-components';
import {
  ShowEyeIcon,
  HideEyeIcon,
  Cart,
  RemoveCart
} from 'components/ui-kits/Icon';
import { formatDollar } from 'utils';
import { Dot, MultilineTrunc } from 'styles/common';
import Image from 'components/ImageDisplay';
import { useMutation, useQueryClient } from 'react-query';
import Loader from 'components/Loader';
import { updatePatchProduct } from 'services/productService';
import { SHORT_UNIT } from 'const/Const';

const DisabledRemove = styled.div<{
  isRemove: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${({ isRemove }) =>
    isRemove &&
    css`
      opacity: 0.5;
    `}
`;

interface IProps {
  pro: any;
  categoryId: string;
  onRemove: (data: any) => void;
  hasExclude: boolean;
  onEditProduct?: (code: string, categoryId: string) => void;
}

const ProductInCategoryItem = ({
  pro,
  categoryId,
  onRemove,
  hasExclude,
  onEditProduct
}: IProps) => {
  const [isRemove, setIsRemove] = useState(false);
  const queryClient = useQueryClient();
  const handleRemoveProductInCategory = (data: any, action: string) => {
    setIsRemove(!isRemove);
    onRemove({ data: data, action: action });
  };
  useEffect(() => {
    setIsRemove(hasExclude);
  }, [hasExclude]);

  const { mutate: mutateUpdateCart, isLoading: isLoadingUpdateCart } =
    useMutation(
      ({
        code,
        data
      }: Required<{ code: string; data: { isSoldOut: boolean } }>) =>
        updatePatchProduct(code, data)
    );

  function updateOutOfStock({
    code,
    isSoldOut
  }: {
    code: string;
    isSoldOut: boolean;
  }) {
    mutateUpdateCart(
      { code, data: { isSoldOut } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('getProducts');
        }
      }
    );
  }

  return (
    <CardBodyItem>
      <DisabledRemove isRemove={isRemove || pro.isSoldOut}>
        <GripAndImage>
          <GripLines></GripLines>
          <ImageWrapper>
            <Image
              src={
                pro.latestImage
                  ? pro.latestImage.path
                  : pro.images?.[pro.images.length - 1]?.path
              }
            />
          </ImageWrapper>
        </GripAndImage>
        <DescriptionWrapper>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 4
            }}>
            <DisplayText label={`${pro.name}`} fontWeight="BOLD" fontSize="M" />
            {pro.quantityProductSoldByWeight && (
              <>
                <Dot />
                <DisplayText
                  label={`${pro.quantityProductSoldByWeight} ${
                    SHORT_UNIT[pro.unit as 'kilogram' | 'liter' | 'lit']
                  }`}
                  fontWeight="BOLD"
                  fontSize="M"
                />
              </>
            )}
          </div>
          <div className="ref">
            <MultilineTrunc line="2">{pro.reference}</MultilineTrunc>
          </div>
          <div className="desc">
            <MultilineTrunc line="2">{pro.description}</MultilineTrunc>
          </div>
        </DescriptionWrapper>
        <DisplayText
          label={`${formatDollar(pro.price)}€`}
          fontSize="M"
          fontWeight="BOLD"
        />
      </DisabledRemove>
      <Actions>
        <Button
          id="edit"
          label="Modifier"
          variant="link"
          fontWeight="BOLD"
          isUpperCase={false}
          onClick={() => onEditProduct?.(pro.code, categoryId)}
        />
        {!pro.isSoldOut ? (
          <Button
            id="cart-show"
            variant="link"
            onClick={() =>
              updateOutOfStock({ code: pro.code, isSoldOut: true })
            }>
            <Cart width="18" height="18" />
          </Button>
        ) : (
          <Button
            id="cart-show"
            variant="link"
            onClick={() =>
              updateOutOfStock({ code: pro.code, isSoldOut: false })
            }>
            <RemoveCart width="18" height="18" />
          </Button>
        )}

        {isRemove ? (
          <Button
            id="add"
            variant="link"
            fontWeight="BOLD"
            isUpperCase={false}
            onClick={() =>
              handleRemoveProductInCategory(
                {
                  categoryItem: categoryId,
                  productId: pro.id
                },
                'add'
              )
            }>
            <HideEyeIcon />
          </Button>
        ) : (
          <Button
            id="remove"
            variant="link"
            fontWeight="BOLD"
            isUpperCase={false}
            onClick={() =>
              handleRemoveProductInCategory(
                {
                  categoryItem: categoryId,
                  productId: pro.id
                },
                'remove'
              )
            }>
            <ShowEyeIcon />
          </Button>
        )}
      </Actions>
      {isLoadingUpdateCart && <Loader />}
    </CardBodyItem>
  );
};

export default ProductInCategoryItem;
