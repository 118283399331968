import { format } from 'date-fns';
import React from 'react';
import { ListOrderRender } from 'types/OrderList';
import { convertTZ, formatDateMonthYear, getTimeZoneRegion } from 'utils';
import { TableRow, TableCellWrapper, TableCell } from './ItemListOrder.style';

const ItemListOrder = ({
  id,
  dateAndTime,
  orderIds,
  attributes,
  soldAt,
  netRevenue,
  isOpenPanelRight,
  passId
}: ListOrderRender) => {
  const onItemDetail = (id: number) => {
    isOpenPanelRight(true);
    passId(id);
  };
  const region = getTimeZoneRegion();
  const newDateTimeZone = convertTZ(dateAndTime, region);
  const date = format(newDateTimeZone, formatDateMonthYear);
  const time = format(newDateTimeZone, 'HH:mm');

  return (
    <TableRow onClick={() => onItemDetail(id)}>
      <TableCellWrapper>
        <div className="timeHours">{time}</div>
        <div className="day">{date}</div>
      </TableCellWrapper>
      <TableCell>
        <div className="orderIds">{orderIds}</div>
      </TableCell>
      <TableCell>
        <div className="attributes">{attributes}</div>
      </TableCell>
      <TableCell>{soldAt}</TableCell>
      <TableCell>{netRevenue}</TableCell>
    </TableRow>
  );
};

export default ItemListOrder;
