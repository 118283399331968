import React from 'react';
import { TableSubscriptionFeeWrapper } from './TableSubscriptionFee.style';
import Table from 'components/ui-kits/Table';
import CardInvoicesMerchantTable from './ItemSubscriptionFee';
import { formatDollar } from 'utils';

interface Iprops {
  onDataTable: any;
}

const headerTable = [
  { label: '' },
  { label: 'Prix unitaire (HT)' },
  { label: 'Qté.' },
  { label: 'Réduction' },
  { label: 'Total HT' },
  { label: 'TVA' },
  { label: 'Montant TVA' },
  { label: 'Total TTC' }
];

const TableSubscriptionFee = ({ onDataTable }: Iprops) => {
  return (
    <TableSubscriptionFeeWrapper>
      <Table header={headerTable}>
        {onDataTable ? (
          <>
            <CardInvoicesMerchantTable
              type="Abonnement"
              qty={1}
              unitPriceHT={
                onDataTable.invoiceData?.subscription?.amountExcludesVat
              }
              discount={onDataTable.invoiceData?.subscription?.discount}
              totalHT={
                onDataTable.invoiceData?.subscription
                  ?.amountExcludesVatWithDiscount
              }
              vat={onDataTable.invoiceData?.subscription?.vatPercentage}
              vatAmount={onDataTable.invoiceData?.subscription?.vatAmount}
              totalTTC={
                onDataTable.invoiceData?.subscription?.amountIncludesVat
              }
            />
            {onDataTable.invoiceData?.installation?.amountExcludesVat ? (
              <CardInvoicesMerchantTable
                type="Frais d'installation"
                qty={1}
                unitPriceHT={
                  onDataTable.invoiceData?.installation?.amountExcludesVat
                }
                totalHT={
                  onDataTable.invoiceData?.installation?.amountExcludesVat
                }
                vat={onDataTable.invoiceData?.installation?.vatPercentage}
                vatAmount={onDataTable.invoiceData?.installation?.vatAmount}
                totalTTC={
                  onDataTable.invoiceData?.installation?.amountIncludesVat
                }
              />
            ) : null}
            {onDataTable.invoiceData?.serviceFee?.amountExcludesVat ? (
              <CardInvoicesMerchantTable
                type="Frais de service Achil"
                qty={1}
                unitPriceHT={
                  onDataTable.invoiceData?.serviceFee?.amountExcludesVat
                }
                totalHT={onDataTable.invoiceData?.serviceFee?.amountExcludesVat}
                vat={onDataTable.invoiceData?.serviceFee?.vatPercentage}
                vatAmount={onDataTable.invoiceData?.serviceFee?.vatAmount}
                totalTTC={
                  onDataTable.invoiceData?.serviceFee?.amountIncludesVat
                }
              />
            ) : null}
          </>
        ) : (
          <tr>
            <td colSpan={8}></td>
          </tr>
        )}
        <tr className="row-total">
          <td colSpan={4}></td>
          <td>
            {onDataTable?.invoiceData?.total?.amountExcludesVat
              ? `${formatDollar(
                  onDataTable.invoiceData.total.amountExcludesVat
                )} €`
              : null}
          </td>
          <td></td>
          <td>
            {onDataTable?.invoiceData?.total?.vatAmount
              ? `${formatDollar(onDataTable.invoiceData.total.vatAmount)} €`
              : null}
          </td>
          <td>
            {onDataTable?.invoiceData?.total?.amountIncludesVat
              ? `${formatDollar(
                  onDataTable.invoiceData.total.amountIncludesVat
                )} €`
              : null}
          </td>
        </tr>
        <tr className="footer">
          <td colSpan={8}>
            <div className="footerContent">
              Achil SAS / 3 Les Vinottes, 25500 Morteau / n° TVA : FR
              11889430765
            </div>
            <div className="footerContent">
              R.C.S Besançon 889 430 765, Société au capital de 92 500€
            </div>
          </td>
        </tr>
      </Table>
    </TableSubscriptionFeeWrapper>
  );
};

export default TableSubscriptionFee;
