import React from 'react';
import {
  MerchantSubscriptionFeeWrapper,
  MerchantHeadWrapper
} from './MerchantSubscriptionFee.style';
import GoBack from 'components/GoBack';
import InvoicesMerchantHead from '../../InvoicesMerchantHead';
import InformationSubscriptionFee from '../../InvoicesMerchantInformation/InformationSubscriptionFee';
import { useQuery } from 'react-query';
import Loader from 'components/Loader';
import { monthlyInvoicesV2 } from 'services/invoicesService';

interface Iprops {
  onId: number;
}

const MerchantSubscriptionFee = ({ onId }: Iprops) => {
  const {
    isLoading: loadingData,
    data: getListMonthlyInvoices
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQuery('getListMonthlyInvoices', ({ signal }) =>
    monthlyInvoicesV2(onId, signal)
  );

  return (
    <MerchantSubscriptionFeeWrapper>
      <MerchantHeadWrapper>
        <GoBack />
        <InvoicesMerchantHead
          onData={getListMonthlyInvoices}
          label="Facture d'abonnement Achil"
        />
      </MerchantHeadWrapper>
      <InformationSubscriptionFee onData={getListMonthlyInvoices} />
      {loadingData ? <Loader /> : null}
    </MerchantSubscriptionFeeWrapper>
  );
};

export default MerchantSubscriptionFee;
