import api from 'api/setupAxios';

type RevenuesParams = {
  startDate: string;
  endDate: string;
  signal?: AbortSignal;
};

export const revenuesApiV2 = async ({
  startDate,
  endDate,
  signal
}: RevenuesParams) => {
  const res = await api
    .call(signal)
    .get(
      `/merchant/statistics/revenues?startDate=${startDate}&endDate=${endDate}`
    );
  return res.data;
};
