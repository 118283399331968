import React from 'react';
import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';
import { CheckIcon } from 'components/ui-kits/Icon';

interface IValidate {
  [key: string]: any;
}
interface IProps {
  register?: any;
  name: string;
  value?: any;
  defaultChecked?: boolean;
  id?: string;
  validate?: IValidate;
  children?: React.ReactNode;
  className?: string;
  fontSize?: keyof typeof FONT_SIZE;
  fontWeight?: keyof typeof FONT_WEIGHT;
  color?: keyof typeof COLORS;
  disabled?: boolean;
  change?: (e: any) => void;
}

type CheckboxType = Required<
  Pick<IProps, 'fontSize' | 'fontWeight' | 'color' | 'disabled'>
>;

const RadioButton = styled.input`
  display: none;
  &:checked + div {
    border: 1px solid ${COLORS.RED};
    svg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      fill: ${COLORS.WHITE};
    }
  }
  &:checked + div:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.RED};
  }
`;
const LabelStyle = styled.label<CheckboxType>`
  display: inline;
  font-size: ${(props) => FONT_SIZE[props.fontSize]};
  font-weight: ${(props) => FONT_WEIGHT[props.fontWeight]};
  color: ${(props) => COLORS[props.color]};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  .label-name {
    display: inline-block;
    vertical-align: middle;
  }
`;
const RadioStyle = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 1.8rem;
  height: 1.8rem;
  min-width: 1.8rem;
  border-radius: 0.3rem;
  border: 1px solid ${COLORS.GREY_6};
  margin-right: 1.8rem;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
  svg {
    display: none;
  }
`;

const Checkbox = ({
  register,
  name,
  value,
  defaultChecked,
  id,
  validate,
  children,
  fontWeight = 'REGULAR',
  fontSize = 'L',
  color = 'BLACK_2',
  className,
  disabled = false,
  change
}: IProps) => {
  return (
    <LabelStyle
      htmlFor={id}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      disabled={disabled}
      className={className}>
      <RadioButton
        type="checkbox"
        id={id}
        defaultChecked={defaultChecked}
        {...register?.(name, validate)}
        value={value}
        name={name}
        disabled={disabled}
        onChange={(e) => {
          register?.(name).onChange(e);
          if (change) {
            change(e);
          }
        }}
      />
      <RadioStyle className="checkbox-icon" disabled={disabled}>
        <CheckIcon />
      </RadioStyle>
      <div className="label-name">{children}</div>
    </LabelStyle>
  );
};

export default Checkbox;
