import React from 'react';
import { TableOperationCostWrapper } from './TableOperationCost.style';
import Table from 'components/ui-kits/Table';
import { formatDollar, formatPercent, replaceDotToComma } from 'utils';

const headerTableRevenues = [
  { label: '' },
  { label: 'Quantité' },
  { label: 'Revenus TTC' },
  {
    label: 'Frais de service'
  },
  {
    label: 'Frais de service HT'
  },
  { label: 'TVA' },
  { label: 'Montant TVA' },
  {
    label: 'Frais de service TTC'
  },
  { label: 'Revenus net' }
];

const headerTableRefund = [
  { label: '' },
  { label: 'Quantité' },
  { label: 'Remboursement' },
  {
    label: 'Frais de service'
  },
  {
    label: 'Frais de service HT'
  },
  { label: 'TVA' },
  { label: 'Montant TVA' },
  {
    label: 'Frais de service TTC'
  },
  { label: 'Remboursement net' }
];

interface Iprops {
  onDataTable: any;
}

const TableOperationCost = ({ onDataTable }: Iprops) => {
  const $revenues = onDataTable?.normalizedData?.revenues;
  const $refunds = onDataTable?.normalizedData?.refunds;
  const $summary = onDataTable?.normalizedData?.summary;
  const $minimumAmount = onDataTable?.minimumAmount / 100;
  const $cardWithFixedPriceLabel =
    $minimumAmount > 0
      ? `Commande par carte > ${replaceDotToComma($minimumAmount)} €`
      : `Commande par carte`;
  const $cardWithoutFixedPriceLabel =
    $minimumAmount > 0
      ? `Commande par carte ≤ ${replaceDotToComma($minimumAmount)} €`
      : null;
  const $refundCardWithFixedPriceLabel =
    $minimumAmount > 0
      ? `Remboursement par carte > ${replaceDotToComma($minimumAmount)} €`
      : `Remboursement par carte`;
  const $refunCardWithoutFixedPriceLabel =
    $minimumAmount > 0
      ? `Remboursement par carte ≤ ${replaceDotToComma($minimumAmount)} €`
      : null;
  const $cardWithFixedPriceFixedAmount =
    $revenues?.cardWithFixedPrice?.fixedAmount / 100;
  const $cardWithFixedPriceFormattedFixedAmount = `${replaceDotToComma(
    $cardWithFixedPriceFixedAmount
  )} €`;
  const $cardWithFixedPriceServiceFeePercent = `${formatPercent(
    String($revenues?.cardWithFixedPrice?.serviceFee * 100)
  )} %`;
  const $cardWithFixedPriceServiceFee =
    $cardWithFixedPriceFixedAmount > 0
      ? `${$cardWithFixedPriceServiceFeePercent} + ${$cardWithFixedPriceFormattedFixedAmount}`
      : $cardWithFixedPriceServiceFeePercent;
  const $cardWithoutFixedPriceFixedAmount =
    $revenues?.cardWithoutFixedPrice?.fixedAmount / 100;
  const $cardWithoutFixedPriceFormattedFixedAmount = `${replaceDotToComma(
    $cardWithFixedPriceFixedAmount
  )} €`;
  const $cardWithoutFixedPriceServiceFeePercent = `${formatPercent(
    String($revenues?.cardWithoutFixedPrice?.serviceFee * 100)
  )} %`;
  const $cardWithoutFixedPriceServiceFee =
    $cardWithoutFixedPriceFixedAmount > 0
      ? `${$cardWithoutFixedPriceServiceFeePercent} + ${$cardWithoutFixedPriceFormattedFixedAmount}`
      : $cardWithoutFixedPriceServiceFeePercent;
  const $refundCardWithFixedPriceFixedAmount =
    $refunds?.cardWithFixedPrice?.fixedAmount / 100;
  const $refundCardWithFixedPriceFormattedFixedAmount = `${replaceDotToComma(
    $refundCardWithFixedPriceFixedAmount
  )} €`;
  const $refundCardWithFixedPriceServiceFeePercent = `${formatPercent(
    String($refunds?.cardWithFixedPrice?.serviceFee * 100)
  )} %`;
  const $refundCardWithFixedPriceServiceFee =
    $cardWithFixedPriceFixedAmount > 0
      ? `${$refundCardWithFixedPriceServiceFeePercent} + ${$refundCardWithFixedPriceFormattedFixedAmount}`
      : $cardWithFixedPriceServiceFeePercent;
  const $refundCardWithoutFixedPriceFixedAmount =
    $refunds?.cardWithoutFixedPrice?.fixedAmount / 100;
  const $refundCardWithoutFixedPriceFormattedFixedAmount = `${replaceDotToComma(
    $refundCardWithoutFixedPriceFixedAmount
  )} €`;
  const $refundCardWithoutFixedPriceServiceFeePercent = `${formatPercent(
    String($refunds?.cardWithoutFixedPrice?.serviceFee * 100)
  )} %`;
  const $refundCardWithoutFixedPriceServiceFee =
    $cardWithoutFixedPriceFixedAmount > 0
      ? `${$refundCardWithoutFixedPriceServiceFeePercent} + ${$refundCardWithoutFixedPriceFormattedFixedAmount}`
      : $cardWithoutFixedPriceServiceFeePercent;

  return (
    <TableOperationCostWrapper>
      <Table header={headerTableRevenues}>
        <tr>
          <td>{$cardWithFixedPriceLabel}</td>
          <td>{$revenues?.cardWithFixedPrice?.quantity}</td>
          <td>{formatDollar($revenues?.cardWithFixedPrice?.revenueTtc)} €</td>
          <td>{$cardWithFixedPriceServiceFee}</td>
          <td>{formatDollar($revenues?.cardWithFixedPrice?.serviceFeeHt)} €</td>
          <td>
            {formatPercent(String($revenues?.cardWithFixedPrice?.vat * 100))} %
          </td>
          <td>{formatDollar($revenues?.cardWithFixedPrice?.vatAmount)} €</td>
          <td>
            {formatDollar($revenues?.cardWithFixedPrice?.serviceFeeTtc)} €
          </td>
          <td>{formatDollar($revenues?.cardWithFixedPrice?.netRevenue)} €</td>
        </tr>
        {$cardWithoutFixedPriceLabel ? (
          <tr>
            <td>{$cardWithoutFixedPriceLabel}</td>
            <td>{$revenues?.cardWithoutFixedPrice?.quantity}</td>
            <td>
              {formatDollar($revenues?.cardWithoutFixedPrice?.revenueTtc)} €
            </td>
            <td>{$cardWithoutFixedPriceServiceFee}</td>
            <td>
              {formatDollar($revenues?.cardWithoutFixedPrice?.serviceFeeHt)} €
            </td>
            <td>
              {formatPercent(
                String($revenues?.cardWithoutFixedPrice?.vat * 100)
              )}{' '}
              %
            </td>
            <td>
              {formatDollar($revenues?.cardWithoutFixedPrice?.vatAmount)} €
            </td>
            <td>
              {formatDollar($revenues?.cardWithoutFixedPrice?.serviceFeeTtc)} €
            </td>
            <td>
              {formatDollar($revenues?.cardWithoutFixedPrice?.netRevenue)} €
            </td>
          </tr>
        ) : null}
        <tr>
          <td>Commande en espèce</td>
          <td>{$revenues?.cash?.quantity}</td>
          <td>{formatDollar($revenues?.cash?.revenueTtc)} €</td>
          <td>{formatPercent(String($revenues?.cash?.serviceFee * 100))}%</td>
          <td>{formatDollar($revenues?.cash?.serviceFeeHt)} €</td>
          <td>{formatPercent(String($revenues?.cash?.vat * 100))} %</td>
          <td>{formatDollar($revenues?.cash?.vatAmount)} €</td>
          <td>{formatDollar($revenues?.cash?.serviceFeeTtc)} €</td>
          <td>{formatDollar($revenues?.cash?.netRevenue)} €</td>
        </tr>
        <tr>
          <td>Commande en titre-restaurant</td>
          <td>{$revenues?.restaurantTickets?.quantity}</td>
          <td>{formatDollar($revenues?.restaurantTickets?.revenueTtc)} €</td>
          <td>
            {formatPercent(
              String($revenues?.restaurantTickets?.serviceFee * 100)
            )}
            %
          </td>
          <td>{formatDollar($revenues?.restaurantTickets?.serviceFeeHt)} €</td>
          <td>
            {formatPercent(String($revenues?.restaurantTickets?.vat * 100))} %
          </td>
          <td>{formatDollar($revenues?.restaurantTickets?.vatAmount)} €</td>
          <td>{formatDollar($revenues?.restaurantTickets?.serviceFeeTtc)} €</td>
          <td>{formatDollar($revenues?.restaurantTickets?.netRevenue)} €</td>
        </tr>
        <tr>
          <td>Commande en chèque-vacances</td>
          <td>{$revenues?.vacationTickets?.quantity}</td>
          <td>{formatDollar($revenues?.vacationTickets?.revenueTtc)} €</td>
          <td>
            {formatPercent(
              String($revenues?.vacationTickets?.serviceFee * 100)
            )}
            %
          </td>
          <td>{formatDollar($revenues?.vacationTickets?.serviceFeeHt)} €</td>
          <td>
            {formatPercent(String($revenues?.vacationTickets?.vat * 100))} %
          </td>
          <td>{formatDollar($revenues?.vacationTickets?.vatAmount)} €</td>
          <td>{formatDollar($revenues?.vacationTickets?.serviceFeeTtc)} €</td>
          <td>{formatDollar($revenues?.vacationTickets?.netRevenue)} €</td>
        </tr>
      </Table>
      <Table header={headerTableRefund}>
        <tr>
          <td>{$refundCardWithFixedPriceLabel}</td>
          <td>{$refunds?.cardWithFixedPrice?.quantity}</td>
          <td>{formatDollar($refunds?.cardWithFixedPrice?.refundTtc)} €</td>
          <td>{$refundCardWithFixedPriceServiceFee}</td>
          <td>{formatDollar($refunds?.cardWithFixedPrice?.serviceFeeHt)} €</td>
          <td>
            {formatPercent(String($refunds?.cardWithFixedPrice?.vat * 100))} %
          </td>
          <td>{formatDollar($refunds?.cardWithFixedPrice?.vatAmount)} €</td>
          <td>{formatDollar($refunds?.cardWithFixedPrice?.serviceFeeTtc)} €</td>
          <td>{formatDollar($refunds?.cardWithFixedPrice?.netRefund)} €</td>
        </tr>
        {$refunCardWithoutFixedPriceLabel ? (
          <tr>
            <td>{$refunCardWithoutFixedPriceLabel}</td>
            <td>{$refunds?.cardWithoutFixedPrice?.quantity}</td>
            <td>
              {formatDollar($refunds?.cardWithoutFixedPrice?.refundTtc)} €
            </td>
            <td>{$refundCardWithoutFixedPriceServiceFee}</td>
            <td>
              {formatDollar($refunds?.cardWithoutFixedPrice?.serviceFeeHt)} €
            </td>
            <td>
              {formatPercent(
                String($refunds?.cardWithoutFixedPrice?.vat * 100)
              )}{' '}
              %
            </td>
            <td>
              {formatDollar($refunds?.cardWithoutFixedPrice?.vatAmount)} €
            </td>
            <td>
              {formatDollar($refunds?.cardWithoutFixedPrice?.serviceFeeTtc)} €
            </td>
            <td>
              {formatDollar($refunds?.cardWithoutFixedPrice?.netRefund)} €
            </td>
          </tr>
        ) : null}
        <tr>
          <td>Remboursement en espèce</td>
          <td>{$refunds?.cash?.quantity}</td>
          <td>{formatDollar($refunds?.cash?.refundTtc)} €</td>
          <td>{formatPercent(String($refunds?.cash?.serviceFee))} %</td>
          <td>{formatDollar($refunds?.cash?.serviceFeeHt)} €</td>
          <td>{formatPercent(String($refunds?.cash?.vat * 100))} %</td>
          <td>{formatDollar($refunds?.cash?.vatAmount)} €</td>
          <td>{formatDollar($refunds?.cash?.serviceFeeTtc)} €</td>
          <td>{formatDollar($refunds?.cash?.netRefund)} €</td>
        </tr>
      </Table>
      <div className="total">
        <div className="total-item">
          <div>
            <span>Total frais de service Achil (HT)</span>
            <span>{formatDollar($summary?.totalServiceFeesHt)} €</span>
          </div>
          <div>
            <span>TVA sur les frais de service Achil</span>
            <span>{formatPercent(String($summary?.vat * 100))} %</span>
          </div>
          <div>
            <span>Montant total TVA</span>
            <span>{formatDollar($summary?.vatAmount)} €</span>
          </div>
          <div className="total-all">
            <span>Total frais de service Achil (TTC)</span>
            <span>{formatDollar($summary?.totalServiceFeesTtc)} €</span>
          </div>
        </div>
        <div className="total-item">
          <div>
            <span>Revenus net</span>
            <span>{formatDollar($summary?.totalNetRevenue)} €</span>
          </div>
          <div className="total-all">
            <span>Paiement net</span>
            <span>{formatDollar($summary?.netPayment)} €</span>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="footerContent">
          Achil SAS / 3 Les Vinottes, 25500 Morteau / n° TVA : FR 11889430765
        </div>
        <div className="footerContent">
          R.C.S. Besançon 889 430 765, Société au capital de 92 500 €
        </div>
      </div>
    </TableOperationCostWrapper>
  );
};

export default TableOperationCost;
