import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';
import Icon, { IconNames } from '../Icon/Icon';

const OptionBtn = styled.div`
  width: 2.5rem;
  height: 3rem;
  display: flex;
  align-items: center;
  margin: 0;
  color: ${COLORS.GREY_1};
  cursor: pointer;
  .wrapper {
    position: absolute;
  }
  svg {
    width: 0.8rem;
  }
  .list-option {
    position: absolute;
    top: 0;
    right: 1.5rem;
    background-color: ${COLORS.WHITE};
    border-radius: 0.7rem;
    overflow: hidden;
    box-shadow: 0.3rem 0.3rem 0.6rem rgba(0, 0, 0, 0.29);
    z-index: 1;
    &-item {
      z-index: 1;
      display: block;
      padding: 0.8rem 1rem;
      font-weight: ${FONT_WEIGHT.BOLD};
      font-size: ${FONT_SIZE.S};
      min-width: 10rem;
      position: relative;
      color: ${COLORS.BLACK_3};

      &:not(:first-child) {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          width: 80%;
          height: 1px;
          transform: translateX(-50%);
          background-color: ${COLORS.GREY_4};
        }
      }
      &:hover {
        background-color: ${COLORS.GREY_5};
      }
      &.del {
        color: ${COLORS.RED_6};
      }
    }
  }
`;

const IconWrapper = styled.div`
  padding: 0.3rem 0.8rem;
  cursor: pointer;
`;

interface IOptionTooltip {
  component?: JSX.Element;
}

function OptionTooltip({ component }: IOptionTooltip) {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(evt: globalThis.MouseEvent): void {
      if (ref.current && !ref.current.contains(evt.target as any)) {
        setShow(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <OptionBtn ref={ref}>
      <div className="wrapper">
        <IconWrapper
          onClick={() => {
            setShow((show) => !show);
          }}>
          <Icon
            name={IconNames.DotMenuIcon}
            width={10}
            viewBox="9 0 6 24" // this new viewbox to trimspace DotMenuIcon
          />
        </IconWrapper>
        <div className="list-option" onClick={() => setShow(false)}>
          {show && component}
        </div>
      </div>
    </OptionBtn>
  );
}

export default OptionTooltip;
