import React, { useState } from 'react';
import Button from 'components/ui-kits/Button';
import { CaretDown } from 'components/ui-kits/Icon';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';

const DropdownStyle = styled.div`
  position: relative;
  .dropdown {
    &-menu {
      position: absolute;
      top: calc(100% + 0.7rem);
      left: 0;
      border: 1px solid ${COLORS.GREY_4};
      border-radius: 0.7rem;
      background-color: ${COLORS.WHITE};
      z-index: 9;
    }

    &-item {
      font-size: 1.6rem;
      font-weight: 500;
      color: ${COLORS.BLACK_2};
      min-width: 23rem;
      padding: 1.3rem 1.7rem;
      cursor: pointer;
      &:hover,
      &.active {
        background-color: rgba(200, 203, 208, 0.2);
      }
      &:not(last-child) {
        border-bottom: 1px solid ${COLORS.GREY_4};
      }
    }
  }
`;

interface DropdownFinancial {
  mode: 'month' | 'period';
  setDisplayMode(mode: 'month' | 'period'): void;
}

const DropdownFinancial = ({ mode, setDisplayMode }: DropdownFinancial) => {
  const [isDropdown, setIsDropdown] = useState<boolean>(false);
  return (
    <DropdownStyle className="dropdown">
      <Button
        onClick={() => setIsDropdown(!isDropdown)}
        className="report-option"
        isFluid={false}
        fontWeight="MEDIUM"
        sizeType="m"
        isUpperCase={false}>
        <p style={{ flex: 1 }}>
          {mode === 'month' ? 'Mois' : 'Période spécifique'}
        </p>
        <CaretDown height="2rem" />
      </Button>
      {isDropdown && (
        <div className="dropdown-menu">
          <div
            onClick={() => setDisplayMode('month')}
            className={
              mode === 'month' ? 'dropdown-item active' : 'dropdown-item'
            }>
            Mois
          </div>
          <div
            onClick={() => setDisplayMode('period')}
            className={
              mode === 'period' ? 'dropdown-item active' : 'dropdown-item'
            }>
            Période spécifique
          </div>
        </div>
      )}
    </DropdownStyle>
  );
};

export default DropdownFinancial;
