import React, { useState, useEffect } from 'react';
import { PlusIcon } from 'components/ui-kits/Icon';
import Label from 'components/ui-kits/Form/Label';
import CropImage from 'components/CropImage';
import { ImageStyle, ImageWrapper, UploadStyle } from './UploadImageCrop.style';
import { MessageErrorName } from 'styles/common';
import ButtonIcon from 'components/ui-kits/ButtonIcon';
import { IconNames } from 'components/ui-kits/Icon/Icon';

interface IValidate {
  [key: string]: any;
}

interface IProps {
  register?: any;
  multiple?: boolean;
  name: any;
  id?: string;
  validate?: IValidate;
  watchValue?: File[];
  dataImage?: any;
  errors?: any;
  getValues?: any;
  setValue?: any;
  errorFileSizeLarge?(): void;
  imgCropped?: File;
  setImgCropped?: React.Dispatch<React.SetStateAction<any>>;
  setEmptyPhoto?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
}

const UploadImageCrop = ({
  register,
  name,
  id,
  validate,
  watchValue,
  dataImage,
  errors,
  getValues,
  setValue,
  errorFileSizeLarge,
  setImgCropped,
  imgCropped,
  className,
  setEmptyPhoto
}: IProps) => {
  const [srcImage, setSrcImage] = useState<string>();
  const [fileList, setFileList] = useState<File[]>();
  const [openModalCropImage, setOpenModalCropImage] = useState<boolean>(false);
  const [srcImageCrop, setSrcImageCrop] = useState<string>();
  const [prevImage, setPrevImage] = useState<string>();

  useEffect(() => {
    if (dataImage && dataImage.length > 0) {
      setSrcImage(dataImage[dataImage.length - 1].path); // lastest update
    } else {
      setSrcImage('');
    }
  }, [dataImage]);
  useEffect(() => {
    setFileList(getValues(name));
    if (watchValue) {
      setEmptyPhoto?.(false);

      const file = watchValue[0];
      if (file) {
        if (file.size > 10485760) {
          // 10485760 B <=> 10 MB
          setValue(name, fileList);
          errorFileSizeLarge?.();
        } else {
          const fileReader = new FileReader();
          fileReader.onload = (e: any) => {
            const result = e.target.result as string;
            setSrcImage(result);
            setSrcImageCrop(result);
          };
          fileReader.readAsDataURL(file);
        }
      } else {
        if (fileList && fileList.length > 0) {
          setValue(name, fileList);
          setSrcImage(srcImage);
        }
      }
      setOpenModalCropImage(true);
    }
    /* eslint-disable */
  }, [watchValue, dataImage, getValues, name, setValue]);

  useEffect(() => {
    if (!imgCropped) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = (e: any) => {
      const result = e.target.result as string;
      setSrcImage(result);
      setPrevImage(result);
    };
    fileReader.readAsDataURL(imgCropped);
  }, [imgCropped]);

  const closeModalUpdateImageProduct = () => {
    setOpenModalCropImage(false);
    if (prevImage) {
      setSrcImage(prevImage);
    } else {
      if (dataImage && dataImage.length > 0) {
        setValue('files[]', setSrcImage(dataImage[dataImage.length - 1].path));
      } else {
        setSrcImage('');
        setValue(name, null);
      }
    }
  };

  const onSave = (fd: any) => {
    setImgCropped?.(fd);
    setOpenModalCropImage(false);
  };

  const onRemoveImage = () => {
    setSrcImage('');
    setImgCropped?.(undefined);
    setEmptyPhoto?.(true);
    setValue(name, null);
    setPrevImage('');
  };

  return (
    <ImageWrapper className={className}>
      <ButtonIcon
        icon={IconNames.Close}
        widthIcon={24}
        className="icon-close-image"
        onClick={onRemoveImage}
      />
      <input
        id={id}
        type="file"
        accept="image/*"
        watchvalue={watchValue}
        {...register?.(name, validate)}
      />
      <Label forId={id}>
        {!srcImage ? (
          <UploadStyle formState={errors.files}>
            <PlusIcon />
          </UploadStyle>
        ) : (
          <ImageStyle src={srcImage} />
        )}
      </Label>

      {errors.files && (
        <MessageErrorName>{errors.files.message}</MessageErrorName>
      )}

      {openModalCropImage && srcImageCrop && getValues('files') && (
        <CropImage
          srcImage={srcImageCrop}
          file={getValues('files')}
          onCloseCropImage={closeModalUpdateImageProduct}
          onModeSave={onSave}
          mode={'product'}
        />
      )}
    </ImageWrapper>
  );
};

export default UploadImageCrop;
