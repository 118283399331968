import FormCreateEditLayout from 'components/FormCreateEditLayout';
import FormLeftLayout from 'components/FormCreateEditLayout/FormLeftLayout';
import PanelRightLayout from 'components/FormCreateEditLayout/PanelRightLayout';
import GoBack from 'components/GoBack';
import Loader from 'components/Loader';
import {
  ActionWrapper,
  BlockRelative,
  ContainerInput,
  ItemWrapper,
  OptionItemWrapper,
  Regulation,
  SectionNameWrapper,
  TextInputWrapper,
  TitleSmall,
  TitleWrapper
} from 'components/ProductLayout/ProductForm/ProductForm.style';
import Button from 'components/ui-kits/Button';
import { DropdownList } from 'components/ui-kits/Form/Dropdown';
import NumberInput from 'components/ui-kits/Form/NumberInput';
import TextInput from 'components/ui-kits/Form/TextInput';
import { Grid, GridItem } from 'components/ui-kits/Grid';
import TrashBinIcon from 'components/ui-kits/Icon/TrashBin';
import ModalPrompt from 'components/ui-kits/ModalPrompt';
import { VAT_OPTIONS } from 'const/Const';
import { CONFIRM_MESSAGE, DELETE_MESSAGE } from 'const/Message';
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { deleteProductOptionV2 } from 'services/productOptionService';
import { MessageErrorName } from 'styles/common';
import {
  GridItemBtnDelete,
  GridItemBtnSave,
  GridItemInput,
  GridSectionName
} from 'styles/createEdit-page';
import {
  ProductOptionFormParamsV3,
  ProductOptionFormV3
} from 'types/ProductOption';
import { ProductRes } from 'types/Products';
import { handleError, handleSuccess, replaceCharacter } from 'utils';
import { GroupOptionsSelect } from './GroupOptionsSelect';
import {
  PriceVatWrapper,
  PriceWrapper,
  VatWrapper
} from './ProductOptionsForm.style';

type ProductOptionsProps = {
  mode: 'create' | 'edit';
  onCreate?: (data: ProductOptionFormParamsV3) => void;
  onEdit?: (data: ProductOptionFormParamsV3) => void;
  dataEdit?: ProductRes;
};

export default function ProductOptionsFrom({
  mode = 'create',
  onCreate,
  onEdit,
  dataEdit
}: ProductOptionsProps) {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [groupOptionSelected, setGroupOptionSelected] = useState<string[]>([]);

  const queryClient = useQueryClient();
  const history = useHistory();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset
  } = useForm();
  const watchTitle = useWatch({
    control,
    name: 'name'
  });

  useEffect(() => {
    reset({
      ...dataEdit,
      vat: VAT_OPTIONS.filter(
        (option) => Number(option.value) == dataEdit?.includedTax
      )[0],
      price: dataEdit?.variants?.[0].price && dataEdit.variants[0].price / 100
    });

    setGroupOptionSelected(
      dataEdit?.groupOptions.map((item) => item['@id']) ?? []
    );
  }, [dataEdit, reset]);

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    deleteProductOptionV2
  );

  const handleDelete = (res: boolean) => {
    if (res && dataEdit?.code) {
      mutateDelete(
        { code: dataEdit?.code, data: { enabled: false } },
        {
          onSuccess: () => {
            handleSuccess(DELETE_MESSAGE.OPTION_SUCCESS);
            queryClient.invalidateQueries('options');
            setConfirmDelete(false);
            history.push('/menu/options');
          },
          onError: (error: any) => {
            handleError(error.response.status, DELETE_MESSAGE.OPTION_ERROR);
            setConfirmDelete(false);
          }
        }
      );
    }
  };

  const onSubmit = (data: ProductOptionFormV3) => {
    const optionData: ProductOptionFormParamsV3 = {
      description: data.description,
      name: data.name,
      includedTax: Number(data.vat.value),
      price: Math.round(
        Number(replaceCharacter(String(data.price), ',', '.')) * 100
      ),
      groupOptionIRIs: groupOptionSelected
    };
    if (mode === 'create') {
      onCreate?.({ ...optionData, inAction: 'create' });
    } else {
      onEdit?.(optionData);
    }
  };

  return (
    <React.Fragment>
      <FormCreateEditLayout>
        <FormLeftLayout>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SectionNameWrapper
              col={GridSectionName.col}
              start={GridSectionName.start}>
              <GoBack />
              <ContainerInput
                colEnd={GridItemInput.colEnd}
                nameEnd={GridItemInput.nameEnd}>
                <TextInputWrapper>
                  <TextInput
                    placeholder="Nom de l'option"
                    variant="title"
                    name="name"
                    register={register}
                    getValues={getValues}
                    watchValue={watchTitle}
                    validate={{
                      required: {
                        value: true,
                        message: "Un nom d'option est requis"
                      },
                      pattern: {
                        value: /^[^\S]*\S/,
                        message: 'Invalid data'
                      }
                    }}
                    defaultValue={dataEdit?.name}
                    hasError={Boolean(errors.name)}
                  />
                </TextInputWrapper>
                {errors.name && (
                  <MessageErrorName>{errors.name.message}</MessageErrorName>
                )}
              </ContainerInput>
              <ActionWrapper
                colStart={GridItemBtnSave.colStart}
                colEnd={GridItemBtnSave.colEnd}
                nameStart={GridItemBtnSave.nameStart}
                nameEnd={GridItemBtnSave.nameEnd}>
                <Button
                  label="Enregistrer"
                  isFluid={true}
                  sizeType="s"
                  variant="primary"
                  fontWeight="BOLD"
                  type="submit"
                  letterSpacing="S"
                />
              </ActionWrapper>
              {mode === 'edit' && (
                <ActionWrapper
                  colStart={GridItemBtnDelete.colStart}
                  colEnd={GridItemBtnDelete.colEnd}
                  nameStart={GridItemBtnDelete.nameStart}
                  nameEnd={GridItemBtnDelete.nameEnd}>
                  <Button
                    isFluid={false}
                    sizeType="s"
                    fontWeight="BOLD"
                    variant="cancel"
                    onClick={() => setConfirmDelete(true)}
                    icon={<TrashBinIcon width="14" height="16" />}
                    label="Supprimer"
                    letterSpacing="S"
                  />
                </ActionWrapper>
              )}
            </SectionNameWrapper>
            <Grid col={7} start="large">
              <GridItem colEnd={6} nameEnd="gutter">
                <ItemWrapper>
                  <Regulation>
                    <p>
                      En cliquant sur &ldquo;Enregistrer&rdquo; vous confirmez
                      prendre la responsabilitée des informations fournies (TVA,
                      ...)
                    </p>
                  </Regulation>

                  <GroupOptionsSelect
                    variant="horizontalLayout"
                    ignoredGroupOptions={[]}
                    key={dataEdit?.groupOptions
                      .map((item: any) => item['@id'])
                      .join('')}
                    initialValues={
                      dataEdit?.groupOptions.map((item: any) => item['@id']) ??
                      []
                    }
                    onSelect={(values) => setGroupOptionSelected(values)}
                  />
                  <PriceVatWrapper>
                    <PriceWrapper>
                      <TitleWrapper>
                        <TitleSmall>Prix TTC</TitleSmall>
                      </TitleWrapper>
                      <NumberInput
                        name="price"
                        validate={{ required: true }}
                        register={register}
                        variant="unit"
                        defaultValue={
                          dataEdit && dataEdit.variants[0].price / 100
                        }
                        setValue={setValue}
                        getValues={getValues}
                        type="text"
                      />
                    </PriceWrapper>
                    <VatWrapper>
                      <TitleSmall>TVA</TitleSmall>
                      <BlockRelative>
                        <DropdownList
                          name="vat"
                          variant="selectSm"
                          placeholder=""
                          options={VAT_OPTIONS}
                          control={control}
                          validate={{
                            required: {
                              value: true,
                              message: 'La TVA est obligatoire'
                            }
                          }}
                          hasError={Boolean(errors.vat)}
                          defaultValue={
                            VAT_OPTIONS.filter(
                              (option) => option.value === dataEdit?.includedTax
                            )[0]
                          }
                        />
                        {errors.vat && (
                          <MessageErrorName>
                            {errors.vat.message}
                          </MessageErrorName>
                        )}
                      </BlockRelative>
                    </VatWrapper>
                  </PriceVatWrapper>
                </ItemWrapper>
              </GridItem>
            </Grid>
          </form>
        </FormLeftLayout>
        <PanelRightLayout>
          <OptionItemWrapper></OptionItemWrapper>
        </PanelRightLayout>
      </FormCreateEditLayout>
      <ModalPrompt
        isShow={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onConfirm={() => handleDelete(true)}
        title={CONFIRM_MESSAGE.DELETE_OPTION}
        confirmType="withTrashBin"
      />
      {isLoadingDelete && <Loader />}
    </React.Fragment>
  );
}
