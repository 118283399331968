import React from 'react';
import NumberInput from 'components/ui-kits/Form/NumberInput';
import {
  BlockItemWrapper,
  TitleWrapper,
  TitleSmall
} from './ProductForm.style';
import {
  Control,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
  useWatch
} from 'react-hook-form';

export function FieldPrice({
  control,
  register,
  getValues,
  setValue,
  defaultValue,
  onBlur
}: {
  control: Control;
  register: any;
  setValue?: UseFormSetValue<FieldValues>;
  getValues?: UseFormGetValues<FieldValues>;
  defaultValue?: string | number | undefined;
  onBlur?: () => void;
}) {
  const watchIsSellProductWithUnits = useWatch({
    control,
    name: 'isSellProductWithUnits'
  });

  return watchIsSellProductWithUnits ? null : (
    <BlockItemWrapper>
      <TitleWrapper>
        <TitleSmall>Prix</TitleSmall>
        <span>(TTC)</span>
      </TitleWrapper>
      <NumberInput
        name="price"
        validate={{ required: true }}
        register={register}
        variant="unit"
        defaultValue={defaultValue}
        setValue={setValue}
        getValues={getValues}
        type="text"
        disabled={watchIsSellProductWithUnits}
        onBlur={onBlur}
      />
    </BlockItemWrapper>
  );
}
