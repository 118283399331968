import React from 'react';

function SearchIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1.8rem"
      width="1.8rem"
      viewBox="0 0 24 24"
      {...props}>
      <path
        fill="#929292"
        xmlns="http://www.w3.org/2000/svg"
        d="m19.575 21.525-6.4-6.375q-.75.525-1.712.85-.963.325-2.088.325-2.85 0-4.837-1.988Q2.55 12.35 2.55 9.5q0-2.85 1.988-4.838 1.987-1.987 4.837-1.987 2.875 0 4.85 1.987Q16.2 6.65 16.2 9.5q0 1.125-.313 2.075-.312.95-.862 1.675l6.425 6.425Zm-10.2-7.85q1.75 0 2.963-1.213Q13.55 11.25 13.55 9.5q0-1.75-1.212-2.963-1.213-1.212-2.963-1.212-1.725 0-2.95 1.212Q5.2 7.75 5.2 9.5t1.225 2.962q1.225 1.213 2.95 1.213Z"
      />
    </svg>
  );
}

export default SearchIcon;
