import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';

export const ItemSubscriptionFeeWrapper = styled.tr`
  td {
    vertical-align: top;
    padding: 2rem 0;
    font-size: 1.6rem;
    color: ${COLORS.BLACK_3};
    text-align: center;

    &:first-child {
      font-weight: 700;
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }

  .ItemLeft,
  .ItemRight {
    display: flex;
    padding-top: 1.4rem;
    font-size: ${FONT_SIZE.M};
    color: ${COLORS.GREY_6};

    .labelWapper {
      .bold {
        font-weight: ${FONT_WEIGHT.BOLD};
      }
      .vatAmount {
        padding-bottom: 2.8rem;
      }
      div {
        padding-bottom: 1.4rem;
      }
    }
    .valueWrapper {
      .vatAmount {
        padding-bottom: 2.8rem;
      }
      div {
        padding-bottom: 1.4rem;
        padding-left: 8.4rem;
      }
    }
  }
  .ItemRight {
    justify-content: flex-end;
  }
`;
