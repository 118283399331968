import React from 'react';
import { useForm } from 'react-hook-form';
import DisplayText from 'components/ui-kits/DisplayText';
import { MerchantInfoWrapper } from './MerchantInfo.style';
import { Checkbox } from 'components/ui-kits/Form/Checkbox';
import TextInput from 'components/ui-kits/Form/TextInput';
import { Merchant } from 'types/Merchant';

const MerchantInfo = ({ data }: { data: Merchant }) => {
  const { register } = useForm();
  return (
    <MerchantInfoWrapper>
      <div className="merchantInfo__header">
        <DisplayText
          label="Informations de société"
          fontSize="XXL"
          fontWeight="LIGHT"
        />
      </div>
      <div className="merchantInfo__fields">
        <div className="merchantInfo__item">
          <DisplayText
            label="Raison sociale"
            fontSize="S"
            color="GREY_1"
            margin="0 0 0.6rem 0"
          />
          <DisplayText
            label={data.enterpriseName}
            fontSize="M"
            fontWeight="BOLD"
          />
        </div>
        <div className="merchantInfo__item">
          <DisplayText
            label="Adresse"
            fontSize="S"
            color="GREY_1"
            margin="0 0 0.6rem 0"
          />
          <DisplayText label={data.address} fontSize="M" fontWeight="BOLD" />
        </div>
        <div className="merchantInfo__item">
          <DisplayText
            label="Ville"
            fontSize="S"
            color="GREY_1"
            margin="0 0 0.6rem 0"
          />
          <DisplayText label={data.city} fontSize="M" fontWeight="BOLD" />
        </div>
        <div className="merchantInfo__item">
          <DisplayText
            label="Code postale"
            fontSize="S"
            color="GREY_1"
            margin="0 0 0.6rem 0"
          />
          <DisplayText label={data.postalCode} fontSize="M" fontWeight="BOLD" />
        </div>
        <div className="merchantInfo__item">
          <DisplayText
            label="SIRET"
            fontSize="S"
            color="GREY_1"
            margin="0 0 0.6rem 0"
          />
          <TextInput
            name="siret"
            variant="title"
            defaultValue={data.siretCode}
            disabled={true}
          />
        </div>
        <div className="merchantInfo__item">
          <DisplayText
            label="n° TVA"
            fontSize="S"
            color="GREY_1"
            margin="0 0 0.6rem 0"
          />
          <TextInput
            name="vatNumber"
            variant="title"
            defaultValue={data.taxNumber}
            disabled={true}
          />
        </div>
      </div>
      <div className="fields__checkbox-wrapper">
        <Checkbox
          register={register}
          name="merchantInfoCheckbox"
          value="Small business"
          defaultChecked={data.isSmallBusiness}
          disabled={true}>
          Régime de petite entreprise ou auto-entrepreneur
        </Checkbox>
        <DisplayText
          fontSize="S"
          colorExternal="#727883"
          className="description"
          margin="1.4rem 0 4.2rem 3.6rem">
          Il se peut qu&#39;un « régime de petite entreprise » ou un « seuil
          d&#39;enregistrement à la TVA » soit applicable dans votre pays. Nous
          souhaitons savoir si vous avez sollicité un tel régime auprès des
          autorités fiscales de votre localité.
        </DisplayText>

        <Checkbox
          register={register}
          name="merchantInfoCheckbox"
          value="Disclaimer"
          defaultChecked={data.acceptDisclaimer}
          disabled={true}>
          Avertissement
        </Checkbox>
        <DisplayText
          fontSize="S"
          colorExternal="#727883"
          className="description"
          margin="1.4rem 0 4.2rem 3.6rem">
          <ol>
            <li>
              Je confirme que les informations que j&#39;ai communiquées à Achil
              concernant la situation fiscale de mon entreprise sont correctes.
              En fournissant ces informations, je consens expressément à ce
              qu&#39;Achil, lorsque cela s&#39;avérera nécessaire, émette des
              factures fiscalement conformes au nom de mon entreprise pour les
              services réalisés par mon entreprise par le biais de la plateforme
              Achil.
            </li>
            <li>
              Je comprends et j&#39;accepte qu&#39;en fournissant un numéro de
              TVA, Achil me traitera automatiquement comme une entreprise
              enregistrée assujettie à la TVA.
            </li>
            <li>
              Je comprends et j&#39;accepte qu&#39;Achil ne saurait être tenu
              responsable de l&#39;inexactitude des informations que j&#39;ai
              fournies, y compris, mais sans s&#39;y limiter, les informations
              liées à : - mon numéro de TVA ; - l&#39;applicabilité d&#39;ƒun
              régime réservé aux petites entreprises.
            </li>
          </ol>
        </DisplayText>
      </div>
    </MerchantInfoWrapper>
  );
};

export default MerchantInfo;
