import styled from 'styled-components';
import { COLORS } from 'styles/variables';

export const TableMonthStyle = styled.div`
  margin: 2rem 4.9rem;

  .table-wrapper {
    overflow-x: auto;

    th,
    td {
      text-align: center;
    }
    th:first-child,
    td:first-child {
      padding: 1.4rem 0.7rem;
    }
    td {
      font-size: 1.6rem;
      font-weight: 300;
      padding: 0;
      border-right: 1px solid ${COLORS.GREY_4};
      color: ${COLORS.GREY_12};
    }
    th {
      padding: 1.4rem 1rem;
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
    .cell-month {
      font-weight: 700;
      color: ${COLORS.BLACK_2};
    }
    .cell-download svg {
      margin: 0 auto;
    }
    button {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;
