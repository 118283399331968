import React from 'react';

function PlusIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="2.4rem"
      height="2.4rem"
      {...props}>
      <g id="surface217436524">
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: '#C8C8C8'
          }}
          d="M 12 2.398438 C 6.699219 2.398438 2.398438 6.699219 2.398438 12 C 2.398438 17.300781 6.699219 21.601562 12 21.601562 C 17.300781 21.601562 21.601562 17.300781 21.601562 12 C 21.601562 6.699219 17.300781 2.398438 12 2.398438 Z M 16.800781 12.800781 L 12.800781 12.800781 L 12.800781 16.800781 C 12.800781 17.242188 12.441406 17.601562 12 17.601562 C 11.558594 17.601562 11.199219 17.242188 11.199219 16.800781 L 11.199219 12.800781 L 7.199219 12.800781 C 6.757812 12.800781 6.398438 12.441406 6.398438 12 C 6.398438 11.558594 6.757812 11.199219 7.199219 11.199219 L 11.199219 11.199219 L 11.199219 7.199219 C 11.199219 6.757812 11.558594 6.398438 12 6.398438 C 12.441406 6.398438 12.800781 6.757812 12.800781 7.199219 L 12.800781 11.199219 L 16.800781 11.199219 C 17.242188 11.199219 17.601562 11.558594 17.601562 12 C 17.601562 12.441406 17.242188 12.800781 16.800781 12.800781 Z M 16.800781 12.800781 "
        />
      </g>
    </svg>
  );
}

export default PlusIcon;
