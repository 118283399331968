import React from 'react';

function QuestionIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.8rem"
      height="1.8rem"
      viewBox="0 0 18 18"
      {...props}>
      <path
        id="Icon_awesome-question-circle"
        data-name="Icon awesome-question-circle"
        d="M18.563,9.563a9,9,0,1,1-9-9A9,9,0,0,1,18.563,9.563ZM9.8,3.538a4.7,4.7,0,0,0-4.23,2.314.436.436,0,0,0,.1.59L6.932,7.4a.435.435,0,0,0,.6-.077c.648-.822,1.093-1.3,2.08-1.3.741,0,1.658.477,1.658,1.2,0,.543-.449.823-1.181,1.233-.854.479-1.983,1.074-1.983,2.564v.145a.436.436,0,0,0,.435.435h2.032a.436.436,0,0,0,.435-.435v-.048c0-1.033,3.019-1.076,3.019-3.871C14.033,5.135,11.85,3.538,9.8,3.538Zm-.242,9a1.669,1.669,0,1,0,1.669,1.669A1.671,1.671,0,0,0,9.563,12.538Z"
        transform="translate(-0.563 -0.563)"
        fill="#878c97"
      />
    </svg>
  );
}

export default QuestionIcon;
