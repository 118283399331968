import React from 'react';
import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';
import Icon, { IconNames } from '../Icon/Icon';

interface ITag {
  label: string;
  onDelete?(): void;
  className?: string;
}

const TagStyle = styled.div`
  border: 1px solid ${COLORS.GREY_4};
  border-radius: 4rem;
  color: ${COLORS.GREY_9};
  background-color: ${COLORS.WHITE};
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  font-size: ${FONT_SIZE.M};
  font-weight: ${FONT_WEIGHT.REGULAR};
  padding: 0.9rem 1.5rem;

  .btn-delete {
    margin-left: 1.7rem;
    width: 1.8rem;
    fill: ${COLORS.GREY_9};
    cursor: pointer;
  }
`;

function Tag({ label, onDelete, className }: ITag) {
  return (
    <TagStyle className={className}>
      {label}
      <Icon
        className="btn-delete"
        onClick={onDelete}
        name={IconNames.Cross}
        width="1.2rem"
      />
    </TagStyle>
  );
}

export default Tag;
