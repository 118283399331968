import React, { useState } from 'react';
import { FieldValues, UseFormGetValues } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { COLORS, FONT_SIZE } from 'styles/variables';

interface IValidate {
  [key: string]: any;
}
const variantStyle = ({
  watchValue,
  disabled,
  hasError
}: {
  watchValue?: string;
  disabled?: boolean;
  hasError?: boolean;
}) => ({
  default: css`
    color: ${COLORS.BLACK_2};
    border: 1px solid ${COLORS.GREY_2};
    border-radius: 0.7rem;
    border: ${hasError && `1px solid ${COLORS.RED_6}`};
    &:focus {
      outline: none;
      border: ${hasError
        ? `1px solid ${COLORS.RED_6};`
        : `1px solid ${COLORS.GREY_2}`};
    }
  `,
  title: css`
    border-bottom: ${disabled || watchValue
      ? 'none;'
      : `1px solid ${COLORS.GREY_2};`};
    background-color: transparent;
    font-size: ${FONT_SIZE.XXL};
    padding: 0;
    &:focus {
      outline: none;
      border-bottom: ${hasError
        ? `2px solid ${COLORS.RED_6};`
        : `2px solid ${COLORS.GREY_2}`};
    }
    border-bottom: ${hasError && `2px solid ${COLORS.RED_6};`};
  `
});

type variantTypes = 'default' | 'title';
interface IProps {
  type?: string;
  name: any;
  id?: string;
  value?: string;
  defaultValue?: string;
  register?: any;
  validate?: IValidate;
  placeholder?: string;
  variant?: variantTypes;
  watchValue?: string;
  getValues?: UseFormGetValues<FieldValues>;
  disabled?: boolean;
  hasError?: boolean;
  className?: string;
}

const InputStyle = styled.input<{
  watchValue?: string;
  variant: variantTypes;
  disabled?: boolean;
  hasError?: boolean;
}>`
  width: 100%;
  height: 5rem;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0 2rem;
  color: ${COLORS.BLACK_2};
  line-height: 1.7rem;
  box-sizing: border-box;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${COLORS.BLACK_2};
    opacity: 0.5;
  }
  :-ms-input-placeholder {
    color: ${COLORS.BLACK_2};
    opacity: 0.5;
  }
  ${({ watchValue, variant, disabled, hasError }) =>
    variantStyle({ watchValue, disabled, hasError })[variant]}
`;

const TextInput = ({
  type = 'text',
  name,
  id,
  register,
  defaultValue,
  validate,
  placeholder,
  getValues,
  value,
  variant = 'default',
  disabled,
  hasError,
  className
}: IProps) => {
  const [isFocus, setIsFocus] = useState(false);
  const registerField =
    register &&
    register(name, {
      ...validate
    });
  return (
    <InputStyle
      {...registerField}
      type={type}
      defaultValue={defaultValue}
      placeholder={placeholder}
      id={id}
      value={value}
      isFocus={isFocus}
      variant={variant}
      disabled={disabled}
      watchValue={getValues && getValues(name)}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      hasError={hasError}
      className={className}
    />
  );
};

export default TextInput;
