import styled from 'styled-components';
import DisplayText from 'components/ui-kits/DisplayText';
import { COLORS } from 'styles/variables';

export const InfoWrapper = styled.div`
  display: flex;
`;

export const CoverImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;

  + div {
    right: 4px;
    bottom: 4px;

    & {
      &:hover {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &:hover + div {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s;

    &:hover {
      .custom-icon {
        background-color: ${COLORS.WHITE};
      }
    }
  }
`;

export const InfoImage = styled.div`
  position: absolute;
  top: 10rem;

  img {
    width: 29.4rem;
    height: 29.4rem;
    object-fit: cover;
    border-radius: 100%;
  }

  form:hover > div {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s;
  }
`;

export const EditAbsolute = styled.div`
  position: relative;
  left: 50%;
  max-width: fit-content;
  transform: translateX(-50%);
  right: -0.4rem;
  bottom: 1.9rem;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;

  input {
    display: none;
  }

  label {
    display: inline-block;
    margin: 0;
  }

  .custom-icon {
    position: relative;
    width: 3.6rem;
    height: 3.6rem;
    background-color: ${COLORS.WHITE};
    box-shadow: 0.1rem 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: ${COLORS.GREY_5};
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1rem;
      height: 1.6rem;
    }
  }
`;

export const InfoDetail = styled.div`
  flex-grow: 1;
  margin-left: 3rem;
  margin-top: 25rem;
  margin-left: 29.5rem;
  padding: 1.4rem 3.2rem 3.2rem 2.8rem;
`;

export const Name = styled(DisplayText)`
  line-height: 7rem;
`;

export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;

  &:hover button {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s;
  }

  button {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
  }
`;

export const TextMargin = styled(DisplayText)`
  margin: 0 0.6rem 0 1.2rem;
`;
