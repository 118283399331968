import React, { useEffect, useState } from 'react';
import Button from 'components/ui-kits/Button';
import ButtonIcon from 'components/ui-kits/ButtonIcon';
import TextInput from 'components/ui-kits/Form/TextInput';
import Icon, { IconNames } from 'components/ui-kits/Icon/Icon';
import styled from 'styled-components';
import { COLORS, GUTTER, MEDIA_BREAKPOINT } from 'styles/variables';
import { FieldValues, useForm, useWatch } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import Loader from 'components/Loader';
import { CREATE_MESSAGE, SIZE_IS_TOO_BIG, UPDATE_MESSAGE } from 'const/Message';
import { handleSuccess, handleError } from 'utils/index';
import { MessageErrorName } from 'styles/common';
import {
  createCategoryV2,
  editCategoryV2,
  uploadImageCategory
} from 'services/categoryService';
import CropImage from 'components/CropImage';
import Label from 'components/ui-kits/Form/Label';
import { UploadStyle } from 'components/UploadImageCrop/UploadImageCrop.style';
import { PlusIcon } from 'components/ui-kits/Icon';

const FormWrapper = styled.form`
  position: relative;
  padding: 1.4rem 2.8rem;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GREY_4};
  border-radius: 0.7rem;
  margin-bottom: 5rem;

  .title-wrapper {
    display: flex;
    align-items: center;
  }
  .input-wrapper {
    width: 100%;
  }
  .btn-icon-wrapper {
    margin-right: 2.6rem;
    color: ${COLORS.GREY_10};
  }
  .btn-wrapper {
    @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
      margin-left: ${GUTTER.DESKTOP_S}px;
    }
    @media ${MEDIA_BREAKPOINT.DESKTOP_M} {
      margin-left: ${GUTTER.DESKTOP_M}px;
    }
    @media ${MEDIA_BREAKPOINT.DESKTOP_L} {
      margin-left: ${GUTTER.DESKTOP_L}px;
    }
  }
  .image-wrapper {
    margin-right: 2rem;
    .upload-image {
      position: relative;
      display: inline-block;
      img {
        width: 7.4rem;
        height: 7.4rem;
        border-radius: 0.7rem;
        object-fit: cover;
        max-width: fit-content;
      }
      label {
        display: inline-block;
        cursor: pointer;
        margin-bottom: 0;
        & > div {
          width: 7.4rem;
          height: 7.4rem;
        }
      }
      input[type='file'] {
        display: none;
      }
      .icon-close-image {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        background: #fff;
        z-index: 2;
        border: 1px solid #a5a5a5;
        width: 3rem;
        height: 3rem;
        svg {
          width: 2rem;
          height: 2rem;
          path {
            fill: #ff6f61;
          }
        }
      }
    }
  }
`;

interface ICategoryNameForm {
  name?: string;
  defaultName?: string;
  defaultImage?: string;
  code?: string;
  onCancel(): void;
  onDelete?(): void;
}

function CategoryNameForm({
  defaultName,
  defaultImage,
  name = 'name',
  code,
  onCancel,
  onDelete = onCancel
}: ICategoryNameForm) {
  const [srcImageCrop, setSrcImageCrop] = useState<string | null>();
  const [srcImage, setSrcImage] = useState<string>();
  const [openModalCropImage, setOpenModalCropImage] = useState<boolean>(false);
  const {
    register,
    getValues,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FieldValues>({
    defaultValues: {
      name: defaultName || ''
    }
  });
  const queryClient = useQueryClient();
  const watchUpload = useWatch({
    control,
    name: 'files'
  });
  const toastErrorFileSizeLarge = () => {
    handleError(undefined, SIZE_IS_TOO_BIG);
  };
  useEffect(() => {
    setSrcImage(defaultImage);
  }, [defaultImage]);
  useEffect(() => {
    if (watchUpload) {
      const file = watchUpload[0];
      if (file) {
        if (file.size > 10485760) {
          // 10485760 B <=> 10 MB
          toastErrorFileSizeLarge();
        } else {
          const fileReader = new FileReader();
          fileReader.onload = (e: any) => {
            const result = e.target.result as string;
            setSrcImage(result);
          };
          fileReader.readAsDataURL(file);
        }
      }
      setOpenModalCropImage(true);
    }
  }, [watchUpload]);

  const { isLoading: loadingUploadImage, mutate: mutateUploadImage } =
    useMutation(uploadImageCategory);
  const { isLoading: loadingCreateCategory, mutate: createNewCategory } =
    useMutation(createCategoryV2, {
      onSuccess: (res) => {
        const fd = new FormData();
        if (srcImageCrop) {
          fd.append('files[]', srcImageCrop);
        }
        if (fd.getAll('files[]').length <= 0) {
          queryClient.invalidateQueries('getListCategories');
          handleSuccess(CREATE_MESSAGE.CATEGORY_SUCCESS);
          onCancel();
          return;
        }
        mutateUploadImage(
          { code: res.code, fd },
          {
            onSuccess: () => {
              queryClient.invalidateQueries('getListCategories');
              handleSuccess('The image is uploaded successfully');
              onCancel();
            },
            onError: (error: any) => {
              handleError(error.response.status);
              onCancel();
            }
          }
        );
      },
      onError: (error: any) => {
        handleError(error.response.status, CREATE_MESSAGE.CATEGORY_ERROR);
      }
    });
  const { isLoading: loadingUpdateCategory, mutate: editOldCategory } =
    useMutation(editCategoryV2, {
      onSuccess: (res) => {
        const fd = new FormData();
        if (srcImageCrop) {
          fd.append('files[]', srcImageCrop);
        }
        if (fd.getAll('files[]').length <= 0) {
          handleSuccess(UPDATE_MESSAGE.CATEGORY_SUCCESS);
          mutateUploadImage(
            { code: res.code, fd: null },
            {
              onSuccess: () => {
                queryClient.invalidateQueries('getListCategories');
                handleSuccess('The image is uploaded successfully');
                onCancel();
              },
              onError: (error: any) => {
                handleError(error.response.status);
                onCancel();
              }
            }
          );
          return;
        }
        mutateUploadImage(
          { code: res.code, fd },
          {
            onSuccess: () => {
              queryClient.invalidateQueries('getListCategories');
              handleSuccess('The image is uploaded successfully');
              onCancel();
            },
            onError: (error: any) => {
              handleError(error.response.status);
              onCancel();
            }
          }
        );
      },
      onError: (error: any) => {
        handleError(error.response.status, UPDATE_MESSAGE.CATEGORY_SUCCESS);
      }
    });
  const closeModalUpdateImageProduct = () => {
    setOpenModalCropImage(false);
  };
  const removeImage = () => {
    setSrcImage('');
    setSrcImageCrop(null);
  };
  const onSave = (file: any) => {
    setSrcImageCrop(file);
    closeModalUpdateImageProduct();
  };
  const onSubmit = () => {
    if (!defaultName || !code) {
      createNewCategory({
        name: getValues('name').trim()
      });
    } else {
      editOldCategory({ data: { name: getValues('name').trim() }, code });
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      {loadingCreateCategory || loadingUpdateCategory || loadingUploadImage ? (
        <Loader />
      ) : null}
      <div className="title-wrapper">
        <div className="image-wrapper">
          <div className="upload-image">
            <ButtonIcon
              icon={IconNames.Close}
              widthIcon={24}
              className="icon-close-image"
              onClick={removeImage}
            />
            <input
              id="files"
              type="file"
              accept="image/*"
              {...register?.('files')}
            />
            <Label forId="files">
              {!srcImage ? (
                <UploadStyle formState={errors.files}>
                  <PlusIcon />
                </UploadStyle>
              ) : (
                <img src={srcImage} />
              )}
            </Label>
          </div>
          {srcImage && openModalCropImage && (
            <CropImage
              srcImage={srcImage}
              file={getValues('files')}
              onCloseCropImage={closeModalUpdateImageProduct}
              onModeSave={onSave}
              mode="product"
            />
          )}
        </div>
        <div className="input-wrapper">
          <TextInput
            placeholder="Nom"
            variant="title"
            name={name}
            register={register}
            getValues={getValues}
            validate={{
              required: {
                value: true,
                message: 'Le nom de la catégorie est obligatoire'
              },
              pattern: {
                value: /^[^\S]*\S/,
                message: 'Invalid data'
              }
            }}
            hasError={Boolean(errors.name)}
          />
          {errors.name && (
            <MessageErrorName>{errors.name.message}</MessageErrorName>
          )}
        </div>
        <div className="btn-wrapper">
          <Button
            label="Enregistrer"
            isFluid={false}
            sizeType="s"
            variant="primary"
            fontWeight="BOLD"
            letterSpacing="S"
            type="submit"
          />
        </div>
        <div className="btn-wrapper">
          <Button
            label="Annuler"
            isFluid={false}
            sizeType="s"
            variant="cancel"
            fontWeight="BOLD"
            letterSpacing="S"
            type="button"
            onClick={onCancel}
          />
        </div>
        <div className="btn-wrapper">
          <Button
            isFluid={false}
            sizeType="s"
            fontWeight="BOLD"
            variant="cancel"
            label="Supprimer"
            letterSpacing="S"
            onClick={onDelete}
            icon={
              <Icon name={IconNames.TrashBin} width="14" height="16" />
            }></Button>
        </div>
      </div>
    </FormWrapper>
  );
}

export default CategoryNameForm;
