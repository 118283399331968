import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';
import { COLORS } from './variables';

export const ScrollbarCustom = styled(Scrollbars)<{ heightScroll?: string }>`
  height: ${({ heightScroll }) => heightScroll || '40rem'};
`;

export const MultilineTrunc = styled.p<{ line: string }>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ line }) => line};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Dot = styled.span`
  display: inline-block;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: black;
  margin: 0 8px;
`;

export const GridHavePanelRight = {
  grid: {
    numberOfColsDesktopS: 12,
    numberOfColsDesktopM: 11
  }
};

export const NotDataYet = styled.td`
  text-align: center !important;
  color: ${COLORS.GREY_1} !important;
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  padding: 4rem 0;
`;

export const MessageError = styled.div`
  font-size: 1.2rem;
  color: ${COLORS.RED_6};
`;

export const MessageErrorName = styled(MessageError)<{ margin?: string }>`
  margin: ${({ margin }) => margin || '0.5rem 0 0 0'};
`;

export const ImageDefault = styled.div`
  border: 1px solid ${COLORS.GREY_2};
  background-color: ${COLORS.WHITE};
  width: 6rem;
  min-width: 6rem;
  height: 6rem;
  border-radius: 0.7rem;
`;

export const PaginationWrapper = styled.div`
  margin-bottom: 2rem;
  .pagination {
    list-style: none;
    display: flex;
    justify-content: flex-end;

    &-item,
    .break,
    .previous,
    .next {
      margin: 0 0.3rem;
    }

    .break a,
    .previous a,
    .next a,
    &-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      font-size: 1.6rem;
      font-weight: 400;
      background-color: ${COLORS.WHITE};
      color: ${COLORS.BLACK};
      border: 1px solid ${COLORS.GREY_7};
    }

    .previous a,
    .next a {
      width: auto;
      padding: 0 1rem;
      font-size: 2rem;
      font-weight: 500;
    }

    .next {
      margin-right: 0;
    }

    &-linkActive {
      background-color: ${COLORS.RED};
      color: ${COLORS.WHITE};
      font-weight: 600;
    }

    .disabled {
      opacity: 0.6;
      a {
        cursor: not-allowed;
      }
    }
  }
`;
