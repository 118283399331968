import React, { useEffect } from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';
import { CloseIcon } from 'components/ui-kits/Icon';

const CLOSE_SIZE = {
  S: {
    width: '1.8rem',
    height: '1.8rem'
  },
  M: {
    width: '2.8rem',
    height: '2.8rem'
  },
  L: {
    width: '4rem',
    height: '4rem'
  }
};
interface IProps {
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  close?(params?: any): void;
  children?: React.ReactNode;
  closeOnClickOverlay?: boolean;
  borderRadius?: string;
  closeSize?: keyof typeof CLOSE_SIZE;
  rightClose?: string;
  topClose?: string;
}

export const ModalScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${COLORS.BLACK_2} 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  backdrop-filter: blur(1.4rem);
  -webkit-backdrop-filter: blur(1.4rem);
  filter: brightness(-50%);
  opacity: 0.7;
  z-index: 500;
`;

const ModalBlock = styled.div<{
  minWidth: string;
  maxWidth: string;
  minHeight: string;
  borderRadius: string;
}>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  min-height: ${({ minHeight }) => minHeight};
  background-color: ${COLORS.WHITE};
  padding-bottom: 1.2rem;
  border-radius: ${({ borderRadius }) => borderRadius};
  z-index: 555;
`;

export const Close = styled.button<{ rightClose: string; topClose: string }>`
  position: absolute;
  width: 4rem;
  height: 4rem;
  top: ${({ topClose }) => topClose};
  right: ${({ rightClose }) => rightClose};
  z-index: 555;
`;

const Modal = ({
  minWidth = '35rem',
  maxWidth = '192rem',
  minHeight = '17rem',
  close,
  children,
  closeOnClickOverlay = false,
  borderRadius = '1.5rem',
  closeSize = 'L',
  rightClose = '4.6rem',
  topClose = '-4.6rem'
}: IProps) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <div>
      <ModalScreen
        {...(closeOnClickOverlay && {
          onClick: close
        })}></ModalScreen>
      <ModalBlock
        minWidth={minWidth}
        maxWidth={maxWidth}
        minHeight={minHeight}
        borderRadius={borderRadius}>
        <Close onClick={close} rightClose={rightClose} topClose={topClose}>
          <CloseIcon
            width={closeSize && CLOSE_SIZE[closeSize].width}
            height={closeSize && CLOSE_SIZE[closeSize].height}
          />
        </Close>
        <div>{children}</div>
      </ModalBlock>
    </div>
  );
};

export default Modal;
