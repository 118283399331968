import React, { useReducer, useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Title,
  BtnWrapper,
  ListCategoriesWrapper,
  CategoriesItem
} from './CurrentCategories.style';
import Button from 'components/ui-kits/Button';
import Collapse from '../Collapse';
import ProductInCategoryItem from '../ProductInCategoryItem';
import xor from 'lodash.xor';
import { TypeSort } from 'types/Sort';
import { CurrentTaxonInCatalog } from 'types/Catalog';
import { EnabledOrDisabledProduct, ProductOptionV2 } from 'types/Products';

interface IProps {
  onOpen: (status: boolean) => void;
  currentListTaxonSelected?: CurrentTaxonInCatalog[];
  onRemoveProductInCategory: (data: EnabledOrDisabledProduct) => void;
  onRemoveCategoryInCatalog: (idx: number) => void;
  onGetIdEditProduct?: (code: string, cateId?: string) => void;
  onOpenModalAddPro: (
    dataItem: CurrentTaxonInCatalog,
    listProduct: Array<ProductOptionV2>
  ) => void;
  onDragAndDropCategories?: (dataCategories: any) => void;
  onSort?: (type: TypeSort, indexSort: number) => void;
  onDataProInCate?: (
    dataPro: ProductOptionV2[],
    dataCate: CurrentTaxonInCatalog
  ) => void;
}

const reducer = (
  state: number[],
  { type, id }: { type: string; id: number }
) => {
  switch (type) {
    case 'toggle':
      let newArr = [...state];
      if (id) {
        newArr = xor(newArr, [id]);
      }
      return newArr;
    default:
      return state;
  }
};

const CurrentCategories = ({
  onOpen,
  currentListTaxonSelected,
  onRemoveProductInCategory,
  onRemoveCategoryInCatalog,
  onGetIdEditProduct,
  onOpenModalAddPro,
  onDragAndDropCategories,
  onSort,
  onDataProInCate
}: IProps) => {
  const [state, dispatch] = useReducer(reducer, []);

  const handleRemoveProductInCategory = (data: EnabledOrDisabledProduct) => {
    onRemoveProductInCategory(data);
  };

  const handleRemoveCategory = (idx: number) => {
    onRemoveCategoryInCatalog(idx);
  };

  const handleIdEditProduct = (code: string, categoryId: string) => {
    onGetIdEditProduct?.(code, categoryId);
  };

  const handleOpenModalAddPro = (
    dataItem: CurrentTaxonInCatalog,
    listProduct: Array<ProductOptionV2>
  ) => {
    onOpenModalAddPro(dataItem, listProduct);
  };

  const [listCategoriesDrag, setListCategoriesDrag] = useState<
    CurrentTaxonInCatalog[] | undefined
  >(currentListTaxonSelected);
  useEffect(() => {
    setListCategoriesDrag(currentListTaxonSelected);
  }, [currentListTaxonSelected]);

  const onDragEnd = (params: any) => {
    if (!params.destination) return;
    const src = params.source.index;
    const dest = params.destination.index;

    if (params.type === 'drop-cate') {
      setListCategoriesDrag((oldOption?: CurrentTaxonInCatalog[]) => {
        if (!oldOption) return;
        const newList: CurrentTaxonInCatalog[] = [...oldOption];
        const [remove] = newList.splice(src, 1);
        newList.splice(dest, 0, remove);
        onDragAndDropCategories?.(newList);
        return newList;
      });
    } else {
      const getIndex = Number(params.type);
      setListCategoriesDrag((oldOption?: CurrentTaxonInCatalog[]) => {
        if (!oldOption) return;
        const newOption = [...oldOption];
        const [removed] = newOption[getIndex].products.splice(src, 1);
        newOption[getIndex].products.splice(dest, 0, removed);
        onDragAndDropCategories?.(newOption);
        return newOption;
      });
    }
  };

  const renderResult = () => {
    if (listCategoriesDrag?.length) {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-cate" type="drop-cate">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {listCategoriesDrag.map(
                  (category: CurrentTaxonInCatalog, indexCate: number) => {
                    return (
                      <Draggable
                        draggableId={`draggable-cate-${indexCate}`}
                        index={indexCate}
                        key={indexCate}>
                        {(provided) => {
                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}>
                              <CategoriesItem>
                                <Collapse
                                  title={category?.taxonName}
                                  isOpen={!!state.includes(category.taxonId)}
                                  index={indexCate}
                                  onRemoveCategory={handleRemoveCategory}
                                  toggle={() =>
                                    dispatch({
                                      type: 'toggle',
                                      id: category.taxonId
                                    })
                                  }
                                  dataItem={category}
                                  listProduct={category?.products}
                                  openModalAddPro={handleOpenModalAddPro}
                                  onSort={onSort}
                                  currentCategory={category}
                                  onDataProInCate={onDataProInCate}
                                  isSort={true}>
                                  <Droppable
                                    droppableId={`droppable-pro-${indexCate}`}
                                    type={String(indexCate)}>
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}>
                                        {category?.products?.map(
                                          (
                                            pro: ProductOptionV2,
                                            indexPro: number
                                          ) => {
                                            let hasExclude = false;
                                            category?.excludedProductIds?.forEach(
                                              (
                                                itemExcluded: string | number
                                              ) => {
                                                if (
                                                  Number(itemExcluded) ===
                                                  pro?.id
                                                ) {
                                                  hasExclude = true;
                                                }
                                              }
                                            );
                                            return (
                                              <Draggable
                                                draggableId={`${indexCate}${indexPro}`}
                                                index={indexPro}
                                                key={`${indexCate}${indexPro}`}>
                                                {(provided, snapshot) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                      ...provided.draggableProps
                                                        .style,
                                                      boxShadow:
                                                        snapshot.isDragging
                                                          ? '0 0 .4rem #666'
                                                          : 'none',
                                                      backgroundColor:
                                                        snapshot.isDragging
                                                          ? '#fff'
                                                          : 'transparent'
                                                    }}>
                                                    <ProductInCategoryItem
                                                      key={pro?.id}
                                                      pro={pro}
                                                      categoryId={
                                                        category?.taxonCode
                                                      }
                                                      onRemove={
                                                        handleRemoveProductInCategory
                                                      }
                                                      hasExclude={hasExclude}
                                                      onEditProduct={
                                                        handleIdEditProduct
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </Draggable>
                                            );
                                          }
                                        )}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </Collapse>
                              </CategoriesItem>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  }
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    }
  };

  return (
    <div>
      <Title label="Catégories actuelles" fontWeight="BOLD" fontSize="XL" />
      <BtnWrapper>
        <Button
          variant="primary"
          label="+ AJOUTER UNE CATÉGORIE"
          isFluid={false}
          sizeType="s"
          fontWeight="BOLD"
          onClick={() => onOpen(true)}
        />
      </BtnWrapper>
      <ListCategoriesWrapper>{renderResult()}</ListCategoriesWrapper>
    </div>
  );
};

export default CurrentCategories;
