import React from 'react';
import {
  MerchantOperationCostWrapper,
  MerchantHeadWrapper
} from './MerchantOperationCost.style';
import GoBack from 'components/GoBack';
import InvoicesMerchantHead from '../../InvoicesMerchantHead';
import InformationOperationCost from '../../InvoicesMerchantInformation/InformationOperationCost';
import { useQuery } from 'react-query';
import Loader from 'components/Loader';
import { weeklyInvoicesV2 } from 'services/invoicesService';

interface Iprops {
  onId: number;
}

const MerchantOperationCost = ({ onId }: Iprops) => {
  const {
    isLoading: loadingData,
    data: getListWeeklyInvoices
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQuery('getListWeeklyInvoices', ({ signal }) =>
    weeklyInvoicesV2(onId, signal)
  );
  return (
    <MerchantOperationCostWrapper>
      <MerchantHeadWrapper>
        <GoBack />
        <InvoicesMerchantHead
          onData={getListWeeklyInvoices}
          label="Facture de frais de service Achil"
        />
      </MerchantHeadWrapper>
      <InformationOperationCost onData={getListWeeklyInvoices} />
      {loadingData ? <Loader /> : null}
    </MerchantOperationCostWrapper>
  );
};

export default MerchantOperationCost;
