import styled from 'styled-components';
import { COLORS } from 'styles/variables';

export const MerchantCustomerWrapper = styled.div``;
export const MerchantHeadWrapper = styled.div`
  position: relative;
  border-bottom: 1px solid ${COLORS.GREY_4};
  height: 8rem;

  button {
    left: 0;
    top: 0;
    margin: 4rem 0 0 4.5rem;
  }
`;
