import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';

export const ItemOrderDetailWrapper = styled.tr`
  .itemNameWrapper,
  .qtyWrapper,
  .priceWrapper {
    padding-bottom: 0.7rem;
  }

  .mainItem,
  .qty,
  .price {
    color: ${COLORS.BLACK_2};
    font-size: ${FONT_SIZE.M};
  }

  .subItem,
  .subQty,
  .subPrice {
    color: ${COLORS.GREY_9};
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.LIGHT};
    padding: 0.7rem 0;
  }
`;
