import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  BlockContent,
  BlockHeader,
  Description,
  FieldWrapper,
  IconLabel,
  InputLocationStyled,
  InputPhoneStyle,
  TitleLarge
} from './EditInfoForm.style';
import Button from 'components/ui-kits/Button';
import DisplayText from 'components/ui-kits/DisplayText';
import TextInput from 'components/ui-kits/Form/TextInput';
import { PhoneIcon, LocatorIcon } from 'components/ui-kits/Icon';
import { Merchant, MerchantReq } from 'types/Merchant';
import { handleError } from 'utils';
import { MessageErrorName } from 'styles/common';

interface IProps {
  onDataSave?(data: MerchantReq): void;
  dataEdit: Merchant;
}

const EditInfoForm = ({ onDataSave, dataEdit }: IProps) => {
  const [lat, setLat] = useState<number | null>(null);
  const [lng, setLng] = useState<number | null>(null);
  const [address, setAddress] = useState<string>('');
  const [hasErrorAddress, setHasErrorAddress] = useState<boolean>(false);
  const addressRef = useRef<any>(null);
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      phoneNumber: dataEdit.phoneNumber
        ? dataEdit.phoneNumber.slice(3, dataEdit.phoneNumber.length)
        : ''
    }
  });
  useEffect(() => {
    setAddress(dataEdit.address);
    setLng(dataEdit.addressPoint.coordinates[0]);
    setLat(dataEdit.addressPoint.coordinates[1]);
  }, [dataEdit]);

  const initializeService = () => {
    if (!window.google) {
      handleError(undefined, 'Google script not loaded');
      throw new Error(
        '[react-google-places-autocomplete]: Google script not loaded'
      );
    }

    if (!window.google.maps) {
      handleError(undefined, 'Google maps script not loaded');
      throw new Error(
        '[react-google-places-autocomplete]: Google maps script not loaded'
      );
    }

    if (!window.google.maps.places) {
      handleError(undefined, 'Google maps places script not loaded');
      throw new Error(
        '[react-google-places-autocomplete]: Google maps places script not loaded'
      );
    }

    const autocomplete: any = new google.maps.places.Autocomplete(
      addressRef.current,
      {
        types: ['geocode']
      }
    );
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      setLat(place.geometry.location.lat());
      setLng(place.geometry.location.lng());
      setAddress(place.formatted_address);
    });
  };

  useEffect(() => {
    initializeService();
  });

  const onSave = ({ phoneNumber }: Pick<Merchant, 'phoneNumber'>) => {
    if (!addressRef.current.value) {
      setHasErrorAddress(true);
    }
    if (!lat || !lng || !phoneNumber || !address || !addressRef.current.value)
      return;
    const data: MerchantReq = {
      phoneNumber: `+33${phoneNumber}`,
      latitude: lat,
      longitude: lng,
      address: address
    };
    onDataSave?.(data);
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <BlockHeader>
        <DisplayText
          label="Modifiez vos informations"
          fontSize="XL"
          fontWeight="BOLD"
        />
        <Button
          variant="primary"
          label="Enregistrer"
          fontWeight="BOLD"
          sizeType="s"
          isFluid={false}
          type="submit"
          letterSpacing="S"
        />
      </BlockHeader>
      <BlockContent>
        <FieldWrapper>
          <div>
            <TitleLarge>Numéro de téléphone</TitleLarge>
            <Description>(Numéro de votre commerce)</Description>
          </div>

          <InputPhoneStyle>
            <IconLabel>
              <PhoneIcon />
              <span>+33 | </span>
            </IconLabel>
            <TextInput
              type="tel"
              variant="title"
              placeholder=""
              name="phoneNumber"
              register={register}
              validate={{
                pattern: {
                  value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/,
                  message: 'Numéro de téléphone invalide'
                },
                required: {
                  value: true,
                  message: 'Numéro de téléphone requis'
                }
              }}
              hasError={Boolean(errors.phoneNumber)}
            />
          </InputPhoneStyle>
          {errors.phoneNumber && (
            <MessageErrorName>{errors.phoneNumber.message}</MessageErrorName>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <div>
            <TitleLarge>Adresse de votre commerce</TitleLarge>
            <Description>(Localisation de votre commerce)</Description>
          </div>

          <InputLocationStyled hasError={hasErrorAddress}>
            <IconLabel>
              <LocatorIcon />
            </IconLabel>
            <input
              type="text"
              placeholder=""
              ref={addressRef}
              defaultValue={address}
            />
          </InputLocationStyled>
          {hasErrorAddress && (
            <MessageErrorName>Address is required.</MessageErrorName>
          )}
        </FieldWrapper>
      </BlockContent>
    </form>
  );
};

export default EditInfoForm;
