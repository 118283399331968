import styled from 'styled-components';
import { COLORS, HEADER_HEIGHT } from 'styles/variables';
import { GridItem, Grid } from 'components/ui-kits/Grid/index';
import { Margin } from 'styles/createEdit-page';

export const SectionNameWrapper = styled(Grid)`
  position: sticky;
  top: calc(${HEADER_HEIGHT} * 2);
  left: 0;
  padding: ${Margin.sectionName};
  background-color: ${COLORS.WHITE_1};
  z-index: 9;
`;

export const ContainerInput = styled(GridItem)`
  margin-right: ${Margin.containerInput};
`;

export const SvgWrapper = styled.div`
  margin: 0 3.3rem 0 1.5rem;
  cursor: pointer;
  svg {
    fill: ${COLORS.BLACK_2};
  }
`;

export const TextInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  input::placeholder {
    color: ${COLORS.GREY_1};
  }
`;

export const MenuHourWrapper = styled.div`
  margin: 5.6rem 0;
`;

export const CurrentCategoriesWrapper = styled.div`
  margin-bottom: 13.6rem;
`;

export const OptionItemWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100vh - ${HEADER_HEIGHT} * 2 - 1px);
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(100% + ${HEADER_HEIGHT});
    background: #c8c8d0;
    top: -${HEADER_HEIGHT};
    left: 0;
    z-index: 11;
  }
`;

export const BtnWrapper = styled(GridItem)`
  display: flex;
  align-items: center;
`;

export const LabelCatalogsRight = styled.div`
  position: sticky;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  text-align: center;
  font-size: 1.6rem;
  color: ${COLORS.BLACK_2};
`;
