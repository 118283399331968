import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Merchant, MerchantReq } from 'types/Merchant';
import EditInfoForm from '../EditInfoForm';
import { handleError, handleSuccess } from 'utils';
import { UPDATE_MESSAGE } from 'const/Message';
import Loader from 'components/Loader';
import { updateMerchantInfoV2 } from 'services/merchantService';

interface EditInfo {
  slug: string;
  onClose(): void;
  dataEdit: Merchant;
}

const EditInfo = ({ slug, onClose, dataEdit }: EditInfo) => {
  const queryClient = useQueryClient();
  const onSave = (data: MerchantReq) => {
    onUpdateInfo(
      { slug: slug, data: data },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('getMerchant');
          handleSuccess(UPDATE_MESSAGE.UPDATE_MERCHANT_INFO_SUCCESS);
          onClose();
        },
        onError: (error: any) => {
          handleError(
            error.response.status,
            UPDATE_MESSAGE.UPDATE_MERCHANT_INFO_ERROR
          );
        }
      }
    );
  };

  const { isLoading, mutate: onUpdateInfo } = useMutation(
    ({ slug, data }: Required<{ slug: string; data: MerchantReq }>) =>
      updateMerchantInfoV2(slug, data)
  );

  return (
    <>
      <EditInfoForm onDataSave={onSave} dataEdit={dataEdit} />
      {isLoading && <Loader />}
    </>
  );
};

export default EditInfo;
