import api from 'api/setupAxios';
import { DELIVERY, DELIVERY_BY_ID, MERCHANT_SLUG } from 'const/endpoint';
import { DeliveryZoneListRes, ZoneField } from 'types/Delivery';
import {
  MerchantDeliveryGeneral,
  MerchantDeliveryGeneralOptional,
  MerchantDeliveryToggle
} from 'types/Merchant';

export async function getListDeliveryZoneV2(
  signal?: AbortSignal
): Promise<DeliveryZoneListRes> {
  const res = await api.call(signal).get(DELIVERY);
  return res.data;
}

export async function createDeliveryZoneV2(data: ZoneField): Promise<any> {
  const res = await api.call().post(DELIVERY, {
    ...data
  });
  return res.data;
}

export async function editDeliveryZoneV2({
  data,
  id
}: {
  data: ZoneField;
  id: number;
}): Promise<any> {
  const res = await api
    .call()
    .put(DELIVERY_BY_ID.replace(':id', id.toString()), {
      ...data
    });
  return res.data;
}

export async function deleteDeliveryZoneV2(id: number): Promise<any> {
  const res = await api
    .call()
    .delete(DELIVERY_BY_ID.replace(':id', id.toString()));
  return res.data;
}

export const getDeliveryGeneralV2 = async (
  slug: string,
  signal?: AbortSignal
) => {
  const res = await api.call(signal).get(MERCHANT_SLUG.replace(':slug', slug));
  return res.data;
};

export const updateDeliveryGeneralV2 = async (
  slug: string,
  data:
    | MerchantDeliveryGeneral
    | MerchantDeliveryGeneralOptional
    | MerchantDeliveryToggle
) => {
  const res = await api.call().put(MERCHANT_SLUG.replace(':slug', slug), data);
  return res.data;
};
