import styled from 'styled-components';
import { FONT_SIZE } from 'styles/variables';

export const DropdownListWrapper = styled.div`
  width: 34.8rem;
  margin-top: 8rem;
  min-height: 4rem;

  .rounded {
    &__control {
      min-height: 4rem;
    }
    &__value-container {
      font-size: ${FONT_SIZE.L};
    }
  }
`;
