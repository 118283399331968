import React from 'react';

function EditPenIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="edit_pen"
      data-name="edit pen"
      width="1.6rem"
      height="1.6rem"
      viewBox="0 0 16 16"
      fill="#2198c4"
      {...props}>
      <path
        id="Icon_material-edit"
        data-name="Icon material-edit"
        d="M4.5,17.163V20.5H7.833l9.83-9.83L14.33,7.334ZM20.24,8.089a.885.885,0,0,0,0-1.253l-2.08-2.08a.885.885,0,0,0-1.253,0L15.281,6.383l3.333,3.333Z"
        transform="translate(-4.5 -4.496)"
      />
    </svg>
  );
}

export default EditPenIcon;
