import styled, { css } from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';

export const TableCell = styled.td<{
  fontSize?: keyof typeof FONT_SIZE;
  fontWeight?: keyof typeof FONT_WEIGHT;
  color?: keyof typeof COLORS;
}>`
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 700;
  ${({ fontSize, fontWeight, color }) => css`
    font-size: ${fontSize && FONT_SIZE[fontSize]};
    font-weight: ${fontWeight && FONT_WEIGHT[fontWeight]};
    color: ${color ? COLORS[color] : COLORS.BLACK_2};
  `}
  vertical-align: middle;
  label {
    display: flex;
  }
  .ref {
    font-size: 1.4rem;
    font-weight: 400;
  }
`;
export const TableHeadCell = styled.th<{
  colWidth?: string;
}>`
  line-height: 2.2rem;
  font-size: 1.6rem;
  color: ${COLORS.BLACK_2};
  ${({ colWidth }) => css`
    width: ${colWidth || 'initial'};
  `}
`;

export const TableWrapper = styled.div<{
  borderHead: boolean;
  hasScroll: boolean;
  heightScroll: string;
  cellWidth: Array<any>;
}>`
  border: 1px solid ${COLORS.GREY_7};
  ${({ borderHead }) =>
    !borderHead &&
    css`
      border: none;
      border-radius: 0 0 0.7rem 0.7rem;

      table {
        border-style: unset !important;
      }
    `}
  border-radius: 0.7rem;
  border-collapse: collapse;
  width: 100%;
  padding: 0.1rem;

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border-style: hidden;
    padding: 0 4.6rem;
    background-color: ${COLORS.WHITE};
  }

  ${TableHeadCell},
  ${TableCell} {
    height: auto;
    border-bottom: 1px solid ${COLORS.GREY_7};
    text-align: left;
    box-sizing: border-box;
  }

  tr:hover ${TableCell} {
    background-color: ${COLORS.GREY_5};
  }

  ${TableHeadCell}:first-child {
    padding-left: 4.6rem;
  }
  ${TableCell}:first-child {
    padding-left: 4.6rem;
  }
  ${({ hasScroll }) =>
    !hasScroll &&
    css`
      ${TableHeadCell} {
        padding: 2.6rem 1.8rem;
      }
      ${TableCell} {
        padding: 1.2rem 1.8rem;
      }
    `}

  ${({ hasScroll, heightScroll, cellWidth }) =>
    hasScroll &&
    css`
      tbody {
        display: block;
        overflow-y: auto;
        height: ${heightScroll};
      }
      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      ${TableHeadCell} {
        padding: 1.5rem 1.8rem;
      }
      ${TableCell} {
        padding: 1.5rem 1.8rem;
      }
      ${renderCellWidthCss(cellWidth)}

      tbody tr:last-child ${TableCell} {
        border-bottom: none;
      }
    `}

  img {
    width: 6rem;
    height: 6rem;
    border-radius: 0.7rem;
    object-fit: cover;
  }
`;

const renderCellWidthCss = (cellWidth: Array<string>) =>
  cellWidth.length &&
  cellWidth.map(
    (item, index) => css`
      ${TableCell}:nth-child(${index + 1}) {
        width: ${item};
      }
    `
  );

export const StyleTable = styled.div`
  [data-style='cate'] {
    line-height: 2.4rem;
  }
  .time {
    margin-bottom: 0.5rem;
  }
  .date {
    text-transform: capitalize;
  }
  .reference {
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0.5rem 0;
    width: 20rem;
    overflow-wrap: break-word;
  }
`;
export const TableRow = styled.tr`
  cursor: pointer;
`;
