import React from 'react';
import styled from 'styled-components';
import { BREAKPOINT } from 'styles/variables';
import { useWindowWidth } from 'components/CustomHooks';
import { GridItem } from 'components/ui-kits/Grid';

const CreateLeftWrapper = styled(GridItem)`
  background-color: #fafafa;
  position: relative;
`;

const FormLeftLayout = ({ children }: { children: React.ReactNode }) => {
  const windowWidth = useWindowWidth();
  return (
    <CreateLeftWrapper
      colStart={2}
      colEnd={windowWidth < BREAKPOINT.DESKTOP_M ? 9 : 8}
      nameStart="large"
      nameEnd="gutter">
      {children}
    </CreateLeftWrapper>
  );
};

export default FormLeftLayout;
