import React from 'react';
import styled from 'styled-components';
import { FONT_WEIGHT, FONT_SIZE, COLORS } from 'styles/variables';

interface IDisplayText {
  label?: string | number;
  fontSize?: keyof typeof FONT_SIZE;
  fontWeight?: keyof typeof FONT_WEIGHT;
  color?: keyof typeof COLORS;
  children?: React.ReactNode;
  className?: string;
  colorExternal?: string;
  as?: React.ElementType;
  [key: string]: any;
  margin?: string;
}

type DisplayTextType = Required<
  Pick<IDisplayText, 'fontSize' | 'fontWeight' | 'color' | 'margin'>
> &
  Pick<IDisplayText, 'colorExternal'>;

const Text = styled.p<DisplayTextType>`
  font-size: ${(props) => FONT_SIZE[props.fontSize]};
  font-weight: ${(props) => FONT_WEIGHT[props.fontWeight]};
  color: ${(props) =>
    props.colorExternal ? props.colorExternal : COLORS[props.color]};
  margin: ${({ margin }) => margin};
`;

function DisplayText({
  label,
  fontSize = 'S',
  fontWeight = 'REGULAR',
  color = 'BLACK_2',
  children,
  className,
  as,
  colorExternal,
  margin,
  ...props
}: IDisplayText) {
  return (
    <Text
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      colorExternal={colorExternal}
      className={className}
      margin={margin}
      as={as}
      {...props}>
      {label}
      {children}
    </Text>
  );
}

export default DisplayText;
