import api from 'api/setupAxios';
import { CATALOG, CATALOG_BY_ID } from 'const/endpoint';
import { CatalogEnabled, CatalogReq } from 'types/Catalog';

export const getCatalogs = (signal?: AbortSignal) => {
  return api.call(signal).get(CATALOG);
};

export const getCatalogById = (id: string, signal?: AbortSignal) => {
  return api.call(signal).get(CATALOG_BY_ID.replace(':id', id));
};

export const createCatalog = (data: CatalogEnabled) => {
  return api.call().post(CATALOG, data);
};

export const updateCatalog = (
  id: string,
  data: CatalogEnabled | CatalogReq
) => {
  return api.call().put(CATALOG_BY_ID.replace(':id', id), data);
};

export const deleteCatalog = (id: string) => {
  return api.call().delete(CATALOG_BY_ID.replace(':id', id));
};
