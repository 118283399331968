import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';

export const CalendarStyle = styled.div`
  margin-top: 8rem;

  .react-datetimerange-picker {
    &__wrapper {
      border-radius: 20px;
      padding: 0 1.5rem;
      height: 4rem;
      margin: 0 3rem 0.8rem;
      border: 1px solid ${COLORS.GREY_4};
      background: ${COLORS.WHITE};
    }

    &__inputGroup {
      font-size: ${FONT_SIZE.L};
      font-weight: ${FONT_WEIGHT.MEDIUM};
    }

    &__clear-button {
      display: none;
    }
  }

  .react-calendar__navigation {
    &__next2-button,
    &__prev2-button {
      display: none;
    }

    &__label__labelText {
      font-size: ${FONT_SIZE.M};
      font-weight: ${FONT_WEIGHT.BOLD};
    }

    &__next-button,
    &__prev-button {
      font-size: ${FONT_SIZE.XL};
      color: ${COLORS.GREY_1};
    }
  }

  .react-calendar__month-view {
    &__weekdays__weekday,
    &__days__day {
      font-size: ${FONT_SIZE.S};
      color: ${COLORS.GREY_9};
      font-weight: ${FONT_WEIGHT.BOLD};
      position: relative;
    }
  }

  .react-calendar {
    border-radius: 5px;

    &__title {
      &--hasActive::after {
        content: '';
        border-radius: 20px;
      }
    }

    &__tile {
      &--active,
      &--hasActive {
        background: ${COLORS.GREY_5} !important;
        position: relative;
      }

      &--active:enabled:focus {
        background: ${COLORS.GREY_1} !important;
      }

      &--hasActive::after {
        content: '';
        border-radius: 20px;
        background-color: ${COLORS.GREY_1};
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
      }

      &--rangeStart {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }

      &--hoverEnd,
      &--rangeEnd {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      &--now {
        background: unset;

        &.react-calendar__tile--hover {
          background: ${COLORS.GREY_5};
        }

        &.react-calendar__tile--hasActive {
          background: unset !important;
        }
      }
    }
    &__month-view__days__day:hover {
      background: unset;
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        background: ${COLORS.GREY_1};
        border-radius: 20px;
        position: absolute;
        left: 0;
        top: 0;
      }

      & abbr {
        display: block;
        color: ${COLORS.WHITE};
      }
    }
  }

  abbr {
    position: relative;
    z-index: 1;

    &[title] {
      text-decoration: none;
      font-weight: ${FONT_WEIGHT.MEDIUM};
    }
  }
`;
