import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from 'react-router-dom';
import Delivery from 'screens/Delivery';
import Home from 'screens/Home';
import Login from 'screens/Login';
import Menu from 'screens/Menu';
import Payment from 'screens/Payment';
import PrepTime from 'screens/PrepTime';
import { JWT_TOKEN } from 'const/Const';
import { decodeJwt } from 'utils/index';
import ToastMessage from 'components/ToastMessage';
import { isAfter } from 'date-fns';
import { addUser } from 'features/user/userSlice';
import { useDispatch } from 'react-redux';
import { statusPageProduct } from 'features/pagePagination/pagePaginationSlice';
import { statusPageOption } from 'features/keepSearchPaginationOption/keepSearchPaginationOptionSlice';

const AuthRoute = ({ children, ...rest }: any) => {
  const token = localStorage.getItem(JWT_TOKEN);
  const dispatch = useDispatch();
  const location = useLocation();

  if (location.pathname.indexOf('products') === -1) {
    dispatch(
      statusPageProduct({
        currentPage: 1,
        search: ''
      })
    );
  }
  if (location.pathname.indexOf('options') === -1) {
    dispatch(
      statusPageOption({
        currentPage: 1,
        search: ''
      })
    );
  }

  if (token) {
    const decode = decodeJwt(token);
    // check token is not expired yet
    if (!decode.exp || isAfter(new Date(decode.exp * 1000), Date.now())) {
      dispatch(
        addUser({
          exp: decode.exp,
          iat: decode.iat,
          merchantId: decode.merchant_id,
          merchantSlug: decode.merchant_slug,
          roles: decode.roles,
          username: decode.username
        })
      );

      return <Route {...rest}>{children}</Route>;
    }
  }
  localStorage.removeItem(JWT_TOKEN);
  return <Redirect to="/login" />;
};

function App() {
  return (
    <Router>
      <Switch>
        <AuthRoute path="/home">
          <Home />
        </AuthRoute>
        <AuthRoute path="/menu">
          <Menu />
        </AuthRoute>
        <AuthRoute path="/delivery">
          <Delivery />
        </AuthRoute>
        <AuthRoute path="/payment">
          <Payment />
        </AuthRoute>
        <AuthRoute path="/prep-time">
          <PrepTime />
        </AuthRoute>
        <Route path="/login">
          <Login />
        </Route>
        <AuthRoute path="*">
          <Redirect to="/home" />
        </AuthRoute>
      </Switch>
      <ToastMessage />
    </Router>
  );
}

export default App;
