import styled from 'styled-components';
import { COLORS } from 'styles/variables';

export const TablePeriodStyle = styled.div`
  margin: 2rem 4.9rem;

  .table-wrapper {
    max-height: 70vh;
    overflow-x: auto;
  }

  .table-wrapper th:first-child,
  .table-wrapper td:first-child {
    padding: 1.4rem 0.7rem;
  }
  .table-wrapper th {
    padding: 1.4rem 1rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-align: center;
  }

  .table-wrapper td {
    text-align: center;
    padding: 1.4rem 1rem;
    font-size: 1.6rem;
    font-weight: 300;
    border-right: 1px solid ${COLORS.GREY_4};
    color: ${COLORS.GREY_12};

    &.bold {
      font-weight: 700;
      color: ${COLORS.BLACK_2};
    }
  }
`;
