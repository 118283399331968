import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FieldValues, useForm, useWatch } from 'react-hook-form';
import {
  InfoIconWrapper,
  LoadMore,
  LabelCatalogsRight,
  SearchResult,
  SearchWidth,
  SearchWrapper,
  ShowInfoHover
} from 'components/ProductLayout/ProductForm/ProductForm.style';
import DisplayText from 'components/ui-kits/DisplayText';
import { CheckboxSwitch, Checkbox } from 'components/ui-kits/Form/Checkbox';
import NumberInput from 'components/ui-kits/Form/NumberInput';
import TextInput from 'components/ui-kits/Form/TextInput';
import { InfoIcon } from 'components/ui-kits/Icon';
import {
  GroupOptionFormsFields,
  IGroupOptions,
  ListOptionsSelected,
  OptionsFormFields
} from 'types/GroupOptions';
import CreateOption from '../CreateOption';
import SectionTitle from '../SectionTitle';
import SearchDnd from '../SelectDnd';
import {
  BlockDescription,
  BlockItemWrapper,
  BlockTitle,
  CheckboxMargin,
  DropDownRule,
  MessageErrorDisplayName,
  OptionItemWrapper,
  OptionMandatoryWrapper,
  OptionNumberStyle,
  OptionNumberWrapper,
  TextInputWrapper,
  MessageError
} from './GroupOptionsForm.style';
import FormCreateEditLayout from 'components/FormCreateEditLayout';
import PanelRightLayout from 'components/FormCreateEditLayout/PanelRightLayout';
import FormLeftLayout from 'components/FormCreateEditLayout/FormLeftLayout';
import { REGEX_NO_SPACE_FIRST } from 'const/Const';
import getInfoPageParams, { handleError } from 'utils';
import { Search } from 'components/ui-kits/Form/Dropdown';
import { useInfiniteQuery, useQuery } from 'react-query';
import { getProducts } from 'services/productService';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import Tag from 'components/ui-kits/Tag';
import classNames from 'classnames';
import type { ProductOption, ProductOptionListData } from 'types/Products';
import { getProductListV2 } from 'services/productService';

export interface GroupOptionsUseForm extends FieldValues {
  name: string;
  displayName: string;
  amountType: typeof ruleOptionMandatory[number];
  isMandatory: boolean;
  requiredAmount: number;
  minAmount: number;
  maxAmount: number;
  isRange: boolean;
  useGOName: boolean;
  optionMaxAmount: number;
  optionsSelected: ListOptionsSelected[];
}

enum RangeValue {
  RANGE = 'range',
  REQUIRED = 'required'
}

const ruleOptionMandatory = [
  { label: 'Exactement', value: RangeValue.REQUIRED },
  { label: 'Entre', value: RangeValue.RANGE }
];

const ruleOptionOptional = [{ label: 'Entre', value: RangeValue.RANGE }];

const VALIDATE_OPTION_MAXAMOUNT = (
  <>
    <div>
      Le nombre maximum d&#39;options sélectionnables dans le groupe doit{' '}
    </div>
    <div>
      être inférieur ou égal au nombre d&#39;options dans le groupe, multiplié{' '}
    </div>
    <div>
      par le nombre de fois qu&#39;un client peut choisir la même option.
    </div>
  </>
);

interface IGroupOptionsForm {
  editItem?: IGroupOptions;
  mutate(data: GroupOptionFormsFields): void;
  mutateDelete?({
    data,
    id
  }: {
    data: GroupOptionFormsFields;
    id: string;
  }): void;
}

const ITEMS_PER_PAGE = 10;

function GroupOptionsForm({
  editItem,
  mutate,
  mutateDelete
}: IGroupOptionsForm) {
  const { merchantId } = useSelector((state: RootState) => state.user);
  const [isOpenOptionForm, setIsOpenOptionForm] = useState(false);
  const [productSelected, setProductSelected] = useState<string[]>([]);
  const [optionEdit, setOptionEdit] = useState<ListOptionsSelected>();

  const { data, isFetching } = useQuery<ProductOptionListData, Error>(
    [
      'getGrOptionItem',
      {
        'merchant.id': merchantId,
        isOptionOrSellingAsOption: true,
        itemsPerPage: 1000
      }
    ],
    (context) => getProductListV2(context.queryKey[1])
  );

  /* === Handle for next version === */
  // const [productInputValue, setProductInputValue] = useState<string>();
  // const debounceProductValue = useDebounce(productInputValue);
  const {
    register,
    setValue,
    getValues,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FieldValues>({
    defaultValues: {
      name: '',
      displayName: '',
      amountType: ruleOptionMandatory[0],
      isMandatory: true,
      requiredAmount: 1,
      minAmount: 1,
      maxAmount: 2,
      isRange: false,
      useGOName: true,
      optionMaxAmount: 1,
      optionsSelected: []
    }
  });
  const watchMandatory = watch('isMandatory');
  const watchAmountType = watch('amountType');
  const watchName = watch('name');

  useEffect(() => {
    if (!editItem || !data) {
      return;
    }

    const { groupOptionProductsArray } = editItem;
    const sortedGroupOptionsProducts = groupOptionProductsArray
      .sort((first, second) => first.position - second.position)
      .map((item) => {
        const customPrice = (
          getValues('optionsSelected') as ListOptionsSelected[]
        ).find((option) => option.id === item.product.id)?.customPrice;

        return {
          ...data['hydra:member'].filter(
            (product: ProductOption) => product.id === Number(item.product.id)
          )[0],
          customPrice: !!customPrice
            ? customPrice
            : groupOptionProductsArray.find(
                (option) => item.product.id === option.product.id
              )?.customPrice ?? 0
        };
      })
      .filter((item) => item !== undefined);

    const optionSelectedInitial: ListOptionsSelected[] =
      sortedGroupOptionsProducts
        .map((item: ProductOption) => ({
          id: item.id,
          name: item.name,
          price: item.price,
          unit: '€' as const,
          originalItem: item,
          customPrice: item.customPrice ?? item.price
        }))
        .filter((item) => item.id !== undefined);

    reset({
      name: editItem.name,
      amountType: editItem.isRange
        ? ruleOptionMandatory[1]
        : ruleOptionMandatory[0],
      requiredAmount: editItem.requiredAmount,
      isMandatory: editItem.isMandatory,
      minAmount: editItem.isMandatory ? 1 : 0,
      maxAmount: editItem.maxAmount,
      isRange: editItem.isRange,
      displayName: editItem.displayName,
      useGOName: Boolean(editItem.name === editItem.displayName),
      optionMaxAmount: editItem.optionMaxAmount,
      optionsSelected: getValues('optionsSelected').length
        ? getValues('optionsSelected')
        : optionSelectedInitial
    });
  }, [editItem, reset, data, getValues]);

  useEffect(() => {
    if (!editItem?.products) return;
    const products = [...editItem.products];
    const listNameOfProduct = products.map((item) => item['@id']);
    setProductSelected(listNameOfProduct);
  }, [editItem, editItem?.products]);

  const useGroupOption = useWatch({
    control,
    name: 'useGOName',
    defaultValue: Boolean(editItem?.name === editItem?.displayName)
  });

  const params = {
    itemsPerPage: ITEMS_PER_PAGE,
    'merchant.id': merchantId,
    isOption: false
  };

  const {
    data: productData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useInfiniteQuery(
    ['getProductList', params],
    ({ pageParam, signal }) =>
      getProducts({ signal, page: pageParam?.page ?? 1, ...params }),
    {
      getNextPageParam: (lastPage: any) => {
        if (!lastPage?.data['hydra:view']['hydra:last']) return undefined;
        const nextPageParams = getInfoPageParams(
          lastPage.data['hydra:view']['@id'],
          lastPage.data['hydra:view']['hydra:last']
        );
        if (!nextPageParams?.currentPage || !nextPageParams?.lastPage)
          return undefined;
        if (nextPageParams.currentPage < nextPageParams.lastPage) {
          return { page: nextPageParams.currentPage + 1 };
        }
        return undefined;
      }
    }
  );

  const productFullData: Array<any> = useMemo(() => {
    if (!productData?.pages) return [];
    return productData.pages.reduce(
      (acc: any, cur) => [...acc, ...cur.data['hydra:member']],
      []
    );
  }, [productData]);

  const onChangeOption = (data: { label: string; value: string }[]) => {
    if (!data?.length) return;
    setProductSelected([...productSelected, data[0].value]);
  };

  // Handle for next version
  // const onInputProductChange = (data: string) => {
  //   setProductInputValue(data);
  // };

  const handleDeleteTag = (code: string) => {
    setProductSelected(productSelected.filter((item: string) => item !== code));
  };

  const onSubmit = () => {
    const minAmount = getValues('minAmount');
    const maxAmount = getValues('maxAmount');
    const optionMaxAmount = getValues('optionMaxAmount');
    const requiredAmount = getValues('requiredAmount');
    const numbersOfOptionItems = getValues('optionsSelected').length;
    if (minAmount >= maxAmount) return;

    if (isRange && watchMandatory) {
      // within range
      if (optionMaxAmount * numbersOfOptionItems < maxAmount) {
        handleError(undefined, VALIDATE_OPTION_MAXAMOUNT);
        return;
      }
    }
    if (!isRange && watchMandatory) {
      // exactly
      if (optionMaxAmount * numbersOfOptionItems < requiredAmount) {
        handleError(undefined, VALIDATE_OPTION_MAXAMOUNT);
        return;
      }
    }

    setValue('displayName', getValues('displayName').trim());
    setValue('name', getValues('name').trim());

    mutate({
      name: getValues('name'),
      isMandatory: getValues('isMandatory'),
      description: '',
      isRange: getValues('amountType').value === RangeValue.RANGE,
      requiredAmount: getValues('requiredAmount') || 1,
      minAmount: getValues('minAmount'),
      maxAmount: getValues('maxAmount'),
      enabled: true,
      options: getValues('optionsSelected').map(
        (item: ListOptionsSelected) => ({
          id: item.id,
          customPrice: item.customPrice
        })
      ),
      displayName: getValues('displayName'),
      optionMaxAmount: getValues('optionMaxAmount'),
      productIRIs: productSelected
    });
  };

  useEffect(() => {
    if (useGroupOption) {
      setValue('displayName', watchName);
    }
  }, [watchName, setValue, useGroupOption]);

  useEffect(() => {
    if (watchMandatory) {
      if (editItem && editItem.isRange) {
        setValue('amountType', ruleOptionMandatory[1]);
      }
      if (editItem && !editItem.isRange) {
        setValue('amountType', ruleOptionMandatory[0]);
      }
      if (!editItem) {
        setValue('minAmount', 1);
        setValue('maxAmount', 2);
      } else {
        setValue('minAmount', editItem?.minAmount || 1);
        setValue('maxAmount', editItem?.maxAmount);
      }
    }

    if (!watchMandatory) {
      if (!editItem) {
        setValue('maxAmount', 1);
      } else {
        setValue('maxAmount', editItem?.maxAmount);
      }

      setValue('minAmount', 0);
      setValue('amountType', ruleOptionOptional[0]);
    }
    if (getValues('minAmount') >= getValues('maxAmount')) {
      setValue('maxAmount', getValues('minAmount') + 1);
    }
    /* eslint-disable */
  }, [watchMandatory]);

  const isRange = useMemo(() => {
    if (!watchAmountType) {
      return getValues('isRange');
    }
    return watchAmountType.value === RangeValue.RANGE;
  }, [watchAmountType, getValues]);

  const handleDelete = () => {
    if (!editItem) return;
    const idGO = editItem['@id'].split('/').pop();
    const options = Object.entries(editItem.groupOptionProducts).map(
      (item: any) => {
        const id = item[0]; // key = id product
        const customPrice = item[1].customPrice;
        return { id: id, customPrice: customPrice };
      }
    );
    const dataUpdate: GroupOptionFormsFields = {
      name: editItem.name,
      displayName: editItem.displayName,
      description: editItem.description,
      isMandatory: editItem.isMandatory,
      isRange: editItem.isRange,
      requiredAmount: editItem.requiredAmount,
      minAmount: editItem.minAmount,
      maxAmount: editItem.maxAmount,
      optionMaxAmount: editItem.optionMaxAmount,
      enabled: false, // enabled = false => delete group option
      options: options
    };

    if (dataUpdate && idGO) {
      mutateDelete?.({ data: dataUpdate, id: idGO });
    }
  };
  const setupOptionForm = (item?: any) => {
    setIsOpenOptionForm(true);
    setOptionEdit(item || undefined);
  };
  const handleEditOption = ({
    data,
    newCode,
    isNewOptionCreated,
    formValues
  }: {
    data: OptionsFormFields;
    newCode: string;
    isNewOptionCreated: boolean;
    formValues: Record<string, any>;
  }) => {
    const optionsSelected: ListOptionsSelected[] = getValues('optionsSelected');
    if (isNewOptionCreated) {
      setValue('optionsSelected', [
        ...optionsSelected,
        {
          ...data,
          customPrice: (data as any).originalItem.price
        } as unknown as ListOptionsSelected
      ]);
    } else {
      const optionEditIndex = optionsSelected.findIndex(
        (item) => item.id === optionEdit?.id
      );
      // This implementation to avoid mutation optionsSelected data
      // TODO: refactor this later?
      const newOptionsSelected = [...optionsSelected];
      const newOptionEdit: any = { ...optionsSelected[optionEditIndex] };
      newOptionEdit.price = Math.round(
        Number(String(data.price).replace(/,/g, '.')) * 100
      );
      newOptionEdit.originalItem.includedTax = formValues.includedTax;
      newOptionEdit.name = data.name;
      newOptionEdit.originalItem.code = newCode;
      newOptionEdit.customPrice = data.customPrice;
      newOptionsSelected[optionEditIndex] = newOptionEdit;
      setValue('optionsSelected', newOptionsSelected);
    }
    setIsOpenOptionForm(false);
  };

  return (
    <FormCreateEditLayout>
      <FormLeftLayout>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SectionTitle
            register={register}
            getValues={getValues}
            onDelete={handleDelete}
            errorTitle={errors?.name}
            showDeleteBtn={Boolean(editItem)}
          />
          <div>
            <BlockItemWrapper>
              <DisplayText
                fontSize="XL"
                label="Nom affiché"
                fontWeight="BOLD"
              />
              <BlockDescription>
                <div>
                  Définissez un nom d'affichage différent pour les clients.
                </div>
                <div>
                  Cette fonctionnalitée vous permettra de vous y retrouvez plus
                  facilement au sein de vos groupes d'options.
                </div>
                <div>
                  Exemple nommer un groupe d'option "Taille pizza" et choissisez
                  de l'afficher "Taille" pour vos clients.
                </div>
              </BlockDescription>

              <TextInputWrapper>
                <TextInput
                  placeholder="Nom"
                  variant="title"
                  name="displayName"
                  register={register}
                  getValues={getValues}
                  defaultValue={editItem?.displayName}
                  validate={{
                    pattern: {
                      value: REGEX_NO_SPACE_FIRST,
                      message: 'Le format est invalide'
                    },
                    required: {
                      value: true,
                      message: `Un nom d'affichage pour les clients est requis`
                    }
                  }}
                  disabled={useGroupOption}
                />
                <MessageErrorDisplayName>
                  {errors.displayName && (
                    <MessageError>{errors.displayName.message}</MessageError>
                  )}
                </MessageErrorDisplayName>
              </TextInputWrapper>
              <CheckboxMargin>
                <Checkbox
                  name="useGOName"
                  fontWeight="BOLD"
                  fontSize="M"
                  register={register}>
                  Utilisez le nom du groupe d'option
                </Checkbox>
              </CheckboxMargin>
            </BlockItemWrapper>
            <BlockItemWrapper>
              <BlockTitle>Ajout d'option(s)</BlockTitle>
              {/* @ts-ignore */}
              <Controller
                name="optionsSelected"
                control={control}
                render={({ field }) => (
                  <SearchDnd
                    setupOptionForm={setupOptionForm}
                    optionsSelected={getValues('optionsSelected')}
                    editItem={editItem}
                    field={field}
                    productOptionListData={data}
                    isFetching={isFetching}
                    handleChangeSearch={(data) => {
                      field.onChange([...field.value, ...data]);
                    }}
                    handleRemove={(idx) => {
                      field.onChange(
                        field.value.filter(
                          (_: any, index: number) => index !== idx
                        )
                      );
                    }}
                  />
                )}
              />
            </BlockItemWrapper>
            <BlockItemWrapper>
              <DisplayText fontSize="XL" label="Règles" fontWeight="BOLD" />
              <BlockDescription>
                Définissez les règles de sélection du groupe d'option pour vos
                clients.
              </BlockDescription>
              <DisplayText
                label="Type de groupe d'option :"
                fontSize="L"
                fontWeight="BOLD"
                className="block-title"
              />
              <OptionMandatoryWrapper>
                <CheckboxSwitch
                  name="isMandatory"
                  id="mandatory"
                  register={register}
                  falseColor="green">
                  {watchMandatory ? (
                    <>
                      <DisplayText
                        label="Obligatoire"
                        fontSize="L"
                        fontWeight="MEDIUM"
                        className="label-option"
                      />
                      <InfoIconWrapper>
                        <InfoIcon />
                        <ShowInfoHover>
                          <span>
                            Un groupe d'option obligatoire exige que les clients
                            sélectionnent une option.
                          </span>
                        </ShowInfoHover>
                      </InfoIconWrapper>
                    </>
                  ) : (
                    <>
                      <DisplayText
                        label="Optionnel"
                        fontSize="L"
                        fontWeight="MEDIUM"
                        className="label-option"
                      />
                      <InfoIconWrapper>
                        <InfoIcon />
                        <ShowInfoHover>
                          <span>
                            Un groupe d'option optionnel n'exige pas que les
                            clients sélectionnent une option.
                          </span>
                        </ShowInfoHover>
                      </InfoIconWrapper>
                    </>
                  )}
                </CheckboxSwitch>
              </OptionMandatoryWrapper>
              <DisplayText
                label="Combien d'options le client peut-il sélectionner au maximum ?"
                fontSize="L"
                fontWeight="REGULAR"
              />
              <OptionNumberWrapper>
                <DropDownRule
                  styles={OptionNumberStyle}
                  variant="rounded"
                  options={
                    watchMandatory ? ruleOptionMandatory : ruleOptionOptional
                  }
                  isFluid={false}
                  control={control}
                  name="amountType"
                />
                {!isRange && (
                  <NumberInput
                    register={register}
                    className="number-input"
                    min={1}
                    name="requiredAmount"
                    setValue={setValue}
                    getValues={getValues}
                  />
                )}
                {isRange && (
                  <>
                    <NumberInput
                      register={register}
                      className="number-input"
                      minOfTypeText={watchMandatory ? 1 : 0}
                      name="minAmount"
                      setValue={setValue}
                      getValues={getValues}
                      type="text"
                      isRange={true}
                      typeRange="min"
                      range={['minAmount', 'maxAmount']} // array attr name of 2 input (minAmount, maxAmount)
                      disabled={!watchMandatory}
                    />
                    <DisplayText fontSize="L" fontWeight="REGULAR" label="et" />
                    <NumberInput
                      register={register}
                      className="number-input"
                      minOfTypeText={watchMandatory ? 2 : 1}
                      name="maxAmount"
                      setValue={setValue}
                      getValues={getValues}
                      type="text"
                      isRange={true}
                      typeRange="max"
                      range={['minAmount', 'maxAmount']} // array attr name of 2 input (minAmount, maxAmount)
                    />
                  </>
                )}
              </OptionNumberWrapper>

              <DisplayText
                as="label"
                fontWeight="REGULAR"
                fontSize="L"
                label="Combien de fois les clients sont-ils autorisés à choisir la même option ?"
                htmlFor="optionMaxAmount"
                margin="0 0 2.5rem 0"
              />
              <NumberInput
                min={1}
                defaultValue={1}
                name="optionMaxAmount"
                id="optionMaxAmount"
                register={register}
                setValue={setValue}
                getValues={getValues}
              />
            </BlockItemWrapper>
            <BlockItemWrapper>
              <BlockTitle>
                Produit dans lequel le groupe d'option est utilisé
              </BlockTitle>
              <SearchWrapper>
                <SearchWidth>
                  <Search
                    change={onChangeOption}
                    placeholder="Ajouter un produit"
                    variant="groupOption"
                    options={productFullData
                      .filter(
                        (item) => productSelected.indexOf(item['@id']) === -1
                      )
                      .map((item) => ({
                        label: item.name,
                        value: item['@id']
                      }))}
                    isMulti={true}
                    value={[]}
                    isLazyLoading={true}
                    fetchNextPage={fetchNextPage}
                    hasNextPage={hasNextPage}
                    ComponentOnOpen={
                      isFetchingNextPage ? (
                        <LoadMore>Chargement...</LoadMore>
                      ) : (
                        <></>
                      )
                    }
                    classNameWrapper={classNames(
                      { loading: isFetchingNextPage },
                      'search-go-wrapper'
                    )}
                  />
                </SearchWidth>
                <SearchResult>
                  {productFullData
                    .filter((item) => productSelected.includes(item['@id']))
                    .map((item) => (
                      <Tag
                        key={item['@id']}
                        label={item.name}
                        className="tag"
                        onDelete={() => handleDeleteTag(item['@id'])}
                      />
                    ))}
                </SearchResult>
              </SearchWrapper>
            </BlockItemWrapper>
          </div>
        </form>
      </FormLeftLayout>
      <PanelRightLayout>
        <OptionItemWrapper>
          {isOpenOptionForm ? (
            <CreateOption
              key={optionEdit?.id}
              groupOption={editItem}
              onClose={() => {
                setIsOpenOptionForm(false);
              }}
              optionEdit={optionEdit}
              handleEditOption={handleEditOption}
            />
          ) : (
            <LabelCatalogsRight>
              Sélectionnez un élément à modifier
            </LabelCatalogsRight>
          )}
        </OptionItemWrapper>
      </PanelRightLayout>
    </FormCreateEditLayout>
  );
}

export default GroupOptionsForm;
