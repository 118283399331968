import api from 'api/setupAxios';

export const getFinancialReportV2 = async ({
  signal,
  filter,
  startDate,
  endDate
}: GetFinancialReportParams) => {
  const res = await api
    .call(signal)
    .get(
      `/merchant/statistics/financial?filter=${filter}&startDate=${startDate}&endDate=${endDate}`
    );
  return res.data;
};

export const financialDownloadPDFV2 = async ({
  startDate,
  endDate
}: {
  startDate: string;
  endDate: string;
}) => {
  const res = await api
    .call()
    .get(
      `/merchant/download-financial-report-pdf?startDate=${startDate}&endDate=${endDate}`,
      { responseType: 'blob' }
    );
  return res.data;
};

type GetFinancialReportParams = {
  signal?: AbortSignal;
  filter: 'by_month' | 'by_month_period';
  startDate: any;
  endDate: any;
};
