import React from 'react';
import ModalPrompt from 'components/ui-kits/ModalPrompt';
import { CONFIRM_MESSAGE } from 'const/Message';
interface ConfirmDeleteModalProps {
  isShow: boolean;
  onClose(): void;
  onDelete(): void;
}

const ConfirmDeleteModal = ({
  isShow,
  onClose,
  onDelete
}: ConfirmDeleteModalProps) => {
  return (
    <ModalPrompt
      isShow={isShow}
      title={CONFIRM_MESSAGE.DELETE_GROUP_OPTION}
      confirmType="withTrashBin"
      onClose={onClose}
      onConfirm={onDelete}
    />
  );
};

export default ConfirmDeleteModal;
