import React from 'react';
import { BREAKPOINT } from 'styles/variables';
import { useWindowWidth } from 'components/CustomHooks';
import { GridItem } from 'components/ui-kits/Grid';

const PanelRightLayout = ({ children }: { children: React.ReactNode }) => {
  const windowWidth = useWindowWidth();
  return (
    <GridItem
      colStart={windowWidth < BREAKPOINT.DESKTOP_M ? 10 : 9}
      colEnd={11}
      nameStart="large"
      nameEnd="margin">
      {children}
    </GridItem>
  );
};

export default PanelRightLayout;
