import styled from 'styled-components';
import { COLORS } from 'styles/variables';

export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.2rem 4.6rem;
  box-sizing: border-box;
  border-bottom: 1px solid ${COLORS.GREY_4};
`;

export const Title = styled.div`
  color: ${COLORS.BLACK_2};
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.9rem;
`;

export const Filter = styled.div`
  display: flex;
  padding: 2.6rem 4.6rem;
`;

export const SearchStyle = styled.div`
  min-width: 41.5rem;
  height: 3.8rem;
  margin-right: 3.2rem;
`;

export const ButtonStyle = styled.div`
  button {
    padding: 0 1.6rem;
    height: 3.8rem;
  }
`;
export const StyleSearch = {
  control: (styles: any) => ({
    ...styles,
    fontSize: '1.8rem',
    letterSpacing: '0.36px',
    fontWeight: '400'
  }),
  placeholder: () => ({
    fontSize: '1.8rem',
    color: `${COLORS.GREY_7}`,
    letterSpacing: '0.36px'
  })
};
export const HeaderData = [
  { label: '', width: '10%' },
  { label: '', width: '13%' },
  { label: 'Nom', width: '25%' },
  { label: 'Catégories', width: '20%' },
  { label: `Groupes d'options`, width: '28%' }
];
export const EmptyListWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding-top: 20px;
`;
