import React from 'react';
import { COLORS } from 'styles/variables';

function MetroExitIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.8rem"
      height="1.9rem"
      viewBox="0 0 18 19.2"
      {...props}>
      <path
        fill={COLORS.RED}
        d="M15.543 12.857v-2.4h-6v-2.4h6v-2.4l3.6 3.6zm-1.2-1.2v4.8h-6v3.6l-7.2-3.6V.857h13.2v6h-1.2v-4.8h-9.6l4.8 2.4v10.8h4.8v-3.6z"
        data-name="Icon metro-exit"
        transform="translate(-1.143 -.857)"></path>
    </svg>
  );
}

export default MetroExitIcon;
