import React from 'react';

function DotMenuIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.4rem"
      height="2.4rem"
      viewBox="0 0 24 24"
      {...props}>
      <path
        fill="#878c97"
        d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"
      />
    </svg>
  );
}

export default DotMenuIcon;
