import React from 'react';
import { ProductOptionsForm } from 'components/ProductOptionsLayout';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ProductOptionFormParamsV3 } from 'types/ProductOption';
import { handleError, handleSuccess } from 'utils';
import { UPDATE_MESSAGE } from 'const/Message';
import Loader from 'components/Loader';
import {
  getProductOptionByCodeV2,
  updateProductOptionV2
} from 'services/productOptionService';

export default function OptionEdit() {
  const { optionCode: code } = useParams<{ optionCode: string }>();
  const queryClient = useQueryClient();
  const history = useHistory();

  const {
    data: result,
    isLoading: isLoadingGetOption,
    isFetching
  } = useQuery(['option', code], ({ signal }) =>
    getProductOptionByCodeV2({
      code,
      signal,
      params: { isProductScreen: false }
    })
  );
  const { mutate: mutateUpdate, isLoading: isLoadingUpdateOption } =
    useMutation(updateProductOptionV2);

  function handleUpdate(data: ProductOptionFormParamsV3) {
    const dataRequest = {
      ...data,
      isOption: result?.data.sellingAsOption ? false : true
    };

    mutateUpdate(
      { code, data: dataRequest },
      {
        onSuccess: () => {
          handleSuccess(UPDATE_MESSAGE.OPTION_SUCCESS);
          queryClient.invalidateQueries('options');
          queryClient.invalidateQueries('getListGroupOptions');
          history.push('/menu/options');
        },
        onError: (error: any) => {
          handleError(error.response.status, UPDATE_MESSAGE.OPTION_ERROR);
        }
      }
    );
  }

  return (
    <React.Fragment>
      {(isLoadingGetOption || isFetching || isLoadingUpdateOption) && (
        <Loader />
      )}
      {result?.data ? (
        <ProductOptionsForm
          mode="edit"
          dataEdit={result.data}
          onEdit={handleUpdate}
        />
      ) : null}
    </React.Fragment>
  );
}
