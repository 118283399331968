import styled from 'styled-components';
import { COLORS } from 'styles/variables';

export const ImageWrapper = styled.div`
  position: relative;
  label {
    display: inline-block;
    cursor: pointer;
  }
  input[type='file'] {
    display: none;
  }
  .icon-close-image {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    background: #fff;
    z-index: 2;
    border: 1px solid #a5a5a5;
    width: 3rem;
    height: 3rem;
    svg {
      width: 2rem;
      height: 2rem;
      path {
        fill: #ff6f61;
      }
    }
  }
`;
export const UploadStyle = styled.div<{ formState: any }>`
  position: relative;
  width: 19.6rem;
  height: 19.6rem;
  border-radius: 0.7rem;
  border: ${({ formState }) =>
    formState ? `2px dashed ${COLORS.RED};` : `1px dashed ${COLORS.GREY_4};`};
  background-color: ${COLORS.WHITE};
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ImageStyle = styled.img`
  width: 19.6rem;
  height: 19.6rem;
  border-radius: 0.7rem;
  object-fit: cover;
`;
