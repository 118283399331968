import React from 'react';

function DoubleArrowIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M6 16.708 2.292 13 6 9.292 6.771 10.062 4.375 12.458H16.792V13.542H4.375L6.771 15.938ZM14 10.708 13.229 9.938 15.625 7.542H3.208V6.458H15.625L13.229 4.062L14 3.292L17.708 7Z"
      />
    </svg>
  );
}

export default DoubleArrowIcon;
