import Spinner from 'components/ui-kits/Spinner';
import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from 'styles/variables';

const LoaderScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  border-radius: 1.5rem;
  background-color: rgba(255, 255, 255, 0.5);
`;

const LoaderContainer = styled.div<{ isFixed: boolean }>`
  ${({ isFixed }) => {
    return isFixed
      ? css`
          position: fixed;
          top: 10%;
          left: 50%;
          transform: translateX(-50%);
        `
      : css`
          position: relative;
        `;
  }}
  z-index: 1000;

  max-width: 23.2rem;
  width: 100%;
  min-height: 3.4rem;
  margin: 0 auto;
  border: 1px solid ${COLORS.GREY_4};
  border-radius: 0.8rem;
  background-color: ${COLORS.WHITE};
`;

const LoaderContent = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0 0.8rem 1.5rem;
`;

const LoaderText = styled.div`
  flex-grow: 1;
  padding: 0 3rem;
  text-align: center;

  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${COLORS.BLACK_3};
`;

interface ILoader {
  message?: string;
  isFixed?: boolean;
}

const Loader = ({ message = 'Chargement...', isFixed = true }: ILoader) => {
  return (
    <>
      <LoaderScreen />
      <LoaderContainer isFixed={isFixed}>
        <LoaderContent>
          <Spinner />
          <LoaderText>{message}</LoaderText>
        </LoaderContent>
      </LoaderContainer>
    </>
  );
};

export default Loader;
