import React from 'react';
import { ListInvoicesMerchantTableSubscriptionFee } from 'types/InvoicesMerchantDetails';
import { formatDollar, formatPercent } from 'utils';
import { ItemSubscriptionFeeWrapper } from './ItemSubscriptionFee.style';

const CardInvoicesMerchantTable = ({
  type,
  qty,
  unitPriceHT,
  discount,
  totalHT,
  vat,
  vatAmount,
  totalTTC
}: ListInvoicesMerchantTableSubscriptionFee) => {
  return (
    <ItemSubscriptionFeeWrapper>
      <td>{type}</td>
      <td>{unitPriceHT ? `${formatDollar(unitPriceHT)} €` : null}</td>
      <td>{qty}</td>
      <td>{discount ? `${formatPercent(String(discount * 100))} %` : '0%'}</td>
      <td>{totalHT ? `${formatDollar(totalHT)} €` : null}</td>
      <td>{vat ? `${formatPercent(String(vat * 100))} %` : null}</td>
      <td>{vatAmount ? `${formatDollar(vatAmount)} €` : null}</td>
      <td>{totalTTC ? `${formatDollar(totalTTC)} €` : null}</td>
    </ItemSubscriptionFeeWrapper>
  );
};

export default CardInvoicesMerchantTable;
