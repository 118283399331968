import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';
import { ArrowLeftIcon } from 'components/ui-kits/Icon';

const BackBtn = styled.button`
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: -9.5rem;
  transform: translateY(-50%);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${COLORS.GREY_5};
  }

  .back-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${COLORS.GREY_10};
  }
`;

const GoBack = () => {
  const history = useHistory();

  return (
    <BackBtn type="button" onClick={() => history.goBack()}>
      <ArrowLeftIcon className="back-icon" width="1.2rem" />
    </BackBtn>
  );
};

export default GoBack;
