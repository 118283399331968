import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';

export const CardInvoicesListWrapper = styled.tr`
  border-top: 1px solid ${COLORS.GREY_4};

  &:hover {
    cursor: pointer;
    background-color: ${COLORS.GREY_5};
  }
`;
export const InvoiceNumberStyle = styled.td`
  padding: 2.6rem 2rem;
  font-size: ${FONT_SIZE.M};
  font-weight: ${FONT_WEIGHT.BOLD};
  color: ${COLORS.BLACK_2};
  padding-left: 4.6rem;
`;
export const TypeStyle = styled.td`
  padding: 2.6rem 2rem;
  font-size: ${FONT_SIZE.M};
  font-weight: ${FONT_WEIGHT.LIGHT};
  color: ${COLORS.BLACK_2};
`;
export const DateTimeWrapper = styled.td`
  padding: 1.6rem 2rem;
`;
export const TimeHourStyle = styled.div`
  font-size: ${FONT_SIZE.S};
  font-weight: ${FONT_WEIGHT.LIGHT};
  color: ${COLORS.BLACK_2};
`;
export const DayStyle = styled.div`
  font-size: ${FONT_SIZE.S};
  font-weight: ${FONT_WEIGHT.BOLD};
  color: ${COLORS.BLACK_2};
`;
export const DownloadPDFStyle = styled.tr`
  position: absolute;
  right: 16.5rem;
  margin-top: -4.5rem;
  svg {
    cursor: pointer;
  }
`;
