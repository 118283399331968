import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ProductForm from 'components/ProductLayout/ProductForm';

import Loader from 'components/Loader';
import { handleSuccess, handleError } from 'utils/index';
import {
  CREATE_MESSAGE,
  REFERENCE_EXISTS_LINE_ONE_MESSAGE,
  REFERENCE_EXISTS_LINE_TWO_MESSAGE
} from 'const/Message';
import type { UploadImageParams, Product, SendProduct } from 'types/Products';
import { createProduct, uploadImageV2 } from 'services/productService';
import {
  CreatePromotionParams,
  createPromotion
} from 'services/promotionService';
import { CHANNELS_PROMOTION } from 'const/Const';
import { handleMessageWhenAppliedPromotion } from 'utils/handleResponse';

const REFERENCE_EXISTS_MESSAGE = (
  <>
    <div>{REFERENCE_EXISTS_LINE_ONE_MESSAGE}</div>
    <div>{REFERENCE_EXISTS_LINE_TWO_MESSAGE}</div>
  </>
);

const ProductsCreate = () => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { isLoading: isCreatingProduct, mutate: onCreateProduct } = useMutation(
    (product: SendProduct) => createProduct(product)
  );

  const { isLoading: isUploadingImage, mutateAsync: mutateUploadImage } =
    useMutation((data: UploadImageParams) =>
      uploadImageV2(data.code, data.formData)
    );

  const { isLoading: isCreatingPromotion, mutateAsync: mutateCreatePromotion } =
    useMutation((data: CreatePromotionParams) => createPromotion(data));

  const onCreate = (product: Product, imgCropped: File) => {
    const data: SendProduct = {
      name: product.name,
      isOption: false,
      maxAmount: product.maxAmount,
      description: product.description,
      categories: product.categories,
      includedTax: product.includedTax,
      isAlcoholicProduct: product.isAlcoholicProduct,
      dietaryAttributes: product.dietaryAttributes,
      groupOptionIRIs: product.groupOptionIRIs,
      sellingAsOption: product.sellingAsOption,
      allergenAttributes: product.allergenAttributes,
      notMultipleQuantityOption: product.notMultipleQuantityOption,
      isSellWithUnits: product.isSellProductWithUnits,
      ...(product.isSellProductWithUnits
        ? {
            pricePerUnit: product.pricePerUnit,
            quantityProductSoldByWeight: product.quantityProductSoldByWeight,
            unit: product.unit
          }
        : { price: product.price })
    };

    if (product.reference) {
      data.reference = product.reference;
    }

    const fd = new FormData();
    if (imgCropped) {
      fd.append('files[]', imgCropped);
    }
    if (!imgCropped && product.files) {
      for (let i = 0; i < product.files.length; i++) {
        fd.append('files[]', product.files[i]);
      }
    }

    onCreateProduct(data, {
      onSuccess: async (res) => {
        handleSuccess(CREATE_MESSAGE.PRODUCT_SUCCESS);

        queryClient.invalidateQueries('getListGroupOptions');
        queryClient.invalidateQueries('getListProducts');
        queryClient.invalidateQueries('getListCategories');

        let promotionData;

        if (product.startDate && product.endDate && product.isReduction) {
          promotionData = {
            channels: [CHANNELS_PROMOTION],
            name: `Discount percent ${product.reductionPercent}`,
            code: `discount-percent-${Math.floor(
              product.reductionPercent || 0
            )}-${uuidv4()}`,
            startDate: product.startDate,
            endDate: product.endDate,
            priority: 1,
            addToAllCatalogs: true,
            exclusive: true,
            enabled: true,
            scopes: [
              {
                type: 'for_variants',
                configuration: {
                  variants: [res.data.variants[0].code as string]
                }
              }
            ],
            actions: [
              {
                type: 'percentage_discount',
                configuration: {
                  amount: product.reductionPercent || 0
                }
              }
            ]
          };
        }

        if (fd.getAll('files[]').length <= 0) {
          if (product.isReduction && promotionData) {
            mutateCreatePromotion(promotionData);
          }

          queryClient.invalidateQueries('getProducts');

          if (product.isReduction) {
            handleMessageWhenAppliedPromotion();
          }

          history.push('/menu/products');

          return;
        }

        const listApi = [
          mutateUploadImage({ code: res.data.code, formData: fd })
        ];

        if (product.isReduction && promotionData) {
          listApi.push(mutateCreatePromotion(promotionData));
        }

        try {
          await Promise.all(listApi);
        } catch (_error) {
          handleError();
        }

        queryClient.invalidateQueries('getProducts');

        if (product.isReduction) {
          handleMessageWhenAppliedPromotion();
        }

        history.push('/menu/products');
      },
      onError: (error: any) => {
        handleError(
          error.response.status,
          error.response.status === 422
            ? REFERENCE_EXISTS_MESSAGE
            : CREATE_MESSAGE.PRODUCT_ERROR
        );
      }
    });
  };

  return (
    <>
      {isCreatingProduct || isUploadingImage || isCreatingPromotion ? (
        <Loader />
      ) : null}
      <ProductForm create={onCreate} mode="create" />
    </>
  );
};

export default ProductsCreate;
