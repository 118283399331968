import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  exp: number;
  iat: number;
  merchantId: number;
  merchantSlug: string;
  roles: string[];
  username: string;
}

const initialState: UserState = {
  exp: 0,
  iat: 0,
  merchantId: 0,
  merchantSlug: '',
  roles: [],
  username: ''
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser(state, action: PayloadAction<UserState>) {
      return (state = action.payload);
    }
  }
});

const { actions, reducer } = userSlice;
export const { addUser } = actions;
export default reducer;
