import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';

export const ItemCustomerWapper = styled.tr`
  padding: 0;
  color: ${COLORS.GREY_12};
  font-size: ${FONT_SIZE.M};

  td {
    padding: 1.4rem 0;
    vertical-align: top;
  }

  .descriptionWrapper {
    display: flex;

    .number {
      margin-right: 2.8rem;
    }
  }
  .description {
    display: flex;
    flex-direction: column;
  }
  .qtyWrapper,
  .vatWrapper,
  .risingWrapper,
  .totalHTWrapper,
  .unitPriceWrapper,
  .col-wrapper {
    text-align: center;
  }
  .totalPriceWrapper {
    text-align: right;
    font-weight: ${FONT_WEIGHT.BOLD};
  }
  .mainDescription,
  .qty,
  .vat,
  .vatAmount,
  .totalHT,
  .totalPrice,
  .col-item {
    padding-bottom: 0.7rem;
  }
  .subDescription {
    margin: -2rem 0 0 2.2rem;
  }
  .subQty,
  .subVat,
  .subVatAmount,
  .subTotalHT,
  .subTotalPrice,
  .sub-col-item {
    margin: -2rem 0 0;
  }
`;
