import api from 'api/setupAxios';
import { PROMOTION, PROMOTION_BY_CODE } from 'const/endpoint';

type Scope = {
  type: string;
  configuration: {
    variants: Array<string>;
  };
};
type Action = {
  type: string;
  configuration: {
    amount: number;
  };
};

export type CreatePromotionParams = {
  channels?: Array<string>;
  name?: string;
  code?: string;
  startDate?: string;
  endDate?: string;
  priority?: number;
  exclusive?: boolean;
  scopes?: Array<Scope>;
  actions?: Array<Action>;
  enabled: boolean;
  addToAllCatalogs?: boolean;
};

export const createPromotion = (data: CreatePromotionParams) => {
  return api.call().post(PROMOTION, data);
};

export const updatePromotion = (code: string, data: CreatePromotionParams) => {
  return api.call().put(PROMOTION_BY_CODE.replace(':code', code), data);
};
