import styled from 'styled-components';
import DisplayText from 'components/ui-kits/DisplayText';
export const Title = styled(DisplayText)`
  margin-bottom: 3.1rem;
`;

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  button {
    text-transform: capitalize;
    letter-spacing: 0.36px;
    &:not(:last-child) {
      padding: 0;
      min-width: auto;
      margin-right: 5.8rem;
    }
  }
`;

export const ListCategoriesWrapper = styled.div`
  margin: 3.9rem 0;
`;
export const CategoriesItem = styled.div`
  position: relative;
  margin-bottom: 3.9rem;
`;
