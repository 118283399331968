import React from 'react';
import styled from 'styled-components';
import { useFieldArray } from 'react-hook-form';
import Button from 'components/ui-kits/Button';
import DisplayText from 'components/ui-kits/DisplayText';
import Timeline from '../Timeline';

const Title = styled(DisplayText)`
  margin-bottom: 3.1rem;
`;
interface IProps {
  register: any;
  control: any;
  setValue: any;
  getValues?: any;
  mode?: 'create' | 'edit';
}

const MenuHour = ({
  register,
  control,
  setValue,
  getValues,
  mode = 'edit'
}: IProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'timeline'
  });

  return (
    <div>
      <Title
        label="Disponibilité du catalague"
        fontWeight="BOLD"
        fontSize="XL"
      />
      {fields.map(({ id }, index) => {
        return (
          <Timeline
            key={id}
            register={register}
            index={index}
            setValue={setValue}
            remove={remove}
            getValues={getValues}
            mode={mode}
          />
        );
      })}
      <Button
        type="button"
        variant="primary"
        isFluid={false}
        label="+ AJOUTER UNE DISPONIBILITÉ"
        fontWeight="BOLD"
        onClick={() => append({})}
      />
    </div>
  );
};

export default MenuHour;
