import React, { useEffect, useState } from 'react';
import Table from 'components/ui-kits/Table';
import { TablePeriodStyle } from './TablePeriod.style';
import { TableCell } from 'components/ui-kits/Table/Table.style';
import { Financial } from 'types/FinancialReport';
import {
  formatDayOfWeek,
  formatDollar,
  MONTHS,
  MONTHS_IN_FRENCH_SHORT
} from 'utils';
import { COLORS } from 'styles/variables';

export const CommonTableHeader = [
  {
    label: <div>TVA 2,1% HT</div>
  },
  {
    label: <div>TVA 2,1%</div>
  },
  {
    label: <div>TVA 5,5% HT</div>
  },
  {
    label: <div>TVA 5,5%</div>
  },
  {
    label: <div>TVA 8,5% HT</div>
  },
  {
    label: <div>TVA 8,5%</div>
  },
  {
    label: <div>TVA 10% HT</div>
  },
  {
    label: <div>TVA 10%</div>
  },
  {
    label: <div>TVA 20% HT</div>
  },
  {
    label: <div>TVA 20%</div>
  },
  {
    label: <div>Total TTC</div>,
    width: '7%'
  },
  { label: 'Espèce' },
  { label: 'Carte' },
  { label: 'Chèque' },
  { label: 'Titres restaurant' },
  { label: 'Chèque-vacances' },
  { label: 'Paiement total' }
];

const TableHeader = [
  { label: '' },
  { label: 'Juill. - Août', width: '2%' },
  ...CommonTableHeader
];

interface IProps {
  dataPeriod: Financial;
  startMonth: string;
  endMonth: string;
}

const TablePeriod = ({ dataPeriod, startMonth, endMonth }: IProps) => {
  const [tableHeaderPeriod, setTableHeaderPeriod] = useState(TableHeader);
  const [dataDisplay, setDataDisplay] = useState<any>();

  useEffect(() => {
    let displayMonth = '';
    if (startMonth === endMonth) {
      const monthNumber = MONTHS[startMonth] - 1;
      const monthFrench = MONTHS_IN_FRENCH_SHORT[monthNumber];
      displayMonth = monthFrench;
    } else {
      const startMonthNumber = MONTHS[startMonth] - 1;
      const endMonthNumber = MONTHS[endMonth] - 1;
      const startMonthFrench = MONTHS_IN_FRENCH_SHORT[startMonthNumber];
      const endMonthFrench = MONTHS_IN_FRENCH_SHORT[endMonthNumber];
      displayMonth = `${startMonthFrench} - ${endMonthFrench}`;
    }
    setTableHeaderPeriod(() => {
      const CloneTableHeader = [...TableHeader];
      CloneTableHeader[1].label = displayMonth;
      return CloneTableHeader;
    });
  }, [endMonth, startMonth]);

  useEffect(() => {
    formatDataDisplay(dataPeriod);
  }, [dataPeriod]);

  function formatDataDisplay(data: Financial) {
    const newData = [];
    for (const k in data) {
      if (k !== 'total') {
        const day = k.slice(0, 3);
        const getLastIndexUnderscore = k.lastIndexOf('_');
        const date = k.slice(
          getLastIndexUnderscore - 2,
          getLastIndexUnderscore
        );
        const obj = { day: day, date: date, ...data[k] };
        newData.push(obj);
      } else {
        const obj = { day: 'Total', date: '', ...data[k] };
        newData.push(obj);
      }
    }
    setDataDisplay(newData);
  }

  return (
    <TablePeriodStyle>
      <Table header={tableHeaderPeriod}>
        {dataDisplay?.map((item: any, index: number) => {
          return (
            <tr
              key={index}
              style={{
                backgroundColor: item.day === 'Total' ? COLORS.GREY_13 : ''
              }}>
              {index !== dataDisplay.length - 1 && (
                <>
                  <TableCell className="bold">
                    {formatDayOfWeek(item.day).slice(0, 3).toLowerCase()}.
                  </TableCell>
                  <TableCell className="bold">{item.date}</TableCell>
                </>
              )}
              {index === dataDisplay.length - 1 && (
                <TableCell colSpan={2} className="bold">
                  {item.day}
                </TableCell>
              )}

              <TableCell>
                {formatDollar(item.tax['0.021'].taxExcluded)}€
              </TableCell>
              <TableCell>
                {formatDollar(item.tax['0.021'].taxAmount)}€
              </TableCell>
              <TableCell>
                {formatDollar(item.tax['0.055'].taxExcluded)}€
              </TableCell>
              <TableCell>
                {formatDollar(item.tax['0.055'].taxAmount)}€
              </TableCell>
              <TableCell>
                {formatDollar(item.tax['0.085'].taxExcluded)}€
              </TableCell>
              <TableCell>
                {formatDollar(item.tax['0.085'].taxAmount)}€
              </TableCell>
              <TableCell>
                {formatDollar(item.tax['0.1'].taxExcluded)}€
              </TableCell>
              <TableCell>{formatDollar(item.tax['0.1'].taxAmount)}€</TableCell>
              <TableCell>
                {formatDollar(item.tax['0.2'].taxExcluded)}€
              </TableCell>
              <TableCell>{formatDollar(item.tax['0.2'].taxAmount)}€</TableCell>
              <TableCell style={{ backgroundColor: COLORS.GREY_13 }}>
                {formatDollar(item.totalOfSales)}€
              </TableCell>
              <TableCell>{formatDollar(item.cashPayment)}€</TableCell>
              <TableCell>{formatDollar(item.cardPayment)}€</TableCell>
              <TableCell>{formatDollar(item.checkPayment)}€</TableCell>
              <TableCell>
                {formatDollar(item.restaurantTicketPayment)}€
              </TableCell>
              <TableCell>{formatDollar(item.vacationTicketPayment)}€</TableCell>
              <TableCell style={{ backgroundColor: COLORS.GREY_13 }}>
                {formatDollar(item.totalPayment)}€
              </TableCell>
            </tr>
          );
        })}
      </Table>
    </TablePeriodStyle>
  );
};

export default TablePeriod;
