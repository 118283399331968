import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';
import Button from 'components/ui-kits/Button';
import ButtonIcon from 'components/ui-kits/ButtonIcon';
import DisplayText from 'components/ui-kits/DisplayText';
import { IconNames } from 'components/ui-kits/Icon/Icon';
import { COLORS } from 'styles/variables';
import TextInput from 'components/ui-kits/Form/TextInput';
import Loader from 'components/Loader';
import { CategoryNameForm } from 'types/Category';
import { handleError, handleSuccess } from 'utils';
import { CREATE_MESSAGE, SIZE_IS_TOO_BIG } from 'const/Message';
import {
  createCategoryV2,
  uploadImageCategory
} from 'services/categoryService';
import Label from 'components/ui-kits/Form/Label';
import { UploadStyle } from 'components/UploadImageCrop/UploadImageCrop.style';
import { PlusIcon } from 'components/ui-kits/Icon';
import CropImage from 'components/CropImage';

const BlockWrapper = styled.div`
  padding: 4rem 0;
  position: fixed;
  .action-wrapper {
    display: flex;
    align-items: center;
  }
  .btn-submit {
    margin-right: 1.5rem;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 2.4rem;

  svg {
    fill: ${COLORS.BLACK_3};
  }
`;

const ContentWrapper = styled.div`
  margin: 2.8rem 3.5rem 0 2.4rem;
`;

const TextInputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  &.border-error input {
    border-bottom: 2px solid ${COLORS.RED};
  }

  label {
    display: block;
    margin-bottom: 0.9rem;
  }

  input::placeholder {
    color: ${COLORS.GREY_1};
  }

  .image-wrapper {
    margin-right: 2rem;
    .upload-image {
      position: relative;
      display: inline-block;
      img {
        width: 9rem;
        height: 9rem;
        border-radius: 0.7rem;
        object-fit: cover;
        max-width: fit-content;
      }
      label {
        display: inline-block;
        cursor: pointer;
        margin-bottom: 0;
        & > div {
          width: 9rem;
          height: 9rem;
        }
      }
      input[type='file'] {
        display: none;
      }
      .icon-close-image {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        background: #fff;
        z-index: 2;
        border: 1px solid #a5a5a5;
        width: 20px;
        height: 20px;
        svg {
          width: 10px;
          height: 10px;
          path {
            fill: #ff6f61;
          }
        }
      }
    }
  }
`;

interface IProps {
  onClose: (res?: any, categoryId?: string, mode?: 'create' | 'edit') => void;
  getNewCreateCategory: (id: string) => void;
}

const CreateCategory = ({ onClose, getNewCreateCategory }: IProps) => {
  const [srcImageCrop, setSrcImageCrop] = useState<string | null>();
  const [srcImage, setSrcImage] = useState<string>();
  const [openModalCropImage, setOpenModalCropImage] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control
  } = useForm();
  const queryClient = useQueryClient();

  const onSave = (data: CategoryNameForm) => {
    createNewCategory(data);
  };

  const { isLoading: loadingUploadImage, mutate: mutateUploadImage } =
    useMutation(uploadImageCategory);
  const { isLoading, mutate: createNewCategory } = useMutation(
    createCategoryV2,
    {
      onSuccess: (res: any) => {
        const fd = new FormData();
        if (srcImageCrop) {
          fd.append('files[]', srcImageCrop);
        }
        if (fd.getAll('files[]').length <= 0) {
          queryClient.invalidateQueries('getListCategories');
          queryClient.invalidateQueries('getListTaxon');
          handleSuccess(CREATE_MESSAGE.CATEGORY_SUCCESS);
          handleSuccess(CREATE_MESSAGE.CATEGORY_SUCCESS);
          getNewCreateCategory(res['@id']);
          onClose();
          return;
        }
        mutateUploadImage(
          { code: res.code, fd },
          {
            onSuccess: () => {
              queryClient.invalidateQueries('getListCategories');
              queryClient.invalidateQueries('getListTaxon');
              handleSuccess('The image is uploaded successfully');
              handleSuccess(CREATE_MESSAGE.CATEGORY_SUCCESS);
              getNewCreateCategory(res['@id']);
              onClose();
            },
            onError: (error: any) => {
              handleError(error.response.status);
              onClose();
            }
          }
        );
      },
      onError: (error: any) => {
        handleError(error.response.status, CREATE_MESSAGE.CATEGORY_ERROR);
      }
    }
  );

  const watchUpload = useWatch({
    control,
    name: 'files'
  });
  const toastErrorFileSizeLarge = () => {
    handleError(undefined, SIZE_IS_TOO_BIG);
  };
  useEffect(() => {
    if (watchUpload) {
      const file = watchUpload[0];
      if (file) {
        if (file.size > 10485760) {
          // 10485760 B <=> 10 MB
          toastErrorFileSizeLarge();
        } else {
          const fileReader = new FileReader();
          fileReader.onload = (e: any) => {
            const result = e.target.result as string;
            setSrcImage(result);
          };
          fileReader.readAsDataURL(file);
        }
      }
      setOpenModalCropImage(true);
    }
  }, [watchUpload]);

  const removeImage = () => {
    setSrcImage('');
    setSrcImageCrop(null);
  };
  const closeModalUpdateImageProduct = () => {
    setOpenModalCropImage(false);
  };
  const onSaveImageCrop = (file: any) => {
    setSrcImageCrop(file);
    closeModalUpdateImageProduct();
  };

  return (
    <BlockWrapper>
      <form onSubmit={handleSubmit(onSave)}>
        <TitleWrapper>
          <DisplayText
            label="Nouvelle catégorie"
            fontSize="XL"
            fontWeight="BOLD"
          />
          <div className="action-wrapper">
            <Button
              variant="primary"
              label="Enregistrer"
              isFluid={false}
              type="submit"
              className="btn-submit"
              letterSpacing="S"
              fontWeight="BOLD"
            />
            <ButtonIcon
              onClick={onClose}
              icon={IconNames.Close}
              widthIcon={50}
              variant="square"
              type="button"
            />
          </div>
        </TitleWrapper>
        <ContentWrapper>
          <TextInputWrapper className={errors.name ? 'border-error' : ''}>
            <div className="image-wrapper">
              <div className="upload-image">
                <ButtonIcon
                  icon={IconNames.Close}
                  widthIcon={24}
                  className="icon-close-image"
                  onClick={removeImage}
                />
                <input
                  id="files"
                  type="file"
                  accept="image/*"
                  {...register?.('files')}
                />
                <Label forId="files">
                  {!srcImage ? (
                    <UploadStyle formState={errors.files}>
                      <PlusIcon />
                    </UploadStyle>
                  ) : (
                    <img src={srcImage} />
                  )}
                </Label>
              </div>
              {srcImage && openModalCropImage && (
                <CropImage
                  srcImage={srcImage}
                  file={getValues('files')}
                  onCloseCropImage={closeModalUpdateImageProduct}
                  onModeSave={onSaveImageCrop}
                  mode="product"
                />
              )}
            </div>
            <div>
              <DisplayText
                label="Nom"
                fontSize="M"
                fontWeight="BOLD"
                as="label"
              />
              <TextInput
                placeholder="Nom"
                variant="title"
                name="name"
                register={register}
                getValues={getValues}
                validate={{
                  required: {
                    value: true,
                    message: 'Le nom de la catégorie est obligatoire'
                  }
                }}
              />
            </div>
          </TextInputWrapper>
        </ContentWrapper>
      </form>
      {(isLoading || loadingUploadImage) && <Loader />}
    </BlockWrapper>
  );
};

export default CreateCategory;
