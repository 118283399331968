import React from 'react';
import Select from 'react-select';
import IDropdownList from 'types/DropdownList';
import styled, { css } from 'styled-components';
import { CaretDown, ChevronDown } from 'components/ui-kits/Icon';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';
import { Control, Controller, FieldValues } from 'react-hook-form';

type VariantStyle = 'default' | 'rounded' | 'selectSm';

const variantStyle = (isDisabled: boolean, hasError?: boolean) => ({
  default: css`
    min-width: 30rem;
  `,
  rounded: css`
    min-width: 30rem;
    .rounded {
      &__control {
        border-radius: 2rem;
        background-color: ${isDisabled ? COLORS.GREY_4 : COLORS.WHITE};
      }
      &__indicators {
        margin-right: 2rem;
        color: ${COLORS.GREY_8};
      }
      &__option {
        border-bottom: 1px solid ${COLORS.GREY_4};
        color: ${COLORS.BLACK_3};
        font-size: ${FONT_SIZE.M};

        &--is-focused {
          background-color: ${COLORS.TRANSPARENT};
        }

        &:hover {
          background-color: ${COLORS.GREY_5};
        }

        &--is-selected {
          background-color: ${COLORS.GREY_5};
        }
      }
      &__value-container {
        font-size: ${FONT_SIZE.M};
        font-weight: ${FONT_WEIGHT.MEDIUM};
      }
      &__menu-list {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  `,
  selectSm: css`
    min-width: 13.5rem;
    max-width: 13.5rem;
    .selectSm {
      &__control {
        border-radius: 2rem;
        position: relative;
        background-color: ${isDisabled ? COLORS.GREY_4 : COLORS.WHITE};
        border: ${hasError
          ? `2px solid ${COLORS.RED_6};`
          : `1px solid ${COLORS.GREY_4};`};

        &:hover {
          border: ${hasError && `2px solid ${COLORS.RED_6};`};
        }
      }
      &__single-value {
        color: ${COLORS.BLACK_2};
      }
      &__indicators {
        color: ${COLORS.WHITE};
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: ${COLORS.BLACK_2};
        position: absolute;
        right: 0.6rem;
        top: 50%;
        transform: translateY(-50%);
      }
      &__option {
        border-bottom: 1px solid ${COLORS.GREY_4};
        color: ${COLORS.BLACK_3};
        font-size: ${FONT_SIZE.M};
        text-align: center;

        &--is-focused {
          background-color: ${COLORS.TRANSPARENT};
        }

        &:hover {
          background-color: ${COLORS.GREY_5};
        }

        &--is-selected {
          background-color: ${COLORS.GREY_5};
        }
      }

      &__value-container {
        font-size: ${FONT_SIZE.L};
        font-weight: ${FONT_WEIGHT.MEDIUM};
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 3rem;
      }

      &__menu-list {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  `
});

const indicatorComponents = {
  default: null,
  rounded: {
    IndicatorSeparator: () => null,
    DropdownIndicator: () => <CaretDown className="rounded-icon" width={10} />
  },
  selectSm: {
    IndicatorSeparator: () => null,
    DropdownIndicator: () => (
      <ChevronDown className="selectSm-icon" width={12} />
    )
  }
};
interface IProps {
  options: IDropdownList[];
  change?: (data: IDropdownList) => void;
  isSearchable?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  variant?: VariantStyle;
  defaultValue?: IDropdownList;
  value?: IDropdownList;
  isFluid?: boolean;
  control?: Control<FieldValues>;
  name?: any;
  styles?: any;
  validate?: any;
  hasError?: boolean;
}

const SelectStyle = styled(Select)<{
  variant: VariantStyle;
  isDisabled: boolean;
  hasError?: boolean;
}>`
  width: ${(props) => (props.isFluid ? '100%' : 'auto')};
  ${(props) => variantStyle(props.isDisabled, props.hasError)[props.variant]}
`;

const DropdownList = ({
  options,
  change,
  isSearchable = false,
  placeholder = 'Select',
  isDisabled = false,
  variant = 'default',
  defaultValue,
  value,
  isFluid = true,
  control,
  name,
  styles,
  validate,
  hasError
}: IProps) => {
  const onChangeDropdown = (data: any) => {
    change?.(data);
  };

  const selectWithoutController = () => (
    <SelectStyle
      onChange={onChangeDropdown}
      options={options}
      isSearchable={isSearchable}
      placeholder={placeholder}
      isDisabled={isDisabled}
      variant={variant}
      className={variant}
      classNamePrefix={variant}
      defaultValue={defaultValue}
      value={value}
      components={indicatorComponents[variant]}
      isFluid={isFluid}
      styles={styles}
    />
  );

  return (
    <>
      {control ? (
        <Controller
          control={control}
          name={name}
          rules={validate}
          defaultValue={defaultValue}
          render={({ field: { onChange, name, value } }) => (
            <SelectStyle
              onChange={(data: any) => {
                onChangeDropdown(data);
                onChange(data);
              }}
              options={options}
              isSearchable={isSearchable}
              placeholder={placeholder}
              styles={styles}
              isDisabled={isDisabled}
              name={name}
              variant={variant}
              className={variant}
              classNamePrefix={variant}
              value={value}
              components={indicatorComponents[variant]}
              isFluid={isFluid}
              hasError={hasError}
            />
          )}
        />
      ) : (
        selectWithoutController()
      )}
    </>
  );
};

export default DropdownList;
