import styled from 'styled-components';

export const CollapseWapper = styled.div`
  position: relative;
  padding: 2rem 3rem 2rem 10rem;
  border-bottom: 1px solid #c8cbd0;
  color: #464a51;
  .collapse {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.8rem;
      font-weight: 500;
      button {
        position: absolute;
        left: 5rem;
        transform: rotate(180deg);
        transition: all 0.4s;
        &.active {
          transform: rotate(0);
          transition: all 0.4s;
        }
      }
      h2 {
        color: #8d9598;
      }
    }
    &-body {
      margin-top: 1.5rem;
      ul {
        list-style: none;
      }
      li {
        display: flex;
        justify-content: space-between;
        margin: 0.5rem 0;
        font-size: 1.4rem;
        &:last-child {
          margin-top: 1rem;
          margin-bottom: 0;
          font-size: 1.8rem;
          font-weight: 500;
        }
        span {
          &:first-child {
            color: #8d9598;
          }
          &:last-child {
            color: #464a51;
            font-weight: 500;
          }
        }
      }
      &.normal {
        li:last-child {
          font-size: 1.4rem;
          font-weight: 400;
        }
      }
    }
  }
`;
