import React, { useState, useEffect, useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import Button from 'components/ui-kits/Button';
import DisplayText from 'components/ui-kits/DisplayText';
import {
  BlockWrapper,
  TitleWrapper,
  Tabs,
  TabsHead,
  TabsContent,
  TextInputWrapper,
  SellAsOptionWrapper,
  DescriptionWrapper,
  FieldWrapper,
  Price,
  RadioWrapper,
  CheckboxWrapper,
  ImageWrapper,
  DietaryWrapper,
  CategoriesWrapper,
  SearchWrapper,
  SearchWidth,
  SearchResult,
  BlockRelative,
  Regulation,
  AllergenWrapper,
  ReferenceWrapper,
  TimelineWrapper,
  MultipleOptionQuantity
} from './EditProductItemForm.style';
import ButtonIcon from 'components/ui-kits/ButtonIcon';
import { IconNames } from 'components/ui-kits/Icon/Icon';
import TextInput from 'components/ui-kits/Form/TextInput';
import { Checkbox, CheckboxSwitch } from 'components/ui-kits/Form/Checkbox';
import Textarea from 'components/ui-kits/Form/Textarea';
import NumberInput from 'components/ui-kits/Form/NumberInput';
import { DropdownList, Search } from 'components/ui-kits/Form/Dropdown';
import Radio from 'components/ui-kits/Form/Radio';
import UploadImageCrop from 'components/UploadImageCrop';
import type {
  IAllergen,
  IDietary,
  Product,
  ProductOption
} from 'types/Products';
import Tag from 'components/ui-kits/Tag';
import { CategoryItemData } from 'types/Category';
import { formatDollar, handleError, replaceCharacter } from 'utils';
import { UNIT_TYPE, VAT_OPTIONS } from 'const/Const';
import type { IGroupOptions } from 'types/GroupOptions';
import CurrentGroupOption from 'components/AddGroupOptionShared/CurrentGroupOption';
import ModalAddOption from 'components/AddGroupOptionShared/ModalAddOption';
import { SIZE_IS_TOO_BIG } from 'const/Message';
import { MessageErrorName } from 'styles/common';
import { getListCategoriesV2 } from 'services/categoryService';
import { getGroupOptionsListV2 } from 'services/groupOptionService';
import { getProductAttrV2 } from 'services/ProductAttrService';
import CustomDatePicker from 'components/ui-kits/CustomDatePicker';
import { CalendarIcon } from 'components/ui-kits/Icon';
import { SellProductWithUnits } from 'components/ProductLayout/ProductForm/ProductForm.style';
import { FieldProductFinalPrice } from './FieldProductFinalPrice';
import { FieldPrice } from './FieldPrice';
import { addSeconds, endOfDay, isSameDay, startOfDay } from 'date-fns';

interface IProps {
  onClose: () => void;
  dataEdit?: ProductOption;
  edit?: (data: Product, imgCropped: File) => void;
  create?: (data: Product, imgCropped: File) => void;
  categoryCreate?: string;
  mode: 'create' | 'edit';
}

const EditProductItemForm = ({
  onClose,
  dataEdit,
  edit,
  create,
  categoryCreate,
  mode = 'edit'
}: IProps) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset
  } = useForm();
  const [toggleTabs, setToggleTabs] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  // const [listOption, setListOption] = useState<GroupOptionRes[]>([]);
  const [categoriesSelected, setCategoriesSelected] = useState<string[]>([]);
  const [isInitialCategory, setIsInitialCategory] = useState(false);
  const [groupOptionData, setGroupOptionData] = useState<string[]>([]);
  const [currentGroupOptionSelected, setCurrentGroupOptionSelected] = useState<
    IGroupOptions[]
  >([]);
  const [imgCropped, setImgCropped] = useState<any>();
  const [startDate, setStartDate] = useState(() => {
    if (!!dataEdit?.promotions?.startDate?.date) {
      const startDateString = dataEdit.promotions.startDate.date + 'Z';
      const formattedStartDate = startDateString.replace(' ', 'T');

      return new Date(formattedStartDate);
    } else {
      return new Date();
    }
  });
  const [endDate, setEndDate] = useState(() => {
    if (!!dataEdit?.promotions?.endDate?.date) {
      const endDateString = dataEdit.promotions.endDate.date + 'Z';
      const formattedEndDate = endDateString.replace(' ', 'T');

      return new Date(formattedEndDate);
    } else {
      return endOfDay(startDate);
    }
  });

  const watchChangeInputFile = useWatch({
    control,
    name: 'files'
  });
  const watchSellProductUnits = useWatch({
    control,
    name: 'isSellProductWithUnits'
  });
  const watchIsReduction = useWatch({
    control,
    name: 'isReduction'
  });
  const watchUnitType = useWatch({
    control,
    name: 'unitType'
  });

  const { data: resultListOption } = useQuery(
    'getListGroupOption',
    ({ signal }) => getGroupOptionsListV2({ signal })
  );

  const { data: listCategories } = useQuery(
    ['getListCategories', { page: 1, itemsPerPage: 1000 }],
    (context) => getListCategoriesV2(context.queryKey, context.signal)
  );

  const { data: resultProductAttr } = useQuery(
    'getListProductAttr',
    ({ signal }) => getProductAttrV2(signal)
  );

  const calculateProductFinalPrice = useCallback(() => {
    const pricePerUnit = Number(
      replaceCharacter(String(getValues('pricePerUnit')), ',', '.')
    );
    const quantity = Number(
      replaceCharacter(
        String(getValues('quantityProductSoldByWeight')),
        ',',
        '.'
      )
    );
    const reductionPercent = Number(
      replaceCharacter(String(getValues('reductionPercent')), ',', '.')
    );
    const originalPrice = Number(
      replaceCharacter(String(getValues('price')), ',', '.')
    );
    const isReduction = getValues('isReduction');
    const isSellProductWithUnits = getValues('isSellProductWithUnits');

    let precalculatedFinalPrice: number;

    if (!isReduction && !isSellProductWithUnits) {
      precalculatedFinalPrice = originalPrice;
    } else if (!isReduction && isSellProductWithUnits) {
      precalculatedFinalPrice = pricePerUnit * quantity;
    } else if (isReduction && !isSellProductWithUnits) {
      precalculatedFinalPrice =
        (originalPrice * (100 - reductionPercent)) / 100;
    } else {
      // isReduction && isSellProductWithUnits
      precalculatedFinalPrice =
        (pricePerUnit * quantity * (100 - reductionPercent)) / 100;
    }

    setValue('proFinalPrice', formatDollar(precalculatedFinalPrice * 100));
  }, [getValues, setValue]);

  useEffect(() => {
    reset({
      ...dataEdit,
      vat: VAT_OPTIONS.filter(
        (option) => Number(option.value) == dataEdit?.includedTax
      )[0],
      price: (dataEdit?.price && dataEdit.originalPrice / 100) || 0,
      // priceWithoutVAT: dataEdit && dataEdit.priceWithoutVAT / 100,
      pricePerUnit: (dataEdit && dataEdit.pricePerUnit / 100) || 0,
      unitType: UNIT_TYPE.filter((option) => option.value == dataEdit?.unit)[0],
      reductionPercent:
        (dataEdit?.promotions?.percent && dataEdit.promotions.percent * 100) ||
        0,
      quantityProductSoldByWeight: dataEdit?.quantityProductSoldByWeight || 0,
      proFinalPrice:
        (dataEdit?.variants?.[0].price && dataEdit.variants[0].price / 100) || 0
    });
    if (dataEdit) {
      setValue('isReduction', dataEdit.appliedPromotion);
      setValue('isSellProductWithUnits', dataEdit.isSellWithUnits);
    }
  }, [dataEdit, reset, setValue]);

  // useEffect(() => {
  //   if (resultListOption) {
  //     const arrListGroupOption = resultListOption['hydra:member'].map(
  //       (item) => ({
  //         id: item['@id'],
  //         name: item.name,
  //         displayName: item.displayName,
  //         contains: Object.values(item.groupOptionProducts),
  //         isMandatory: item.isMandatory
  //       })
  //     );
  //     setListOption(arrListGroupOption);
  //   }
  // }, [resultListOption]);

  const dietary: IDietary[] = [];
  const allergen: IAllergen[] = [];

  let defaultAlcoholic = 'false';
  let defaultDietary: string[] = [];
  let defaultAllergen: string[] = [];

  if (resultProductAttr?.data) {
    resultProductAttr.data['hydra:member'].forEach((item: any) => {
      if (item.code === 'dietary_attributes') {
        const choices = item.configuration.choices;
        for (const k in choices) {
          dietary.push({ value: k, label: choices[k].en_US });
        }
      }

      if (item.code === 'allergen_attributes') {
        const choices = item.configuration.choices;
        for (const k in choices) {
          allergen.push({ value: k, label: choices[k].en_US });
        }
      }
    });
  }

  dataEdit?.attributes.map((item: any) => {
    if (item.code === 'alcoholic_product') {
      defaultAlcoholic = String(item.value);
    }
    if (item.code === 'dietary_attributes') {
      defaultDietary = item.value;
    }
    if (item.code === 'allergen_attributes') {
      defaultAllergen = item.value;
    }
  });

  const checkedDietary = (listDietary: string) => {
    return defaultDietary.includes(listDietary) ? true : false;
  };

  const checkedAllergen = (listAllergen: string) => {
    return defaultAllergen.includes(listAllergen) ? true : false;
  };

  // save data
  const onSave = (product: Product) => {
    if (typeof product.isAlcoholicProduct == 'string') {
      product.isAlcoholicProduct == 'true'
        ? (product.isAlcoholicProduct = true)
        : (product.isAlcoholicProduct = false);
    }
    if (typeof product.dietaryAttributes == 'string') {
      product.dietaryAttributes = [product.dietaryAttributes];
    }
    if (typeof product.allergenAttributes == 'string') {
      product.allergenAttributes = [product.allergenAttributes];
    }
    product.categories = categoriesSelected;
    product.maxAmount = 0;
    product.includedTax = Number(product.vat.value);
    product.dietaryAttributes = product.dietaryAttributes || [];
    product.allergenAttributes = product.allergenAttributes || [];
    product.groupOptionIRIs = groupOptionData;
    product.price =
      Number(replaceCharacter(String(product.price), ',', '.')) * 100;
    // product.priceWithoutVAT = Math.round(
    //   Number(replaceCharacter(String(product.priceWithoutVAT), ',', '.')) * 100
    // );
    product.unit = product.unitType?.value;
    product.pricePerUnit =
      Number(replaceCharacter(String(product.pricePerUnit), ',', '.')) * 100;
    product.reductionPercent = Number(
      Number(
        Number(replaceCharacter(String(product.reductionPercent), ',', '.')) /
          100
      ).toFixed(4)
    );
    product.quantityProductSoldByWeight = Number(
      replaceCharacter(String(product.quantityProductSoldByWeight), ',', '.')
    );

    // Change to the start of selected start date plus 1 second
    // when the start date is not current date (larger than current date).
    const isCurrentDay =
      (!!dataEdit?.promotions?.startDate?.date &&
        isSameDay(startDate, new Date(dataEdit.promotions.startDate.date))) ||
      isSameDay(startDate, new Date());

    product.startDate = isCurrentDay
      ? new Date().toISOString()
      : addSeconds(startOfDay(startDate), 1).toISOString();

    product.endDate = endOfDay(endDate).toISOString();
    product.isEditPrice = product.price !== dataEdit?.originalPrice;

    if (categoryCreate) {
      product.categories.push(categoryCreate);
    }

    if (edit) {
      edit(product, imgCropped);
    } else if (create) {
      create(product, imgCropped);
    }
  };

  useEffect(() => {
    if (categoriesSelected.length || isInitialCategory) {
      return;
    }
    if (dataEdit?.productTaxons?.length) {
      const listCategoriesSelected = dataEdit?.productTaxons.map(
        (item: any) => item.taxon?.code
      );
      setCategoriesSelected(listCategoriesSelected);
      setIsInitialCategory(true);
    }
  }, [dataEdit, categoriesSelected.length, isInitialCategory]);

  const onChangeSearch = (data: { label: string; value: string }[]) => {
    setCategoriesSelected([...categoriesSelected, data[0].value]);
  };

  // Render data edit of groupoption
  useEffect(() => {
    if (dataEdit && resultListOption) {
      const groupOptionChosen = dataEdit.groupOptions?.map(
        (option: any) => option['@id']
      );
      const filterGroupOption: any = groupOptionChosen.map((id: string) => {
        let data = {};
        resultListOption['hydra:member'].forEach((option) => {
          if (option['@id'] === id) {
            data = option;
          }
        });
        return data;
      });
      setCurrentGroupOptionSelected(filterGroupOption);
      setGroupOptionData(groupOptionChosen);
    }
  }, [dataEdit, resultListOption]);

  // Handle group option selected and render UI
  const handleGroupOptionSelected = (
    data: { addgroupoption: string[] },
    idGOAdd?: string[],
    idGORemove?: string[]
  ) => {
    if (typeof data.addgroupoption === 'string') {
      data.addgroupoption = [data.addgroupoption];
    }

    if (resultListOption && data.addgroupoption) {
      let currentGroupOptionSelectedClone = [...currentGroupOptionSelected];
      let groupOptionDataClone = [...groupOptionData];

      if (idGOAdd) {
        groupOptionDataClone.push(...idGOAdd);

        const filterGOAdd: any[] = resultListOption['hydra:member'].filter(
          (option) => idGOAdd.includes(option['@id'])
        );

        if (filterGOAdd.length) {
          currentGroupOptionSelectedClone.push(...filterGOAdd);
        }
      }

      if (idGORemove) {
        groupOptionDataClone = groupOptionDataClone.filter(
          (id: string) => !idGORemove.includes(id)
        );

        currentGroupOptionSelectedClone =
          currentGroupOptionSelectedClone.filter((option: IGroupOptions) => {
            return !idGORemove.includes(option['@id']);
          });
      }

      setCurrentGroupOptionSelected(currentGroupOptionSelectedClone);
      setGroupOptionData(groupOptionDataClone);
      setOpenModal(false);
    } else {
      setCurrentGroupOptionSelected([]);
      setGroupOptionData([]);
      setOpenModal(false);
    }
  };

  const handleRemoveGroupOptionItem = (id: string) => {
    setCurrentGroupOptionSelected(
      currentGroupOptionSelected.filter((item: any) => item['@id'] != id)
    );
    setGroupOptionData(groupOptionData.filter((item: string) => item != id));
  };

  const handleDeleteTag = (code: string) => {
    setCategoriesSelected(
      categoriesSelected.filter((item: string) => item !== code)
    );
  };

  const toastErrorFileSizeLarge = () => {
    handleError(undefined, SIZE_IS_TOO_BIG);
  };

  function handleDragGO(GO: any[]) {
    const idGODrag: string[] = GO?.map((item: any) => item['@id']);
    const sortDisplayGO: any[] = idGODrag.map((id: string) => {
      let data = {};
      currentGroupOptionSelected.forEach((option) => {
        if (option['@id'] === id) {
          data = option;
        }
      });
      return data;
    });

    setGroupOptionData(idGODrag);
    setCurrentGroupOptionSelected(sortDisplayGO);
  }

  const listOption = resultListOption
    ? resultListOption['hydra:member'].map((item) => ({
        id: item['@id'],
        name: item.name,
        displayName: item.displayName,
        contains: Object.values(item.groupOptionProducts),
        isMandatory: item.isMandatory
      }))
    : [];

  return (
    <BlockWrapper>
      {openModal ? (
        <ModalAddOption
          listOption={listOption}
          onClose={() => setOpenModal(false)}
          onGroupOptionSelected={handleGroupOptionSelected}
          currentGroupOptionSelected={currentGroupOptionSelected}
          mode="catalog"
        />
      ) : null}

      <form onSubmit={handleSubmit(onSave)}>
        <TitleWrapper>
          <DisplayText
            label={mode === 'edit' ? 'Modifier un produit' : 'Nouveau produit'}
            fontSize="XL"
            fontWeight="BOLD"
          />
          <div className="action-wrapper">
            <Button
              variant="primary"
              label="Enregistrer"
              isFluid={false}
              type="submit"
              className="btn-submit"
              fontWeight="BOLD"
              letterSpacing="S"
            />
            <ButtonIcon
              onClick={onClose}
              icon={IconNames.Close}
              widthIcon={50}
              variant="square"
              type="button"
            />
          </div>
        </TitleWrapper>

        <Tabs>
          <TabsHead>
            <li
              className={toggleTabs === 0 ? 'active-tabs' : ''}
              onClick={() => setToggleTabs(0)}>
              Infos
            </li>
            <li
              className={toggleTabs === 1 ? 'active-tabs' : ''}
              onClick={() => setToggleTabs(1)}>
              Détails
            </li>
          </TabsHead>
          <TabsContent className={toggleTabs === 0 ? 'active-content' : ''}>
            <TextInputWrapper className={errors.name ? 'border-error' : ''}>
              <DisplayText
                label="Nom"
                fontSize="M"
                fontWeight="BOLD"
                as="label"
              />
              <TextInput
                placeholder="Nom"
                variant="title"
                name="name"
                register={register}
                getValues={getValues}
                validate={{
                  required: {
                    value: true,
                    message: 'Ce champ est requis'
                  }
                }}
                defaultValue={dataEdit?.name}
              />
            </TextInputWrapper>
            {errors.name && (
              <MessageErrorName>{errors.name.message}</MessageErrorName>
            )}

            <ReferenceWrapper>
              <DisplayText
                as="label"
                label="Référence"
                fontSize="M"
                fontWeight="BOLD"
              />
              <TextInput
                name="reference"
                register={register}
                defaultValue={dataEdit?.reference}
                placeholder="Veuillez saisir la référence de votre produit ici. (facultatif)"
              />
            </ReferenceWrapper>

            <Regulation>
              <p>
                En cliquant sur &ldquo;Enregistrer&rdquo; vous confirmez prendre
                la responsabilitée des informations fournies (droit d&#39;auteur
                photo, TVA, ...)
              </p>
            </Regulation>
            <SellAsOptionWrapper>
              <CheckboxSwitch
                name="sellingAsOption"
                id="selling-as-option"
                defaultValue={dataEdit?.sellingAsOption}
                isChildLeft={false}
                register={register}>
                <DisplayText fontSize="L" fontWeight="BOLD" className="title">
                  Vendre également en tant qu&#39;option
                </DisplayText>
              </CheckboxSwitch>
            </SellAsOptionWrapper>

            <ImageWrapper>
              <DisplayText
                as="label"
                fontWeight="BOLD"
                fontSize="M"
                label="Photo"
                margin="0 0 2rem 0"
              />
              <UploadImageCrop
                className="center"
                id="upload"
                name="files"
                register={register}
                watchValue={watchChangeInputFile}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
                dataImage={dataEdit?.images}
                errorFileSizeLarge={toastErrorFileSizeLarge}
                imgCropped={imgCropped}
                setImgCropped={setImgCropped}
              />
            </ImageWrapper>

            <DescriptionWrapper>
              <Textarea
                label="Description"
                countLength={true}
                name="description"
                register={register}
                defaultValue={dataEdit?.description}
                vaLengthDefault={dataEdit?.description.length}
                maxlength={400}></Textarea>
            </DescriptionWrapper>

            <CategoriesWrapper>
              <DisplayText
                as="label"
                fontWeight="BOLD"
                fontSize="M"
                label="Catégories"
              />
              <SearchWrapper>
                <SearchResult>
                  {listCategories?.['hydra:member']
                    .filter((item: CategoryItemData) =>
                      categoriesSelected.includes(item.code)
                    )
                    .map((item: CategoryItemData) => (
                      <Tag
                        key={item['@id']}
                        label={item.name}
                        className="tag"
                        onDelete={() => handleDeleteTag(item.code)}
                      />
                    ))}
                </SearchResult>
                <SearchWidth>
                  <Search
                    change={onChangeSearch}
                    placeholder=""
                    variant="default"
                    options={listCategories?.['hydra:member']
                      .filter(
                        (item: CategoryItemData) =>
                          categoriesSelected.indexOf(item.code) === -1
                      )
                      .map((item: CategoryItemData) => ({
                        label: item.name,
                        value: item.code
                      }))}
                    isMulti={true}
                    value={[]}
                  />
                </SearchWidth>
              </SearchWrapper>
            </CategoriesWrapper>

            <SellProductWithUnits>
              <CheckboxSwitch
                name="isSellProductWithUnits"
                id="isSellProductWithUnits"
                isChildLeft={false}
                handleChange={calculateProductFinalPrice}
                register={register}>
                <DisplayText
                  label="Produit vendu au poids / litre"
                  fontSize="L"
                  fontWeight="BOLD"
                  className="title"
                />
              </CheckboxSwitch>
            </SellProductWithUnits>

            {watchSellProductUnits && (
              <React.Fragment>
                <FieldWrapper>
                  <Price>
                    <DisplayText
                      as="label"
                      fontWeight="BOLD"
                      fontSize="M"
                      label="Prix unitaire TTC"
                    />
                  </Price>
                  <NumberInput
                    name="pricePerUnit"
                    validate={{ required: true }}
                    register={register}
                    variant="unit"
                    setValue={setValue}
                    getValues={getValues}
                    type="text"
                    onBlur={calculateProductFinalPrice}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <DisplayText
                    as="label"
                    fontWeight="BOLD"
                    fontSize="M"
                    label="Unité"
                  />
                  <BlockRelative className="unit-type">
                    <DropdownList
                      name="unitType"
                      variant="selectSm"
                      placeholder="Select"
                      options={UNIT_TYPE}
                      control={control}
                      validate={{
                        required: {
                          value: true,
                          message: 'La Unit est obligatoire'
                        }
                      }}
                      hasError={Boolean(errors.unitType)}
                      styles={{
                        option: (style: any) => ({
                          ...style,
                          fontSize: '1.6rem'
                        })
                      }}
                    />
                    {errors.unitType && (
                      <MessageErrorName>
                        {errors.unitType.message}
                      </MessageErrorName>
                    )}
                  </BlockRelative>
                </FieldWrapper>
                <FieldWrapper>
                  <Price>
                    <DisplayText
                      as="label"
                      fontWeight="BOLD"
                      fontSize="M"
                      label="Quantité"
                    />
                  </Price>
                  <NumberInput
                    name="quantityProductSoldByWeight"
                    variant="unit"
                    type="text"
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                    noIcon={true}
                    numberToFixed={3}
                    onBlur={calculateProductFinalPrice}
                    unitType={watchUnitType?.value}
                  />
                </FieldWrapper>
              </React.Fragment>
            )}

            <FieldPrice
              control={control}
              register={register}
              getValues={getValues}
              setValue={setValue}
              onBlur={calculateProductFinalPrice}
              defaultValue={dataEdit && dataEdit.originalPrice / 100}
            />

            <FieldWrapper>
              <DisplayText
                as="label"
                fontWeight="BOLD"
                fontSize="M"
                label="TVA"
              />
              <BlockRelative>
                <DropdownList
                  name="vat"
                  variant="selectSm"
                  placeholder=""
                  options={VAT_OPTIONS}
                  control={control}
                  validate={{
                    required: { value: true, message: 'La TVA est obligatoire' }
                  }}
                  hasError={Boolean(errors.vat)}
                  defaultValue={
                    VAT_OPTIONS.filter(
                      (option) => option.value === dataEdit?.includedTax
                    )[0]
                  }
                  styles={{
                    option: (style: any) => ({
                      ...style,
                      fontSize: '1.6rem'
                    })
                  }}
                />
                {errors.vat && (
                  <MessageErrorName>{errors.vat.message}</MessageErrorName>
                )}
              </BlockRelative>
            </FieldWrapper>

            {/* Apply for next version */}
            {/* <FieldWrapper>
              <Price>
                <DisplayText
                  as="label"
                  fontWeight="BOLD"
                  fontSize="M"
                  label="Price not subject to VAT"
                />
              </Price>
              <NumberInput
                name="priceWithoutVAT"
                validate={{ required: true }}
                register={register}
                variant="unit"
                setValue={setValue}
                getValues={getValues}
                type="text"
                defaultValue={dataEdit && dataEdit.priceWithoutVAT / 100}
              />
            </FieldWrapper> */}

            <SellAsOptionWrapper>
              <CheckboxSwitch
                name="isReduction"
                id="isReduction"
                isChildLeft={false}
                handleChange={calculateProductFinalPrice}
                register={register}>
                <DisplayText
                  label="Ajouter une réduction au produit"
                  fontSize="L"
                  fontWeight="BOLD"
                  className="title"
                />
              </CheckboxSwitch>
            </SellAsOptionWrapper>

            {watchIsReduction && (
              <React.Fragment>
                <FieldWrapper>
                  <Price>
                    <DisplayText
                      as="label"
                      fontWeight="BOLD"
                      fontSize="M"
                      label="Pourcentage de réduction"
                    />
                  </Price>
                  <NumberInput
                    name="reductionPercent"
                    variant="percent"
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    type="text"
                    onBlur={calculateProductFinalPrice}
                  />
                </FieldWrapper>

                <TimelineWrapper>
                  <DisplayText
                    as="label"
                    fontWeight="BOLD"
                    fontSize="M"
                    label="Durée de la réduction"
                  />
                  <div className="dateAndTime__wrapper">
                    <div>
                      <div className="dateAndTime__title">Date de début</div>
                      <CustomDatePicker
                        onChange={(date: any) => {
                          setStartDate(date);
                          const s = new Date(date);
                          const e = new Date(endDate);
                          if (s.getTime() > e.getTime()) {
                            setEndDate(date);
                          }
                        }}
                        variant="default"
                        icon={<CalendarIcon />}
                        id="startDate"
                        placeholderText="DD/MM/YYYY"
                        selected={startDate}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        minDate={new Date()}
                      />
                    </div>
                    <div>
                      <div className="dateAndTime__title">Date de fin</div>
                      <CustomDatePicker
                        onChange={(date: any) => setEndDate(date)}
                        variant="default"
                        icon={<CalendarIcon />}
                        id="endDate"
                        placeholderText="DD/MM/YYYY"
                        selected={endDate}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                      />
                    </div>
                  </div>
                </TimelineWrapper>
              </React.Fragment>
            )}

            <FieldProductFinalPrice
              control={control}
              register={register}
              getValues={getValues}
              setValue={setValue}
              defaultValue={dataEdit && dataEdit.variants[0].price / 100}
            />

            <FieldWrapper padding="top">
              <DisplayText
                as="label"
                fontWeight="BOLD"
                fontSize="M"
                label="Produit contenant de l'alcool ?"
              />
              <RadioWrapper>
                <Radio
                  name="isAlcoholicProduct"
                  id="yes"
                  value="true"
                  validate={{
                    required: true,
                    value: defaultAlcoholic
                  }}
                  register={register}>
                  Oui
                </Radio>
                <Radio
                  name="isAlcoholicProduct"
                  id="no"
                  value="false"
                  validate={{ required: true, value: defaultAlcoholic }}
                  register={register}>
                  Non
                </Radio>
              </RadioWrapper>
            </FieldWrapper>
            <DietaryWrapper>
              <DisplayText
                as="label"
                fontWeight="BOLD"
                fontSize="M"
                label="Cartéristiques alimentaires"
              />
              <CheckboxWrapper>
                {dietary?.map((item, i) => (
                  <div key={i} className="dietary-item">
                    <Checkbox
                      key={i}
                      name="dietaryAttributes"
                      id={item.label}
                      value={item.value}
                      defaultChecked={checkedDietary(item.label)}
                      register={register}>
                      {item.label}
                    </Checkbox>
                  </div>
                ))}
              </CheckboxWrapper>
            </DietaryWrapper>
            <AllergenWrapper>
              <DisplayText
                as="label"
                fontWeight="BOLD"
                fontSize="M"
                label="Listes des allergènes"
              />
              <CheckboxWrapper>
                {allergen?.map((item, i) => (
                  <div key={i} className="allergen-item">
                    <Checkbox
                      key={i}
                      name="allergenAttributes"
                      id={item.label}
                      value={item.value}
                      defaultChecked={checkedAllergen(item.label)}
                      register={register}>
                      {item.label}
                    </Checkbox>
                  </div>
                ))}
              </CheckboxWrapper>
            </AllergenWrapper>
          </TabsContent>
          <TabsContent className={toggleTabs === 1 ? 'active-content' : ''}>
            <MultipleOptionQuantity>
              <CheckboxSwitch
                name="notMultipleQuantityOption"
                id="isNotMultipleQuantityOption"
                isChildLeft={true}
                register={register}>
                <DisplayText
                  fontSize="L"
                  fontWeight="BOLD"
                  className="title"
                  label="Ne pas multiplier la quantité d'option par la quantité de produit"
                />
              </CheckboxSwitch>
            </MultipleOptionQuantity>
            <CurrentGroupOption
              currentGroupOptionSelected={currentGroupOptionSelected}
              onOpenModal={() => setOpenModal(true)}
              onRemoveGroupOptionItem={handleRemoveGroupOptionItem}
              onHandleDragGO={handleDragGO}
              mode="catalog"
              className="config"
            />
          </TabsContent>
        </Tabs>
      </form>
    </BlockWrapper>
  );
};

export default EditProductItemForm;
