import React from 'react';
import DisplayText from 'components/ui-kits/DisplayText';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';
import { BestSelling, Period } from 'types/Merchant';

const CardBestSellingWrapper = styled.div`
  grid-column: 3/5;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GREY_4};
  border-radius: 0.7rem;
  padding: 2.8rem;
  box-sizing: border-box;
`;

const Title = styled(DisplayText)`
  line-height: 2.9rem;
`;

const ListProSelling = styled.div`
  margin-top: 2.8rem;
`;

const ProItemSellingStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.4rem;
  span {
    margin-right: 1rem;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

interface CardBestSelling {
  dataBestSelling: BestSelling;
  period: Period;
}

const CardBestSelling = ({ dataBestSelling, period }: CardBestSelling) => {
  return (
    <CardBestSellingWrapper>
      <Title label="Produits les plus vendus" fontSize="XL" fontWeight="BOLD" />
      <DisplayText
        label={`Top 10 des produits les plus vendus ces ${period} derniers jours`}
        fontSize="S"
        fontWeight="MEDIUM"
        color="GREY_1"
      />
      <ListProSelling>
        {Object.entries(dataBestSelling).map((item: any, index) => {
          return (
            <ProItemSellingStyle key={index}>
              <div>
                <DisplayText
                  as="span"
                  label={index < 9 ? `0${index + 1}.` : `${index + 1}.`}
                  fontWeight="MEDIUM"
                />
                <DisplayText as="span" label={item[0]} fontWeight="MEDIUM" />
              </div>
              <div>
                <DisplayText label={item[1]} fontWeight="BOLD" fontSize="S" />
              </div>
            </ProItemSellingStyle>
          );
        })}
      </ListProSelling>
    </CardBestSellingWrapper>
  );
};

export default CardBestSelling;
