import styled from 'styled-components';
import {
  COLORS,
  MEDIA_BREAKPOINT,
  FONT_SIZE,
  FONT_WEIGHT
} from 'styles/variables';
import Button from 'components/ui-kits/Button';

export const Card = styled.div`
  position: relative;
  padding: 2.8rem;
  border: 1px solid ${COLORS.GREY_7};
  border-radius: 0.7rem;
  background-color: ${COLORS.WHITE};
  margin-bottom: 2.8rem;

  &:first-of-type:last-of-type {
    button {
      display: none;
    }
  }
`;

export const DayWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -1rem;
`;

export const DayItem = styled.div`
  margin-left: 2rem;
  margin-bottom: 2rem;
  width: calc(100% / 7 - 2rem);
  @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
    width: calc(100% / 4 - 2rem);
  }
  @media ${MEDIA_BREAKPOINT.DESKTOP_M} {
    width: calc(100% / 6 - 2rem);
  }
`;

export const TimeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
  padding: 0 1.5rem;
`;

export const TimeItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;

  label {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.9rem;
    letter-spacing: 0.32px;
    color: ${COLORS.BLACK_2};
  }

  .react-datepicker {
    font-size: 1.4rem;
    background-color: ${COLORS.WHITE};
    color: ${COLORS.BLACK_2};
    border: 1px solid ${COLORS.GREY_4};
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    border-radius: 7px;
  }
  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;

    input {
      border-radius: 0.4rem;
      border: 1px solid ${COLORS.GREY_4};
      line-height: 1.6rem;
      padding: 0.6rem 1rem 0.5rem;
      text-align: center;
      width: 13.4rem;
      border-radius: 2rem;
      height: 4rem;
      color: ${COLORS.BLACK_2};
      font-size: ${FONT_SIZE.L};
      font-weight: ${FONT_WEIGHT.MEDIUM};
    }
  }
  .react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    padding: 0.8rem 0;
    position: relative;
  }
  .react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .react-datepicker__header--time {
    display: none;
  }
  .react-datepicker__time-container {
    float: right;
    width: 13.4rem;
  }
  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 1rem;
    z-index: 99;
  }
  .react-datepicker__time-container {
    .react-datepicker__time-box {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .react-datepicker__time ul.react-datepicker__time-list {
      list-style: none;
      margin: 0;
      height: calc(19.5rem + 0.85rem);
      width: 115%;
      overflow-y: scroll;
      padding-right: 17px;
      box-sizing: content-box;
    }
    .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected {
      background-color: ${COLORS.GREY_3};
    }
  }
  .react-datepicker__time-container .react-datepicker__time {
    border-radius: 7px;
  }
  .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 3rem;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    border-bottom: 1px solid ${COLORS.GREY_4};
    font-size: ${FONT_SIZE.M};

    &:last-child {
      border-bottom: none;
    }
  }
  .react-datepicker__triangle {
    display: none;
  }
`;

export const ButtonStyle = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);

  padding: 0;
  width: 3rem;
  height: 3rem;
  min-width: auto;
  border-radius: 50%;
  background-color: ${COLORS.BLACK_3};
  svg {
    width: 2rem;
    margin-right: 0;
  }
`;
