import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { FieldValues, useForm } from 'react-hook-form';
import Button from 'components/ui-kits/Button';
import DisplayText from 'components/ui-kits/DisplayText';
import { Checkbox } from 'components/ui-kits/Form/Checkbox';
import NumberInput from 'components/ui-kits/Form/NumberInput';
import Radio from 'components/ui-kits/Form/Radio';
import {
  BlockWrapper,
  CustomizeZoneWrapper,
  DataTimeline,
  FormDeliverySettings,
  MenuHourWrapper,
  TimeStyle
} from './DeliverySettings.style';
import {
  Merchant,
  MerchantDeliveryGeneral,
  MerchantDeliveryGeneralForm,
  MerchantDeliveryGeneralOptional
} from 'types/Merchant';
import MenuHour from './MenuHour';
import format from 'date-fns/format';
import { IOpeningHour } from 'types/Catalog';
import Loader from 'components/Loader';
import {
  formatDayOfWeek,
  formatDollar,
  handleError,
  handleSuccess,
  replaceCharacter
} from 'utils';
import { UPDATE_MESSAGE } from 'const/Message';
import ButtonIcon from 'components/ui-kits/ButtonIcon';
import { IconNames } from 'components/ui-kits/Icon/Icon';
import { updateDeliveryGeneralV2 } from 'services/deliveryService';

interface IProps {
  dataMerchant: Merchant;
  slug: string;
}

export default function DeliverySettings({ slug, dataMerchant }: IProps) {
  const [isEditGeneral, setIsEditGeneral] = useState<boolean>(false);
  const [deliveryTime, setDeliveryTime] = useState<any>();
  const { register, watch, handleSubmit, getValues, setValue, control } =
    useForm<FieldValues>({
      defaultValues: {
        isMinimum: dataMerchant?.minimumShippingAmount ? true : false,
        minimumShippingAmount: dataMerchant?.minimumShippingAmount
          ? dataMerchant.minimumShippingAmount / 100
          : 0,
        deliveryTimeMode: dataMerchant?.deliveryTimeMode || 'opening_hour',
        timeline: deliveryTime
      }
    });
  const isMinimum = watch('isMinimum');
  const queryClient = useQueryClient();

  const deliveryTimeMode = watch('deliveryTimeMode');

  useEffect(() => {
    if (dataMerchant) {
      const listTimeline: any = [];
      if (dataMerchant.deliveryTimes?.length > 0) {
        dataMerchant.deliveryTimes.forEach((wd: any) => {
          const check = listTimeline.some((item: any) => {
            return (
              item.closed_at === wd.closed_at &&
              item.started_at === wd.started_at
            );
          });
          if (!check) {
            const timelineItem: any = {
              weekday: [wd.weekday],
              closed_at: wd.closed_at,
              started_at: wd.started_at
            };
            listTimeline.push(timelineItem);
          } else {
            listTimeline.forEach((item: any) => {
              if (
                item.started_at === wd.started_at &&
                item.closed_at === wd.closed_at
              ) {
                item.weekday.push(wd.weekday);
              }
            });
          }
        });
      }
      setDeliveryTime(listTimeline);
    }
  }, [dataMerchant]);

  useEffect(() => {
    setValue('timeline', deliveryTime);
    setValue(
      'minimumShippingAmount',
      dataMerchant?.minimumShippingAmount
        ? dataMerchant.minimumShippingAmount / 100
        : 0
    );
  }, [dataMerchant, setValue, deliveryTime, getValues]);

  const onSave = (res: MerchantDeliveryGeneralForm) => {
    const hours: IOpeningHour[] = [];
    if (res.timeline.weekday) {
      if (typeof res.timeline.weekday === 'string') {
        res.timeline.weekday = [res.timeline.weekday];
      }
      res.timeline.weekday.forEach((day: string) => {
        hours.push({
          started_at: format(new Date(res.timeline.started_at), 'HH:mm'),
          closed_at: format(new Date(res.timeline.closed_at), 'HH:mm'),
          weekday: Number(day)
        });
      });
    }

    let data: MerchantDeliveryGeneral | MerchantDeliveryGeneralOptional;
    if (res.deliveryTimeMode === 'opening_hour') {
      data = {
        deliveryTimeMode: res.deliveryTimeMode,
        minimumShippingAmount: res.isMinimum
          ? Math.round(
              Number(
                replaceCharacter(String(res.minimumShippingAmount), ',', '.')
              ) * 100
            )
          : null
      };
    } else {
      data = {
        deliveryTimes: hours,
        deliveryTimeMode: res.deliveryTimeMode,
        minimumShippingAmount: res.isMinimum
          ? Math.round(
              Number(
                replaceCharacter(String(res.minimumShippingAmount), ',', '.')
              ) * 100
            )
          : null
      };
    }

    onUpdateDeliveryGeneral(
      {
        slug: slug,
        data: data
      },
      {
        onSuccess: () => {
          handleSuccess(UPDATE_MESSAGE.DELI_GENERAL_SUCCESS);
          queryClient.invalidateQueries('getDeliveryGeneral');
          setIsEditGeneral(false);
        },
        onError: (errors: any) => {
          handleError(
            errors.response.status,
            UPDATE_MESSAGE.DELI_GENERAL_ERROR
          );
        }
      }
    );
  };

  const { isLoading, mutate: onUpdateDeliveryGeneral } = useMutation(
    ({
      slug,
      data
    }: Required<{
      slug: string;
      data: MerchantDeliveryGeneral | MerchantDeliveryGeneralOptional;
    }>) => updateDeliveryGeneralV2(slug, data)
  );

  const disabledSchedule = (mode: string) => {
    if (!isEditGeneral && dataMerchant?.deliveryTimeMode !== mode) {
      return true;
    } else {
      return false;
    }
  };

  const MinimumAmountRes = () => {
    if (dataMerchant?.minimumShippingAmount) {
      return (
        <span className="style-price">
          {`${replaceCharacter(
            String(getValues('minimumShippingAmount')),
            '[.]',
            ','
          )}€`}
        </span>
      );
    } else {
      return <span> (Non)</span>;
    }
  };

  const renderWeekday = (arrDay: number[]) => {
    const newWeekday = arrDay.map((day) => {
      return formatDayOfWeek(day);
    });
    return <DisplayText fontWeight="BOLD" label={newWeekday.join(', ')} />;
  };

  const closeEditGeneral = () => {
    const minimumDefault = formatDollar(
      dataMerchant?.minimumShippingAmount || 0
    );
    const deliveryTimeModeDefault = dataMerchant?.deliveryTimeMode;
    setValue('minimumShippingAmount', minimumDefault);
    setValue('deliveryTimeMode', deliveryTimeModeDefault);
    setIsEditGeneral(false);
  };

  return (
    <>
      {isLoading && <Loader />}
      <form onSubmit={handleSubmit(onSave)}>
        <div className="form-block__title-wrapper">
          <div className="form-block__title-left">
            <DisplayText
              className="form-block__title"
              label="Général"
              fontSize="XL"
              fontWeight="BOLD"
            />
            {!isEditGeneral && (
              <Button
                label="Modifier"
                variant="text"
                sizeType="m"
                fontWeight="BOLD"
                onClick={() => setIsEditGeneral(true)}
                isFluid={false}></Button>
            )}
          </div>
          <div className="form-block__title-right">
            {isEditGeneral && (
              <div className="action-wrapper">
                <Button
                  type="submit"
                  label="Enregistrer"
                  variant="primary"
                  sizeType="m"
                  fontWeight="BOLD"
                  letterSpacing="S"
                  isFluid={false}
                />
                <ButtonIcon
                  onClick={closeEditGeneral}
                  icon={IconNames.Close}
                  widthIcon={50}
                  className="close-edit"
                  variant="square"
                  type="button"
                />
              </div>
            )}
          </div>
        </div>
        <FormDeliverySettings>
          <div className="conditions">
            <DisplayText
              className="form-block__title"
              label="Conditions"
              fontSize="M"
              fontWeight="BOLD"
            />
            {!isEditGeneral && (
              <DisplayText
                fontSize="S"
                fontWeight="BOLD"
                label="Définissez un montant minimum de commande pour accéder au service de livraison (factultatif)">
                {MinimumAmountRes()}
              </DisplayText>
            )}
            <BlockWrapper>
              {isEditGeneral && (
                <>
                  <Checkbox
                    className="isMinimum-wrapper"
                    name="isMinimum"
                    fontSize="S"
                    fontWeight="BOLD"
                    register={register}>
                    Définissez un montant minimum de commande pour accéder au
                    service de livraison (factultatif)
                  </Checkbox>
                  {isMinimum && (
                    <NumberInput
                      name="minimumShippingAmount"
                      className="custom"
                      register={register}
                      variant="unit"
                      setValue={setValue}
                      getValues={getValues}
                      type="text"
                    />
                  )}
                </>
              )}
            </BlockWrapper>
          </div>
          <div className="delivery_schedule">
            <DisplayText
              className="form-block__title"
              label="Disponibilité du service de livraison"
              fontSize="M"
              fontWeight="BOLD"
            />
            <Radio
              className="radio-field"
              register={register}
              name="deliveryTimeMode"
              id="catalogType"
              disabled={disabledSchedule('opening_hour')}
              value="opening_hour">
              <span>Utiliser la disponibilité du ou des catalogues</span>
            </Radio>
            <CustomizeZoneWrapper>
              <Radio
                className="radio-field"
                register={register}
                name="deliveryTimeMode"
                id="zoneType"
                disabled={disabledSchedule('delivery_time')}
                value="delivery_time">
                <span>Définir la disponibilité du service de livraison</span>
              </Radio>
              {!isEditGeneral && !disabledSchedule('delivery_time') && (
                <DataTimeline>
                  {deliveryTime && deliveryTime.length > 0 && (
                    <>
                      <TimeStyle>
                        <DisplayText label={deliveryTime[0]?.started_at} />
                        <span>-</span>
                        <DisplayText label={deliveryTime[0]?.closed_at} />
                      </TimeStyle>
                      {renderWeekday(deliveryTime[0].weekday)}
                    </>
                  )}
                </DataTimeline>
              )}
            </CustomizeZoneWrapper>
            {isEditGeneral && (
              <>
                {deliveryTimeMode === 'delivery_time' && (
                  <MenuHourWrapper>
                    <MenuHour
                      register={register}
                      getValues={getValues}
                      setValue={setValue}
                      control={control}
                    />
                  </MenuHourWrapper>
                )}
              </>
            )}
          </div>
        </FormDeliverySettings>
      </form>
    </>
  );
}
