import React from 'react';

function StarIcon({ fill, ...rest }: any) {
  return (
    <svg height="20" width="20" {...rest}>
      <polygon
        fill={fill ? fill : '#E9EAEC'}
        points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78"></polygon>
    </svg>
  );
}

export default StarIcon;
