import styled, { css } from 'styled-components';
import { COLORS, MEDIA_BREAKPOINT } from 'styles/variables';

export const BlockWrapper = styled.div`
  height: calc(100vh - 5.4rem);
  overflow-y: auto;
  padding: 4rem 0;
  position: fixed;
  z-index: 15;
  .action-wrapper {
    display: flex;
    align-items: center;
  }
  .btn-submit {
    margin-right: 1.5rem;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 2.4rem;

  svg {
    fill: ${COLORS.BLACK_3};
  }
`;

export const Tabs = styled.div`
  margin: 1.4rem 0 2.8rem 0;
`;

export const TabsHead = styled.ol`
  display: flex;
  border-bottom: 1px solid ${COLORS.GREY_1};
  list-style: none;
  padding: 0 2.4rem;

  li {
    position: relative;
    font-size: 1.6rem;
    letter-spacing: 0.32px;
    color: ${COLORS.BLACK_2};
    text-transform: capitalize;
    margin-right: 2rem;
    padding: 0 2rem 0.8rem 2rem;
    cursor: pointer;
    &.active-tabs:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0.3rem;
      width: 100%;
      background-color: ${COLORS.RED};
      border-radius: 0.6rem 0.6rem 0 0;
    }
  }
`;

export const TabsContent = styled.div`
  display: none;
  margin: 2.8rem 3.5rem 0 2.4rem;
  &.active-content {
    display: block;
  }
`;

export const TextInputWrapper = styled.div`
  width: 100%;
  &.border-error input {
    border-bottom: 2px solid ${COLORS.RED};
  }

  label {
    display: block;
    margin-bottom: 0.9rem;
  }

  input::placeholder {
    color: ${COLORS.GREY_1};
  }
`;

export const SellAsOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 3.5rem 0;
  gap: 1.6rem;

  label {
    font-size: 1.6rem;
    font-weight: 700;
  }
`;

export const DescriptionWrapper = styled.div`
  ${TitleWrapper} {
    justify-content: space-between;
    margin-bottom: 0;
  }
  textarea {
    width: 100%;
    height: 12.5rem;
    color: ${COLORS.BLACK_2};
    background-color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.GREY_4};
    border-radius: 0.7rem;
    resize: none;
    padding: 2rem 7.8rem 2rem 2rem;
    opacity: 1;
  }
  label {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: ${COLORS.BLACK_2};
    letter-spacing: 0.36px;
    text-transform: capitalize;
  }
  p {
    font-size: 1.4rem;
    font-weight: 300;
  }
`;

export const FieldWrapper = styled.div<{ padding?: 'top' | 'bottom' }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4rem 0;
  ${({ padding }) =>
    padding === 'top' &&
    css`
      padding-top: 3.5rem;
      border-top: 1px solid #c8cbd0;
    `}
  ${({ padding }) =>
    padding === 'bottom' &&
    css`
      padding-bottom: 3.5rem;
      border-bottom: 1px solid #c8cbd0;
    `}
    .unit-type {
    .selectSm {
      min-width: 17rem;
      max-width: 17rem;
    }
  }
`;

export const Price = styled.h2`
  span {
    font-size: 1.6rem;
    letter-spacing: 0.32px;
    font-weight: 300;
    margin-left: 0.5rem;
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  label:last-child {
    margin: 0 2rem 0 5rem;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.6rem;
  width: 100%;

  .dietary-item {
    width: calc(100% / 3);
    margin-bottom: 1.8rem;
    align-items: center;

    @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
      width: auto;
      margin-right: 2rem;
    }
  }
`;

export const ImageWrapper = styled.div`
  margin-bottom: 3.5rem;
  label {
    display: inline-block;
    cursor: pointer;
  }
  input[type='file'] {
    display: none;
  }
  .center {
    display: block;
    width: 19.6rem;
    margin: 0 auto;
  }
`;

export const DietaryWrapper = styled.div`
  margin: 3.5rem 0;
  min-height: 3.5rem;

  label {
    display: flex;
  }
`;

export const CategoriesWrapper = styled.div`
  margin: 3.5rem 0;
  padding-bottom: 3.5rem;
  border-bottom: 1px solid #c8cbd0;
`;

export const SearchWrapper = styled.div`
  margin-top: 3.4rem;
  .tag {
    font-size: 1.4rem;
    margin: 0 1.2rem 1.4rem 0;

    svg {
      margin-left: 0.8rem;
      width: 1.4rem;
    }
  }
`;

export const SearchWidth = styled.div`
  width: 100%;
`;

export const SearchResult = styled.div`
  width: 100%;
`;

export const BlockRelative = styled.div`
  position: relative;
`;

export const Regulation = styled.div`
  margin: 2rem 0;
  p {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: ${COLORS.BLACK_2};
  }
`;

export const MessageErrorPhoto = styled.div`
  text-align: center;
`;

export const AllergenWrapper = styled.div`
  margin: 3.5rem 0;

  .allergen-item {
    width: calc(100% / 2);
    margin: 1rem 0;
    min-height: 3.5rem;

    @media ${MEDIA_BREAKPOINT.DESKTOP_L} {
      width: calc(100% / 3);
    }

    label {
      display: flex;
    }
  }
`;

export const ReferenceWrapper = styled.div`
  margin: 2rem 0;
  display: flex;
  align-items: center;

  label {
    margin-right: 2rem;
  }

  input {
    padding: 0 1rem;
    height: 4rem;
    line-height: 4rem;
  }

  input:placeholder-shown {
    text-overflow: ellipsis;
  }
`;

export const TimelineWrapper = styled.div`
  margin: 3.5rem 0;
  .dateAndTime {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      & > div {
        width: 100%;
        margin-right: 2rem;
      }
      .react-datepicker__day--selected {
        background-color: #8d9598;
      }
      .react-datepicker__day--today {
        background: transparent;
        &:hover {
          color: #676767;
          background-color: #8d9598;
          border-radius: 20px;
        }
      }
      .react-datepicker__day--in-selecting-range {
        background-color: #e9eaec;
        &:hover {
          background-color: #8d9598;
        }
      }
    }
    &__title {
      font-size: 1.4rem;
      margin: 1rem 0 0.7rem 0;
    }
  }
`;

export const MultipleOptionQuantity = styled.div`
  display: flex;
  margin: 3.5rem 0;
  & > label {
    margin-left: 2rem;
    width: 10rem;
  }
`;
