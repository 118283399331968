import styled from 'styled-components';

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const ListGroupOptionWrapper = styled.div`
  margin: 3.9rem 0;
`;

export const GroupOptionItem = styled.div`
  position: relative;
  margin-bottom: 3.9rem;
`;
