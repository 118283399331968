import styled from 'styled-components';
import { COLORS } from 'styles/variables';

export const CustomPeriodStyle = styled.div`
  margin: 3rem 0;
  .download {
    display: flex;
    margin-left: auto;
    background-color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.GREY_4};
    color: ${COLORS.BLACK_2};
    letter-spacing: 0px;
    border-radius: 0.7rem;
    box-sizing: border-box;
    height: 4rem;
  }

  .custom-datepicker {
    width: 25rem;

    .icon {
      right: -1.3rem;
    }
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;

  .react-datepicker-wrapper {
    width: 28rem;
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  & > div {
    margin-right: 2.8rem;
  }
`;
