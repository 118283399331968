import React from 'react';
import { Grid } from 'components/ui-kits/Grid';
import { GridHavePanelRight } from 'styles/common';
import { BREAKPOINT } from 'styles/variables';
import { useWindowWidth } from 'components/CustomHooks';

const FormCreateEditLayout = ({ children }: { children: React.ReactNode }) => {
  const windowWidth = useWindowWidth();
  return (
    <Grid
      col={
        windowWidth < BREAKPOINT.DESKTOP_M
          ? GridHavePanelRight.grid.numberOfColsDesktopS
          : GridHavePanelRight.grid.numberOfColsDesktopM
      }
      start="large"
      marginLeft={Boolean(windowWidth < BREAKPOINT.DESKTOP_M)}
      marginRight={true}>
      {children}
    </Grid>
  );
};

export default FormCreateEditLayout;
