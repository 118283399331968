import React from 'react';
import {
  CardItem,
  CardHeader,
  CollapseIcon,
  CardHeaderLeft,
  CardHeaderRight,
  GripLines,
  NameCategory,
  MandatoryStyle,
  CloseIconStyle
} from './CollapseDrag.style';
import { ChevronTopIcon, CloseIcon } from 'components/ui-kits/Icon';
import DisplayText from 'components/ui-kits/DisplayText';

interface ICollapse {
  children?: React.ReactNode;
  title?: string;
  isOpen?: boolean;
  toggle?: () => void;
  isMandatory?: boolean;
  onRemoveOptionItem?: (id: string) => void;
  idGO: string;
  requiredAmount: number;
  maxAmount: number;
  hideMandatory?: boolean;
  className?: string;
  isRange?: boolean;
}

const CollapseDrag = ({
  title,
  children,
  isOpen,
  toggle,
  isMandatory,
  onRemoveOptionItem,
  idGO,
  requiredAmount,
  maxAmount,
  hideMandatory,
  className = 'default',
  isRange
}: ICollapse) => {
  return (
    <CardItem className={className}>
      <CloseIconStyle
        isFluid={false}
        icon={<CloseIcon />}
        borderRadius="50%"
        onClick={() => onRemoveOptionItem?.(idGO)}
      />
      <CardHeader>
        <CardHeaderLeft>
          <GripLines></GripLines>
          <div className="infoGO">
            <NameCategory label={title} fontWeight="MEDIUM" fontSize="XL" />
            {isMandatory && !isRange && (
              <DisplayText label={`(Exactement ${requiredAmount})`} />
            )}
            {(!isMandatory || (isMandatory && isRange)) && (
              <DisplayText label={`(Jusqu’à ${maxAmount})`} />
            )}
          </div>
        </CardHeaderLeft>
        <CardHeaderRight>
          <MandatoryStyle fontSize="S" className="mandatory">
            {!hideMandatory
              ? isMandatory
                ? 'Obligatoire'
                : 'Optionnel'
              : null}
          </MandatoryStyle>
          <CollapseIcon onClick={toggle} className={!isOpen ? '' : 'active'}>
            <ChevronTopIcon />
          </CollapseIcon>
        </CardHeaderRight>
      </CardHeader>
      {!isOpen ? <div>{children}</div> : null}
    </CardItem>
  );
};

export default CollapseDrag;
