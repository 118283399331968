import React, { useEffect } from 'react';
import {
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue
} from 'react-hook-form';
import { PlusStrokeIcon, MinusStrokeIcon } from 'components/ui-kits/Icon';

interface INumberInput {
  min?: number;
  max?: number;
  step?: number;
  className?: string;
  register?: UseFormRegister<FieldValues>;
  name: any;
  id?: string;
  setValue?: UseFormSetValue<FieldValues>;
  getValues?: UseFormGetValues<FieldValues>;
  variant?: 'incremental' | 'unit' | 'default' | 'percent';
  noValidate?: boolean;
  defaultValue?: number | string;
  validate?: { [key: string]: any };
  disabled?: boolean;
  type?: 'number' | 'text' | 'submit' | 'hide';
  minOfTypeText?: number;
  isRange?: boolean;
  range?: any;
  typeRange?: 'min' | 'max';
  value?: string | number;
  noIcon?: boolean;
  numberToFixed?: number;
  onBlur?: () => void;
  unitType?: 'kilogram' | 'lit';
}

import styled, { css } from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';
import { replaceCharacter } from 'utils';
import { KeyBoard } from 'const/Enum';
import classNames from 'classnames';

const Wrapper = styled.div`
  min-width: 13.5rem;
  width: 13.5rem;
  height: 4rem;
  position: relative;

  svg path {
    fill: ${COLORS.WHITE};
  }
`;

const RoundedWrapperCss = css`
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  background-color: ${COLORS.BLACK_2};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const NumberInputStyle = styled.input<{
  variant: 'incremental' | 'unit' | 'default' | 'percent';
  disabled?: boolean;
  noIcon?: boolean;
}>`
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  color: ${COLORS.BLACK_2};
  background-color: ${(props) =>
    props.disabled ? COLORS.GREY_4 : COLORS.WHITE};
  border: 1px solid ${COLORS.GREY_4};
  text-align: center;
  font-size: ${FONT_SIZE.L};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  padding-right: ${(props) =>
    props.variant === 'unit' && !props.noIcon ? '3rem' : ''};
`;

const RoundIcon = styled.div`
  ${RoundedWrapperCss}
  right: 0.8rem;
  font-size: ${FONT_SIZE.L};
`;

const DecrementBtn = styled.button`
  ${RoundedWrapperCss}
  left: 0.8rem;
  font-size: ${FONT_SIZE.L};
`;

const IncrememtBtn = styled.button`
  ${RoundedWrapperCss}
  right: 0.8rem;
  font-size: ${FONT_SIZE.L};
`;

const NumberInput = ({
  min,
  max,
  step = 1, // if Step < 1 --> be careful with float calculation precision
  className,
  register,
  name,
  id,
  setValue,
  getValues,
  variant = 'incremental',
  noValidate = true,
  defaultValue,
  validate,
  disabled = false,
  type = 'number',
  minOfTypeText,
  isRange,
  range,
  typeRange,
  value,
  noIcon,
  onBlur,
  numberToFixed = 2,
  unitType
}: INumberInput) => {
  useEffect(() => {
    if (type === 'text' && (variant === 'unit' || variant === 'percent')) {
      if (String(getValues?.(name))?.indexOf(',') < 0) {
        setValue?.(
          name,
          Number(getValues?.(name)).toFixed(numberToFixed).replace(/[.]/g, ',')
        );
      }
    }
  });
  const handleDecrease = () => {
    if (!getValues || !setValue || disabled) {
      return;
    }
    const val = getValues(name);
    if (typeof min === 'number' && val - step < min) {
      return;
    }
    if (minOfTypeText && val <= minOfTypeText) {
      setValue(name, minOfTypeText);
      return;
    }
    if (isRange) {
      handleDecreaseRange();
      return;
    }
    setValue(name, val - step);
  };

  const handleIncrease = () => {
    if (!getValues || !setValue || disabled) {
      return;
    }
    const val = Number(getValues(name));
    if (typeof max === 'number' && val + step > max) {
      return;
    }
    if (isRange) {
      handleIncreaseRange();
      return;
    }
    setValue(name, val + step);
  };

  function handleIncreaseRange() {
    if (!setValue || !getValues) {
      return;
    }
    if (typeRange === 'min' && isCheckMinMaxClosest(range)) {
      setValue(range[0], Number(getValues(range[0])) + 1);
      setValue(range[1], Number(getValues(range[1])) + 1);
    }
    if (typeRange === 'min' && !isCheckMinMaxClosest(range)) {
      setValue(name, Number(getValues(name)) + 1);
    }
    if (typeRange === 'max') {
      setValue(name, Number(getValues(name)) + 1);
    }
  }

  function handleDecreaseRange() {
    if (!setValue || !getValues) {
      return;
    }
    if (typeRange === 'max' && isCheckMinMaxClosest(range)) {
      setValue(range[0], Number(getValues(range[0])) - 1);
      setValue(range[1], Number(getValues(range[1])) - 1);
    }
    if (typeRange === 'max' && !isCheckMinMaxClosest(range)) {
      setValue(name, Number(getValues(name)) - 1);
    }
    if (typeRange === 'min' && Number(getValues(range[0])) > 0) {
      setValue(name, Number(getValues(name)) - 1);
    }
  }

  function isCheckMinMaxClosest(range: any) {
    if (getValues?.(range[1]) - getValues?.(range[0]) === 1) {
      return true;
    }
    return false;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((variant === 'unit' || variant === 'percent') && setValue) {
      const newPrice = e.target.value.replace(
        /[`~!@#$%^&*()_|+\-=?;:'".<>\{\}\[\]\\\/a-zA-Z\s]/,
        ''
      );
      const lastIndex = newPrice.lastIndexOf(',');
      if (lastIndex > -1) {
        setValue(name, newPrice.slice(0, lastIndex + numberToFixed + 1));
      }
    } else {
      if (typeof min !== 'number' || typeof max !== 'number' || !setValue) {
        return;
      }
      const val = Number(e.target.value);
      if (val > max) {
        setValue(name, max);
      } else if (val < min) {
        setValue(name, min);
      }
    }
  };

  const formatPrice = () => {
    if (
      type === 'text' &&
      setValue &&
      getValues &&
      (variant === 'unit' || variant === 'percent')
    ) {
      const numPrice = Number(replaceCharacter(getValues(name), ',', '.'));
      if (!getValues(name) || isNaN(numPrice) || numPrice < 0) {
        setValue(name, '0,00');
      } else {
        const transformPrice = formatDecimal(numPrice);
        setValue(name, replaceCharacter(transformPrice, '[.]', ','));
      }
    }

    if (
      type === 'text' &&
      variant === 'incremental' &&
      setValue &&
      getValues &&
      !getValues(name)
    ) {
      setValue(name, minOfTypeText);
    }
  };

  const formatDecimal = (num: number) => {
    return num.toFixed(numberToFixed);
  };

  function keyUp(e: any) {
    if (type === 'text' && variant === 'incremental') {
      if (!setValue || !getValues || typeof minOfTypeText !== 'number') {
        return;
      }
      if (e.which === KeyBoard.Backspace) {
        setValue(name, '');
        return;
      }
      if (e.which >= KeyBoard.Zero && e.which <= KeyBoard.Nine) {
        setValue(name, parseInt(getValues(name), 10));
        if (getValues(name) < minOfTypeText) {
          setValue(name, minOfTypeText);
        }
        if (typeRange === 'min' && getValues(range[0]) >= getValues(range[1])) {
          setValue(range[1], getValues(range[0]) + 1);
        }
        if (typeRange === 'max' && getValues(range[0]) >= getValues(range[1])) {
          setValue(range[0], getValues(range[1]) - 1);
        }
      } else {
        setValue(name, minOfTypeText);
      }
    }
  }

  //{ valueAsNumber: true }
  const registerField =
    register &&
    register(
      name,
      type === 'text' ? { ...validate } : { ...validate, valueAsNumber: true }
    );
  return (
    <Wrapper className={classNames(className, 'number-input__wrapper')}>
      {variant === 'incremental' && (
        <DecrementBtn type="button" onClick={handleDecrease}>
          <MinusStrokeIcon />
        </DecrementBtn>
      )}
      <NumberInputStyle
        type={type}
        {...registerField}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        defaultValue={defaultValue || min}
        value={value}
        variant={variant}
        id={id}
        autoComplete="off"
        {...(noValidate && {
          onChange: (e) => {
            registerField?.onChange(e);
            handleChange(e);
          }
        })}
        onKeyPress={(e) => {
          if (type === 'number' && (e.key === 'e' || e.key === '-')) {
            e.preventDefault();
          }
        }}
        onKeyUp={(e) => keyUp(e)}
        onBlur={() => {
          formatPrice();
          onBlur?.();
        }}
        noIcon={noIcon}
      />
      {variant === 'incremental' && (
        <IncrememtBtn type="button" onClick={handleIncrease}>
          <PlusStrokeIcon />
        </IncrememtBtn>
      )}
      {variant === 'unit' && !noIcon && <RoundIcon>€</RoundIcon>}
      {variant === 'percent' && <RoundIcon>%</RoundIcon>}
      {unitType === 'kilogram' && <RoundIcon>kg</RoundIcon>}
      {unitType === 'lit' && <RoundIcon>L</RoundIcon>}
    </Wrapper>
  );
};

export default NumberInput;
