import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FILTER_MERCHANT_OPTIONS } from 'const/Const';
import { periodWeekly } from 'utils';

interface FilterInvoice {
  filter: { label: string; value: string };
  startDate: string;
  endDate: string;
}

const dateInWeek = periodWeekly();

const initialState: FilterInvoice = {
  filter: FILTER_MERCHANT_OPTIONS[0],
  startDate: new Date(dateInWeek[0]).toISOString(),
  endDate: new Date(dateInWeek[1]).toISOString()
};

const filterInvoiceSlice = createSlice({
  name: 'filterInvoice',
  initialState,
  reducers: {
    queryFilter(state, action: PayloadAction<FilterInvoice>) {
      return (state = action.payload);
    }
  }
});

const { actions, reducer } = filterInvoiceSlice;
export const { queryFilter } = actions;
export default reducer;
