import React, { useEffect, useRef, useState } from 'react';
import { ZoneInfo, ZoneWithPolygon } from 'types/Delivery';
import rfdc from 'rfdc';

function useDrawingZone(
  initZone: Array<ZoneInfo>,
  editingZoneIndex: number,
  setPolluted: React.Dispatch<React.SetStateAction<boolean>>
) {
  const drawingService = useRef<any>(null);
  const polygons = useRef<Array<ZoneWithPolygon>>(
    rfdc({ proto: false })(initZone)
  );
  const [map, setMap] = useState<any>(null);
  const [isInitPolygon, setIsInitPolygon] = useState(false);
  const [isActiveDraw, setActiveDraw] = useState(false);
  const [isPolygon, setIsPolygon] = useState(false);
  const [editedZone, setEditedZone] = useState(initZone);

  useEffect(() => {
    // Initialize the drawing service
    if (!map) {
      return;
    }
    if (!(window as any).google)
      return console.error(
        'Google has not been found. Make sure your provide apiKey prop.'
      );
    if (!drawingService.current) {
      drawingService.current = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        markerOptions: {
          icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'
        }
      });
      drawingService.current.setMap(map);
    }
  }, [map]);

  useEffect(() => {
    setEditedZone(initZone);
  }, [initZone]);

  useEffect(() => {
    if (!map) {
      return;
    }
    if (!drawingService.current) {
      return;
    }
    if (isInitPolygon) {
      return;
    }
    let initPoly = false;

    initZone.forEach((item, index) => {
      if (!item.coords.length) {
        return;
      }
      const polygon = new google.maps.Polygon({
        paths: item.coords,
        editable: true,
        strokeColor: item.color,
        fillColor: item.color,
        fillOpacity: 0.3
      });
      if (item.enabled) {
        polygon.setMap(map);
      }
      polygon.setEditable(false);
      polygon.setDraggable(false);

      polygons.current[index].polygon = polygon;
      if (!initPoly) {
        initPoly = true;
      }
    });
    setIsInitPolygon(initPoly);
  }, [initZone, map, isInitPolygon]);

  useEffect(() => {
    if (!drawingService.current) {
      return;
    }
    if (isActiveDraw && editingZoneIndex > -1) {
      drawingService.current.setOptions({
        polygonOptions: {
          editable: true,
          strokeColor: initZone[editingZoneIndex].color,
          fillColor: initZone[editingZoneIndex].color,
          fillOpacity: 0.3,
          draggable: true
        }
      });
      drawingService.current.setDrawingMode(
        google.maps.drawing.OverlayType.POLYGON
      );
      const listener = google.maps.event.addListener(
        drawingService.current,
        'polygoncomplete',
        function (polygon: google.maps.Polygon) {
          const pathArr = polygon
            .getPath()
            .getArray()
            .map((item) => item.toJSON());
          const newEditedZone = rfdc({ proto: false })(editedZone);
          const newEditZoneItem = newEditedZone[editingZoneIndex];
          newEditZoneItem.coords = pathArr;
          newEditZoneItem.isPolygon = true;
          polygons.current[editingZoneIndex].polygon = polygon; // add Polygon instance
          drawingService.current.setDrawingMode(null);
          setEditedZone(newEditedZone);
          setActiveDraw(false);
          setIsPolygon(true);
          setPolluted(true);
          setIsInitPolygon(true); // this trick to not allow init polygon run again when delete first ever polygon in the map
        }
      );
      return () => listener.remove();
    } else {
      drawingService.current.setDrawingMode(null);
    }
  }, [isActiveDraw, map, editingZoneIndex, initZone, editedZone, setPolluted]);

  const deleteZone = (index: number) => {
    const newEditedZone = rfdc({ proto: false })(editedZone);
    const newEditZoneItem = newEditedZone[index];
    newEditZoneItem.coords = [];
    newEditZoneItem.isPolygon = false;
    setActiveDraw(false);
    setEditedZone(newEditedZone);
    if (typeof polygons.current[index].polygon !== 'undefined') {
      polygons.current[index].polygon?.setMap(null);
      polygons.current[index].polygon = undefined;
    }
    drawingService.current.setDrawingMode(null);
    setIsPolygon(false);
    setPolluted(false);
  };

  return {
    zone: editedZone,
    drawingService: drawingService,
    zoneWithPolygon: polygons.current,
    isPolygon: isPolygon,
    isActiveDraw: isActiveDraw,
    deleteZone: deleteZone,
    setZone: setEditedZone,
    setMapInstance: setMap,
    setActiveDraw: setActiveDraw
  };
}

export default useDrawingZone;
