import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';

export const ListOrderWrapper = styled.div`
  margin: 5.6rem 0;

  table {
    th {
      text-transform: none;
      &:nth-child(3) {
        text-align: center;
      }
    }
  }
`;
export const NotDataYet = styled.td`
  text-align: center !important;
  color: ${COLORS.GREY_1} !important;
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  padding: 4rem 0;
`;
export const OrderCancelStyle = styled.div`
  &:before {
    content: 'COMMANDE ANNULÉE';
    text-transform: uppercase;
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.BOLD};
    color: ${COLORS.WHITE};
    background: ${COLORS.ORANGE};
    padding: 0.6rem 1.2rem;
  }
`;
