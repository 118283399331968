import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { CheckboxText } from 'components/ui-kits/Form/Checkbox';
import {
  Card,
  DayWrapper,
  DayItem,
  TimeWrapper,
  TimeItem
} from './MenuHour.style';
import { Day } from 'const/Const';

interface IDay {
  label: string;
  value: number;
}

interface IProps {
  register: any;
  control: any;
  setValue: any;
  getValues?: any;
}

const MenuHour = ({ register, setValue, getValues }: IProps) => {
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());

  useEffect(() => {
    if (getValues('timeline')) {
      const getValueStarted = getValues('timeline')?.[0]?.started_at;
      const getValueClosed = getValues('timeline')?.[0]?.closed_at;
      if (getValueStarted?.length === 5 && getValueClosed?.length === 5) {
        const dStartedAt = new Date();
        const dClosedAt = new Date();
        const hoursStarted = getValueStarted.slice(0, 2);
        const minuteStarted = getValueStarted.slice(3, 5);
        const hoursClosed = getValueClosed.slice(0, 2);
        const minuteClosed = getValueClosed.slice(3, 5);
        dStartedAt.setHours(hoursStarted, minuteStarted);
        dClosedAt.setHours(hoursClosed, minuteClosed);
        setStartDate(new Date(dStartedAt));
        setEndDate(new Date(dClosedAt));
      }
    }
  }, [getValues]);

  useEffect(() => {
    setValue('timeline.started_at', startDate);
    setValue('timeline.closed_at', endDate);
  }, [startDate, endDate, setValue]);

  const setValueStartDate = (date: any) => {
    setStartDate(date);
    setValue('timeline.started_at', date);
  };

  const setValueEndDate = (date: any) => {
    setEndDate(date);
    setValue('timeline.closed_at', date);
  };

  const checkedWeekdaySelected = (value: number) => {
    if (getValues('timeline')?.[0]?.weekday) {
      return getValues('timeline')?.[0]?.weekday?.includes(value);
    }
  };

  return (
    <Card>
      <DayWrapper>
        {Day.map((item: IDay) => {
          return (
            <DayItem key={item.value}>
              <CheckboxText
                name={`timeline.weekday`}
                id={item.label}
                register={register}
                value={item.value}
                label={item.label}
                checked={checkedWeekdaySelected(item.value)}
              />
            </DayItem>
          );
        })}
      </DayWrapper>

      <TimeWrapper>
        <TimeItem>
          <label>Heure de début</label>
          <input
            name="timeline.started_at"
            type="hidden"
            {...register('timeline.started_at')}
          />
          <div>
            <DatePicker
              selected={startDate}
              onChange={(date) => setValueStartDate(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              timeFormat="HH:mm"
              dateFormat="HH:mm"
            />
          </div>
        </TimeItem>
        <TimeItem>
          <label>Heure de fin</label>
          <input
            name="timeline.closed_at"
            type="hidden"
            {...register('timeline.closed_at')}
          />
          <div>
            <DatePicker
              selected={endDate}
              onChange={(date) => setValueEndDate(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              timeFormat="HH:mm"
              dateFormat="HH:mm"
            />
          </div>
        </TimeItem>
      </TimeWrapper>
    </Card>
  );
};

export default MenuHour;
