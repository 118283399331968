import React from 'react';
import { format } from 'date-fns';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { convertTZ, formatDateMonthYear, getTimeZoneRegion } from 'utils';
import { ListInvoicesRender } from 'types/InvoicesList';
import {
  CardInvoicesListWrapper,
  DateTimeWrapper,
  DayStyle,
  DownloadPDFStyle,
  InvoiceNumberStyle,
  TimeHourStyle,
  TypeStyle
} from './CardInvoicesList.style';

const CardInvoicesList = ({
  id,
  invoicesNumber,
  type,
  createdAt,
  downloadPdf
}: ListInvoicesRender) => {
  const history = useHistory();
  const path = useRouteMatch().path;

  const onDetail = (id: number) => {
    const data = {
      id: id,
      invoicesNumber: invoicesNumber,
      type: type
    };

    history.push({
      pathname: `${path}/detail/${id}`,
      state: data /* pass data to invoices detail */
    });
  };

  const region = getTimeZoneRegion();
  const newDateTimeZone = createdAt ? convertTZ(createdAt, region) : null;
  const date = newDateTimeZone
    ? format(newDateTimeZone, formatDateMonthYear)
    : '-';
  const time = newDateTimeZone ? format(newDateTimeZone, 'HH:mm') : '-';

  return (
    <>
      <CardInvoicesListWrapper onClick={() => onDetail(id)}>
        <InvoiceNumberStyle>{invoicesNumber}</InvoiceNumberStyle>
        <TypeStyle>{type}</TypeStyle>
        <DateTimeWrapper>
          <TimeHourStyle>{time}</TimeHourStyle>
          <DayStyle>{date}</DayStyle>
        </DateTimeWrapper>
        <td></td>
      </CardInvoicesListWrapper>
      <DownloadPDFStyle>
        <td colSpan={4}>{downloadPdf}</td>
      </DownloadPDFStyle>
    </>
  );
};

export default CardInvoicesList;
