import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';

export const CardInformationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4.4rem 4.6rem;
`;
export const BlockLeftLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .labelTopLeft {
    padding-bottom: 1rem;
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.MEDIUM};
    color: ${COLORS.BLACK_2};
  }

  .leftBottomWapper {
    display: flex;

    .leftBottom {
      &:first-of-type {
        margin-left: 0;
      }
      margin-left: 2.8rem;
    }

    .labelBottom {
      padding: 0.7rem 0;
      font-size: ${FONT_SIZE.S};
      font-weight: ${FONT_WEIGHT.LIGHT};
      color: ${COLORS.BLACK_2};

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }
`;
export const BlockRightLayout = styled.div`
  .title {
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.LIGHT};
    color: ${COLORS.BLACK_2};
    margin-bottom: 2.5rem;
  }
  .subAddress {
    font-size: ${FONT_SIZE.M};
    text-transform: upper-case;
    font-weight: ${FONT_WEIGHT.LIGHT};
    margin-bottom: 1rem;
  }
  .mainName {
    font-size: ${FONT_SIZE.XL};
    font-weight: ${FONT_WEIGHT.BOLD};
    margin-bottom: 1.6rem;
  }
  .inforWrapper {
    margin-bottom: 2.8rem;
    max-width: 60%;
  }
  .address {
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.LIGHT};
    text-transform: uppercase;
  }
  .vatWrapper {
    display: flex;

    .vatLabel {
      &:first-of-type {
        margin-left: 0;
      }
      margin-left: 6rem;
    }
    .vatNumber {
      font-size: ${FONT_SIZE.M};
      font-weight: ${FONT_WEIGHT.LIGHT};
      padding-bottom: 1rem;

      &:last-of-type {
        padding-bottom: 0;
      }
    }
    .vatValue {
      .vatNumber {
        font-weight: 500;
        color: #464a51;
      }
    }
  }
`;
