import React from 'react';
import styled, { css } from 'styled-components';
import { IGridItem } from 'types/Grid';

const GridItemWrapper = styled.div<Exclude<IGridItem, 'children'>>`
  ${({ colStart, colEnd, nameStart, nameEnd }) => css`
    grid-column-start: ${nameStart || ''} ${colStart};
    grid-column-end: ${nameEnd || ''} ${colEnd};
  `}
`;

function GridItem({
  children,
  colStart = 1,
  colEnd,
  className,
  ...props
}: IGridItem) {
  return (
    <GridItemWrapper
      colStart={colStart}
      colEnd={colEnd}
      className={className}
      {...props}>
      {children}
    </GridItemWrapper>
  );
}

export default GridItem;
