import React, { useState } from 'react';
import Table from 'components/ui-kits/Table';
import {
  StyleTable,
  TableCell,
  TableRow
} from 'components/ui-kits/Table/Table.style';
import {
  CONFIRM_MESSAGE,
  DELETE_MESSAGE,
  DONT_DATA_MESSAGE
} from 'const/Message';
import { MultilineTrunc, NotDataYet } from 'styles/common';
import { ProductOptionV3 } from 'types/ProductOption';
import {
  OptionTooltip,
  OptionTooltipItem
} from 'components/ui-kits/OptionTooltip';
import { formatDollar, handleError, handleSuccess } from 'utils';
import DisplayText from 'components/ui-kits/DisplayText';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ModalPrompt from 'components/ui-kits/ModalPrompt';
import { useMutation, useQueryClient } from 'react-query';
import Loader from 'components/Loader';
import { deleteProductOptionV2 } from 'services/productOptionService';

const headerTable = [
  { label: 'Nom' },
  { label: "Groupes d'options", width: '30%' },
  { label: 'Prix', width: '15%' },
  { label: 'Dernière modification', width: '15%' },
  { label: '', width: '5%' }
];

type OptionListProps = {
  data?: Array<ProductOptionV3>;
};

export default function OptionList({ data }: OptionListProps) {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [codeDelete, setCodeDelete] = useState<string>();
  const history = useHistory();
  const { path } = useRouteMatch();
  const queryClient = useQueryClient();

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    deleteProductOptionV2
  );

  const handleDelete = (res: boolean) => {
    if (res && codeDelete) {
      mutateDelete(
        { code: codeDelete, data: { enabled: false } },
        {
          onSuccess: () => {
            handleSuccess(DELETE_MESSAGE.OPTION_SUCCESS);
            queryClient.invalidateQueries('options');
            setConfirmDelete(false);
          },
          onError: (error: any) => {
            handleError(error.response.status, DELETE_MESSAGE.OPTION_ERROR);
            setConfirmDelete(false);
          }
        }
      );
    }
  };
  const openModalDelete = (code: string) => {
    setCodeDelete(code);
    setConfirmDelete(true);
  };
  const openEditPage = (code: string) => {
    history.push(`${path}/edit/${code}`);
  };
  return (
    <StyleTable>
      <Table header={headerTable}>
        {data?.length ? (
          <React.Fragment>
            {data.map((option) => {
              return (
                <TableRow key={option.id}>
                  <TableCell onClick={() => openEditPage(option.code)}>
                    <MultilineTrunc line="2">{option.name}</MultilineTrunc>
                  </TableCell>
                  <TableCell onClick={() => openEditPage(option.code)}>
                    {option.groupOptions.map((item) => item.name).join(', ')}
                  </TableCell>
                  <TableCell onClick={() => openEditPage(option.code)}>
                    {formatDollar(option.price)}€
                  </TableCell>
                  <TableCell onClick={() => openEditPage(option.code)}>
                    <DisplayText
                      fontSize="S"
                      fontWeight="LIGHT"
                      className="time"
                      label={format(new Date(option.updatedAt), 'HH : mm')}
                    />
                    <div className="date">
                      {format(new Date(option.updatedAt), 'cccc d MMM yyyy', {
                        locale: fr
                      })}
                    </div>
                  </TableCell>
                  <TableCell>
                    <OptionTooltip
                      component={
                        <>
                          <OptionTooltipItem
                            label="Modifier"
                            onClick={() => openEditPage(option.code)}
                          />
                          <OptionTooltipItem
                            label="Supprimer"
                            className="list-option-item del"
                            onClick={() => openModalDelete(option.code)}
                          />
                        </>
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </React.Fragment>
        ) : (
          <tr>
            <NotDataYet colSpan={5}>{DONT_DATA_MESSAGE('options')}</NotDataYet>
          </tr>
        )}
      </Table>
      <ModalPrompt
        isShow={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onConfirm={() => handleDelete(true)}
        title={CONFIRM_MESSAGE.DELETE_OPTION}
        confirmType="withTrashBin"
      />
      {isLoadingDelete && <Loader />}
    </StyleTable>
  );
}
