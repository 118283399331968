import React from 'react';
import styled, { keyframes } from 'styled-components';
import { COLORS } from 'styles/variables';

const ldsRing = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderAnimation = styled.div`
  display: inline-block;
  position: relative;
  width: 1.6rem;
  height: 1.6rem;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    border: 2px solid ${COLORS.RED};
    border-radius: 50%;
    animation: ${ldsRing} 1.3s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${COLORS.RED} transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export default function Spinner() {
  return (
    <LoaderAnimation>
      <div></div> <div></div> <div></div> <div></div>
    </LoaderAnimation>
  );
}
