import React from 'react';

function LocatorIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1rem"
      height="1.4285rem"
      viewBox="0 0 10 14.285"
      {...props}>
      <path
        id="Locator"
        d="M8.333,1.333a5,5,0,0,0-5,5c0,3.75,5,9.286,5,9.286s5-5.536,5-9.286a5,5,0,0,0-5-5Zm0,6.786a1.786,1.786,0,1,1,1.786-1.786A1.786,1.786,0,0,1,8.333,8.119Z"
        transform="translate(-3.334 -1.334)"
        fill="#464a51"
      />
    </svg>
  );
}

export default LocatorIcon;
