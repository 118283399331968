import styled from 'styled-components';
import { COLORS } from 'styles/variables';

export const PaymentMethodWrapper = styled.div`
  margin: 5.6rem 0 14.3rem 0;

  .action-wrapper {
    display: flex;
    align-items: center;
    button:last-child {
      margin-left: 1rem;
    }
    svg {
      fill: ${COLORS.BLACK_3};
    }
  }
`;

export const DefaultPayment = styled.div`
  margin: 2.8rem 0;

  .title {
    margin-bottom: 1.4rem;
  }
`;
export const SelectPayment = styled.div`
  margin: 2.8rem 0;

  .title {
    margin-bottom: 2.8rem;
  }

  .checkbox-icon {
    margin-right: 3.3rem;
  }
`;

export const FieldPaymentWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 1.5rem;
  }

  svg path {
    fill: ${COLORS.GREY_1};
  }

  .text {
    color: ${COLORS.BLACK_2};
  }
`;

export const JsFieldPaymentWrapper = styled(FieldPaymentWrapper)<{
  mode?: 'list' | 'edit';
  selected?: boolean;
}>`
  opacity: ${({ selected }) => (selected ? '1' : '0.6')};
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .wrapper {
    display: flex;
  }
  .edit {
    padding: 0;
    min-width: auto;
    margin-left: 1.4rem;
    margin-bottom: -0.8rem;
  }
`;

export const BlockWrapper = styled.div`
  margin: 1.6rem 0;
`;
