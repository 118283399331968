import { Grid, GridItem } from 'components/ui-kits/Grid';
import styled from 'styled-components';
import { Margin } from 'styles/createEdit-page';
import { COLORS, HEADER_HEIGHT } from 'styles/variables';

export const SectionNameWrapper = styled(Grid)`
  position: sticky;
  top: calc(${HEADER_HEIGHT} * 2);
  left: 0;
  padding: ${Margin.sectionName};
  background-color: ${COLORS.WHITE_1};
  z-index: 9;
`;

export const TextInputGridWrapper = styled(GridItem)`
  position: relative;
  margin-right: ${Margin.containerInput};
`;

export const TextInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  input::placeholder {
    color: ${COLORS.GREY_1};
  }
`;

export const BtnWrapper = styled(GridItem)`
  display: flex;
  align-items: center;
`;

export const BtnMargin = styled.div`
  margin-left: 2.5rem;
  padding: 0;
`;

export const BackBtn = styled.button`
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 5rem;
  transform: translateY(-50%);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${COLORS.GREY_5};
  }

  .back-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${COLORS.GREY_10};
  }
`;
