import React from 'react';

function MinusStrokeIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.6rem"
      height="0.3429rem"
      viewBox="0 0 16 3.429"
      {...props}>
      <path
        id="Icon_awesome-minus"
        data-name="Icon awesome-minus"
        d="M14.857,14.625H1.143A1.143,1.143,0,0,0,0,15.768v1.143a1.143,1.143,0,0,0,1.143,1.143H14.857A1.143,1.143,0,0,0,16,16.911V15.768A1.143,1.143,0,0,0,14.857,14.625Z"
        transform="translate(0 -14.625)"
        fill="#8d9598"
      />
    </svg>
  );
}

export default MinusStrokeIcon;
