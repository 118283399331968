import styled from 'styled-components';
import { COLORS } from 'styles/variables';
import { ContentPadding } from 'styles/paymentParams-tab';

export const MerchantInfoWrapper = styled(ContentPadding)`
  width: 70%;
  border-right: 1px solid ${COLORS.GREY_4};

  .merchantInfo {
    &__header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 6rem;

      button {
        padding: 0.5rem;
        min-width: auto;
      }
    }

    &__fields {
      display: flex;
      flex-wrap: wrap;
    }

    &__item {
      margin: 0 3.5rem 2.8rem 0;
      width: 38%;

      input {
        font-size: 1.6rem;
        height: auto;
        padding-bottom: 0.3rem;

        &.status-save {
          border-bottom: 1px solid ${COLORS.GREY_4};
        }
      }
    }
  }

  .fields__checkbox-wrapper {
    margin-top: 2rem;

    .description {
      max-width: 80%;
      width: 100%;
      line-height: 20px;
    }

    ol {
      margin-left: 1.5rem;
    }
  }
`;
