import React from 'react';

function MenuBarsIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 30 25"
      width="3rem"
      height="2.5rem"
      {...props}>
      <g>
        <rect
          x="0"
          y="0"
          style={{ fill: '#8D9598' }}
          width="3rem"
          height="0.5rem"
        />
        <rect
          x="0"
          y="1.2rem"
          style={{ fill: '#8D9598' }}
          width="3rem"
          height="0.5rem"
        />
        <rect
          x="0"
          y="2.4rem"
          style={{ fill: '#8D9598' }}
          width="3rem"
          height="0.5rem"
        />
      </g>
    </svg>
  );
}

export default MenuBarsIcon;
