import styled from 'styled-components';
import { COLORS, HEADER_HEIGHT, MEDIA_BREAKPOINT } from 'styles/variables';

export const HeaderStyle = styled.header`
  height: ${HEADER_HEIGHT};
  background-color: white;
  box-shadow: 0 0.2rem 0.3rem #74424233;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 12;

  .logo {
    cursor: pointer;
  }

  .bars-mobile {
    display: none;
  }

  @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
    .bars-mobile {
      display: block;
      margin: 0 auto;
    }
  }

  .container {
    align-items: center;
    height: 100%;
  }

  .item-inline button {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 500;
    color: ${COLORS.BLACK_2};
    svg {
      margin-right: 1.1rem;
    }
  }

  @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
    .brand {
      grid-column-start: 4;
      grid-column-end: 5;
    }

    .help {
      grid-column-start: 20;
      grid-column-end: 21;
    }

    .logout {
      grid-column-start: 22;
      grid-column-end: 23;
    }
  }
`;

export const ImageStyle = styled.div`
  // width: 8rem;
  // height: 2.5rem;
  // overflow: hidden;
`;

export const MenuBars = styled.div`
  display: inline-block;
  cursor: pointer;

  div {
    width: 2.5rem;
    height: 3px;
    background-color: #8d9598;
    &:not(:last-child) {
      margin-bottom: 3px;
    }
    transition: all 0.3s;
  }

  &.active {
    div:first-child {
      transform: translateY(6px) rotate(45deg);
      transition: all 0.3s;
    }
    div:last-child {
      transform: translateY(-6px) rotate(-45deg);
      transition: all 0.3s;
    }
    div:nth-child(2) {
      opacity: 0;
      overflow: hidden;
      transition: all 0.3s;
    }
  }
`;
