import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Button from 'components/ui-kits/Button';
import ButtonIcon from 'components/ui-kits/ButtonIcon';
import DisplayText from 'components/ui-kits/DisplayText';
import NumberInput from 'components/ui-kits/Form/NumberInput';
import { IconNames } from 'components/ui-kits/Icon/Icon';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';
import { PrepTime } from 'types/PrepTime';
import Loader from 'components/Loader';
import { handleSuccess } from 'utils';
import { UPDATE_MESSAGE } from 'const/Message';
import { updatePrepTimeV2 } from 'services/prepTimeService';

const PrepTimeEditWrapper = styled.div`
  max-width: 63.8rem;
  width: 100%;
  padding: 2.8rem;
  border: 1px solid ${COLORS.GREY_4};
  border-radius: 0.7rem;
  background-color: ${COLORS.WHITE};

  .action-wrapper {
    display: flex;
    align-items: center;
  }
  .btn-submit {
    margin-right: 1.5rem;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    fill: ${COLORS.BLACK_3};
  }
`;

const MinuteWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 26rem;
  width: 100%;
  margin-top: 3rem;
`;

interface IProps {
  onClose: () => void;
  minute: number;
  slug: string;
}

const PrepTimeEdit = ({ onClose, minute, slug }: IProps) => {
  const { register, handleSubmit, setValue, getValues } = useForm();
  const queryClient = useQueryClient();

  const onSave = (data: { minute: number }) => {
    onUpdatePrepTime(
      {
        slug: slug,
        prepTime: { preparationTime: data.minute }
      },
      {
        onSuccess: () => {
          handleSuccess(UPDATE_MESSAGE.PREP_TIME_SUCCESS);
          queryClient.invalidateQueries('getPrepTime');
          onClose();
        },
        onError: () => {
          handleSuccess(UPDATE_MESSAGE.PREP_TIME_ERROR);
        }
      }
    );
  };

  const { isLoading, mutate: onUpdatePrepTime } = useMutation(
    ({ slug, prepTime }: Required<{ slug: string; prepTime: PrepTime }>) =>
      updatePrepTimeV2(slug, prepTime)
  );

  return (
    <PrepTimeEditWrapper>
      {isLoading && <Loader />}
      <form onSubmit={handleSubmit(onSave)}>
        <TitleWrapper>
          <DisplayText label="Modifier" fontSize="XL" fontWeight="BOLD" />
          <div className="action-wrapper">
            <Button
              variant="primary"
              label="Enregistrer"
              isFluid={false}
              type="submit"
              className="btn-submit"
              fontWeight="BOLD"
              letterSpacing="S"
            />
            <ButtonIcon
              onClick={onClose}
              icon={IconNames.Close}
              widthIcon={50}
              variant="square"
              type="button"
            />
          </div>
        </TitleWrapper>
        <MinuteWrapper>
          <DisplayText label="Minutes" fontSize="M" fontWeight="BOLD" />
          <NumberInput
            name="minute"
            min={0}
            defaultValue={minute}
            register={register}
            setValue={setValue}
            getValues={getValues}
            validate={{
              required: true
            }}
          />
        </MinuteWrapper>
      </form>
    </PrepTimeEditWrapper>
  );
};

export default PrepTimeEdit;
