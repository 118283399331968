import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { CheckboxText } from 'components/ui-kits/Form/Checkbox';
import { CloseIcon } from 'components/ui-kits/Icon';
import {
  Card,
  ButtonStyle,
  DayWrapper,
  DayItem,
  TimeWrapper,
  TimeItem
} from './Timeline.style';
import { Day } from 'const/Const';

interface IDay {
  label: string;
  value: number;
}

interface IProps {
  index: number;
  remove: any;
  register: any;
  setValue?: any;
  getValues?: any;
  mode?: 'create' | 'edit';
}

const Timeline = ({
  index,
  remove,
  register,
  setValue,
  getValues,
  mode
}: IProps) => {
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());

  useEffect(() => {
    if (getValues('timeline')) {
      const getValueStarted = getValues('timeline')[index].started_at;
      const getValueClosed = getValues('timeline')[index].closed_at;
      if (
        getValueStarted?.length === 5 &&
        getValueClosed?.length === 5 &&
        mode === 'edit'
      ) {
        const dStartedAt = new Date();
        const dClosedAt = new Date();
        const hoursStarted = getValueStarted.slice(0, 2);
        const minuteStarted = getValueStarted.slice(3, 5);
        const hoursClosed = getValueClosed.slice(0, 2);
        const minuteClosed = getValueClosed.slice(3, 5);
        dStartedAt.setHours(hoursStarted, minuteStarted);
        dClosedAt.setHours(hoursClosed, minuteClosed);
        setStartDate(new Date(dStartedAt));
        setEndDate(new Date(dClosedAt));
      }
    }
  }, [getValues, index, mode]);

  useEffect(() => {
    setValue(`timeline[${index}].started_at`, startDate);
    setValue(`timeline[${index}].closed_at`, endDate);
  }, [startDate, endDate, index, setValue]);

  const setValueStartDate = (date: any) => {
    setStartDate(date);
    setValue(`timeline[${index}].started_at`, date);
  };

  const setValueEndDate = (date: any) => {
    setEndDate(date);
    setValue(`timeline[${index}].closed_at`, date);
  };

  const checkedWeekdaySelected = (value: number, index: number) => {
    if (getValues('timeline')[index].weekday) {
      return getValues('timeline')[index]?.weekday?.includes(value);
    }
  };

  return (
    <Card>
      <ButtonStyle
        onClick={() => remove(index)}
        isFluid={false}
        icon={<CloseIcon />}
      />
      <DayWrapper>
        {Day.map((item: IDay) => {
          return (
            <DayItem key={item.value}>
              <CheckboxText
                name={`timeline[${index}].weekday`}
                register={register}
                id={`${item.label}${index}`}
                value={item.value}
                label={item.label}
                checked={checkedWeekdaySelected(item.value, index)}
              />
            </DayItem>
          );
        })}
      </DayWrapper>

      <TimeWrapper>
        <TimeItem>
          <label>Heure de début</label>
          <input
            name={`timeline[${index}].started_at`}
            type="hidden"
            {...register(`timeline[${index}].started_at`)}
          />
          <div>
            <DatePicker
              selected={startDate}
              onChange={(date) => setValueStartDate(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              timeFormat="HH:mm"
              dateFormat="HH:mm"
            />
          </div>
        </TimeItem>
        <TimeItem>
          <label>Heure de fin</label>
          <input
            name={`timeline[${index}].closed_at`}
            type="hidden"
            {...register(`timeline[${index}].closed_at`)}
          />
          <div>
            <DatePicker
              selected={endDate}
              onChange={(date) => setValueEndDate(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              timeFormat="HH:mm"
              dateFormat="HH:mm"
            />
          </div>
        </TimeItem>
      </TimeWrapper>
    </Card>
  );
};

export default Timeline;
