import React from 'react';
import Table from 'components/ui-kits/Table';
import CardInvoicesList from './CardInvoicesList';
import { InvoicesTableWrapper, NotDataYet } from './InvoicesTable.style';
import { DownloadIcon } from 'components/ui-kits/Icon';
import Loader from 'components/Loader';
import { useMutation } from 'react-query';
import { downLoadFileNameInvoices, downLoadPdfFile } from 'utils';
import { invoicesDownloadPDFV3 } from 'services/invoicesService';

interface Iprops {
  onData: any;
}

const headerTable = [
  { label: 'Numéro de Facture' },
  { label: 'Type' },
  { label: 'Date' },
  { label: 'Télécharger le PDF' }
];

const notDataText = "Vous n'avez pas encore de commandes avec factures.";

const InvoicesTable = ({ onData }: Iprops) => {
  const listInvoices = onData?.invoices;
  const { isLoading, mutate } = useMutation(invoicesDownloadPDFV3, {
    onSuccess: (blob: any, data: any) => {
      downLoadPdfFile(
        blob,
        downLoadFileNameInvoices(data?.invoiceType, data?.id)
      );
    }
  });

  const handleDownloadPDF = (e: any) => {
    const invoiceType = getInvoiceType(e.getAttribute('data-type')) || '';
    const id = e.getAttribute('data-id') || '';
    mutate({ invoiceType, id });
  };

  function getInvoiceType(type: string) {
    switch (type) {
      case `App\\Entity\\Invoice\\CustomerInvoice`:
        return `customer_invoice`;
      case `App\\Entity\\Invoice\\MonthlyInvoice`:
        return `merchant_subscription_invoice`;
      case `App\\Entity\\Invoice\\WeeklyInvoice`:
        return `merchant_operation_invoice`;
      default:
        break;
    }
  }

  return (
    <InvoicesTableWrapper>
      <Table header={headerTable}>
        {listInvoices && listInvoices?.length > 0 ? (
          listInvoices?.map((invoice: any) => {
            const renderTypeString = (type: string) => {
              switch (type) {
                case `App\\Entity\\Invoice\\CustomerInvoice`:
                  return `Commerçant pour le client`;
                case `App\\Entity\\Invoice\\MonthlyInvoice`:
                  return `Abonnement Achil`;
                case `App\\Entity\\Invoice\\WeeklyInvoice`:
                  return `Frais de service Achil`;
                default:
                  break;
              }
            };
            return (
              <CardInvoicesList
                key={invoice?.id}
                id={invoice?.id}
                invoicesNumber={invoice?.number}
                type={renderTypeString(invoice?.type)}
                createdAt={invoice?.createdAt}
                dateOfInvoice={invoice?.dateOfInvoice}
                downloadPdf={
                  <DownloadIcon
                    data-id={invoice?.id}
                    data-type={invoice?.type}
                    onClick={(e: any) => {
                      handleDownloadPDF(e.currentTarget);
                    }}
                  />
                }
              />
            );
          })
        ) : (
          <tr>
            <NotDataYet colSpan={4}>{notDataText}</NotDataYet>
          </tr>
        )}
      </Table>
      {isLoading && <Loader />}
    </InvoicesTableWrapper>
  );
};

export default InvoicesTable;
