import React from 'react';

function HomeIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="home"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}>
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1966"
            data-name="Rectangle 1966"
            width="16"
            height="15.651"
            fill="#8d9598"
          />
        </clipPath>
      </defs>
      <circle
        id="Ellipse_370"
        data-name="Ellipse 370"
        cx="12"
        cy="12"
        r="12"
        fill="none"
      />
      <g id="A-logo" transform="translate(4 3.999)">
        <g id="Group_2173" data-name="Group 2173" clipPath="url(#clip-path)">
          <path
            id="Path_668"
            data-name="Path 668"
            d="M139.777,15.246h0a5.233,5.233,0,0,1-6.841-2.82L127.929.4a5.233,5.233,0,0,1,6.841,2.82Z"
            transform="translate(-123.777 0)"
            fill="#8d9598"
          />
          <path
            id="Path_669"
            data-name="Path 669"
            d="M5.936,284.122c-.279-.665-.5-1.35-.724-2.035a2.665,2.665,0,0,0-5.095,1.567,3.074,3.074,0,0,0,.123.328,2.611,2.611,0,0,0,.4.639,5.231,5.231,0,0,0,6.237,1.736Z"
            transform="translate(0 -271.111)"
            fill="#8d9598"
          />
        </g>
      </g>
    </svg>
  );
}

export default HomeIcon;
