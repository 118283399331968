import React from 'react';
import Table from 'components/ui-kits/Table';
import { Catalog, ListCatalogRender } from 'types/Catalog';
import CatalogItemOfList from 'components/CatalogsLayout/CatalogItemOfList';
import { NotDataYet } from 'styles/common';
import { DONT_DATA_MESSAGE } from 'const/Message';

const headerTable = [
  { label: '', width: '7%' },
  { label: 'Nom du catalogue' },
  { label: 'Disponibilités du catalague' },
  { label: 'Nombre de catégorie(s)' },
  { label: 'Nombre de produit(s)' },
  { label: '' }
];

interface IProps {
  listCatalog: ListCatalogRender[];
  listCatalogOriginal: Catalog[];
}

const CatalogsList = ({ listCatalog, listCatalogOriginal }: IProps) => {
  return (
    <Table header={headerTable}>
      {listCatalog && listCatalog.length > 0 ? (
        <>
          {listCatalog.map((item: ListCatalogRender, index: number) => {
            return (
              <CatalogItemOfList
                key={index}
                catalogItem={item}
                catalogOriginalItem={listCatalogOriginal[index]}
              />
            );
          })}
        </>
      ) : (
        <tr>
          <NotDataYet colSpan={6}>{DONT_DATA_MESSAGE('catalogue')}</NotDataYet>
        </tr>
      )}
    </Table>
  );
};

export default CatalogsList;
