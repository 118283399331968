import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ListLayout from 'components/ListLayout';
import DateRange from '../Revenues/DateRange';
import DropdownListFilter from './DropdownListFilter';
import styled from 'styled-components';
import InvoicesTable from './InvoicesTable';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import Loader from 'components/Loader';
import { RootState } from 'app/store';
import { invoicesApiV2 } from 'services/invoicesService';

const InvoicesWrapperDropdown = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Invoices = () => {
  const { filter, startDate, endDate } = useSelector(
    (state: RootState) => state.filterInvoice
  );

  const {
    isLoading: loadingData,
    data: dataInvoices,
    refetch
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQuery(
    'getListInvoices',
    ({ signal }) =>
      invoicesApiV2({
        filter: filter.value,
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
        signal
      }),
    { enabled: Boolean(filter && startDate && endDate) }
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    refetch();
    /* eslint-disable */
  }, [filter, startDate, endDate]);

  return (
    <ListLayout>
      <InvoicesWrapperDropdown>
        <DateRange haveSaveFilter={true} />
        <DropdownListFilter />
      </InvoicesWrapperDropdown>
      <InvoicesTable onData={dataInvoices} />
      {loadingData ? <Loader /> : null}
    </ListLayout>
  );
};

export default Invoices;
