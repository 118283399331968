import React from 'react';

function RestaurantTicketIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="16"
      viewBox="0 0 24 16"
      {...props}>
      <path
        id="Icon_awesome-ticket-alt"
        data-name="Icon awesome-ticket-alt"
        d="M5.333,8.5H18.667v8H5.333ZM22,12.5a2,2,0,0,0,2,2v4a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2v-4a2,2,0,1,0,0-4v-4a2,2,0,0,1,2-2H22a2,2,0,0,1,2,2v4A2,2,0,0,0,22,12.5ZM20,8.167a1,1,0,0,0-1-1H5a1,1,0,0,0-1,1v8.667a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1Z"
        transform="translate(0 -4.5)"
        fill="#676767"
      />
    </svg>
  );
}

export default RestaurantTicketIcon;
