import React from 'react';

function StopWatchIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5001rem"
      height="1.8462rem"
      viewBox="0 0 15.001 18.462"
      {...props}>
      <path
        id="Icon_awesome-stopwatch"
        data-name="Icon awesome-stopwatch"
        d="M15.5,10.962a7.5,7.5,0,1,1-8.654-7.41V2.308H5.837A.434.434,0,0,1,5.4,1.875V.433A.434.434,0,0,1,5.837,0h4.327A.434.434,0,0,1,10.6.433V1.875a.434.434,0,0,1-.433.433H9.154V3.552a7.455,7.455,0,0,1,3.6,1.608l.992-.992a.433.433,0,0,1,.613,0l1.02,1.02a.433.433,0,0,1,0,.613l-1.06,1.06-.022.022A7.425,7.425,0,0,1,15.5,10.962Zm-6.346,1.3V6.8a.434.434,0,0,0-.433-.433H7.279a.434.434,0,0,0-.433.433V12.26a.434.434,0,0,0,.433.433H8.721A.434.434,0,0,0,9.154,12.26Z"
        transform="translate(-0.5)"
        fill="#878c97"
      />
    </svg>
  );
}

export default StopWatchIcon;
