import styled from 'styled-components';
import { COLORS, FONT_SIZE, FONT_WEIGHT } from 'styles/variables';

export const InvoicesTableWrapper = styled.div`
  margin: 3rem 0;

  table {
    position: relative;

    thead {
      tr {
        th:last-child {
          text-align: center;
        }
      }
    }
  }
`;
export const NotDataYet = styled.td`
  text-align: center;
  color: ${COLORS.GREY_1};
  font-size: ${FONT_SIZE.S};
  font-weight: ${FONT_WEIGHT.LIGHT};
  padding: 4rem 0;
`;
