import styled from 'styled-components';
import { COLORS } from 'styles/variables';

export const SummaryTableWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3.4rem;
  border-radius: 0.5rem;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GREY_4};
`;

export const SummaryTableBlockLeft = styled.div`
  margin: 0;
`;

export const SummaryTableBlockRight = styled.div`
  border-left: 1px solid ${COLORS.GREY_4};
  width: 40%;
`;

export const BlockBorder = styled.div`
  border-bottom: 1px solid ${COLORS.GREY_4};
  margin: 1.2rem 0 2.5rem;
`;

export const RevenusNetWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 3rem 2rem 10rem;
  color: #8d9598;
  font-size: 1.8rem;
  font-weight: 500;
  span {
    &:last-child {
      color: #ff6f61;
    }
  }
`;
