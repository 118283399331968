import { DropdownList } from 'components/ui-kits/Form/Dropdown';
import { GridItem } from 'components/ui-kits/Grid/index';
import styled from 'styled-components';
import {
  COLORS,
  FONT_SIZE,
  FONT_WEIGHT,
  HEADER_HEIGHT
} from 'styles/variables';

export const CreateLeftWrapper = styled(GridItem)`
  background-color: #fafafa;
  position: relative;
`;

export const BlockItemWrapper = styled.div`
  width: 100%;
  max-width: 85%;
  margin: 5rem 0;
  padding-bottom: 6rem;
  border-bottom: 1px solid ${COLORS.GREY_7};
  position: relative;
  .block-title {
    margin-top: 4.5rem;
  }
  .search-item-wrapper {
    position: absolute;
    top: 5rem;
    width: 65%;
    z-index: 1;
  }

  &:last-child {
    border-bottom: none;
  }

  label {
    display: block;
  }
`;
export const BlockTitle = styled.h3`
  width: 100%;
  font-size: ${FONT_SIZE.XL};
  font-weight: ${FONT_WEIGHT.BOLD};
  letter-spacing: 0.64px;
  color: ${COLORS.BLACK_2};
  margin-bottom: 6rem;
`;

export const BlockDescription = styled.h4`
  width: 100%;
  font-size: ${FONT_SIZE.XS};
  font-weight: ${FONT_WEIGHT.REGULAR};
  letter-spacing: 0.36px;
  color: ${COLORS.GREY_7};
  margin-top: 1.2rem;

  div:first-child {
    margin-bottom: 1rem;
  }
`;

export const OptionItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(100% + ${HEADER_HEIGHT});
    background: #c8c8d0;
    top: -${HEADER_HEIGHT};
    left: 0;
    z-index: 11;
  }
`;

export const OptionMandatoryWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 2.5rem 0;
  .label-option {
    margin-left: 3rem;
  }
  input {
    &:checked + label {
      span {
        background-color: ${COLORS.RED};
        transform: translateX(0);
      }
    }
    & + label {
      height: auto;
      span {
        background-color: ${COLORS.GREEN_1};
        transform: translateX(100%);
      }
    }
  }
`;

export const OptionNumberWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 2.5rem 0;
  .number-input {
    margin: 0 2rem;
  }
`;

export const OptionNumberStyle = {
  control: (styles: any) => ({
    ...styles,
    fontSize: '1.8rem',
    padding: '0 2.7rem',
    fontWeight: '500'
  }),
  option: (styles: any) => ({
    ...styles,
    fontSize: '1.8rem',
    fontWeight: '400'
  })
};

export const DropDownRule = styled(DropdownList)`
  &.rounded {
    width: 30rem;
    margin-right: 3rem;
  }
`;

export const TextInputWrapper = styled.div`
  margin-top: 3.1rem;
  max-width: 45rem;
  input {
    font-size: 1.4rem;
    height: 2.8rem;
  }
`;

export const CheckboxMargin = styled.div`
  margin-top: 1.5rem;
`;

export const MessageError = styled.div`
  font-size: 1.2rem;
  color: ${COLORS.RED_3};
`;

export const MessageErrorDisplayName = styled.div`
  margin: 0.5rem 0;
`;
