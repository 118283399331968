import styled from 'styled-components';
import { COLORS } from 'styles/variables';

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  h2 {
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
`;
export const TitleLarge = styled.h2`
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 2.9rem;
  color: ${COLORS.BLACK_2};
  letter-spacing: 0.48px;
  margin-bottom: 2.2rem;
`;
export const CollapseIcon = styled.div`
  transform: rotate(0);
  transition: all 0.4s;
  cursor: pointer;
  &.active {
    transform: rotate(180deg);
    transition: all 0.4s;
  }
`;
export const CollapseWrapper = styled.div`
  margin-top: 2.2rem;
  border-bottom: 1px solid ${COLORS.GREY_4};
  button {
    text-transform: capitalize;
    letter-spacing: 0.36px;
    &:not(:last-child) {
      padding: 0;
      min-width: auto;
      margin-right: 5.8rem;
    }
  }
  &:last-child {
    border-bottom: none;
  }
  ${BtnWrapper} {
    align-items: flex-end;
  }
  ${TitleWrapper} {
    justify-content: space-between;
  }
  svg {
    fill: ${COLORS.BLACK_2};
  }
`;

export const SubTitle = styled.div`
  font-size: 1.8rem;
  color: ${COLORS.GREY_7};
  line-height: 2.2rem;
  letter-spacing: 0.36px;
  margin: 1.2rem 0 4.4rem 0;
`;
