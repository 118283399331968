import styled from 'styled-components';
import { COLORS, GRID_COLUMN_WIDTH, GUTTER } from 'styles/variables';

export const FormDeliverySettings = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 2.8rem;
  .conditions {
    padding-right: 2rem;
    width: calc(
      (${GRID_COLUMN_WIDTH.DESKTOP_L} * 3) + ${GUTTER.DESKTOP_L * 2}px
    );

    span {
      color: ${COLORS.GREY_9};
    }
  }
  .form-block__title {
    margin-bottom: 2.8rem;
  }

  .delivery_schedule {
    flex-grow: 1;
  }

  input ~ span {
    font-size: 1.5rem;
    opacity: 0.5;
    color: ${COLORS.BLACK_2};
  }

  input:checked ~ span {
    font-weight: bold;
    opacity: 1;
  }

  .style-price {
    font-size: 2.4rem;
    color: ${COLORS.GREY_9};
    margin-left: 1rem;
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  .isMinimum-wrapper {
    margin-right: 2.8rem;

    .label-name {
      max-width: 22rem;
    }
  }
  .custom {
    input {
      min-width: 12.6rem;
      width: 12.6rem;
      padding-right: 2.4rem;
    }
    div {
      right: 1.7rem;
    }
  }
`;

export const MenuHourWrapper = styled.div`
  margin: 2.8rem 0;
`;

export const CustomizeZoneWrapper = styled.div`
  display: flex;
`;

export const DataTimeline = styled.div`
  margin-left: 1.5rem;
  font-size: 1.4rem;
`;

export const TimeStyle = styled.div`
  display: flex;
  span {
    margin: 0 0.5rem;
    font-weight: 300;
    font-size: 1.4rem;
  }
`;
