import styled, { css } from 'styled-components';
import { Grid, GridItem } from 'components/ui-kits/Grid/index';
import { COLORS, HEADER_HEIGHT, MEDIA_BREAKPOINT } from 'styles/variables';
import { Margin } from 'styles/createEdit-page';

export const SectionNameWrapper = styled(Grid)`
  position: sticky;
  top: calc(${HEADER_HEIGHT} * 2);
  left: 0;
  padding: ${Margin.sectionName};
  background-color: ${COLORS.WHITE_1};
  z-index: 9;
`;

export const ContainerInput = styled(GridItem)`
  margin-right: ${Margin.containerInput};
`;

export const GridWrapper = styled(Grid)`
  padding-left: 2rem;
`;

export const SvgWrapper = styled.div`
  margin: 0 3.3rem 0 1.5rem;
  cursor: pointer;
  svg {
    fill: ${COLORS.BLACK_2};
  }
`;

export const Actions = styled.div`
  margin: 3.5rem 0;
`;

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const BtnMargin = styled.div`
  margin-right: 2.8rem;
  padding: 0;
`;

export const TextInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  input::placeholder {
    color: ${COLORS.GREY_1};
  }
`;

export const ItemWrapper = styled.div`
  width: 100%;
  margin-top: 6rem;
`;

export const Regulation = styled.div`
  margin-bottom: 4rem;
  p {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: ${COLORS.BLACK_2};
  }
`;

export const TitleLarge = styled.h2`
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 2.9rem;
  color: ${COLORS.BLACK_2};
  letter-spacing: 0.48px;
  margin-bottom: 1.2rem;
`;

export const TitleSmall = styled.h2`
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.2rem;
  color: ${COLORS.BLACK_2};
  letter-spacing: 0.36px;
  margin-bottom: 1.2rem;
`;

export const ItemInfo = styled.div`
  margin-top: 2.2rem;
  padding-bottom: 6rem;
  border-bottom: 1px solid ${COLORS.GREY_4};
  label {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.9rem;
    letter-spacing: 0.32px;
    color: ${COLORS.BLACK_2};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  h2 {
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
  span {
    font-size: 1.6rem;
    letter-spacing: 0.26px;
  }
`;

export const CategoriesWrapper = styled.div`
  margin-bottom: 3.6rem;
  border-bottom: 1px solid #dcdcdc;
`;

export const GroupOptionsWrapper = CategoriesWrapper;

export const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4rem;

  .tag {
    margin: 0 1.5rem 1.5rem 0;
  }

  .search-go-wrapper {
    z-index: 1;

    &.loading {
      .group-option__menu {
        filter: blur(1px);
      }
    }
  }
`;

export const SearchWidth = styled.div`
  width: 50%;
`;

export const SearchResult = styled.div`
  width: 50%;
  padding: 0 1.5rem;
`;

export const SearchWrapperVariant = styled(SearchWrapper)<{
  variant: 'verticalLayout' | 'horizontalLayout';
}>`
  ${(props) =>
    props.variant === 'verticalLayout' &&
    `
        width: 100%;
        gap: 1.6rem;
        display: flex;
        flex-direction: column;
        padding-bottom: 0;

        .search-go-wrapper {
          width: 100%;
          position: relative;
        }

       ${SearchWidth} {
         width: 100%
       }

       ${SearchResult} {
         width: 100%;
         padding: 0;
       }
  `}
`;

export const PriceVatWrapper = styled.div`
  display: flex;
  width: 100%;
  font-size: 1.6rem;
  h2 {
    margin-bottom: 0;
  }
  ${TitleWrapper} {
    margin-right: 2rem;
  }
`;

export const CollapseIcon = styled.div`
  transform: rotate(0);
  transition: all 0.4s;
  cursor: pointer;
  &.active {
    transform: rotate(180deg);
    transition: all 0.4s;
  }
`;

export const CustomizeItem = styled.div`
  margin-top: 2.2rem;
  padding-bottom: 6rem;
  border-bottom: 1px solid ${COLORS.GREY_4};
  button {
    text-transform: capitalize;
    letter-spacing: 0.36px;
    &:not(:last-child) {
      padding: 0;
      min-width: auto;
      margin-right: 5.8rem;
    }
  }
  ${BtnWrapper} {
    align-items: flex-end;
  }
  ${TitleWrapper} {
    justify-content: space-between;
  }
  svg {
    fill: ${COLORS.BLACK_2};
  }
`;
export const ItemValues = styled.div`
  margin-top: 2.2rem;
  padding-bottom: 6rem;
  ${TitleWrapper} {
    justify-content: space-between;
  }
  svg {
    fill: ${COLORS.BLACK_2};
  }
`;

export const SellAsOptionWrapper = styled.div<{ type?: 'option' | 'product' }>`
  display: flex;
  align-items: center;
  gap: 2.8rem;

  ${({ type }) =>
    type === 'option'
      ? css`
          margin-bottom: 6rem;
        `
      : css`
          margin: 3.5rem 0;
        `}
`;

export const ShowInfoHover = styled.div`
  position: absolute;
  top: 50%;
  left: calc(100% + 0.8rem);
  transform: translateY(-50%);
  min-width: 38.5rem;
  background-color: ${COLORS.BLACK_2};
  padding: 1.4rem 1.6rem;
  border-radius: 0.7rem;
  span:first-child {
    color: ${COLORS.WHITE_3};
  }
  span {
    color: ${COLORS.GREY_4};
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
  p {
    color: ${COLORS.WHITE_3};
    &:not(:last-child) {
      margin-bottom: 0.4rem;
    }
  }
  visibility: hidden;
  opacity: 0;
  transition: all 0.4;
  z-index: 5;
`;

export const InfoIconWrapper = styled.div`
  position: relative;
  margin-left: 0.8rem;
  &:hover > ${ShowInfoHover} {
    visibility: visible;
    opacity: 1;
    transition: all 0.4;
  }
`;

export const RadioWrapper = styled.div`
  label {
    margin-right: 8.8rem;
  }
`;

export const CheckboxWrapper = styled.div`
  width: 100%;
  .dietary-item,
  .allergen-item {
    width: calc(100% / 5);
    margin-bottom: 1.8rem;
    align-items: center;
    min-height: 3.5rem;

    @media ${MEDIA_BREAKPOINT.DESKTOP_S} {
      width: calc(100% / 4);
      margin-right: 1rem;
    }

    label {
      display: flex;
    }
  }
  .dietary-item {
    &:nth-child(3) {
      @media ${MEDIA_BREAKPOINT.DESKTOP_L} {
        width: calc(100% / 2);
      }
    }
  }
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 4.2rem;
  h2 {
    margin-bottom: 0;
    line-height: inherit;
    width: 16.9rem;
  }
  & > div {
    display: flex;
    flex-wrap: wrap;
  }
  .option-right-wrapper {
    width: calc(100% - 16.9rem);
  }
  .option-item {
    width: calc(100% / 5);
  }
  .alcoholic-item {
    margin-right: 0;
  }
  .checkbox-icon,
  .radio-icon {
    margin-right: 1rem;
  }
`;

export const DescriptionWrapper = styled.div`
  margin-bottom: 6rem;
  ${TitleWrapper} {
    justify-content: space-between;
    margin-bottom: 2.4rem;
  }
  textarea {
    width: 100%;
    height: 12.5rem;
    color: ${COLORS.BLACK_2};
    background-color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.GREY_4};
    border-radius: 0.7rem;
    resize: none;
    padding: 2.1rem 1.8rem 2.1rem 1.8rem;
    opacity: 1;
  }
  label {
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: ${COLORS.BLACK_2};
    letter-spacing: 0.36px;
    text-transform: capitalize;
    margin-bottom: 1.2rem;
  }
  p {
    font-size: 1.4rem;
  }
`;

export const BlockRelative = styled.div`
  position: relative;
`;

export const ActionWrapper = styled(GridItem)`
  display: flex;
  align-items: center;
`;

export const OptionItemWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100vh - ${HEADER_HEIGHT} * 2 - 1px);
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(100% + ${HEADER_HEIGHT});
    background: #c8c8d0;
    top: -${HEADER_HEIGHT};
    left: 0;
    z-index: 11;
  }
`;

export const LabelCatalogsRight = styled.div`
  position: sticky;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  text-align: center;
  font-size: 1.6rem;
  color: ${COLORS.BLACK_2};
`;

export const UploadImageStyle = styled.div`
  margin-bottom: 6rem;
  display: inline-block;
  label {
    display: inline-block;
  }
`;

export const ReferenceWrapper = styled.div`
  margin-bottom: 6rem;
  display: flex;
  align-items: center;

  h2 {
    margin-bottom: 0;
    margin-right: 4rem;
  }

  input {
    font-size: 1.6rem;
    width: 50%;
  }

  input:placeholder-shown {
    text-overflow: ellipsis;
  }
`;

export const LoadMore = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 22rem;
  bottom: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 15px;
  font-size: 1.4rem;
  z-index: 2;
`;

export const PriceWithoutVatWrapper = styled.div`
  &.promotion-wrapper {
    .number-input__wrapper {
      width: 14.5rem;
    }
  }
`;

export const SellProductWithUnits = styled.div`
  display: flex;
  align-items: center;
  margin: 3.5rem 0;
  gap: 2.8rem;
`;

export const TimelineWrapper = styled.div`
  margin: 3.5rem 0;
  .dateAndTime {
    &__wrapper {
      display: flex;
      width: 100%;
      & > div {
        width: 30%;
        margin-right: 2rem;
      }
      .react-datepicker__day--selected {
        background-color: #8d9598;
      }
      .react-datepicker__day--today {
        background: transparent;
        &:hover {
          color: #676767;
          background-color: #8d9598;
          border-radius: 20px;
        }
      }
      .react-datepicker__day--in-selecting-range {
        background-color: #e9eaec;
        &:hover {
          background-color: #8d9598;
        }
      }
    }
    &__title {
      font-size: 1.4rem;
      margin: 1rem 0 0.7rem 0;
    }
  }
`;

export const BlockItemWrapper = styled.div`
  display: flex;
  align-items: center;
  &:first-child {
    width: 36rem;
    justify-content: space-between;
  }
  &:nth-child(2) {
    width: 30rem;
    justify-content: space-between;
    padding: 0 1rem;
    margin: 0 2rem;
  }
  &.half-width {
    width: 50%;
    justify-content: space-between;
  }
  ${TitleWrapper} {
    margin-right: 2rem;
  }
  .unit-type {
    .selectSm {
      min-width: 17rem;
      max-width: 17rem;
    }
  }
`;

export const ProductWithUnitsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 0;
`;
