import styled from 'styled-components';
import { COLORS, FONT_SIZE } from 'styles/variables';

export const TableOperationCostWrapper = styled.div`
  padding: 0 4.6rem;
  background-color: ${COLORS.WHITE};
  > div {
    border: none;
  }

  .table-wrapper {
    margin-bottom: 4rem;
  }

  thead {
    tr {
      th {
        border-bottom: 1px solid ${COLORS.GREY_6} !important;
        padding: 0 0 0.5rem !important;
        color: ${COLORS.GREY_6};
        font-size: ${FONT_SIZE.M};
        vertical-align: top;
        text-align: center !important;

        &:first-of-type {
          text-align: left !important;
        }

        &:last-child {
          text-align: right !important;
        }
      }
    }
  }
  tbody {
    td {
      padding: 1.5rem 0;
      color: ${COLORS.GREY_6};
      font-size: ${FONT_SIZE.M};
      text-align: center;

      &:first-of-type {
        display: flex;
        align-items: center;
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }

      & > span:first-child {
        margin-right: 0.5rem;
        font-size: ${FONT_SIZE.XS};
        width: 7rem;
        display: inline-block;
      }
    }
  }

  .total {
    margin-top: 5rem;

    &-item {
      margin-bottom: 4rem;
      max-width: 40%;
      margin-left: auto;

      & > div {
        display: flex;
        justify-content: space-between;
        margin: 1.5rem 0;
      }
    }

    span {
      color: ${COLORS.GREY_6};
      font-size: ${FONT_SIZE.M};
    }

    &-all {
      font-weight: bold;
    }
  }

  .footer {
    padding: 40.7rem 0 25.2rem;
    text-align: center;
    font-size: ${FONT_SIZE.M};

    .footerContent {
      padding: 0.6rem 0;
      color: ${COLORS.GREY_10};
    }
  }
`;
