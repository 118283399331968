import React from 'react';
import { useForm } from 'react-hook-form';
import Table from 'components/ui-kits/Table';
import Button from 'components/ui-kits/Button';
import { Search } from 'components/ui-kits/Form/Dropdown';
import Modal from 'components/Modal';
import styled from 'styled-components';
import { COLORS } from 'styles/variables';
import { Checkbox } from 'components/ui-kits/Form/Checkbox';
import { TableCell } from 'components/ui-kits/Table/Table.style';
import { ScrollbarCustom } from 'styles/common';

const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.2rem 4.6rem;
  box-sizing: border-box;
  border-bottom: 1px solid ${COLORS.GREY_4};
`;

const Title = styled.div`
  color: ${COLORS.BLACK_2};
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.9rem;
`;

const Filter = styled.div`
  display: flex;
  padding: 2.6rem 4.6rem;
`;

const SearchStyle = styled.div`
  min-width: 41.5rem;
  height: 3.8rem;
  margin-right: 3.2rem;
`;

const ButtonStyle = styled.div`
  button {
    padding: 0 1.6rem;
    height: 3.8rem;
  }
`;

const HeaderData = [
  { label: '', width: '7%' },
  { label: 'Nom' },
  { label: 'Produits' },
  { label: 'Catalogues' }
];

interface IListCategories {
  id: string;
  name: string;
  catalogs: string[];
  code: string;
  numberOfProducts: number;
}

interface IListCategorySelected {
  taxon: string;
  excludedProductIds: number[];
}

interface IProps {
  onClose: (status: boolean) => void;
  listCategories: IListCategories[];
  onSelectedCategories: (data: { addcategories: string[] }) => void;
  listCategorySelected: IListCategorySelected[];
  openPanelCreateCategory?: () => void;
}

const ModalAddCategories = ({
  onClose,
  listCategories,
  onSelectedCategories,
  listCategorySelected,
  openPanelCreateCategory
}: IProps) => {
  const { handleSubmit, register } = useForm();
  const onChangeSearch = (data: any) => {
    console.log(data);
  };
  const categoriesExcludeCategoryEmpty = listCategories.filter(
    (item) => !!item.name
  );
  const onSave = (data: any) => {
    data.addcategories
      ? onSelectedCategories(data)
      : onSelectedCategories({ addcategories: [] });
  };

  const checkedTaxonChoose = (id: string) => {
    const checkedItem = listCategorySelected.filter(
      (val: IListCategorySelected) => val.taxon === id
    );
    return checkedItem.length > 0 ? true : false;
  };

  const renderResult = (data: IListCategories[]) => {
    if (data?.length > 0) {
      return data.map((item) => (
        <tr key={item.id}>
          <TableCell>
            <Checkbox
              name="addcategories"
              value={item.id}
              register={register}
              defaultChecked={checkedTaxonChoose(item.id)}
            />
          </TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell fontSize="S" fontWeight="BOLD">
            {item.numberOfProducts} Produits
          </TableCell>
          <TableCell fontSize="M" fontWeight="REGULAR">
            {item.catalogs.join(', ')}
          </TableCell>
        </tr>
      ));
    } else {
      return null;
    }
  };

  return (
    <div>
      <Modal close={() => onClose(false)}>
        <form onSubmit={handleSubmit(onSave)}>
          <HeaderTable>
            <Title>Ajout de catégorie(s)</Title>
            <Button
              type="submit"
              isFluid={false}
              variant="primary"
              label="Enregistrer"
              fontWeight="BOLD"
              letterSpacing="S"
            />
          </HeaderTable>
          <Filter>
            <SearchStyle>
              <Search change={onChangeSearch} placeholder="Rechercher" />
            </SearchStyle>
            <ButtonStyle>
              <Button
                type="button"
                label="+ CRÉER UNE CATÉGORIE"
                variant="cancel"
                fontWeight="BOLD"
                onClick={openPanelCreateCategory}
              />
            </ButtonStyle>
          </Filter>
          <div>
            <Table header={HeaderData} borderHead={false} hasScroll={true}>
              <ScrollbarCustom>
                {renderResult(categoriesExcludeCategoryEmpty)}
              </ScrollbarCustom>
            </Table>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ModalAddCategories;
